import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import axios from 'axios';

import chestIcon from '../../assets/images/upgrade/chest.png';
import mini_energy from '../../assets/images/upgrade/icon_mini_energy.png';
import mini_pickaxe from '../../assets/images/upgrade/icon_mini_pickaxe.png';
import tickIcon from '../../assets/images/upgrade/Tick.png';
import jailIcon from '../../assets/images/upgrade/prison.png';
import gnomIcon from '../../assets/images/upgrade/gnom.png';
import keyIcon from '../../assets/images/upgrade/key.png';
import lockMissIcon from '../../assets/images/upgrade/lockMiss.png';
import plusDolar from '../../assets/images/upgrade/plusDolar.png';
import minusDolar from '../../assets/images/upgrade/minusDolar.png';
import vremenno from '../../assets/images/upgrade/_.png'
import UpgradeGameModal from "./UpgradeGameModal";
import UpgradeGameModalProgress from "./UpgradeGameModalProgress";
import '../../assets/styles/upgrade/UpgradeGameRender.css'
import { getBackgroundImage, getActualPickaxe, getActualDrink, useUpgrade } from '../../contexts/UpgradeContext';

import ramka from '../../assets/images/upgrade/ramka.png'
import lockLvl from '../../assets/images/upgrade/lockLvl.png'

import { useUser } from '../../contexts/UserContext';
import { API_URLS } from '../../config/config';

const ProgressItem = ({ task, stageId, style, onClick, idLvlUser, idStageMission, stage_drink, stage_instrument }) => {
    const { type, status, rewards, price, stage } = task;
    const reward = rewards && rewards.length > 0 ? rewards[0] : null;
    // const actualIdStage = task.
    


    // const getPickaxeIcon = (stage, stageId, stage_instrument) => {
    //     if (reward.id < '1000') {
    //         return require(`../../assets/images/upgrade/pickaxe/pickaxe_${user.rank.id}_${stage_instrument}.webp`);
    //     }
    //     else {
    //         return require(`../../assets/images/upgrade/pickaxe/pickaxe_${user.rank.id}_${reward.id}.webp`);
    //     }
    // };

    // const getEnergyIcon = (stage, stageId, stage_drink) => {
    //     if (reward.id < '1000') {
    //         return require(`../../assets/images/upgrade/energy/energy_${user.rank.id}_${stage_drink}.webp`);
    //     }
    //     else {
    //         return require(`../../assets/images/upgrade/energy/energy_${user.rank.id}_${reward.id}.webp`);
    //     }

    // };

    const isSmallSize = type === 'buy chest' &&
        (status === 'Claimed' || status === 'Not claimed') &&
        reward &&
        ['1', '7', '5', '6'].includes(reward.reward_type);

    const itemStyle = {
        ...style,
        width: isSmallSize ? '50px' : '83px',
        height: isSmallSize ? '50px' : '83px',
        padding: isSmallSize ? '0px' : '14px',
        borderRadius: isSmallSize ? '10px' : '15px'
    };

    const getIconZIndex = () => {
        if ((type === 'buy chest') && status === 'Claimed') {
            return 2;
        }
        if ((type === 'subscribe to channel' || type === 'invite friend') && status === 'In progress') {
            return 4;
        }
        if (status === 'In progress') return 1;
        if (status === 'Not claimed') return 2;
        if (status === 'Claimed') return 1;
        return 1; // Default z-index for other statuses
    };

    const getExpiredOverlayZIndex = () => {
        if ((type === 'subscribe to channel' || type === 'invite friend') && status === 'In progress') {
            return 3;
        }
        if (status === 'In progress') return 2;
        if (status === 'Claimed') return 2;
        if (status === 'Not claimed') return 3;
        if (status === 'Unavailable' || status === 'Expired') return 2;
        return 1; // Default z-index for other statuses
    };

    const renderContent = () => {
        let content;
        const iconZIndex = getIconZIndex();
        const expiredOverlayZIndex = getExpiredOverlayZIndex();


        if (type === 'buy chest') {
            if ((status === 'Claimed' || status === 'Not claimed') && reward) {
                switch (reward.reward_type) {
                    case '1':
                        content = (
                            <>
                                <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                                {/* <div className={`reward-gold ${reward.amount < 0 ? 'negative' : 'positive'}`} style={{ zIndex: iconZIndex, fontSize: '12px' }}>{reward.amount < 0 ? '' : '+'}{reward.amount}$</div> */}
                                <img className={`${reward.amount < 0 ? 'negativeD' : 'positiveD'}`} style={{ height: '35px' }} src={reward.amount < 0 ? minusDolar : plusDolar} />

                            </>
                        );
                        break;
                    case '7':
                        content = <img src={jailIcon} alt="Jail" className="reward-icon jail_icon" style={{ zIndex: iconZIndex }} />;
                        break;
                    case '5':
                        content = (
                            <>
                                <img src={keyIcon} alt="Key" className="reward-icon" style={{ zIndex: 3 }} />
                                <div className="expired-overlay" style={{ zIndex: 2 }}></div>
                            </>
                        )
                        break;
                    case '6':
                        content = (
                            <div className="gnom-reward">
                                <div className="expired-overlay-gnom expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                                <img src={gnomIcon} alt="Gnom" style={{ zIndex: iconZIndex }} className="reward-icon" />
                                <span className="gnom-amount" style={{ zIndex: iconZIndex + 1 }}>x{reward.amount}</span>
                            </div>
                        );
                        break;
                    default:
                        content = (
                            <>
                                <img src={chestIcon} alt="Chest" className="task-icon" style={{ zIndex: iconZIndex }} />;
                            </>
                        )
                }
            } else {
                content = (
                    <>
                        <img src={chestIcon} alt="Chest" style={{ zIndex: iconZIndex }} className="task-icon" />
                        {status === 'In progress' && price && (
                            <>
                                <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                                <div className="chest-price" style={{ zIndex: iconZIndex + 1 }}>{price}$</div>
                            </>
                        )}
                        {(status === 'Unavailable' || status === 'Expired') && (
                            <>
                                <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                            </>
                        )}
                    </>
                );
            }
        } else {
            switch (reward?.reward_type) {
                case '1': // GOLD
                    content = (
                        <>
                            <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                            <div className={`reward-gold ${reward.amount < 0 ? 'negative' : 'positive'}`} style={{ zIndex: iconZIndex }}>{reward.amount}</div>
                        </>
                    );
                    break;
                case '2': // buy multiclick
                    content = (
                        <div className='con_energy_icon'>
                            <div style={{ zIndex: expiredOverlayZIndex }} className="expired-overlay"></div>
                            {/* <img src={getActualPickaxe(reward?.id, stageId, stage_instrument)} alt="Pickaxe" className="task-icon" style={{ zIndex: iconZIndex }} /> */}
                            <img src={getActualPickaxe(idLvlUser, stage_instrument, reward?.id)} alt="Pickaxe" className="task-icon" style={{ zIndex: iconZIndex }} />
                            {(status === 'Claimed' || status === 'Not claimed') &&
                                <div className='info-block-miss-section'>
                                    <div className='info-block-miss-container'>
                                        <div className='con_plus'>
                                            <div className='con_plus_img_con'>
                                                <img className='pickaxe_mini' src={mini_pickaxe} />
                                            </div>
                                            <span>{`+${reward.amount}`}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    );
                    break;
                case '3': // add g token
                    content = (
                        <>
                            <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                            <div className="reward-token" style={{ zIndex: iconZIndex }}>+{reward.amount}</div>
                        </>
                    );
                    break;
                case '4': // buy energy balance
                    content = (
                        <div className='con_energy_icon'>
                            <div style={{ zIndex: expiredOverlayZIndex }} className="expired-overlay"></div>
                            {/* <img src={getActualDrink(reward?.id, stageId, stage_drink)} alt="Energy" style={{ zIndex: iconZIndex }} className="task-icon" /> */}
                            <img src={getActualDrink(idLvlUser, stage_drink, reward?.id)} alt="Energy" style={{ zIndex: iconZIndex }} className="task-icon" />
                            {(status === 'Claimed' || status === 'Not claimed' || (status === 'In progress' && task.price === 0)) &&
                                <div className='info-block-miss-section'>
                                    <div className='info-block-miss-container'>
                                        <div className='con_plus'>
                                            <img className='energy_mini' src={mini_energy} />
                                            <span>{`+${reward.amount}`}</span>

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    );
                    break;
                case '5': // key
                    content = <img src={keyIcon} alt="Key" className="reward-icon" style={{ zIndex: iconZIndex }} />;
                    break;
                case '6': // gnome
                    content = (
                        <div className="gnom-reward">
                            <div className="expired-overlay-gnom expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                            <img src={gnomIcon} alt="Gnom" style={{ zIndex: iconZIndex }} className="reward-icon" />
                            <span className="gnom-amount" style={{ zIndex: iconZIndex + 1 }}>x{reward.amount}</span>
                        </div>
                    );
                    break;
                case '7': // jaile
                    content = <img src={jailIcon} alt="Jail" className="reward-icon jail_icon" style={{ zIndex: iconZIndex }} />;
                    break;
                default:
                    content = (
                        <>
                            <div style={{ zIndex: expiredOverlayZIndex }} className="expired-overlay"></div>
                            <img src={vremenno} alt="vremenno" className="task-icon" style={{ zIndex: iconZIndex }} />
                        </>
                    );
            }
        }

        const showPrice = status === 'In progress' &&
            price !== null &&
            price !== undefined &&
            price !== 0 &&
            type !== 'subscribe to channel' &&
            type !== 'invite friend';

        return (
            <div className={`content-container ${status.toLowerCase()}`}>
                {content}
                {status === 'Claimed' && !isSmallSize && (
                    <img src={tickIcon} alt="Claimed" className="completed-icon" style={{ zIndex: iconZIndex + 1 }} />
                )}
                {(status === 'Unavailable' || status === 'Expired') && (
                    <img src={lockMissIcon} alt={status} className="expired-icon" style={{ zIndex: iconZIndex + 1 }} />
                )}
                {showPrice && <div className="chest-price" style={{ zIndex: iconZIndex + 1 }}>{price}$</div>}
            </div>
        );
    };

    return (
        <div
            className={`progress-item ${status.toLowerCase().replace(' ', '-')}`}
            style={itemStyle}
            onClick={() => onClick(task)}
        >
            <div className="item-content">{renderContent()}</div>
        </div>
    );
}



const UpgradeGameRenderFix = ({ gameData, rankData, userData, globalRefreshData, preloadedData }) => {
    useEffect(() => {
        console.log("-=-=-=-=gameData: ", gameData.data);
        console.log("-=-=-=-=rankData: ", rankData);
        console.log("-=-=-=-=userData: ", userData);
    }, [gameData]);

    const { firstStart, setFirstStart, scrollPosition: contextScrollPosition, setScrollPosition: setContextScrollPosition } = useUpgrade();

    const [gameModalProgressModal, setgameModalProgressModal] = useState(false);
    const [statusModalError, setStatusModalError] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [selectedGameDataId, setSelectedGameDataId] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [stageDrink, setStageDrink] = useState(null);
    const [stageInstrument, setStageInstrument] = useState(null);

    const [selectedStageId, setSelectedStageId] = useState(null);

    const scrollContainerRef = useRef(null);
    const scrollTimeoutRef = useRef(null);

    // If gameData.stages is not available yet, return an empty array to avoid errors
    const allTasks = gameData?.stages?.flatMap(stage => stage.tasks) || [];

    // Position calculation variables
    const xValues = allTasks.length > 0 ? allTasks.map(t => t.x) : [];
    const yValues = allTasks.length > 0 ? allTasks.map(t => t.y) : [];
    const minX = xValues.length > 0 ? Math.min(...xValues) : 0;
    const maxX = xValues.length > 0 ? Math.max(...xValues) : 0;
    const minY = yValues.length > 0 ? Math.min(...yValues) : 0;
    const maxY = yValues.length > 0 ? Math.max(...yValues) : 0;

    const cellSize = 83;
    const smallCellSize = 50;
    const gap = 40;
    const padding = 15;

    const isSmallSize = (task) =>
        task.type === 'buy chest' &&
        (task.status === 'Claimed' || task.status === 'Not claimed') &&
        task.rewards &&
        task.rewards.length > 0 &&
        ['1', '7', '5', '6'].includes(task.rewards[0].reward_type);

    const getPosition = (task, minX, maxY) => {
        const size = isSmallSize(task) ? smallCellSize : cellSize;
        const x = (task.x - minX) * (cellSize + gap) + padding;
        const y = (maxY - task.y) * (cellSize + gap);

        if (isSmallSize(task)) {
            return {
                left: x + (cellSize - size) / 2,
                top: y + (cellSize - size) / 2,
                width: size,
                height: size,
            };
        } else {
            return {
                left: x,
                top: y,
                width: cellSize,
                height: cellSize,
            };
        }
    };

    // Function to calculate the initial scroll position
    const calculateInitialScrollPosition = () => {
        if (gameData?.data?.stages && userData) {
            const currentStage = gameData.data.stages.find(stage => stage.id === userData.stage.id);
            if (currentStage && currentStage.tasks.length > 0) {
                // Calculate center coordinates
                const xValues = currentStage.tasks.map(task => task.x);
                const yValues = currentStage.tasks.map(task => task.y);
                const minX = Math.min(...xValues);
                const maxX = Math.max(...xValues);
                const minY = Math.min(...yValues);
                const maxY = Math.max(...yValues);

                const centerX = (minX + maxX) / 2;
                const centerY = (minY + maxY) / 2;

                // Fixed sizes and gaps
                const elementWidth = 851;
                const elementHeight = 329;
                const separatorHeight = 104;
                const gap = 20;

                // Calculate initial Y considering all preceding elements and separators
                let initialY = 0;
                for (let i = 0; i < gameData.data.stages.length; i++) {
                    const stage = gameData.data.stages[i];
                    if (stage.id === currentStage.id) {
                        break;
                    }
                    initialY += elementHeight + gap;
                    if (i < gameData.data.stages.length - 1) {
                        initialY += separatorHeight;
                    }
                }
                // Calculate initial X
                const initialX = Math.max(0, (elementWidth - window.innerWidth) / 2);

                // Set scroll position in context
                setContextScrollPosition({ x: initialX, y: initialY });
            }
        }
    };

    // Эффект для обработки начальной прокрутки и сохранения позиции прокрутки
    useEffect(() => {
        const container = scrollContainerRef.current;
        if (!container) return;
    
        if (firstStart) {
            calculateInitialScrollPosition();
            setFirstStart();
        } else if (contextScrollPosition) {
            container.scrollTo({
                left: contextScrollPosition.x,
                top: contextScrollPosition.y,
                behavior: 'auto',
            });
        }
    
        // Обработчик события прокрутки
        const handleScroll = () => {
            const newScrollPosition = {
                x: container.scrollLeft,
                y: container.scrollTop,
            };
            setContextScrollPosition(newScrollPosition);
    
            // Выводим текущие координаты в консоль при прокрутке
            console.log('Текущая позиция прокрутки:', newScrollPosition);
        };
    
        // Добавляем слушатель события прокрутки
        container.addEventListener('scroll', handleScroll);
    
        // Очищаем слушатель при размонтировании компонента
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [firstStart, contextScrollPosition, gameData, userData, setContextScrollPosition]);


    const renderLines = (tasks, minX, maxY) => {
        return tasks.flatMap((task) => {
            if (!task.routes) return [];
            return task.routes.map((route, index) => {
                const targetTask = tasks.find((t) => t.x === route.coord_x && t.y === route.coord_y);
                if (!targetTask) return null;

                const startPos = getPosition(task, minX, maxY);
                const endPos = getPosition(targetTask, minX, maxY);

                const dx = endPos.left - startPos.left;
                const dy = endPos.top - startPos.top;

                let left, top, width, height;
                let style = {
                    position: 'absolute',
                    backgroundColor: '#FFFFFF',
                    zIndex: 1,
                    boxShadow: 'inset 0px 0px 15px 0px rgba(0,0,0,0.3)',
                };

                if (Math.abs(dx) > Math.abs(dy)) {
                    if (dx > 0) {
                        left = startPos.left + startPos.width;
                        width = endPos.left - (startPos.left + startPos.width);
                    } else {
                        left = endPos.left + endPos.width;
                        width = startPos.left - (endPos.left + endPos.width);
                    }
                    top = startPos.top + startPos.height / 2 - 9;
                    height = 18;
                    style = {
                        ...style,
                        borderTop: '1px solid #FFC700',
                        borderBottom: '1px solid #FFC700',
                    };
                } else {
                    left = startPos.left + startPos.width / 2 - 9;
                    width = 18;
                    if (dy > 0) {
                        top = startPos.top + startPos.height;
                        height = endPos.top - (startPos.top + startPos.height);
                    } else {
                        top = endPos.top + endPos.height;
                        height = startPos.top - (endPos.top + endPos.height);
                    }
                    style = {
                        ...style,
                        borderLeft: '1px solid #FFC700',
                        borderRight: '1px solid #FFC700',
                    };
                }

                return (
                    <div
                        key={`${task.id}-${index}`}
                        style={{
                            ...style,
                            left,
                            top,
                            width,
                            height,
                        }}
                    />
                );
            });
        }).filter(Boolean);
    };

    const handleTaskClick = (task, gameDataId, stageId, drinkId, istrumentId) => {
        setSelectedTask(task);
        setStageDrink(drinkId);
        setStageInstrument(istrumentId);
        setSelectedGameDataId(gameDataId);
        setSelectedStageId(stageId);
        console.log('----------------------------stageId ', stageId);
    };

    const closeModal = () => {
        setSelectedTask(null);
        setStageDrink(null);
        setStageInstrument(null);
        setSelectedGameDataId(null);
        setSelectedStageId(null);
    };

    const renderInterStageLine = (currentStage, nextStage, minX, maxY) => {
        // Находим задание с координатами x0, y0 в текущем уровне
        const sourceTask = currentStage.tasks.find(task => task.x === 0 && task.y === 0);
        
        if (sourceTask && nextStage) {
            // Находим задание с тем же x в следующем уровне
            const targetTask = nextStage.tasks.find(task => task.x === 0);
            
            if (targetTask) {
                const startPos = getPosition(sourceTask, minX, maxY);
                const endPos = getPosition(targetTask, minX, maxY);
                const startY = startPos.top + startPos.height;
                const endY = endPos.top;
                const x = startPos.left + startPos.width / 2 - 9; // Центрируем линию

                return (
                    <div
                        key={`inter-stage-line-${sourceTask.id}-${targetTask.id}`}
                        style={{
                            position: 'absolute',
                            left: x,
                            top: startY,
                            width: '18px',
                            height: '350px',
                            backgroundColor: '#FFFFFF',
                            zIndex: 0,
                            borderLeft: '1px solid #FFC700',
                            borderRight: '1px solid #FFC700',
                            boxShadow: 'inset 0px 0px 15px 0px rgba(0,0,0,0.3)',
                        }}
                    />
                );
            }
        }
        return null;
    };

    if (!gameData || !gameData.data.stages || gameData.data.stages.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <>
            {/* Основной контейнер для секции миссий */}
            <div ref={scrollContainerRef} className={`upgrade-game-progress-mission-section ${rankData.rank.id === 1 ? 'one-stage-style' : ''}`}>
                <div  className="upgrade-game-progress-scroll-container">
                    {/* Проход по всем stageData из gameData.stages */}
                    {gameData.data?.stages?.map((stageData, stageIndex) => {    
                        // Деструктуризация stageData для получения данных задач и текущей стадии
                        const { tasks, id: stageId, stage_instrument: istrumentId, stage_drink: drinkId, relevant_id: idStageMission } = stageData;
                        
                        // Вычисление минимальных и максимальных координат x и y
                        const xValues = tasks.map((t) => t.x);
                        const yValues = tasks.map((t) => t.y);
                        const minX = Math.min(...xValues);
                        const maxX = Math.max(...xValues);
                        const minY = Math.min(...yValues);
                        const maxY = Math.max(...yValues);
                        const gridWidth = (maxX - minX + 1) * (cellSize + gap) - gap + padding * 2;
                        const gridHeight = (maxY - minY + 1) * (cellSize + gap) - gap;
    
                        // Следующая стадия, если она есть
                        const nextStage = gameData.data.stages[stageIndex + 1];
    
                        return (
                            <React.Fragment key={`stage-${stageData.id}`}>
                                {/* Разделитель между уровнями */}
                                {stageIndex > 0 && (
                                    <div className="upgrade-game-progress-separator">
                                        <img src={ramka} className="ramka-img" alt="Separator frame" />
                                        <img
                                            src={lockLvl}
                                            className="lockLvl-img"
                                            alt="Lock level"
                                            style={{ opacity: rankData.rank.id >= stageId ? 0 : 1 }}
                                        />
                                        <img src={ramka} className="ramka-img" alt="Separator frame" />
                                    </div>
                                )}
    
                                {/* Основной контейнер прогресса уровня */}
                                <div className="upgrade-game-progress-con">
                                    <div className="upgrade-game-progress" style={{ width: gridWidth, height: gridHeight }}>
                                        {/* Линии между заданиями */}
                                        <div className="lines-layer" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                                            {renderLines(tasks, minX, maxY)}
                                        </div>
    
                                        {/* Рендеринг каждого задания */}
                                        {tasks.map((task) => {
                                            const position = getPosition(task, minX, maxY);
                                            // console.log('---stageData---stageData---- : ', stageData);
                                            return (
                                                <ProgressItem
                                                    key={task.id}
                                                    task={task}
                                                    stageId={stageId}
                                                    drinkId={drinkId}
                                                    istrumentId={istrumentId}
                                                    idLvlUser={rankData.rank.id}
                                                    idStageMission={idStageMission}
                                                    stage_drink={stageData.stage_drink}
                                                    stage_instrument={stageData.stage_instrument}
                                                    style={{
                                                        left: position.left,
                                                        top: position.top,
                                                        width: position.width,
                                                        height: position.height,
                                                    }}
                                                    onClick={() => handleTaskClick(task, stageData.id, stageId, istrumentId, drinkId)}
                                                />
                                            );
                                        })}
    
                                        {/* Линия между стадиями, если нужно */}
                                        {userData.stage.id > stageId && renderInterStageLine(stageData, nextStage, minX, maxY)}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            
            {/* Модальное окно с информацией о задании, если оно выбрано */}
            {selectedTask && (
                <UpgradeGameModal
                    task={selectedTask}
                    onClose={closeModal}
                    globalRefreshData={globalRefreshData}
                    userStage={userData.stage}
                    preloadedData={preloadedData}
                    gameDataId={selectedGameDataId}
                    stageId={selectedStageId}
                    userlvl={rankData.rank.id}
                    stageInstrument={stageInstrument}
                    stageDrink={stageDrink}

                    rankDataId={rankData.rank.id}
                    
                />
            )}
        </>
    );
    
};

export default UpgradeGameRenderFix;