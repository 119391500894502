import React, { useEffect, memo } from 'react';
import { useUser } from '../contexts/UserContext';
import { useReward } from '../contexts/RewardContext';
import axios from 'axios';
import { API_URLS } from '../config/config';

const BackendInitializer = memo(({ userId, onError, onComplete }) => {
  const { updateUser } = useUser();
  const { setLastVisitTime } = useReward();

  useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(API_URLS.GET_USER_INFO, {
          params: { userId },
          headers: { 
            'Accept': 'application/json',
            'ngrok-skip-browser-warning': 'true'
          }
        });
        if (isMounted) {
          if (typeof response.data === 'object' && response.data.info) {
            const userInfo = { ...response.data.info, user_id: userId };
            updateUser(userInfo);
            
            // Update the last_visited time in RewardContext
            if (userInfo.last_visited) {
              setLastVisitTime(new Date(userInfo.last_visited));
            }
            
            onComplete();
            console.log("user info", userInfo);
          } else {
            throw new Error("Unexpected response structure from backend");
          }
        }
      } catch (error) {
        if (isMounted) {
          console.error('BackendInitializer: Error fetching user data:', error);
          onError();
        }
      }
    };

    fetchUserData();

    return () => {
      isMounted = false;
    };
  }, [userId, onError, onComplete, updateUser, setLastVisitTime]);

  return null;
});

export default BackendInitializer;