import React, { useState, useCallback, useMemo, useEffect } from 'react';
import axios from 'axios';
import TradeViewChart from 'react-crypto-chart';
import "../../assets/styles/games/game_core4.css";
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg"
import CryptoPocket from "../../assets/images/Crypto/pocket.webp";
import CryptoBinance from "../../assets/images/Crypto/binance.webp";
import CryptoBit from "../../assets/images/Crypto/bybit.webp";
import CryptoOkx from "../../assets/images/Crypto/okx.webp";
import { useUser } from "../../contexts/UserContext";
import {API_URLS} from "../../config/config";

function Four() {
    const [chartColor] = useState('#1E53E5');
    const [betAmount, setBetAmount] = useState('');
    const { user, updateUser } = useUser();
    const [localBalance, setLocalBalance] = useState(user.g_token);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [gameResult, setGameResult] = useState(null);
    const [btcUsdtPrice, setBtcUsdtPrice] = useState("0.00");
    const [timer, setTimer] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isRequestPending, setIsRequestPending] = useState(false);

    const updateLocalBalance = useCallback((newBalance) => {
        setLocalBalance(newBalance);
        updateUser({ ...user, g_token: newBalance });
    }, [user, updateUser]);

    const styles = useMemo(() => ({
        input: {
            width: '100%',
            padding: '5px',
            color: 'white',
            fontSize: '18px',
            backgroundColor: 'transparent',
            borderRadius: '4px',
            outline: 'none',
        },
        whiteText: {
            color: 'white',
        },
        resultText: {
            color: 'white',
            textAlign: 'center',
            marginTop: '10px',
        },
        timerText: {
            color: 'white',
            textAlign: 'center',
            fontSize: '24px',
            marginTop: '10px',
        }
    }), []);

    const handleBetChange = useCallback((e) => {
        setBetAmount(e.target.value);
    }, []);

    useEffect(() => {
        let interval;
        if (isTimerRunning) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 0.1);
            }, 100);
        } else if (!isTimerRunning && timer !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);

    const placeBet = useCallback(async (isUp) => {
        if (isRequestPending) {
            console.log('Запит вже в обробці. Будь ласка, дочекайтесь відповіді.');
            return;
        }

        setError(null);
        setGameResult(null);
        setIsLoading(true);
        setIsRequestPending(true);
        setTimer(0);
        setIsTimerRunning(true);

        const bet = parseFloat(betAmount);
        if (isNaN(bet) || bet <= 0) {
            setError("Будь ласка, введіть правильну суму ставки.");
            setIsLoading(false);
            setIsTimerRunning(false);
            setIsRequestPending(false);
            return;
        }

        try {
            console.log('Відправка запиту на:', API_URLS.REACT_APP_GAME4_TAKEBET);
            console.log('Дані запиту:', { tg_user_id: user.user_id, bet, is_up: isUp ? 1 : 0 });

            const response = await axios.post(API_URLS.REACT_APP_GAME4_TAKEBET, {
                tg_user_id: user.user_id,
                bet: bet,
                is_up: isUp ? 1 : 0
            });
            console.log('Відповідь сервера:', response.data);

            const data = response.data;

            if (data.status === "ok") {
                setGameResult({
                    isWin: data.is_win,
                    oldBalance: data.old,
                    newBalance: data.new
                });
                updateLocalBalance(data.new_g_token.toString());
            } else {
                setError("Невідома помилка. Спробуйте ще раз.");
            }
        } catch (err) {
            console.error('Помилка запиту:', err);
            if (err.response) {
                console.error('Дані відповіді:', err.response.data);
                console.error('Статус відповіді:', err.response.status);
                console.error('Заголовки відповіді:', err.response.headers);
            }
            if (err.response && err.response.data && err.response.data.type === "error") {
                setError(`Помилка: ${err.response.data.error_code}`);
            } else {
                setError("Помилка при з'єднанні з сервером. Спробуйте ще раз.");
            }
        } finally {
            setIsLoading(false);
            setIsTimerRunning(false);
            setIsRequestPending(false);
        }
    }, [betAmount, user.user_id, updateLocalBalance, isRequestPending]);

    const memoizedTradeViewChart = useMemo(() => (
        <TradeViewChart
            containerStyle={{
                minHeight: '250px',
                minWidth: '350px',
                maxHeight: '250px',
                maxWidth: '350px',
                backgroundColor: 'black',
                marginBottom: '10px',
            }}
            pair="BTCUSDT"
            chartLayout={{
                priceScale: {
                    scaleMargins: {
                        top: 0.001,
                        bottom: 0.01,
                    },
                },
                grid: {
                    vertLines: {
                        color: 'rgba(70, 130, 180, 0.1)',
                        style: 1,
                        visible: true,
                    },
                    horzLines: {
                        color: 'rgba(70, 130, 180, 0.1)',
                        style: 1,
                        visible: true,
                    },
                },
            }}
            candleStickConfig={{
                upColor: chartColor,
                downColor: '#880808',
                borderUpColor: chartColor,
                borderDownColor: '#880808',
                wickUpColor: chartColor,
                wickDownColor: '#880808',
            }}
            onPriceUpdate={(price) => setBtcUsdtPrice(price.toFixed(2))}
        />
    ), [chartColor]);

    useEffect(() => {
        const timer = setInterval(() => {
            setBtcUsdtPrice(prevPrice => {
                const change = (Math.random() - 0.5) * 10;
                return (parseFloat(prevPrice) + change).toFixed(2);
            });
        }, 5000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="graph_game_cont">
            <div className="cont-game-4">
                <div className="earning-game">
                    <div className="earning-content-game">
                        <div className="earning-icon-container-game">
                            <IconCoin className="earning-icon-small-game" />
                            <p className="token-game" style={styles.whiteText}>Token</p>
                        </div>
                        <div className="earning-text-container-game">
                            <p className="earning-text-game" style={styles.whiteText}>{localBalance}</p>
                        </div>
                    </div>
                </div>
                <div className="earning-game">
                    <div className="earning-content-game">
                        <div className="earning-icon-container-game2">
                            <p className="earning-text-game" style={styles.whiteText}>{btcUsdtPrice}</p>
                        </div>
                        <div className="earning-text-container-game">
                            <p className="earning-text-game" style={styles.whiteText}>BTC/USDT</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="graph-cont">
                <div className="chart_wrapper">
                    {memoizedTradeViewChart}
                </div>
            </div>

            <div className="game-playwith">
                <p className="game-playwith-text">Переходь на біржу</p>
                <div className="img-show">
                    <div className="img-container"><img src={CryptoPocket} alt="CryptoPocket" /></div>
                    <div className="img-container"><img src={CryptoBinance} alt="CryptoBinance" /></div>
                    <div className="img-container"><img src={CryptoOkx} alt="CryptoOkx" /></div>
                    <div className="img-container"><img src={CryptoBit} alt="CryptoBit" /></div>
                </div>
                <p className="game-playwith-text2">Заробляй разом з нами!</p>
            </div>

            <div className="game-buttom-list">
                <div className="earning-game">
                    <div className="earning-content-game">
                        <div className="earning-icon-container-game1">
                            <IconCoin className="earning-icon-small-game"/>
                        </div>
                        <div className="earning-content-game">
                            <div className="earning-text-container-game">
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    placeholder="Ставка"
                                    style={styles.input}
                                    value={betAmount}
                                    onChange={handleBetChange}
                                    disabled={isRequestPending}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className="game-button-up"
                    onClick={() => placeBet(true)}
                    disabled={isLoading || isRequestPending}
                >
                    Вверх
                </button>
                <button
                    className="game-button-down"
                    onClick={() => placeBet(false)}
                    disabled={isLoading || isRequestPending}
                >
                    Вниз
                </button>
            </div>
            {isTimerRunning && (
                <div style={styles.timerText}>
                    Час: {timer.toFixed(1)} с
                </div>
            )}
            {error && <div className="error-message" style={{color: 'red', textAlign: 'center', marginTop: '10px'}}>{error}</div>}
            {isLoading && <div className="loading-message" style={styles.resultText}>Обробка ставки...</div>}
            {gameResult && (
                <div style={styles.resultText}>
                    {gameResult.isWin ? "Ви виграли!" : "Ви програли."}
                    <br />
                    Старий баланс: {gameResult.oldBalance}
                    <br />
                    Новий баланс: {gameResult.newBalance}
                    <br />
                    Час очікування: {timer.toFixed(1)} с
                </div>
            )}
        </div>
    );
}

export default React.memo(Four);