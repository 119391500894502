import React, { useState, useEffect, useCallback, useRef } from "react";

const LoadingDots = () => {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevDots => {
        if (prevDots.length >= 3) return '.';
        return prevDots + '.';
      });
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return <span className="loading-dots">{dots}</span>;
};
export default LoadingDots;