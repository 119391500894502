import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useUser } from './UserContext';
import { FAIRY_GIFT_SHOW_INTERVAL } from '../config/config';

const RewardContext = createContext();

export const useReward = () => useContext(RewardContext);

export const RewardProvider = ({ children }) => {
  const [nextRewardTime, setNextRewardTime] = useState(null);
  const [adType, setAdType] = useState('fairy');
  const [rewardIntervalMinutes, setRewardIntervalMinutes] = useState(FAIRY_GIFT_SHOW_INTERVAL / 60); // Convert to minutes
  const [absenceThresholdMinutes, setAbsenceThresholdMinutes] = useState(5);
  const [lastVisitTime, setLastVisitTime] = useState(null);

  const calculateNextRewardTime = useCallback(() => {
    if (!lastVisitTime) {
      console.log("Last visit time not set, skipping calculation");
      return;
    }

    const now = Date.now();
    const timeSinceLastVisit = now - lastVisitTime.getTime();

    console.log("Time since last visit (minutes):", timeSinceLastVisit / (60 * 1000));
    console.log("Reward interval (minutes):", rewardIntervalMinutes);
    console.log("Absence threshold (minutes):", absenceThresholdMinutes);

    let initialDelay;
    if (timeSinceLastVisit > absenceThresholdMinutes * 60 * 1000) {
      initialDelay = rewardIntervalMinutes * 60 * 1000 / 2;
      console.log("User was absent, using half interval");
    } else {
      initialDelay = rewardIntervalMinutes * 60 * 1000;
      console.log("User was not absent, using full interval");
    }

    const newNextRewardTime = now + initialDelay;
    console.log("New next reward time:", new Date(newNextRewardTime).toLocaleString());
    setNextRewardTime(newNextRewardTime);
  }, [lastVisitTime, rewardIntervalMinutes, absenceThresholdMinutes]);

  useEffect(() => {
    console.log("Last visited time updated:", lastVisitTime);
    if (lastVisitTime) {
      calculateNextRewardTime();
    }
  }, [lastVisitTime, calculateNextRewardTime]);

  useEffect(() => {
    const fetchIntervals = async () => {
      try {
        const rewardResponse = await new Promise(resolve => 
          setTimeout(() => resolve({ data: FAIRY_GIFT_SHOW_INTERVAL / 60 }), 1000)
        );
        // const rewardResponse = await new Promise(resolve => 
        //   setTimeout(() => resolve({ data: 0.25 }), 1000)
        // );
        const absenceResponse = await new Promise(resolve => 
          setTimeout(() => resolve({ data: 5 }), 1000)
        );
        
        console.log("Fetched reward interval (minutes):", rewardResponse.data);
        console.log("Fetched absence threshold (minutes):", absenceResponse.data);
        
        setRewardIntervalMinutes(rewardResponse.data);
        setAbsenceThresholdMinutes(absenceResponse.data);
      } catch (error) {
        console.error("Error fetching intervals:", error);
      }
    };

    fetchIntervals();
  }, []);

  const toggleRewardType = () => {
    setAdType(prevType => prevType === 'fairy' ? 'gift' : 'fairy');
  };

  const value = {
    nextRewardTime,
    setNextRewardTime,
    adType,
    toggleRewardType,
    rewardIntervalMinutes,
    absenceThresholdMinutes,
    lastVisitTime,
    setLastVisitTime,
  };

  return <RewardContext.Provider value={value}>{children}</RewardContext.Provider>;
};