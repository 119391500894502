import React, { useEffect, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAudio } from "../contexts/AudioContext";
import "../assets/styles/trade.css";
import clickSoundMp3 from '../assets/sounds/menu-button-click.mp3';

const RequestModal = ({
  isOpen,
  onClose,
  sendAmount,
  sendIcon,
  receiveAmount,
  receiveIcon,
  backgroundImage,
  onConfirm
}) => {
  const { i18n, t } = useTranslation();
  const modalRef = useRef(null);
  const [animationClass, setAnimationClass] = useState("");
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      setAnimationClass("slide-in");
      document.addEventListener("mousedown", handleOutsideClick);
      document.body.classList.add("modal-open");
      const tradeElement = document.querySelector("#trade");
      if (tradeElement) {
        tradeElement.classList.add("disable-trade");
      }
      const headerElement = document.querySelector(".header");
      if (headerElement) {
        headerElement.classList.add("disabled");
      }
    } else {
      setAnimationClass("slide-out");
      document.body.classList.remove("modal-open");
      const tradeElement = document.querySelector("#trade");
      if (tradeElement) {
        tradeElement.classList.remove("disable-trade");
      }
      const headerElement = document.querySelector(".header");
      if (headerElement) {
        headerElement.classList.remove("disabled");
      }
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.classList.remove("modal-open");
      const tradeElement = document.querySelector("#trade");
      if (tradeElement) {
        tradeElement.classList.remove("disable-trade");
      }
      const headerElement = document.querySelector(".header");
      if (headerElement) {
        headerElement.classList.remove("disabled");
      }
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([
            fetch(clickSoundMp3),
          ]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;
      
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
      
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      
      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const handleAnimationEnd = () => {
    if (animationClass === "slide-out") {
      setAnimationClass("");
    }
  };

  const handleConfirm = () => {
    playSound();
    onConfirm();
    onClose();
  };


  if (!isOpen && !animationClass) return null;

  return (
    <>
      <div className={`modal-overlay ${isOpen ? "active" : ""}`} onClick={onClose}></div>
      <div
        ref={modalRef}
        className={`modal ${animationClass}`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: "30%",
          // transition: "height 0.3s ease",
        }}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className="modal-content">
          <div className="flex flex-col justify-center items-center w-full">
            <div className="w-full flex justify-center items-center">
              <p className="request-modal-title">{t('TradePage.MakeRequestModalTitle')}</p>
            </div>
            <div className="modal-row items-center justify-center mt-4">
              <p className="pocket-label2">
              {t('TradePage.MakeRequestModalText')}
              </p>
            </div>
          </div>
          <div className="modal-row flex items-end h-1/3">
            <div className="flex flex-col">
              <p className="pocket-input-label">{t('TradePage.MakeRequestModalFrom')}</p>
              <div className="modal-money">
                <div className="w-2/5 flex justify-end">
                  <img
                    src={sendIcon}
                    className="trader-modal-icon"
                    alt="Send Icon"
                  />
                </div>
                <div className="w-2/5 flex justify-start">
                  <p className="modal-money-text">{sendAmount}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="pocket-input-label"></p>
              <button 
                className="confirm-button"
                onClick={handleConfirm}
              >
                {t('TradePage.MakeRequestModalBtn')}
              </button>
            </div>
            <div className="flex flex-col">
              <p className="pocket-input-label">{t('TradePage.MakeRequestModalTo')}</p>
              <div className="modal-money">
                <div className="w-2/5 flex justify-end">
                  <img
                    src={receiveIcon}
                    className="trader-modal-icon"
                    alt="Receive Icon"
                  />
                </div>
                <div className="w-2/5 flex justify-start">
                  <p className="modal-money-text">{receiveAmount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestModal;