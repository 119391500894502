// //TelegramInitializer.js
// import React, { useEffect, useCallback, memo, useRef } from 'react';
// import { useUser } from '../contexts/UserContext';
// import axios from 'axios';
// import { TELEGRAM_BOT_TOKEN } from '../config/config';

// const TelegramInitializer = memo(({ onSuccess, onError }) => {
//   const { updateUser } = useUser();
//   const isInitialized = useRef(false);

//   // const fetchUserPhoto = useCallback(async (userId) => {
//   //   console.log('Fetching user photo');
//   //   try {
//   //     const response = await axios.get(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/getUserProfilePhotos?user_id=${userId}`);
//   //     if (response.data.result.photos.length > 0) {
//   //       const fileId = response.data.result.photos[0][0].file_id;
//   //       const fileResponse = await axios.get(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/getFile?file_id=${fileId}`);
//   //       return `https://api.telegram.org/file/bot${TELEGRAM_BOT_TOKEN}/${fileResponse.data.result.file_path}`;
//   //     }
//   //   } catch (error) {
//   //     console.error('Error fetching user photo:', error);
//   //   }
//   //   return '';
//   // }, []);

//   const initializeTelegram = useCallback(async () => {
//     console.log('Initializing Telegram');
//     if (isInitialized.current) {
//       console.log('Telegram already initialized, skipping');
//       return;
//     }
//     isInitialized.current = true;

//     window.Telegram.WebApp.ready();
//     console.log('Telegram WebApp ready called');

//     const user = window.Telegram.WebApp.initDataUnsafe.user;
//     if (user && user.id) {
//       console.log('User found, fetching photo');
//       // const avatarUrl = await fetchUserPhoto(user.id);

//       console.log('Updating user info');
//       updateUser({
//         tgInfo: {
//           id: user.id,
//           first_name: user.first_name,
//           last_name: user.last_name,
//           username: user.username,
//           language: user.language_code,
//         }
//       });
//       onSuccess(user.id);
//     } else {
//       console.log('User not found, calling onError');
//       onError();
//     }
//   }, [updateUser, onSuccess, onError]);

//   useEffect(() => {
//     console.log('TelegramInitializer effect running');
//     if (window.Telegram && window.Telegram.WebApp) {
//       console.log('Telegram WebApp already loaded, initializing');
//       initializeTelegram();
//     } else {
//       console.log('Loading Telegram WebApp script');
//       const script = document.createElement('script');
//       script.src = 'https://telegram.org/js/telegram-web-app.js';
//       script.async = true;
//       script.onload = () => {
//         console.log('Telegram WebApp script loaded');
//         initializeTelegram();
//       };
//       document.body.appendChild(script);

//       return () => {
//         if (script.parentNode) {
//           console.log('Removing Telegram WebApp script');
//           document.body.removeChild(script);
//         }
//       };
//     }
//   }, []); // Пустой массив зависимостей

//   console.log('TelegramInitializer rendered');

//   return null;
// });

// export default TelegramInitializer;


// import React, { useEffect, useCallback, memo, useRef } from 'react';
// import { useUser } from '../contexts/UserContext';
// import axios from 'axios';
// import { TELEGRAM_BOT_TOKEN } from '../config/config';

// const TelegramInitializer = memo(({ onSuccess, onError }) => {
//   const { updateUser } = useUser();
//   const isInitialized = useRef(false);

//   const lockOrientation = useCallback(() => {
//     const root = document.documentElement;
//     if (window.screen.orientation) {
//       if (window.screen.orientation.type.includes('portrait')) {
//         root.style.setProperty('--app-height', `${window.innerHeight}px`);
//       } else {
//         root.style.setProperty('--app-height', `${window.innerWidth}px`);
//         root.style.setProperty('--app-width', `${window.innerHeight}px`);
//       }
//     } else {
//       // Fallback for browsers that don't support screen.orientation
//       if (window.innerHeight > window.innerWidth) {
//         root.style.setProperty('--app-height', `${window.innerHeight}px`);
//       } else {
//         root.style.setProperty('--app-height', `${window.innerWidth}px`);
//         root.style.setProperty('--app-width', `${window.innerHeight}px`);
//       }
//     }
//   }, []);

//   const initializeTelegram = useCallback(async () => {
//     console.log('Initializing Telegram');
//     if (isInitialized.current) {
//       console.log('Telegram already initialized, skipping');
//       return;
//     }
//     isInitialized.current = true;

//     if (window.Telegram && window.Telegram.WebApp) {
//       window.Telegram.WebApp.ready();
//       console.log('Telegram WebApp ready called');

//       // Блокировка ориентации
//       lockOrientation();

//       const user = window.Telegram.WebApp.initDataUnsafe.user;
//       if (user && user.id) {
//         console.log('User found, updating user info');
//         updateUser({
//           tgInfo: {
//             id: user.id,
//             first_name: user.first_name,
//             last_name: user.last_name,
//             username: user.username,
//             language: user.language_code,
//           }
//         });
//         onSuccess(user.id);
//       } else {
//         console.log('User not found, calling onError');
//         onError();
//       }
//     } else {
//       console.error('Telegram WebApp is not available');
//       onError();
//     }
//   }, [updateUser, onSuccess, onError, lockOrientation]);

//   useEffect(() => {
//     console.log('TelegramInitializer effect running');
//     if (window.Telegram && window.Telegram.WebApp) {
//       console.log('Telegram WebApp already loaded, initializing');
//       initializeTelegram();
//     } else {
//       console.log('Loading Telegram WebApp script');
//       const script = document.createElement('script');
//       script.src = 'https://telegram.org/js/telegram-web-app.js';
//       script.async = true;
//       script.onload = () => {
//         console.log('Telegram WebApp script loaded');
//         initializeTelegram();
//       };
//       document.body.appendChild(script);

//       return () => {
//         if (script.parentNode) {
//           console.log('Removing Telegram WebApp script');
//           document.body.removeChild(script);
//         }
//       };
//     }
//   }, [initializeTelegram]);

//   useEffect(() => {
//     window.addEventListener('resize', lockOrientation);
//     window.addEventListener('orientationchange', lockOrientation);

//     return () => {
//       window.removeEventListener('resize', lockOrientation);
//       window.removeEventListener('orientationchange', lockOrientation);
//     };
//   }, [lockOrientation]);

//   console.log('TelegramInitializer rendered');

//   return null;
// });

// export default TelegramInitializer;






import { useEffect, useCallback, memo, useRef } from 'react';
import { useUser } from '../contexts/UserContext';

const TelegramInitializer = memo(({ onSuccess, onError }) => {
  const { updateUser, user } = useUser();
  const isInitialized = useRef(false);

  const lockOrientation = useCallback(() => {
        const root = document.documentElement;
        if (window.screen.orientation) {
          if (window.screen.orientation.type.includes('portrait')) {
            root.style.setProperty('--app-height', `${window.innerHeight}px`);
          } else {
            root.style.setProperty('--app-height', `${window.innerWidth}px`);
            root.style.setProperty('--app-width', `${window.innerHeight}px`);
          }
        } else {
          // Fallback for browsers that don't support screen.orientation
          if (window.innerHeight > window.innerWidth) {
            root.style.setProperty('--app-height', `${window.innerHeight}px`);
          } else {
            root.style.setProperty('--app-height', `${window.innerWidth}px`);
            root.style.setProperty('--app-width', `${window.innerHeight}px`);
          }
        }
      }, []);

  const initializeTelegram = useCallback(async () => {
    console.log('Initializing Telegram');
    if (isInitialized.current) {
      console.log('Telegram already initialized, skipping');
      return;
    }
    isInitialized.current = true;

    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      console.log('Telegram WebApp ready called');

      lockOrientation();

      const tgUser = window.Telegram.WebApp.initDataUnsafe.user;
      if (tgUser && tgUser.id) {
        console.log('User found, updating user info');
        // updateUser({
        //   tgInfo: {
        //     id: tgUser.id,
        //     first_name: tgUser.first_name,
        //     last_name: tgUser.last_name,
        //     username: tgUser.username,
        //     language: tgUser.language_code,
        //   },
        // });
        updateUser(prevUser => ({
          ...prevUser,
          tgInfo: {
            id: tgUser.id,
            first_name: tgUser.first_name,
            last_name: tgUser.last_name,
            username: tgUser.username,
            language: tgUser.language_code,
          },
        }));

        // console.log("user user userr", user);
        updateUser(prevUser => {
          if (!prevUser || !prevUser.lang_code) {
            return {
              ...prevUser,
              lang_code: tgUser.language_code || 'en'
            };
          }
          return prevUser;
        });
        // if (!user.lang_code) {
        //   updateUser({
        //     lang_code: tgUser.language_code || 'en'
        //   });
        // }
        onSuccess(tgUser.id);
      } else {
        console.log('User not found, calling onError');
        onError();
      }
    } else {
      console.error('Telegram WebApp is not available');
      onError();
    }
  }, [updateUser, onSuccess, onError, lockOrientation]);

  useEffect(() => {
    console.log('TelegramInitializer effect running');
    if (window.Telegram && window.Telegram.WebApp) {
      console.log('Telegram WebApp already loaded, initializing');
      initializeTelegram();
    } else {
      console.log('Loading Telegram WebApp script');
      const script = document.createElement('script');
      script.src = 'https://telegram.org/js/telegram-web-app.js';
      script.async = true;
      script.onload = () => {
      //  console.log('Telegram WebApp script loaded');
        initializeTelegram();
      };
      document.body.appendChild(script);

      return () => {
        if (script.parentNode) {
         // console.log('Removing Telegram WebApp script');
          document.body.removeChild(script);
        }
      };
    }
  }, [initializeTelegram]);

  useEffect(() => {
    window.addEventListener('resize', lockOrientation);
    window.addEventListener('orientationchange', lockOrientation);

    return () => {
      window.removeEventListener('resize', lockOrientation);
      window.removeEventListener('orientationchange', lockOrientation);
    };
  }, [lockOrientation]);

 // console.log('TelegramInitializer rendered');

  return null;
});

export default TelegramInitializer;