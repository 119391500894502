//тут норм
// import React, { useState, useEffect, useRef } from 'react';
// import iconCross from "../assets/icons/cross.webp";
// import "../assets/styles/adViewer.css";

// const AdViewer = ({ mediaUrl, adFileName, isVideo, onClose }) => {
//   const [canSkip, setCanSkip] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [filePath, setFilePath] = useState('');
//   const [timeLeft, setTimeLeft] = useState(30);
//   const videoRef = useRef(null);
//   const intervalRef = useRef(null);

//   useEffect(() => {
//     const importAd = async () => {
//       if (!adFileName) {
//         return;
//       }

//       try {
//         const adModule = await import(`../assets/images/Adverts/${adFileName}`);
//         setFilePath(adModule.default);
//       } catch (error) {
//         console.error('Error importing ad file:', error);
//       }
//     };

//     importAd();
//   }, [adFileName]);

//   useEffect(() => {
//     const header = document.querySelector('.header');
//     const menu = document.querySelector('.menu-container');
    
//     if (header) header.style.display = 'none';
//     if (menu) menu.style.display = 'none';

//     intervalRef.current = setInterval(() => {
//       setTimeLeft((prevTime) => {
//         if (prevTime <= 1) {
//           clearInterval(intervalRef.current);
//           setCanSkip(true);
//           return 0;
//         }
//         return prevTime - 1;
//       });
//       setProgress((prevProgress) => prevProgress + (100 / 30));
//     }, 1000);

//     return () => {
//       clearInterval(intervalRef.current);
//       if (header) header.style.display = '';
//       if (menu) menu.style.display = '';
//     };
//   }, []);

//   useEffect(() => {
//     if (isVideo && videoRef.current) {
//       const updateProgress = () => {
//         const videoProgress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
//         setProgress(videoProgress);
//       };
//       videoRef.current.addEventListener('timeupdate', updateProgress);
//       return () => videoRef.current.removeEventListener('timeupdate', updateProgress);
//     }
//   }, [isVideo]);

//   const handleSkip = () => {
//     if (canSkip) {
//       onClose();
//     }
//   };

//   return (
//     <div className="ad-viewer-fullscreen flex justify-center">
//       {isVideo ? (
//         <video ref={videoRef} className="ad-content" autoPlay muted>
//           <source src={mediaUrl} type="video/mp4" />
//         </video>
//       ) : (
//         <img src={filePath} alt="Advertisement" className="ad-content" />
//       )}
      
//       <div className="ad-controls">
//         <div className="ad-timer-container">
//           {!canSkip && <div className="ad-timer">Skip in {timeLeft}s</div>}
//         </div>
//         <div className="ad-button-container">
//           <button 
//             onClick={handleSkip} 
//             className={`ad-skip-button ${!canSkip ? 'disabled' : ''}`}
//             disabled={!canSkip}
//           >
//             <img src={iconCross} alt="cross" className="ad-cross-icon"/>
//           </button>
//         </div>
//       </div>
      
//       <div className="ad-progress-bar-container">
//         <div className="ad-progress-bar">
//           <div
//             className="ad-progress-fill"
//             style={{ width: `${progress}%` }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdViewer;



//тут різний прогрес бар
// import React, { useState, useEffect, useRef } from 'react';
// import iconCross from "../assets/icons/cross.webp";
// import "../assets/styles/adViewer.css";

// const AdViewer = ({ mediaUrl, adFileName, isVideo, onClose }) => {
//   const [canSkip, setCanSkip] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [filePath, setFilePath] = useState('');
//   const [timeLeft, setTimeLeft] = useState(30);
//   const [isClickable, setIsClickable] = useState(false);
//   const videoRef = useRef(null);
//   const intervalRef = useRef(null);

//   useEffect(() => {
//     const importAd = async () => {
//       if (!adFileName) {
//         return;
//       }

//       try {
//         const adModule = adFileName.endsWith('.mp4') 
//         ? `../assets/images/Adverts/${adFileName}`
//         : await import(`../assets/images/Adverts/${adFileName}`);
//       console.log(adModule);
//       setFilePath(adModule.default || adModule);
//       } catch (error) {
//         console.error('Error importing ad file:', error);
//       }
//     };

//     importAd();
//   }, [adFileName]);

//   useEffect(() => {
//     const header = document.querySelector('.header');
//     const menu = document.querySelector('.menu-container');
    
//     if (header) header.style.display = 'none';
//     if (menu) menu.style.display = 'none';

//     intervalRef.current = setInterval(() => {
//       setTimeLeft((prevTime) => {
//         if (prevTime <= 1) {
//           clearInterval(intervalRef.current);
//           setCanSkip(true);
//           setIsClickable(true);
//           return 0;
//         }
//         return prevTime - 1;
//       });
//       if (!isVideo) {
//         setProgress((prevProgress) => prevProgress + (100 / 30));
//       }
//     }, 1000);

//     return () => {
//       clearInterval(intervalRef.current);
//       if (header) header.style.display = '';
//       if (menu) menu.style.display = '';
//     };
//   }, [isVideo]);

//   useEffect(() => {
//     if (isVideo && videoRef.current) {
//       const updateProgress = () => {
//         const videoProgress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
//         setProgress(videoProgress);
//       };
//       videoRef.current.addEventListener('timeupdate', updateProgress);
//       return () => videoRef.current.removeEventListener('timeupdate', updateProgress);
//     }
//   }, [isVideo]);

//   const handleSkip = () => {
//     if (canSkip) {
//       onClose();
//     }
//   };

//   const handleAdClick = () => {
//     if (isClickable) {
//       window.location.href = mediaUrl;
//     }
//   };

//   return (
//     <div className="ad-viewer-fullscreen flex justify-center">
//       <div className="ad-content-wrapper" onClick={handleAdClick}>
//       {isVideo ? (
//   <video className="ad-content" autoPlay muted>
//     <source src={filePath} type="video/mp4" />
//   </video>
// ) : (
//   <img src={filePath} alt="Advertisement" className="ad-content" />
// )}
//       </div>
      
//       <div className="ad-controls">
//         <div className="ad-timer-container">
//           {!canSkip && <div className="ad-timer">Skip in {timeLeft}s</div>}
//         </div>
//         <div className="ad-button-container">
//           <button 
//             onClick={handleSkip} 
//             className={`ad-skip-button ${!canSkip ? 'disabled' : ''}`}
//             disabled={!canSkip}
//           >
//             <img src={iconCross} alt="cross" className="ad-cross-icon"/>
//           </button>
//         </div>
//       </div>
      
//       <div className="ad-progress-bar-container">
//         <div className="ad-progress-bar">
//           <div
//             className="ad-progress-fill"
//             style={{ width: `${progress}%` }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdViewer;



//WORKS
// import React, { useState, useEffect, useRef } from 'react';
// import iconCross from "../assets/icons/cross.webp";
// import "../assets/styles/adViewer.css";
// import LoadingScreen from '../utils/LoadingScreen';

// function importAll(r) {
//   let files = {};
//   r.keys().forEach((item, index) => {
//     const key = item.replace('./', '').toLowerCase(); 
//     files[key] = r(item).default || r(item);
//     console.log(`Imported file ${index}: ${key}`);
//   });
//   console.log('All imported files:', Object.keys(files)); 
//   return files;
// }

// const files = importAll(require.context('../assets/images/Adverts', false, /\.(png|jpeg|jpg|svg|jfif|mp4|avi)$/i));

// const AdViewer = ({ mediaUrl, adFileName, isVideo, onClose }) => {
//   const [canSkip, setCanSkip] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [timeLeft, setTimeLeft] = useState(30);
//   const [isClickable, setIsClickable] = useState(false);
//   const [mediaSource, setMediaSource] = useState('');
//   const mediaRef = useRef(null);
//   const intervalRef = useRef(null);

//   useEffect(() => {
//     const getMediaSource = async (fileName) => {
//       const normalizedFileName = fileName.toLowerCase();
//       const fileKey = Object.keys(files).find(key => key.includes(normalizedFileName));
//       if (fileKey) {
//         return files[fileKey];
//       } else {
//         return `${process.env.PUBLIC_URL}/assets/images/Adverts/${fileName}`;
//       }
//     };

//     getMediaSource(adFileName).then(source => setMediaSource(source));
//   }, [adFileName]);

//   useEffect(() => {
//     const header = document.querySelector('.header');
//     const menu = document.querySelector('.menu-container');
    
//     if (header) header.style.display = 'none';
//     if (menu) menu.style.display = 'none';

//     intervalRef.current = setInterval(() => {
//       setTimeLeft((prevTime) => {
//         if (prevTime <= 1) {
//           clearInterval(intervalRef.current);
//           setCanSkip(true);
//           setIsClickable(true);
//           return 0;
//         }
//         return prevTime - 1;
//       });

//       if (!isVideo) {
//         setProgress((prevProgress) => {
//           const newProgress = prevProgress + (100 / 30);
//           return newProgress > 100 ? 100 : newProgress;
//         });
//       }
//     }, 1000);

//     return () => {
//       clearInterval(intervalRef.current);
//       if (header) header.style.display = '';
//       if (menu) menu.style.display = '';
//     };
//   }, [isVideo]);

//   useEffect(() => {
    
//     const updateVideoProgress = () => {
//       if (mediaRef.current && !isNaN(mediaRef.current.duration)) {
//         const videoProgress = (mediaRef.current.currentTime / mediaRef.current.duration) * 100;
//         setProgress(videoProgress);
//       } 
//     };

//     const handleLoadedMetadata = () => {
//       updateVideoProgress();
//     };

//     if (isVideo && mediaRef.current) {
//       mediaRef.current.addEventListener('timeupdate', updateVideoProgress);
//       mediaRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
//     }

//     return () => {
//       if (isVideo && mediaRef.current) {
//         mediaRef.current.removeEventListener('timeupdate', updateVideoProgress);
//         mediaRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
//       }
//     };
//   }, [isVideo, mediaSource]);

//   const handleSkip = () => {
//     if (canSkip) {
//       onClose();
//     }
//   };

//   const handleAdClick = () => {
//     if (isClickable) {
//       window.open(mediaUrl, '_blank');
//     }
//   };

//   return (
//     <div className="ad-viewer-fullscreen flex justify-center">
//       <div className="ad-content-wrapper" onClick={handleAdClick}>
//         {isVideo ? (
//           <video
//             key={mediaSource}
//             ref={mediaRef}
//             className="ad-content"
//             autoPlay
//             playsInline
//             muted={false}
//           >
//             <source src={mediaSource} type="video/mp4" />
//           </video>
//         ) : (
//           <img ref={mediaRef} src={mediaSource} alt="Advertisement" className="ad-content" />
//         )}
//       </div>
      
//       <div className="ad-controls">
//         <div className="ad-timer-container">
//           {!canSkip && <div className="ad-timer">Skip in {timeLeft}s</div>}
//         </div>
//         <div className="ad-button-container">
//           <button 
//             onClick={handleSkip} 
//             className={`ad-skip-button ${!canSkip ? 'disabled' : ''}`}
//             disabled={!canSkip}
//           >
//             <img src={iconCross} alt="cross" className="ad-cross-icon"/>
//           </button>
//         </div>
//       </div>
      
//       <div className="ad-progress-bar-container">
//         <div className="ad-progress-bar">
//           <div
//             className="ad-progress-fill"
//             style={{ width: `${progress}%` }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdViewer;




import React, { useState, useEffect, useRef } from 'react';
import { API_URLS } from "../config/config";
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext"; 
import axios from "axios";
import iconCross from "../assets/icons/cross.webp";
import "../assets/styles/adViewer.css";
import LoadingScreen from '../utils/LoadingScreen';

// function importAll(r) {
//   let files = {};
//   r.keys().forEach((item, index) => {
//     const key = item.replace('./', '').toLowerCase(); 
//     files[key] = r(item).default || r(item);
//     console.log(`Imported file ${index}: ${key}`);
//   });
//   console.log('All imported files:', Object.keys(files)); 
//   return files;
// }

// const files = importAll(require.context('../assets/images/Adverts', false, /\.(png|jpeg|jpg|svg|jfif|mp4|avi)$/i));

const AdViewer = ({ id, mediaUrl, rewardType, adFileName, goldReward, isVideo, onClose }) => {
  const [canSkip, setCanSkip] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(15);
  const [isClickable, setIsClickable] = useState(true);
  const [mediaSource, setMediaSource] = useState(`${API_URLS.API_BASE_URL}${adFileName}`);
  // const [isLoading, setIsLoading] = useState(true);
  const [rewardClaimed, setRewardClaimed] = useState(0); // 0: не отримано, 1: 50% отримано, 2: 100% отримано
  const [isAdEnded, setIsAdEnded] = useState(false);
  const mediaRef = useRef(null);
  const intervalRef = useRef(null);
  const { user, updateUser } = useUser();
  const { pauseBackgroundMusic, resumeBackgroundMusic } = useAudio(); 

  useEffect(() => {
    // const getMediaSource = async (fileName) => {
    //   const normalizedFileName = fileName.toLowerCase();
    //   const fileKey = Object.keys(files).find(key => key.includes(normalizedFileName));
    //   if (fileKey) {
    //     return files[fileKey];
    //   } else {
    //     return `${process.env.PUBLIC_URL}/assets/images/Adverts/${fileName}`;
    //   }
    // };

    // getMediaSource(adFileName).then(source => {
    //   setMediaSource(source);
    //   setIsLoading(false);
    // });
    console.log("file path",mediaSource );

    pauseBackgroundMusic();

    return () => {
      resumeBackgroundMusic();
    };
  }, [adFileName, pauseBackgroundMusic, resumeBackgroundMusic]);

  useEffect(() => {
    const header = document.querySelector('.header');
    const menu = document.querySelector('.menu-container');
    
    if (header) header.style.display = 'none';
    if (menu) menu.style.display = 'none';

    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalRef.current);
          setCanSkip(true);
          setIsAdEnded(true);
          if (rewardClaimed === 0) {
            claimReward(0.5);
          }
          return 0;
        }
        return prevTime - 1;
      });

      setProgress((prevProgress) => {
        const newProgress = prevProgress + (100 / 15);
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
      if (header) header.style.display = '';
      if (menu) menu.style.display = '';
    };
  }, [rewardClaimed]);

  useEffect(() => {
    if (isVideo && mediaRef.current) {
      const playVideoWithSound = async () => {
        try {
          mediaRef.current.muted = false;
          await mediaRef.current.play();
        } catch (error) {
          console.log('Autoplay with sound failed:', error);
          mediaRef.current.muted = true;
          await mediaRef.current.play();
        }
      };

      playVideoWithSound();

      const updateVideoProgress = () => {
        if (mediaRef.current && !isNaN(mediaRef.current.duration)) {
          const videoProgress = (mediaRef.current.currentTime / mediaRef.current.duration) * 100;
          setProgress(videoProgress);
        }
      };

      const handleVideoEnded = async () => {
        setIsAdEnded(true);
        setCanSkip(true);
        if (rewardClaimed === 0) {
          await claimReward(0.5);
        }
      };

      mediaRef.current.addEventListener('timeupdate', updateVideoProgress);
      mediaRef.current.addEventListener('ended', handleVideoEnded);

      return () => {
        if (mediaRef.current) {
          mediaRef.current.removeEventListener('timeupdate', updateVideoProgress);
          mediaRef.current.removeEventListener('ended', handleVideoEnded);
        }
      };
    }
  }, [isVideo, mediaSource, rewardClaimed]);

  const claimReward = async (multiplier) => {
    if (rewardClaimed === 2) return;

    try {
      const data = {
        userId: user.user_id,
        assetId: rewardType,
        rewardAmount: Math.floor(goldReward * multiplier)
      };

      const response = await axios.post(API_URLS.GIVE_ADVIEW_REWARD_ENDPOINT, data);

      if (response.data.result === "ok") {
        console.log(response.data, data);
        setRewardClaimed(prevState => Math.min(prevState + 1, 2));
        updateUser({
          gold_balance: response.data.new_user_gold_balance,
          gnome_amount: response.data.new_user_gnome_balance,
        });
      }
    } catch (error) {
      console.error("An error occurred during give adview reward", error);
    }
  };

  const handleSkip = () => {
    if (canSkip) {
      onClose();
    }
  };

  const handleAdClick = async () => {
    if (isClickable) {
      if (isAdEnded) {
        // If the ad has ended, just open the URL without restarting the video
        if (rewardClaimed === 1) {
          await claimReward(0.5);
        }
        window.open(mediaUrl, '_blank');
      } else {
        // If the ad hasn't ended, claim the full reward and open the URL
        if (rewardClaimed === 0) {
          await claimReward(1);
        }
        window.open(mediaUrl, '_blank');
        
      }
    }
  };

  return (
    <div className="ad-viewer-fullscreen">
        <div className="ad-content-wrapper" onClick={handleAdClick}>
          {isVideo ? (
            <video
              key={mediaSource}
              ref={mediaRef}
              className="ad-content"
              autoPlay
              playsInline
              muted
              preload="auto"
              loop={false}
            >
              <source src={mediaSource} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          ) : (
            <img ref={mediaRef} src={mediaSource} alt="Advertisement" className="ad-content" />
          )}
        </div>
      {/* )} */}
      
      <div className="ad-controls">
        <div className="ad-timer-container">
          {!canSkip && <div className="ad-timer">Skip in {timeLeft}s</div>}
        </div>
        <div className="ad-button-container">
          <button 
            onClick={handleSkip} 
            className={`ad-skip-button ${!canSkip ? 'disabled' : ''}`}
            disabled={!canSkip}
          >
            <img src={iconCross} alt="cross" className="ad-cross-icon"/>
          </button>
        </div>
      </div>
      
      <div className="ad-progress-bar-container">
        <div className="ad-progress-bar">
          <div
            className="ad-progress-fill"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdViewer;