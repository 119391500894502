import React, { useEffect, useState, memo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAudio } from "../contexts/AudioContext";
import "../assets/styles/copmonents/Modal/noResourceModal.css";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, background }) => {
  const [isActive, setIsActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { t } = useTranslation();
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
      setTimeout(() => setIsActive(true), 50);
    } else {
      setIsClosing(true);
      setIsActive(false);
      const timer = setTimeout(() => {
        setIsClosing(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([fetch(clickSoundMp3)]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const handleClose = () => {
    playSound();
    setIsClosing(true);
    setIsActive(false);
    setTimeout(onClose, 500);
  };

  const handleConfirm = () => {
    playSound();
    onConfirm();
  };

  if (!isOpen && !isActive && !isClosing) return null;

  const modalContainerStyle = {
    "--modal-background": `url(${background})`,
  };

  return (
    <div
      className={`no-resourse-modal-overlay ${isActive ? "active" : ""} ${
        isClosing ? "closing" : ""
      }`}
      onClick={handleClose}
    >
      <div
        className={`no-resourse-modal-container h-40 ${isActive ? "active" : ""} ${
          isClosing ? "closing" : ""} `}
        style={{
          ...modalContainerStyle,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="no-resourse-modal-content h-30">
          <div className="modal-content">
            <h2 className="no-resourse-modal-title text-center mb-2">{t("AdOfficePage.ConfirmDeleteModal.ConfirmDeleteTitle")}</h2>
            <p className="confirm-text mb-2">{t("AdOfficePage.ConfirmDeleteModal.ConfirmDeleteMessage")}</p>
            <div className="flex flex-row justify-between items-center w-full">
              <button onClick={handleConfirm} className="confirm-button w-2/5">
                {t("AdOfficePage.ConfirmDeleteModal.ConfirmDelete")}
              </button>
              <button onClick={handleClose} className="confirm-button w-2/5">
                {t("AdOfficePage.ConfirmDeleteModal.CancelDelete")}
              </button>
            </div>
          </div>         
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;