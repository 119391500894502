import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import backgroundSound from '../assets/sounds/background-sound.mp3'; // Змінено на .mp3

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [generalVolume, setGeneralVolume] = useState(100);
  const [musicVolume, setMusicVolume] = useState(100);
  const [effectsVolume, setEffectsVolume] = useState(100);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const gainNodeRef = useRef(null);

  const initializeAudio = useCallback(async () => {
    if (!audioContextRef.current) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      audioContextRef.current = new AudioContext();
      
      try {
        const response = await fetch(backgroundSound);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
        
        sourceNodeRef.current = audioContextRef.current.createBufferSource();
        sourceNodeRef.current.buffer = audioBuffer;
        sourceNodeRef.current.loop = true;
        
        gainNodeRef.current = audioContextRef.current.createGain();
        
        sourceNodeRef.current.connect(gainNodeRef.current);
        gainNodeRef.current.connect(audioContextRef.current.destination);

        setIsInitialized(true);
      } catch (error) {
        console.error('Error initializing audio:', error);
      }
    }
  }, []);

  const calculateVolume = useCallback((general, music) => {
    return (general / 100) * (music / 100);
  }, []);

  const updateVolume = useCallback(() => {
    if (gainNodeRef.current && isInitialized) {
      const volume = calculateVolume(generalVolume, musicVolume);
      gainNodeRef.current.gain.setValueAtTime(volume, audioContextRef.current.currentTime);
      console.log('Volume updated:', volume, 'General:', generalVolume, 'Music:', musicVolume);
    }
  }, [generalVolume, musicVolume, calculateVolume, isInitialized]);

  const playBackgroundMusic = useCallback(async () => {
    try {
      if (!audioContextRef.current) {
        await initializeAudio();
      }
      
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }
      
      if (sourceNodeRef.current && !sourceNodeRef.current.isStarted) {
        sourceNodeRef.current.start();
        sourceNodeRef.current.isStarted = true;
      }
      
      updateVolume();
      setIsPaused(false);
      
      console.log('Background music started playing');
    } catch (error) {
      console.error('Error playing background music:', error);
    }
  }, [initializeAudio, updateVolume]);

  const pauseBackgroundMusic = useCallback(() => {
    if (audioContextRef.current && audioContextRef.current.state === 'running') {
      audioContextRef.current.suspend();
      setIsPaused(true);
      console.log('Background music paused');
    }
  }, []);

  const resumeBackgroundMusic = useCallback(() => {
    if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
      setIsPaused(false);
      console.log('Background music resumed');
    }
  }, []);

  const setVolumes = useCallback((general, music, effects) => {
    console.log('Setting volumes:', general, music, effects);
    setGeneralVolume(general);
    setMusicVolume(music);
    setEffectsVolume(effects);
  }, []);

  useEffect(() => {
    if (isInitialized) {
      updateVolume();
    }
  }, [generalVolume, musicVolume, updateVolume, isInitialized]);

  return (
    <AudioContext.Provider value={{
      generalVolume,
      setGeneralVolume,
      musicVolume,
      setMusicVolume,
      effectsVolume,
      setEffectsVolume,
      playBackgroundMusic,
      pauseBackgroundMusic,
      resumeBackgroundMusic,
      updateVolume,
      setVolumes,
      calculateVolume,
      isInitialized,
      isPaused
    }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);