//ProgressBar
import React from 'react';
import backimg from '../assets/images/loadingScreen/backgr.webp'

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar-container" style={{backgroundImage: `url(${backimg})`}}>
      <div className="progress-bar" style={{ width: `${progress}%` }}>
        <div className="arrow-animation"></div>
      </div>
    </div>
  );
};

export default ProgressBar;