import React, { useRef, useEffect, useState, useCallback } from "react";
import AboutLevelModal from "../components/AboutLevelModal";
import { useTranslation } from 'react-i18next';
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext";
import { API_URLS } from "../config/config";
import axios from "axios";
import clickSoundMp3 from '../assets/sounds/menu-button-click.mp3';
import "../assets/styles/levels.css";

const LevelsPage = ({ preloadedData }) => {
  const contentRef = useRef(null);
  const scrollableRef = useRef(null);
  const { t } = useTranslation();
  const [levelHeight, setLevelHeight] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const { user } = useUser();
  const activeLevelIndex = user.rank.id;
  const levelCount = 10;
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([
            fetch(clickSoundMp3),
          ]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;
      
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
      
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      
      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);


  const updateLastActive = async (userId) => {
    try {
      const response = await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
      console.log('Last active time updated:', response.data);
    } catch (error) {
      console.error('Error updating last active time:', error);
    }
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]); 


  useEffect(() => {
    const calculateHeights = () => {
      const img = new Image();
      img.onload = () => {
        const imageHeight = img.height;
        const screenHeight = window.innerWidth;
        
        const calculatedTitleHeight = screenHeight * 0.33;
        
        const availableHeight = imageHeight - calculatedTitleHeight;
        const calculatedLevelHeight = (availableHeight / levelCount) * 0.66;
        
        setTitleHeight(calculatedTitleHeight);
        setLevelHeight(calculatedLevelHeight);
      };
      img.src = preloadedData.background;
    };

    calculateHeights();
    window.addEventListener('resize', calculateHeights);
    return () => window.removeEventListener('resize', calculateHeights);
  }, [preloadedData.background, levelCount]);

  useEffect(() => {
    const content = contentRef.current;
    const scrollable = scrollableRef.current;

    if (content && scrollable && levelHeight > 0 && titleHeight > 0) {
      const totalHeight = levelHeight * levelCount + titleHeight;
      scrollable.style.height = `${totalHeight}px`;
    }
  }, [levelHeight, titleHeight, levelCount]);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current && scrollableRef.current) {
        const scrollPercentage =
          contentRef.current.scrollTop /
          (scrollableRef.current.offsetHeight -
            contentRef.current.offsetHeight);
        scrollableRef.current.style.transform = `translateY(-${
          scrollPercentage * 100
        }%)`;
      }
    };

    const content = contentRef.current;
    if (content) {
      content.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (content) {
        content.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleLevelClick = (level) => {
    playSound();
      setSelectedLevel(level);
      setIsModalOpen(true);
  };

  const getLevelDescription = (level) => {
    return t(`LevelsPage.Level${level}Description`, '');
  };

  const getLevelImage = (level) => {
    return t(`LevelsPage.Level${level}Image`, '');
  };

  const getLevelTitle = (level) => {
    return t(`LevelsPage.Level${level}Title`, '');
  };

  const getLevelFloorImage = (level) => {
    const imagePath = t(`LevelsPage.Level${level}FloorImage`, '');
    return require(`../${imagePath}`);
  };

  return (
    <div
      id="levels"
      ref={contentRef}
      style={{ backgroundImage: `url(${preloadedData.backgroundMain})` }}
    >
      <div className="levels-container">
        <div className="levels-content">
          <div
            ref={scrollableRef}
            className="scrollable-content"
          >
            <div 
              className="levels-page-title-container" 
              style={{ 
                backgroundImage: `url(${getLevelFloorImage(0)})`,
                // height: `${titleHeight}px`,
                // backgroundSize: 'cover',
                // backgroundPosition: 'center'
              }}
            >
              <p className="levels-page-title-text">{t('LevelsPage.Title')}</p>
            </div>
            {[...Array(levelCount).keys()].map((index) => {
              const level = index + 1;
              return (
                <div 
                  className="level-div" 
                  key={index} 
                  style={{ 
                    backgroundImage: `url(${getLevelFloorImage(level)})`,
                  //   height: `${levelHeight}px`,
                  //   backgroundSize: 'cover',
                  //   backgroundPosition: 'center'
                   }}
                  onClick={() => handleLevelClick(level)}
                >
                  <div
                    className={
                      index === activeLevelIndex-1
                        ? "current-level"
                        : "level-container-page3"
                    }
                  >
                    <div className="level-content">
                      <p className="level-title">{t("LevelsPage.Level")}</p>
                      <div className="level-number-container">
                        <p className="level">{level}</p>
                        {(index < activeLevelIndex-1 ||
                          index > activeLevelIndex-1) && (
                          <div className="icon-container">
                            {index < activeLevelIndex && (
                              <img
                                src={preloadedData.iconLevelsCheck}
                                alt="Completed"
                                className="level-icon"
                              />
                            )}
                            {index > activeLevelIndex-1 && (
                              <img
                                src={preloadedData.iconLevelsLock}
                                alt="Locked"
                                className="level-icon"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div 
              className="level-div next-balcony" 
              style={{ 
                height: `${levelHeight}px`,
                backgroundImage: `url(${getLevelFloorImage(levelCount + 1)})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
            </div>
          </div>
        </div>
      </div>
      <AboutLevelModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        iconArrowLeft={preloadedData.iconArrowLeft}
        levelNumber={selectedLevel}
        levelImage={selectedLevel ? getLevelImage(selectedLevel) : ''}
        levelDescription={selectedLevel ? getLevelDescription(selectedLevel) : ''}
        levelTitle={selectedLevel ? getLevelTitle(selectedLevel) : ''}
        activeLevelIndex={activeLevelIndex}
      />
    </div>
  );
};

export default LevelsPage; 