import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';
import { API_URLS } from '../config/config';
import UpgradeGame from '../components/upgrade/UpgradeGame';
import UpgradeGameRoad from '../components/upgrade/UpgradeGameRoad';
import backgroundImageRoad from '../assets/images/upgrade/Road/background-page3.png';
import '../assets/styles/upgrade/UpgradePage.css';
import jailIcon from '../assets/images/prison.webp'
import '../assets/styles/upgrade/jail-msg.css';

const UpgradePage = ({ preloadedData }) => {
    const [getUserData, setUserData] = useState(null);
    const [getGameData, setGameData] = useState(null);
    const [showRoadProgress, setShowRoadProgress] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const { updateUser, user } = useUser();
    const isMounted = useRef(true);




    const [isBlocked, setIsBlocked] = useState(false);
    const [blockedUntil, setBlockedUntil] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);

    const checkBlockStatus = async () => {
        try {
            const userResponse = await axios.get(API_URLS.GET_USER_INFO, {
                params: { userId: user.user_id },
                headers: {
                    Accept: "application/json",
                    "ngrok-skip-browser-warning": "true",
                },
            });

            if (typeof userResponse.data === "object" && userResponse.data.info) {
                const userInfo = userResponse.data.info;
                updateUser({ ...userInfo });

                // Check if user is blocked
                setIsBlocked(userInfo.is_blocked);
                if (userInfo.is_blocked && userInfo.blocked_until) {
                    setBlockedUntil(new Date(userInfo.blocked_until));
                } else {
                    setBlockedUntil(null);
                }
            } else {
                throw new Error(
                    "Unexpected response structure from backend for user data"
                );
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    };

    const formatTime = (ms) => {
        const seconds = Math.floor(ms / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        return `${hours.toString().padStart(2, "0")}:${(minutes % 60)
          .toString()
          .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
      };

    useEffect(() => {
        checkBlockStatus();
    }, []);

    useEffect(() => {
        let intervalId;
    
        if (isBlocked === true && blockedUntil) {
          intervalId = setInterval(() => {
            const now = new Date();
            const timeLeft = blockedUntil.getTime() - now.getTime();
    
            if (timeLeft <= 0) {
              setIsBlocked(false);
              setBlockedUntil(null);
              clearInterval(intervalId);
            } else {
              setRemainingTime(timeLeft);
            }
          }, 1000);
        }
    
        return () => {
          if (intervalId) {
            clearInterval(intervalId);
          }
        };
      }, [isBlocked, blockedUntil]);


    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (user?.user_id) {
            fetchUserData();
            fetchGameData();
        }
    }, [user]);

    const fetchUserData = useCallback(async () => {
        try {
            const response = await axios.get(API_URLS.GET_USER_INFO, {
                params: { userId: user.user_id },
                headers: {
                    'Accept': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            if (isMounted.current) {
                if (typeof response.data === 'object' && response.data.info) {
                    const userInfo = { ...response.data.info, user_id: user.user_id };
                    updateUser(userInfo);
                    setUserData(userInfo);
                } else {
                    throw new Error("Unexpected response structure from backend");
                }
            }
        } catch (error) {
            if (isMounted.current) {
                console.error('UpgradePage: Error fetching user data:', error);
                setError("Error fetching user data: " + error.message);
            }
        }
    }, [user.user_id, updateUser]);

    const fetchGameData = useCallback(async () => {
        try {
            const gameResponse = await axios({
                method: 'POST',
                url: `${API_URLS.GET_USER_CURRENT_STAGE_INFO}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                },
                data: { userId: user.user_id }
            });
            if (isMounted.current) {
                console.log('-=-=-=gameResponse', gameResponse.data);
                setGameData(gameResponse.data);
                setShowRoadProgress(false);
            }
        } catch (error) {
            if (isMounted.current) {
                if (error.response && error.response.status === 403) {
                    console.error('Access forbidden:', error.response.data);
                    setGameData(error.response.data);
                    setShowRoadProgress(true);
                } else {
                    console.error('UpgradePage: Error fetching game data:', error);
                    setError("Error fetching game data: " + error.message);
                }
            }
        } finally {
            if (isMounted.current) {
                setTimeout(() => {
                    setIsLoading(false);
                    setIsUpdating(false);
                }, 500);
            }
        }
    }, [user.user_id]);

    const globalRefreshData = useCallback(async () => {
        setIsUpdating(true);
        await fetchUserData();
        await fetchGameData();
        setIsUpdating(false);
    }, [fetchUserData, fetchGameData]);

    const getBackgroundImage = (rankId, stageId) => {
        try {
            return require(`../assets/images/upgrade/GameBackground/${rankId}_${stageId}.webp`);
        } catch (error) {
            console.error(`Failed to load background image for stage ${rankId} and rank ${stageId}:`, error);
            return '';
        }
    };

    useEffect(() => {
        // const backgroundImage = showRoadProgress
        //     ? backgroundImageRoad
        //     : getBackgroundImage(user.rank?.id, user.stage?.id);

        const backgroundImage = getBackgroundImage(user.rank?.id, user.stage?.id);

        // Обновляем фоновое изображение для ::before
        const upgradeSection = document.querySelector('.upgrade-page-section');
        if (upgradeSection) {
            upgradeSection.style.setProperty('--background-image', `url(${backgroundImage})`);
        }
    }, [showRoadProgress, user.rank?.id, user.stage?.id]);

    return (
        <div className="upgrade-page-section-line">
            {isBlocked ? (
                <div className="jail-msg-section">
                    <div className='jail-msg-container'>
                        <div className='jail-msg-logo-con'>
                            <img src={jailIcon} alt="Prison" className="jail-msg-logo" />
                        </div>

                        <div className="jail-msg-time">{remainingTime ? formatTime(remainingTime) : "00:00:00"}</div>
                    </div>

                </div>
            ) : null}
            <div className='upgrade-page-section'>
                <div className={`upgrade-page ${isLoading ? '' : 'loaded'}`}>
                    {showRoadProgress ? (
                        <UpgradeGameRoad
                            gameData={getGameData}
                            userData={getUserData}
                            preloadedData={preloadedData}
                            refreshData={globalRefreshData}
                        />
                    ) : (
                        <UpgradeGame
                            gameData={getGameData}
                            userData={getUserData}
                            preloadedData={preloadedData}
                            globalRefreshData={globalRefreshData}
                            isUpdating={isUpdating}
                        />
                    )}
                </div>
                <div className={`loading-overlay-up-page ${isLoading || isUpdating ? 'visible' : ''}`}>
                    <div className="loading-content">
                        <div className="loading-spinner"></div>
                        <p className="loading-text">{isUpdating ? 'Updating...' : 'Loading...'}</p>
                    </div>
                </div>
                {error && <div className="error-message">Error: {error}</div>}
            </div>
        </div>
    );
};

export default UpgradePage;
