import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useUser } from "../../contexts/UserContext";
import { API_URLS } from "../../config/config";
import "../../assets/styles/games/game_core1.css";
import gameIcon from "../../assets/icons/pocket.webp";
import gameGnome from "../../assets/icons/gnom.webp"
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
import formatBalance from "../../utils/formatBalance";
import { useTranslation } from "react-i18next";

const ANIMATION_CONFIG   = {
    totalElements: 9,
    elements: [
        { type: '+', count: 4 },
        { type: '-', count: 4 },
        { type: '*', count: 1 }
    ]
};

const CoreGameOne = () => {
    const [gameData, setGameData] = useState(null);
    const [flipped, setFlipped] = useState(Array(9).fill(false));
    const [shuffling, setShuffling] = useState(false);
    const [positions, setPositions] = useState(Array(9).fill(null));
    const [gameStarted, setGameStarted] = useState(false);
    const [openedCard, setOpenedCard] = useState(null);
    const [gamePrice, setGamePrice] = useState(null);
    const [gameResultData, setGamesResultData] = useState(Array(9).fill(null));
    const { user } = useUser();
    const [gToken, setGToken] = useState(user.g_token);
    const containerRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [showTempContent, setShowTempContent] = useState(false);
    const [tempResults, setTempResults] = useState(Array(9).fill(null));
    const { t } = useTranslation();

    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await axios.post(API_URLS.POST_GAME1_MONEY, {
                    headers: {
                        Accept: "application/json",
                        "ngrok-skip-browser-warning": "true",
                    },
                    tg_user_id: user.user_id
                });
                if (response.data.type === "error" && response.data.error_code === "553") {
                    return;
                }
                setGamePrice(response.data.cost);
                setLoading(false);
            } catch (err) {
                setError('Error fetching game data');
                setLoading(false);
            }
        };

        fetchGameData();
    }, []);

    const handleClick = async (index) => {
        if (gameStarted && !shuffling && !isAnimating && openedCard === null && index !== 4) {
            const newFlipped = [...flipped];
            newFlipped[index] = true;
            setFlipped(newFlipped);
            setOpenedCard(index);

            try {
                if (!gameData || !gameData.id_game) {
                    return;
                }

                const response = await axios.post(API_URLS.POST_GAME1_RESULT, {
                    game_started_id: gameData.id_game
                });

                if (response.data && response.data.type === "ok") {
                    setGamesResultData(prevState => {
                        const newState = [...prevState];
                        newState[index] = response.data.data;
                        return newState;
                    });
                    if (response.data.new_g_token !== undefined) {
                        setGToken(prevToken => {
                            const updatedToken = Number(response.data.new_g_token);
                            return updatedToken;
                        });
                    }
                }
            } catch (error) {
            }
        }
    };

    const getCardBackContent = (index) => {
        const getmoney = gamePrice + gamePrice;
        if (showTempContent) {
            const result = tempResults[index];
            if (result === '+') {
                return (
                    <div className="card-back positive">
                        <IconCoin className="earning-icon-mega-small" />
                        <p>+{getmoney}</p>
                    </div>
                );
            } else if (result === '-') {
                return (
                    <div className="card-back negative">
                        <IconCoin className="earning-icon-mega-small" />
                        <p>-{getmoney}</p>

                    </div>
                );
            } else if (result === '*') {
                return (
                    <div className="card-back gnome">
                        <img className="card-img-gnome" src={gameGnome} alt="" />
                    </div>
                );
            }
        } else if (gameResultData[index] !== null) {
            const result = gameResultData[index];
            if (result === '+') {
                return (
                    <div className="card-back positive">
                        <IconCoin className="earning-icon-mega-small" />
                        <p>+{getmoney}</p>
                    </div>
                );
            } else if (result === '-') {
                return (
                    <div className="card-back negative">
                        <IconCoin className="earning-icon-mega-small" />
                        <p>-{getmoney}</p>
                    </div>
                );
            } else if (result === '*') {
                return (
                    <div className="card-back gnome">
                        <img className="card-img-gnome" src={gameGnome} alt="" />
                    </div>
                );
            }
        }
        return (
            <div className="card-back default">
            </div>
        );
    };

    const getGridPosition = (index) => {
        const cardSize = (300 - 20) / 3;
        const row = Math.floor(index / 3);
        const col = index % 3;
        return { x: col * (cardSize + 10), y: row * (cardSize + 10), rotate: 0 };
    };

    useEffect(() => {
        setPositions(Array(9).fill(null).map((_, i) => getGridPosition(i)));
    }, []);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const generateConfiguredResults = () => {
        let results = [];
        ANIMATION_CONFIG.elements.forEach(element => {
            results = results.concat(Array(element.count).fill(element.type));
        });
        while (results.length < ANIMATION_CONFIG.totalElements) {
            results.push(null);
        }
        return shuffleArray(results);
    };

    const shuffle = async () => {
        if (isAnimating) return;

        setIsAnimating(true);
        setShuffling(true);
        setGameStarted(true);
        setOpenedCard(null);
        setGamesResultData(Array(9).fill(null));
        setTempResults(generateConfiguredResults());
        setShowTempContent(true);

        try {
            const response = await axios.post(API_URLS.POST_GAME1_START, {
                tg_user_id: user.user_id
            });

            if (response.data.type === "error" && response.data.error_code === "551") {
                setShuffling(false);
                setGameStarted(false);
                setIsAnimating(false);
                setShowTempContent(false);
                return;
            }

            if (response.data) {
                setGameData(response.data);
                setGToken(response.data.new_g_token);
            }
        } catch (error) {
            setShuffling(false);
            setGameStarted(false);
            setIsAnimating(false);
            setShowTempContent(false);
            return;
        }

        setPositions(positions.map((pos, i) => ({
            x: 100,
            y: 100,
            rotate: i * 40,
            scale: 0.5
        })));
        setFlipped(Array(9).fill(true));

        setTimeout(() => {
            setPositions(positions.map((_, i) => ({
                ...getGridPosition(i),
                scale: 1,
                rotate: 360
            })));
        }, 1500);

        setTimeout(() => {
            const shuffledIndices = shuffleArray([...Array(9).keys()]);
            setPositions(shuffledIndices.map((i) => ({
                ...getGridPosition(i),
                scale: 1,
                rotate: 360
            })));
        }, 3000);

        setTimeout(() => {
            setPositions(positions.map((pos) => ({ ...pos, rotate: 0 })));
            setFlipped(Array(9).fill(false));
            setShuffling(false);
            setIsAnimating(false);
            setShowTempContent(false);
        }, 4500);
    };
    return (
        <div className="game-wrapper">
            <div className={`gameContainer-one ${shuffling ? 'shuffling' : ''}`} ref={containerRef}>
                {[...Array(9)].map((_, index) => (
                    <div
                        key={index}
                        className={`gameButton-main ${shuffling ? 'shuffle-animation' : ''} ${index === 4 ? 'invisible' : ''}`}
                        onClick={() => handleClick(index)}
                        style={{
                            transform: `translate(${positions[index]?.x}px, ${positions[index]?.y}px) 
                                            rotate(${positions[index]?.rotate || 0}deg) 
                                            scale(${positions[index]?.scale || 1})`,
                            visibility: index === 4 ? 'hidden' : 'visible',
                            pointerEvents: index === 4 ? 'none' : 'auto'
                        }}
                    >
                        <div className={`card-inner ${flipped[index] || shuffling ? 'is-flipped' : ''}`}>
                            <div className="card-front">
                                <img src={gameIcon} alt={`Game button ${index + 1}`} />
                            </div>
                            {getCardBackContent(index)}
                        </div>
                    </div>
                ))}
            </div>
            <div className="game-buttom-list">
                <button className="game-button" onClick={shuffle} disabled={isAnimating}>
                    {gameStarted ? `${t('GamePage.Game1.StartGame')} (${gamePrice}G)` : `${t('GamePage.Game1.StartGame')} (${gamePrice}G)`}
                </button>
                <div className="earning-game">
                    <div className="earning-content-game">
                        <div className="earning-icon-container-game">
                            <IconCoin className="earning-icon-small-game" />
                            <p className="token-game">Token</p>
                        </div>
                        <div className="earning-text-container-game">
                            <p className="earning-text-game">{formatBalance(gToken)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoreGameOne;