const formatBalance = (number) => {

    const absNumber = Math.abs(Number(number));
    
    if (absNumber >= 1.0e+9) {
      return (absNumber / 1.0e+9).toFixed(2).replace(/\.00$/, '') + "B";
    } else if (absNumber >= 1.0e+6) {
      return (absNumber / 1.0e+6).toFixed(2).replace(/\.00$/, '') + "M";
    } else if (absNumber >= 1.0e+3) {
      return (absNumber / 1.0e+3).toFixed(2).replace(/\.00$/, '') + "K";
    } else {
      return absNumber % 1 === 0 ? absNumber.toString() : absNumber.toFixed(5);
    }
  };
  
  export default formatBalance;
