// import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
// import axios from 'axios';
// import TradeViewChart from 'react-crypto-chart';
// import "../../assets/styles/games/game_core4.css";
// import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg"
// import CryptoPocket from "../../assets/images/Crypto/pocket.webp";
// import CryptoBinance from "../../assets/images/Crypto/binance.webp";
// import CryptoBit from "../../assets/images/Crypto/bybit.webp";
// import CryptoOkx from "../../assets/images/Crypto/okx.webp";
// import { useUser } from "../../contexts/UserContext";
// import {API_URLS} from "../../config/config";
// import TradeWarningModal from "../../components/TradeWarningModal";
// import modalBackground from "../../assets/images/fairy-modal-background.png";
// import formatBalance from "../../utils/formatBalance";

// function Four() {
//     const [chartColor] = useState('#1E53E5');
//     const [betAmount, setBetAmount] = useState('');
//     const { user, updateUser } = useUser();
//     const [localBalance, setLocalBalance] = useState(user.g_token);
//     const [isLoading, setIsLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [gameResult, setGameResult] = useState(null);
//     const [btcUsdtPrice, setBtcUsdtPrice] = useState("0.00");
//     const [timer, setTimer] = useState(180);
//     const [isTimerRunning, setIsTimerRunning] = useState(false);
//     const [isRequestPending, setIsRequestPending] = useState(false);
//     const [modalState, setModalState] = useState({
//         isOpen: false,
//         message: "",
//         link: "",
//     });
//     const [exchangeLinks, setExchangeLinks] = useState({
//         pocketoption: "",
//         binance: "",
//         okx: "",
//         bybit: ""
//     });
//     const [showBetPlaced, setShowBetPlaced] = useState(false);
//     const timerRef = useRef(null);
//     const [startPrice, setStartPrice] = useState(null);
//     const [priceColor, setPriceColor] = useState('white');

//     useEffect(() => {
//         const fetchExchangeLinks = async () => {
//             try {
//                 const response = await axios.post(API_URLS.REACT_APP_GAME4_LINKSS);
//                 setExchangeLinks(response.data);
//             } catch (error) {
//                 console.error("Error fetching exchange links:", error);
//             }
//         };

//         fetchExchangeLinks();
//     }, []);

//     const updateModalState = (message, isOpen = true, link = "") => {
//         setModalState({ isOpen, message, link });
//     };

//     const updateLocalBalance = useCallback((amount) => {
//         setLocalBalance(prevBalance => {
//             const newBalance = (parseFloat(prevBalance) + parseFloat(amount)).toFixed(5);
//             updateUser({ ...user, g_token: newBalance });
//             return newBalance;
//         });
//     }, [user, updateUser]);

//     const formatPrice = (price) => {
//         return (parseFloat(price) / 1000).toFixed(3);
//     };

//     const styles = useMemo(() => ({
//         input: {
//             width: '100%',
//             padding: '5px',
//             color: 'white',
//             fontSize: '18px',
//             backgroundColor: 'transparent',
//             borderRadius: '4px',
//             outline: 'none',
//         },
//         whiteText: {
//             color: 'white',
//         },
//         resultText: {
//             color: 'white',
//             textAlign: 'center',
//             marginTop: '10px',
//         },
//         timerText: {
//             color: 'white',
//             textAlign: 'center',
//             fontSize: '24px',
//             marginTop: '10px',
//         },
//         betPlacedMessage: {
//             position: 'fixed',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             backgroundColor: 'rgba(0, 0, 0, 0.8)',
//             color: 'white',
//             padding: '20px',
//             borderRadius: '10px',
//             zIndex: 1000,
//             transition: 'opacity 0.5s ease-in-out',
//         },
//         priceColumns: {
//             display: 'flex',
//             justifyContent: 'space-between',
//             marginTop: '10px',
//             padding: '0 10px',
//         },
//         priceColumn: {
//             flex: 1,
//             textAlign: 'center',
//             color: 'white',
//         },
//         currentPrice: {
//             color: priceColor,
//             transition: 'color 0.3s ease',
//         },
//     }), [priceColor]);

//     const handleBetChange = useCallback((e) => {
//         setBetAmount(e.target.value);
//     }, []);

//     const startTimer = useCallback(() => {
//         setTimer(180);
//         setIsTimerRunning(true);
//         timerRef.current = setInterval(() => {
//             setTimer((prevTimer) => {
//                 if (prevTimer <= 0) {
//                     clearInterval(timerRef.current);
//                     setIsTimerRunning(false);
//                     return 0;
//                 }
//                 return prevTimer - 0.1;
//             });
//         }, 100);
//     }, []);

//     const stopTimer = useCallback(() => {
//         setIsTimerRunning(false);
//         if (timerRef.current) {
//             clearInterval(timerRef.current);
//         }
//     }, []);

//     useEffect(() => {
//         return () => {
//             if (timerRef.current) {
//                 clearInterval(timerRef.current);
//             }
//         };
//     }, []);

//     useEffect(() => {
//         let timeout;
//         if (showBetPlaced) {
//             timeout = setTimeout(() => {
//                 setShowBetPlaced(false);
//             }, 3000);
//         }
//         return () => clearTimeout(timeout);
//     }, [showBetPlaced]);

//     const updatePriceColor = useCallback(() => {
//         if (startPrice && btcUsdtPrice) {
//             if (parseFloat(btcUsdtPrice) > parseFloat(startPrice)) {
//                 setPriceColor('green');
//             } else if (parseFloat(btcUsdtPrice) < parseFloat(startPrice)) {
//                 setPriceColor('red');
//             } else {
//                 setPriceColor('white');
//             }
//         }
//     }, [startPrice, btcUsdtPrice]);

//     useEffect(() => {
//         updatePriceColor();
//     }, [btcUsdtPrice, updatePriceColor]);

//     const placeBet = useCallback(async (isUp) => {
//         if (isRequestPending) {
//             return;
//         }

//         setError(null);
//         setGameResult(null);
//         setIsLoading(true);
//         setIsRequestPending(true);
//         startTimer();
//         setShowBetPlaced(true);
//         setStartPrice(btcUsdtPrice);
//         setPriceColor('white');  // Reset color when new bet is placed

//         const bet = parseFloat(betAmount);
//         if (isNaN(bet) || bet <= 0) {
//             setError("Будь ласка, введіть правильну суму ставки.");
//             setIsLoading(false);
//             stopTimer();
//             setIsRequestPending(false);
//             setShowBetPlaced(false);
//             return;
//         }

//         try {
//             const response = await axios.post(API_URLS.REACT_APP_GAME4_TAKEBET, {
//                 tg_user_id: user.user_id,
//                 bet: bet,
//                 is_up: isUp ? 1 : 0
//             });

//             const data = response.data;

//             if (data.status === "ok") {
//                 const winAmount = data.new_g_token - localBalance;
//                 const resultMessage = data.is_win
//                     ? `Ви виграли! ${winAmount.toFixed(2)} токенів`
//                     : `Ви програли. ${Math.abs(winAmount).toFixed(2)} токенів`;

//                 setGameResult({
//                     isWin: data.is_win,
//                     oldBalance: data.old,
//                     newBalance: data.new,
//                     winAmount: winAmount
//                 });
//                 updateLocalBalance(data.new_g_token.toString());
//                 updateModalState(resultMessage, true);
//             } else {
//                 setError("Невідома помилка. Спробуйте ще раз.");
//             }
//         } catch (err) {
//             console.error('Помилка запиту:', err);
//             setError("Сталася помилка. Будь ласка, спробуйте ще раз.");
//         } finally {
//             setIsLoading(false);
//             stopTimer();
//             setIsRequestPending(false);
//         }
//     }, [betAmount, user.user_id, updateLocalBalance, isRequestPending, updateModalState, startTimer, stopTimer, btcUsdtPrice]);

//     const memoizedTradeViewChart = useMemo(() => (
//         <TradeViewChart
//             containerStyle={{
//                 minHeight: '250px',
//                 minWidth: '350px',
//                 maxHeight: '250px',
//                 maxWidth: '350px',
//                 backgroundColor: 'black',
//                 marginBottom: '10px',
//             }}
//             pair="BTCUSDT"
//             chartLayout={{
//                 priceScale: {
//                     scaleMargins: {
//                         top: 0.001,
//                         bottom: 0.01,
//                     },
//                 },
//                 grid: {
//                     vertLines: {
//                         color: 'rgba(70, 130, 180, 0.1)',
//                         style: 1,
//                         visible: true,
//                     },
//                     horzLines: {
//                         color: 'rgba(70, 130, 180, 0.1)',
//                         style: 1,
//                         visible: true,
//                     },
//                 },
//             }}
//             candleStickConfig={{
//                 upColor: chartColor,
//                 downColor: '#880808',
//                 borderUpColor: chartColor,
//                 borderDownColor: '#880808',
//                 wickUpColor: chartColor,
//                 wickDownColor: '#880808',
//             }}
//             onPriceUpdate={(price) => setBtcUsdtPrice(price.toFixed(2))}
//         />
//     ), [chartColor]);

//     return (
//         <div className="graph_game_cont">
//             <div className="cont-game-4">
//                 <div className="earning-game">
//                     <div className="earning-content-game">
//                         <div className="earning-icon-container-game">
//                             <IconCoin className="earning-icon-small-game" />
//                             <p className="token-game" style={styles.whiteText}>Token</p>
//                         </div>
//                         <div className="earning-text-container-game">
//                             <p className="earning-text-game" style={styles.whiteText}>{formatBalance(localBalance)}</p>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="earning-game">
//                     <div className="earning-content-game">
//                         <div className="earning-icon-container-game2">
//                             <p className="earning-text-game" style={styles.whiteText}>
//                                 {isTimerRunning ? timer.toFixed(1) : "180.0"}
//                             </p>
//                         </div>
//                         <div className="earning-text-container-game">
//                             <p className="earning-text-game" style={styles.whiteText}>BTC/USDT</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="graph-cont">
//                 <div className="chart_wrapper">
//                     {memoizedTradeViewChart}
//                 </div>
//             </div>

//             <div style={styles.priceColumns}>
//                 <div style={styles.priceColumn}>
//                     <p>{startPrice ? formatPrice(startPrice) : "-"}</p>
//                 </div>
//                 <div style={styles.priceColumn}>
//                     <p style={styles.currentPrice}>{formatPrice(btcUsdtPrice)}</p>
//                 </div>
//             </div>

//             <div className="game-playwith">
//                 <p className="game-playwith-text">Переходь на біржу</p>
//                 <div className="img-show">
//                     <div className="img-container">
//                         <a href={exchangeLinks.pocketoption} target="_blank" rel="noopener noreferrer">
//                             <img src={CryptoPocket} alt="CryptoPocket" />
//                         </a>
//                     </div>
//                     <div className="img-container">
//                         <a href={exchangeLinks.binance} target="_blank" rel="noopener noreferrer">
//                             <img src={CryptoBinance} alt="CryptoBinance" />
//                         </a>
//                     </div>
//                     <div className="img-container">
//                         <a href={exchangeLinks.okx} target="_blank" rel="noopener noreferrer">
//                             <img src={CryptoOkx} alt="CryptoOkx" />
//                         </a>
//                     </div>
//                     <div className="img-container">
//                         <a href={exchangeLinks.bybit} target="_blank" rel="noopener noreferrer">
//                             <img src={CryptoBit} alt="CryptoBit" />
//                         </a>
//                     </div>
//                 </div>
//                 <p className="game-playwith-text2">Заробляй разом з нами!</p>
//             </div>

//             <div className="game-buttom-list">
//                 <div className="earning-game">
//                     <div className="earning-content-game">
//                         <div className="earning-icon-container-game1">
//                             <IconCoin className="earning-icon-small-game"/>
//                         </div>
//                         <div className="earning-content-game">
//                             <div className="earning-text-container-game">
//                                 <input
//                                     type="text"
//                                     inputMode="decimal"
//                                     placeholder="0.001"
//                                     style={styles.input}
//                                     value={betAmount}
//                                     onChange={handleBetChange}
//                                     disabled={isRequestPending}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <button
//                     className="game-button-up"
//                     onClick={() => placeBet(true)}
//                     disabled={isLoading || isRequestPending}
//                 >
//                     Вверх
//                 </button>
//                 <button
//                     className="game-button-down"
//                     onClick={() => placeBet(false)}
//                     disabled={isLoading || isRequestPending}
//                 >
//                     Вниз
//                 </button>
//             </div>
//             {error && <div className="error-message" style={{color: 'red', textAlign: 'center', marginTop: '10px'}}>{error}</div>}
//             {showBetPlaced && (
//                 <div style={{
//                     ...styles.betPlacedMessage,
//                     opacity: showBetPlaced ? 1 : 0
//                 }}>
//                     Ставку зроблено!
//                 </div>
//             )}
//             <TradeWarningModal
//                 key={modalState.isOpen ? "open" : "closed"}
//                 modalState={modalState}
//                 background={modalBackground}
//                 onClose={() => updateModalState("", false)}
//             />
//         </div>
//     );
// }

// export default React.memo(Four);

import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import TradeViewChart from "react-crypto-chart";
import "../../assets/styles/games/game_core4.css";
import { useAudio } from "../../contexts/AudioContext";
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
import { useTranslation } from "react-i18next";
import PocketoptionSmal from "../../assets/icons/pocketoption.webp";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { useUser } from "../../contexts/UserContext";
import { API_URLS } from "../../config/config";
import PartnersModal  from "../../components/games/PartnersModal";
import TradeWarningModal from "../../components/TradeWarningModal";
import modalBackground from "../../assets/images/fairy-modal-background.png";
import formatBalance from "../../utils/formatBalance";
import backSound from "../../assets/sounds/game4-back.mp3";
import iconArrowLeft from "../../assets/icons/arrow-left.png";
import clickSoundMp3 from '../../assets/sounds/menu-button-click.mp3';
import moneySoundMp3 from "../../assets/sounds/bonus-money-sound.mp3";

function Four() {
  const [chartColor] = useState("#1E53E5");
  const [betAmount, setBetAmount] = useState("");
  const { user, updateUser } = useUser();
  const [localBalance, setLocalBalance] = useState(user.g_token);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gameResult, setGameResult] = useState(null);
  const [btcUsdtPrice, setBtcUsdtPrice] = useState("0.00");
  const [timer, setTimer] = useState(180);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isAdModalOpen, setIsAdModalOpen] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    link: "",
  });
  const [exchangeLinks, setExchangeLinks] = useState({});
  const [isLinksLoading, setIsLinksLoading] = useState(true);
  const [showBetPlaced, setShowBetPlaced] = useState(false);
  const [betStartPrice, setBetStartPrice] = useState(null);
  const [betEndPrice, setBetEndPrice] = useState(null);
  const [userBet, setUserBet] = useState(null);
  const [betDirection, setBetDirection] = useState(null);
  const [betResult, setBetResult] = useState(null);
  const [winAmount, setWinAmount] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const timerRef = useRef(null);
  const [startPrice, setStartPrice] = useState(null);
  const [priceColor, setPriceColor] = useState("white");
  const { t } = useTranslation();
  const wsRef = useRef(null);
  const { changeBackgroundMusic, revertToDefaultMusic, activeMusic } = useAudio();
  const { effectsVolume, generalVolume } = useAudio();
const [isAudioInitialized, setIsAudioInitialized] = useState(false);
const audioContextRef = useRef(null);
const clickAudioBufferRef = useRef(null);
const moneyAudioBufferRef = useRef(null);


const playSound = useCallback(() => {
  if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
    const source = audioContextRef.current.createBufferSource();
    source.buffer = clickAudioBufferRef.current;
    
    const gainNode = audioContextRef.current.createGain();
    gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
    
    source.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);
    
    source.start(0);
  }
}, [isAudioInitialized, effectsVolume, generalVolume]);

const playMoneySound = useCallback(() => {
  if (isAudioInitialized && audioContextRef.current && moneyAudioBufferRef.current) {
    const source = audioContextRef.current.createBufferSource();
    source.buffer = moneyAudioBufferRef.current;

    const gainNode = audioContextRef.current.createGain();
    gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

    source.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);

    source.start(0);
  }
}, [isAudioInitialized, effectsVolume, generalVolume]);

useEffect(() => {
  const initializeAudio = async () => {
    if (!isAudioInitialized) {
      try {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        audioContextRef.current = new AudioContext();

        const clickResponse = await fetch(clickSoundMp3);
        const clickArrayBuffer = await clickResponse.arrayBuffer();
        clickAudioBufferRef.current = await audioContextRef.current.decodeAudioData(clickArrayBuffer);

        const moneyResponse = await fetch(moneySoundMp3);
        const moneyArrayBuffer = await moneyResponse.arrayBuffer();
        moneyAudioBufferRef.current = await audioContextRef.current.decodeAudioData(moneyArrayBuffer);

        setIsAudioInitialized(true);
      } catch (error) {
        console.error("Error initializing audio:", error);
      }
    }
  };

  initializeAudio();
}, [isAudioInitialized]);

//   useEffect(() => {
//     const handleAudioChange = async () => {
//         if (activeMusic !== backSound) {
//             await changeBackgroundMusic(backSound);
//         }
//     };

//     handleAudioChange();

//     return () => {
//         revertToDefaultMusic();
//     };
// }, [changeBackgroundMusic, revertToDefaultMusic, activeMusic]);

useEffect(() => {
  const fetchExchangeLinks = async () => {
    setIsLinksLoading(true);
    try {
      const response = await axios.post(API_URLS.REACT_APP_GAME4_LINKSS);
      if (response.data && typeof response.data === 'object') {
        setExchangeLinks(response.data);
      } else {
        console.error("Unexpected data structure from API:", response.data);
      }
    } catch (error) {
      console.error("Error fetching exchange links:", error);
    } finally {
      setIsLinksLoading(false);
    }
  };

  fetchExchangeLinks();
}, []);

  const ArrowUp = ({ color }) => (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 8.5L9 0.5M9 0.5L1 0.5M9 0.5L1 8.5" stroke={color}/>
    </svg>
  );

  const ArrowDown = ({ color }) => (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 8.5H9M9 8.5V0.5M9 8.5L1 0.5" stroke={color}/>
    </svg>
  );

  const getBetColor = () => {
    switch(betDirection) {
      case 'up':
        return '#15FF00';
      case 'down':
        return '#FF0000';
      default:
        return 'white';
    }
  };

  const handlePocketoptionClick = useCallback(() => {
    playSound();
    setIsAdModalOpen(true);

  }, []);

  const getEndPriceColor = () => {
    if (!betEndPrice || !betStartPrice) return 'white';
    return parseFloat(betEndPrice) > parseFloat(betStartPrice) ? '#15FF00' : '#FF0000';
  };

  const EndPriceArrow = () => {
    if (!betEndPrice || !betStartPrice) return null;
    const color = getEndPriceColor();
    return parseFloat(betEndPrice) > parseFloat(betStartPrice) ? 
      <ArrowUp color={color} /> : 
      <ArrowDown color={color} />;
  };

  const updateModalState = (message, isOpen = true, link = "") => {
    setModalState({ isOpen, message, link });
  };

  // const updateLocalBalance = useCallback(
  //   (amount) => {
  //     setLocalBalance((prevBalance) => {
  //       const newBalance = (parseFloat(prevBalance) + parseFloat(amount)).toFixed(5);
  //       updateUser({ ...user, g_token: newBalance });
  //       return newBalance;
  //     });
  //   },
  //   [user, updateUser]
  // );

  const formatPrice = (price) => {
    return (parseFloat(price) / 1000).toFixed(3);
  };

  const styles = useMemo(
    () => ({
      input: {
        width: "100%",
        padding: "5px",
        color: "white",
        fontSize: "18px",
        backgroundColor: "transparent",
        borderRadius: "4px",
        outline: "none",
      },
      whiteText: {
        color: "white",
      },
      resultText: {
        color: "white",
        textAlign: "center",
        marginTop: "10px",
      },
      timerText: {
        color: "white",
        textAlign: "center",
        fontSize: "24px",
        marginTop: "10px",
      },
      betPlacedMessage: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: "20px",
        borderRadius: "10px",
        zIndex: 1000,
        transition: "opacity 0.5s ease-in-out",
      },
      priceColumns: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px",
        padding: "0 10px",
      },
      priceColumn: {
        flex: 1,
        textAlign: "center",
        color: "white",
      },
      currentPrice: {
        color: priceColor,
        transition: "color 0.3s ease",
      },
    }),
    [priceColor]
  );

  const handleBetChange = useCallback((e) => {
    setBetAmount(e.target.value);
    connectWebSocket();
  }, []);

//   const startTimer = useCallback(() => {
//     setTimer(180);
//     setIsTimerRunning(true);
//     timerRef.current = setInterval(() => {
//       setTimer((prevTimer) => {
//         if (prevTimer <= 0) {
//           clearInterval(timerRef.current);
//           setIsTimerRunning(false);
//           return 0;
//         }
//         return prevTimer - 0.1;
//       });
//     }, 100);
//   }, []);

//   const stopTimer = useCallback(() => {
//     setIsTimerRunning(false);
//     if (timerRef.current) {
//       clearInterval(timerRef.current);
//     }
//   }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (showBetPlaced) {
      timeout = setTimeout(() => {
        setShowBetPlaced(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [showBetPlaced]);

//   const connectWebSocket = useCallback(() => {
//     try {
//       const wsUrl = "wss://" + API_URLS.WEBS_GAME4_CONNECT;

//       if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
//         return;
//       }

//       wsRef.current = new WebSocket(wsUrl);

//       const timeoutId = setTimeout(() => {
//         if (wsRef.current && wsRef.current.readyState === WebSocket.CONNECTING) {
//           wsRef.current.close();
//           setError(t('GamePage.Game3.ConnectError'));
//         }
//       }, 10000);

//       wsRef.current.onopen = (event) => {
//         clearTimeout(timeoutId);
//         setError(null);
//       };

//       wsRef.current.onmessage = (event) => {
//         const data = JSON.parse(event.data);
//         console.log("WebSocket Data:", data);
//         if (data) {
//           setCurrentBtcPrice(data);
//         }
//       };

//       wsRef.current.onclose = (event) => {
//         clearTimeout(timeoutId);
//         setTimeout(connectWebSocket, 5000);
//       };

//       wsRef.current.onerror = (error) => {
//         clearTimeout(timeoutId);
//       };
//     } catch (error) {
//       console.error("WebSocket connection error:", error);
//     }
//   }, [t]);

//   useEffect(() => {
//     connectWebSocket();

//     return () => {
//       if (wsRef.current) {
//         wsRef.current.close();
//       }
//     };
//   }, [connectWebSocket]);

//   const placeBet = useCallback(
//     async (isUp) => {
//       if (isRequestPending) {
//         return;
//       }

//       setError(null);
//       setGameResult(null);
//       setIsLoading(true);
//       setIsRequestPending(true);
//       startTimer();
//       setShowBetPlaced(true);
//       setBetStartPrice(currentBtcPrice);
//       setPriceColor("white");

//       const bet = parseFloat(betAmount);
//       if (isNaN(bet) || bet <= 0) {
//         setError(t("GamePage.Game4.IncorrectBet"));
//         setIsLoading(false);
//         stopTimer();
//         setIsRequestPending(false);
//         setShowBetPlaced(false);
//         return;
//       }

//       try {
//         const response = await axios.post(API_URLS.REACT_APP_GAME4_TAKEBET, {
//           tg_user_id: user.user_id,
//           bet: bet,
//           is_up: isUp ? 1 : 0,
//         });

//         const data = response.data;

//         if (data.status === "ok") {
//           // ... (handle successful bet)
//         } else {
//           setError(t("GamePage.Game4.UnexpectedError"));
//         }
//       } catch (err) {
//         console.error("Помилка запиту:", err);
//         setError(t("GamePage.Game4.UnexpectedError"));
//       } finally {
//         setIsLoading(false);
//         stopTimer();
//         setIsRequestPending(false);
//       }
//     },
//     [
//       betAmount,
//       user.user_id,
//       updateLocalBalance,
//       isRequestPending,
//       updateModalState,
//       startTimer,
//       stopTimer,
//       currentBtcPrice,
//       t
//     ]
//   );
const closeWebSocket = useCallback(() => {
  if (wsRef.current) {
    wsRef.current.close();
  }
}, []);
const connectWebSocket = useCallback(() => {
  if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
    return;
  }

  wsRef.current = new WebSocket("wss://" + API_URLS.WEBS_GAME4_CONNECT);

  wsRef.current.onopen = () => {
    console.log("WebSocket connected");
  };

  wsRef.current.onmessage = (event) => {
    const data = JSON.parse(event.data);
    console.log("WebSocket Data:", data);
    if (data.status === 'ok' && data.data) {
      const price = typeof data.data === 'number' ? data.data.toFixed(2) : data.data;
      setCurrentPrice(price);
    }
  };

  wsRef.current.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  wsRef.current.onclose = () => {
    console.log("WebSocket disconnected");
  };
}, []);

  // useEffect(() => {
  //   connectWebSocket();

  //   return () => {
  //     if (wsRef.current) {
  //       wsRef.current.close();
  //     }
  //   };
  // }, [connectWebSocket]);

  const startTimer = useCallback(() => {
    setTimer(180);
    setIsTimerRunning(true);
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(timerRef.current);
          setIsTimerRunning(false);
          stopTimer();
          return 0;
        }
        return prevTimer - 0.1;
      });
    }, 100);
  }, []);

  const stopTimer = useCallback(() => {
    setIsTimerRunning(false);
    setIsRequestPending(false);
    console.log("USER BET", userBet);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({ action: 'getFinalPrice' }));
      
      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.status === 'ok' && data.data) {
          const finalPrice = typeof data.data === 'number' ? data.data.toFixed(2) : data.data;
          setBetEndPrice(finalPrice);
          const isWin = (betDirection === 'up' && parseFloat(finalPrice) > parseFloat(betStartPrice)) || 
                        (betDirection === 'down' && parseFloat(finalPrice) < parseFloat(betStartPrice));
          setBetResult(isWin ? 'lose' : 'win');
          closeWebSocket();
          // Calculate winAmount based on the bet result
          // const calculatedWinAmount = isWin ? parseFloat(userBet) * 2 : -parseFloat(userBet);
          // setWinAmount(calculatedWinAmount);
          
          // Show modal with result
          
          
          // Update local balance with winnings (if any)
          // if (isWin) {
          //   updateLocalBalance(calculatedWinAmount);
          // }

          // Close the WebSocket connection after getting the final price
         //wsRef.current.close();
        }
      };
    }
  }, [betDirection, betStartPrice, userBet, closeWebSocket]);

  const placeBet = useCallback(
    async (isUp) => {
      if (isRequestPending) {
        return;
      }
      playSound();
      setError(null);
      setGameResult(null);
      setIsLoading(true);
      setIsRequestPending(true);
      setBetDirection(isUp ? 'up' : 'down');
      setBetStartPrice(currentPrice);
      setBetEndPrice(null);
      setUserBet(betAmount);
      startTimer();
      setShowBetPlaced(true);
      setPriceColor("white");

      const bet = parseFloat(betAmount);
      console.log("Set bet localBalance", localBalance);
      console.log("Set bet betAmount", bet);

      const newBalance = localBalance-bet;
      console.log("Set bet newBalance", newBalance.toFixed(6)  );

      updateUser({ g_token: newBalance.toFixed(6) });
      if (isNaN(bet) || bet <= 0) {
        setError(t("GamePage.Game4.IncorrectBet"));
        setIsLoading(false);
        stopTimer();
        setIsRequestPending(false);
        setShowBetPlaced(false);
        return;
      }

      try {
        const response = await axios.post(API_URLS.REACT_APP_GAME4_TAKEBET, {
          tg_user_id: user.user_id,
          bet: bet,
          is_up: isUp ? 1 : 0,
        });


        const data = response.data;
        console.log("DDDDDDDDDDDDDDDD",response);
        if (data.status === "ok") {
          playMoneySound();
          console.log("AAAAAAAAAAAAAA");
          const calculatedWinAmount = data.new_g_token - localBalance;
          setWinAmount(calculatedWinAmount);
          console.log("localBalance", localBalance);
          console.log("calculatedWinAmount", calculatedWinAmount);
          updateUser({ g_token: data.new_g_token });
          const resultMessage = !data.isWin
          ? t("GamePage.Game4.ResultMessage.Win", { winAmount: Math.abs(calculatedWinAmount).toFixed(2) })
          : t("GamePage.Game4.ResultMessage.Lose", { winAmount: Math.abs(calculatedWinAmount*2).toFixed(2) });
        updateModalState(resultMessage, true);
        } 
        else if(data.status === "error" || data.error_code==='551'){
          updateModalState(t("GamePage.NotEnoughBalance"), true);
        }
        else {
          updateModalState(t("GamePage.UnexpectedError"), true);
        }
      } catch (err) {
        console.error("Помилка запиту:", err);
        setError(t("GamePage.UnexpectedError"));
      } finally {
        setIsLoading(false);
        setIsRequestPending(false);
      }
    },
    [
      updateUser,
      user,
      betAmount,
      localBalance,
      isRequestPending,
      startTimer,
      stopTimer,
      currentPrice,
      t
    ]
  );

  // useEffect(() => {
  //     connectWebSocket();

  //     return () => {
  //         if (wsRef.current) {
  //             wsRef.current.close();
  //         }
  //     };
  // }, [connectWebSocket]);

  const updatePriceColor = useCallback(() => {
    if (startPrice && btcUsdtPrice) {
      if (parseFloat(btcUsdtPrice) > parseFloat(startPrice)) {
        setPriceColor("green");
      } else if (parseFloat(btcUsdtPrice) < parseFloat(startPrice)) {
        setPriceColor("red");
      } else {
        setPriceColor("white");
      }
    }
  }, [startPrice, btcUsdtPrice]);

  useEffect(() => {
    updatePriceColor();
  }, [btcUsdtPrice, updatePriceColor]);

//   const placeBet = useCallback(
//     async (isUp) => {
//       if (isRequestPending) {
//         return;
//       }

//       setError(null);
//       setGameResult(null);
//       setIsLoading(true);
//       setIsRequestPending(true);
//       startTimer();
//       setShowBetPlaced(true);
//       setStartPrice(btcUsdtPrice);
//       setPriceColor("white"); // Reset color when new bet is placed

//       const bet = parseFloat(betAmount);
//       if (isNaN(bet) || bet <= 0) {
//         setError(t("GamePage.Game4.IncorrectBet"));
//         setIsLoading(false);
//         stopTimer();
//         setIsRequestPending(false);
//         setShowBetPlaced(false);
//         return;
//       }

//       try {
//         const response = await axios.post(API_URLS.REACT_APP_GAME4_TAKEBET, {
//           tg_user_id: user.user_id,
//           bet: bet,
//           is_up: isUp ? 1 : 0,
//         });

//         const data = response.data;

//         if (data.status === "ok") {
//           const winAmount = data.new_g_token - localBalance;
//           const resultMessage = data.is_win
//             ? t("GamePage.Game4.ResultMessage.Win", {
//                 winAmount: winAmount.toFixed(2),
//               })
//             : t("GamePage.Game4.ResultMessage.Lose", {
//                 winAmount: Math.abs(winAmount).toFixed(2),
//               });

//           setGameResult({
//             isWin: data.is_win,
//             oldBalance: data.old,
//             newBalance: data.new,
//             winAmount: winAmount,
//           });
//           updateLocalBalance(data.new_g_token.toString());
//           updateModalState(resultMessage, true);
//         } else {
//           setError(t("GamePage.Game4.UnexpectedError"));
//         }
//       } catch (err) {
//         console.error("Помилка запиту:", err);
//         setError(t("GamePage.Game4.UnexpectedError"));
//       } finally {
//         setIsLoading(false);
//         stopTimer();
//         setIsRequestPending(false);
//       }
//     },
//     [
//       betAmount,
//       user.user_id,
//       updateLocalBalance,
//       isRequestPending,
//       updateModalState,
//       startTimer,
//       stopTimer,
//       btcUsdtPrice,
//     ]
//   );

  const memoizedTradeViewChart = useMemo(
    () => (
      <TradeViewChart
        containerStyle={{
          // minHeight: "250px",
          // minWidth: "350px",
          // maxHeight: "250px",
          // maxWidth: "350px",
          // backgroundColor: "black",
          // marginBottom: "10px",
          minHeight: "20vh",
          minWidth: "80vw",
          maxHeight: "50vh",
          maxWidth: "90vw",
          backgroundColor: "black",
          marginBottom: "10px",
        }}
        pair="BTCUSDT"
        chartLayout={{
          priceScale: {
            scaleMargins: {
              top: 0.001,
              bottom: 0.01,
            },
          },
          grid: {
            vertLines: {
              color: "rgba(70, 130, 180, 0.1)",
              style: 1,
              visible: true,
            },
            horzLines: {
              color: "rgba(70, 130, 180, 0.1)",
              style: 1,
              visible: true,
            },
          },
        }}
        candleStickConfig={{
          upColor: chartColor,
          downColor: "#880808",
          borderUpColor: chartColor,
          borderDownColor: "#880808",
          wickUpColor: chartColor,
          wickDownColor: "#880808",
        }}
        onPriceUpdate={(price) => setBtcUsdtPrice(price.toFixed(2))}
      />
    ),
    [chartColor]
  );

  return (
    <div className="graph_game_cont">
       <PartnersModal
        iconArrowLeft={iconArrowLeft}
        background={modalBackground}
        isOpen={isAdModalOpen}
        onClose={() => setIsAdModalOpen(false)}
        exchangeLinks={exchangeLinks}
        isLinksLoading={isLinksLoading}
      />
      <div className="cont-game-4">
        <div className="earning-game">
          <div className="earning-content-game">
            <div className="earning-icon-container-game">
              <IconCoin className="earning-icon-small-game" />
              <p className="token-game" style={styles.whiteText}>
                Token
              </p>
            </div>
            <div className="earning-text-container-game">
              <p className="earning-text-game" style={styles.whiteText}>
                {user.g_token}
              </p>
            </div>
          </div>
        </div>
        <div className="earning-game">
          <div className="earning-content-game">
            <div className="earning-icon-container-game2">
              <p className="earning-text-game" style={styles.whiteText}>
                {isTimerRunning ? timer.toFixed(1) : "180.0"}
              </p>
            </div>
            <div className="earning-text-container-game">
              <p className="earning-text-game" style={styles.whiteText}>
                BTC/USDT
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="graph-cont">
        <div className="chart_wrapper">{memoizedTradeViewChart}</div>
      </div>
      <div className="game-buttom-list2">
        <div className="flex flex-col justify-end w-1/3">
          <p className="bet-label">{t("GamePage.Game4.Bet")}</p>
          <div className="earning-game-b-price">
            <div className="earning-content-game">
              <div className="earning-text-container-game flex flex-row">
              <p className="earning-text-game mr-1" style={{
                  ...styles.whiteText,
                  color: getBetColor()
                }}>
                  {betStartPrice || "-"}
                </p>
                {betDirection === 'up' && <ArrowUp color={getBetColor()} />}
                {betDirection === 'down' && <ArrowDown color={getBetColor()} />}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-end  w-1/3">
          <p className="bet-label">{t("GamePage.Game4.Ending")}</p>
          <div className="earning-game-b-price">
            <div className="earning-content-game">
              <div className="earning-text-container-game">
              <p className="earning-text-game mr-1" style={{
                  ...styles.whiteText,
                  color: getEndPriceColor()
                }}>
                  {betEndPrice || "-"}
                </p>
                <EndPriceArrow />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-end  w-1/4">
          <p className="bet-label"></p>
          <div className="earning-game-b-price">
            <div className="earning-content-game">
            <div className="earning-icon-container-game ml-1">
                                <IconCoin className="earning-icon-small-game" />
                                <p className="token-game" style={styles.whiteText}>Token</p>
                            </div>
              <div className="earning-text-container-game">
                {/* <p className="earning-text-game" style={styles.whiteText}>
                {userBet || "-"}
                </p> */}
                 <p className="earning-text-game" style={{
                  ...styles.whiteText,
                  color: betResult === 'win' ? '#15FF00' : betResult === 'lose' ? '#FF0000' : 'white'
                }}>
                  {betResult ? 
                    (betResult === 'win' ? 
                      `+${(parseFloat(userBet) * 2).toFixed(2)}` : 
                      `-${parseFloat(userBet).toFixed(2)}`) : 
                    userBet || "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      <div className="flex flex-col justify-start  w-12">
          <div className="earning-game-b-price" onClick={handlePocketoptionClick}>
          <img src={PocketoptionSmal} alt="CryptoPocket" className="w-full h-full" />
          </div>
          </div>
      </div>

      {/* <div className="game-playwith">
                <p className="game-playwith-text">{t("GamePage.Game4.GoToStock")}</p>
                <div className="img-show">
                    <div className="img-container">
                        <a href={exchangeLinks.pocketoption} target="_blank" rel="noopener noreferrer">
                            <img src={CryptoPocket} alt="CryptoPocket" />
                        </a>
                    </div>
                    <div className="img-container">
                        <a href={exchangeLinks.binance} target="_blank" rel="noopener noreferrer">
                            <img src={CryptoBinance} alt="CryptoBinance" />
                        </a>
                    </div>
                    <div className="img-container">
                        <a href={exchangeLinks.okx} target="_blank" rel="noopener noreferrer">
                            <img src={CryptoOkx} alt="CryptoOkx" />
                        </a>
                    </div>
                    <div className="img-container">
                        <a href={exchangeLinks.bybit} target="_blank" rel="noopener noreferrer">
                            <img src={CryptoBit} alt="CryptoBit" />
                        </a>
                    </div>
                </div>
                <p className="game-playwith-text2">{t("GamePage.Game4.EarnWithUs")}</p>
            </div> */}

      <div className="game-buttom-list">
        <div className="earning-game">
          <div className="earning-content-game">
            <div className="earning-icon-container-game1">
              <IconCoin className="earning-icon-small-game" />
            </div>
            <div className="earning-content-game">
              <div className="earning-text-container-game">
                <input
                  type="text"
                  inputMode="decimal"
                  placeholder="0.001"
                  style={styles.input}
                  value={betAmount}
                  onChange={handleBetChange}
                  disabled={isRequestPending}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          className="game-button-up"
          onClick={() => placeBet(true)}
          disabled={isLoading || isRequestPending}
        >
          {t("GamePage.Game4.Up")}
        </button>
        <button
          className="game-button-down"
          onClick={() => placeBet(false)}
          disabled={isLoading || isRequestPending}
        >
          {t("GamePage.Game4.Down")}
        </button>
      </div>
      {error && (
        <div
          className="error-message"
          style={{ color: "red", textAlign: "center", marginTop: "10px" }}
        >
          {error}
        </div>
      )}
      {showBetPlaced && (
        <div
          style={{
            ...styles.betPlacedMessage,
            opacity: showBetPlaced ? 1 : 0,
          }}
        >
          {t("GamePage.Game4.BetMade")}
        </div>
      )}
      <TradeWarningModal
        key={modalState.isOpen ? "open" : "closed"}
        modalState={modalState}
        background={modalBackground}
        onClose={() => updateModalState("", false)}
        exchangeLinks={exchangeLinks}
      />
    </div>
  );
}

export default React.memo(Four);