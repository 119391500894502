// // LoadingScreen.js
// import React from 'react';
// import logo from '../assets/images/loadingScreen/Subtract.webp'

// const LoadingScreen = () => {
//   return (
//     <div className="progressPage">
//       {/* <Grid /> */}
//       <img className='logo_pr' src={logo}></img>

//       <p className='progress_title'>Грай, вигравай, заробляй!</p>
//       <ProgressBar progress={55} />
//     </div>
//   );
// };

// export default LoadingScreen;


// src/components/LoadingScreen.js


//LoadingScreen.js
import React from 'react';
import ProgressBar from '../components/ProgressBar';
import logo from '../assets/images/loadingScreen/Subtract.webp'
import backgroundImage from '../assets/images/loadingScreen/backgr.webp';
import '../assets/styles/LoadingPage.css'

const LoadingScreen = React.memo(({ progress }) => {
  return (
    <div className="progressPage" style={{backgroundImage: `url(${backgroundImage})`}}>
      <img className='logo_pr' src={logo}></img>
      <p className='progress_title'>Грай, вигравай, заробляй!</p>
      <ProgressBar progress={progress} />
    </div>
  );
});

export default LoadingScreen;