// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-in {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-in.visible {
    opacity: 1;
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/fade.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oCAAoC;EACtC;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".fade-in {\r\n    opacity: 0;\r\n    transition: opacity 0.5s ease-in-out;\r\n  }\r\n  \r\n  .fade-in.visible {\r\n    opacity: 1;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
