import React, { useMemo } from 'react';
import axios from 'axios';
import "../../assets/styles/games/game_core4.css";
import { useUser } from "../../contexts/UserContext";
import {API_URLS} from "../../config/config";

function Four() {


    const styles = useMemo(() => ({

    }), []);



    return (
        <div className="graph_game_cont">

        </div>
    );
}

export default React.memo(Four);