import React, { useState, useRef, useEffect, useCallback } from "react";
import backgroundImage from "../assets/images/settings-background.png";
import LanguageCarousel from "../components/LanguageCarusel";
import { useTranslation } from "react-i18next";
import { useAudio } from "../contexts/AudioContext";
import { useUser } from "../contexts/UserContext";
import { API_URLS } from "../config/config";
import axios from "axios";
import { getAvailableLocales } from "../i18n";
import clickSoundMp3 from '../assets/sounds/menu-button-click.mp3';
import "../assets/styles/settings.css";

const getLanguageDisplayNames = (locales) => {
  return locales.reduce((acc, code) => {
    const nameGenerator = new Intl.DisplayNames([code], { type: "language" });
    const displayName = nameGenerator.of(code);
    acc[code] = displayName.charAt(0).toUpperCase() + displayName.slice(1);
    return acc;
  }, {});
};

const getLanguageCodes = (displayNames) => {
  return Object.entries(displayNames).reduce((acc, [code, name]) => {
    acc[name] = code;
    return acc;
  }, {});
};

const availableLocales = getAvailableLocales();
const languageNames = getLanguageDisplayNames(availableLocales);
const languageCodes = getLanguageCodes(languageNames);

const Settings = () => {
  const { user, updateUser } = useUser();
  const { i18n, t } = useTranslation();
  const { setGeneralVolume, setEffectsVolume, setMusicVolume, updateVolume } = useAudio();

  const [isOn, setIsOn] = useState(!user.visual_effects);
  const [localGeneralVolume, setLocalGeneralVolume] = useState(user.general_volume);
  const [localEffectsVolume, setLocalEffectsVolume] = useState(user.effects_volume);
  const [localMusicVolume, setLocalMusicVolume] = useState(user.music_volume);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageNames[user.lang_code]);
  const [isSliderChanging, setIsSliderChanging] = useState(false);
  
  const generalSliderRef = useRef(null);
  const effectsSliderRef = useRef(null);
  const musicSliderRef = useRef(null);

  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  const initialSettings = useRef({
    isOn,
    generalVolume: user.general_volume,
    effectsVolume: user.effects_volume,
    musicVolume: user.music_volume,
    selectedLanguage,
  });

  const lastPlayedTimeRef = useRef(0);

  const updateLastActive = async (userId) => {
    try {
      const response = await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
      console.log('Last active time updated:', response.data);
    } catch (error) {
      console.error('Error updating last active time:', error);
    }
  };
  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([
            fetch(clickSoundMp3),
          ]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    const now = Date.now();
    if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current && !isSliderChanging && now - lastPlayedTimeRef.current > 300) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;
      
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
      
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      
      source.start(0);
      lastPlayedTimeRef.current = now;
    }
  }, [isAudioInitialized, effectsVolume, generalVolume, isSliderChanging]);


  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);


  const updateSliderBackground = (value, ref) => {
    if (ref.current) {
      const percentage = ((value - 0) / (100 - 0)) * 100;
      ref.current.style.setProperty("--slider-percentage", `${percentage}%`);
    }
  };

  const handleSliderMouseDown = () => {
    setIsSliderChanging(true);
  };

  const handleSliderMouseUp = () => {
    setIsSliderChanging(false);
  };

  useEffect(() => {
    updateSliderBackground(localGeneralVolume, generalSliderRef);
    updateSliderBackground(localEffectsVolume, effectsSliderRef);
    updateSliderBackground(localMusicVolume, musicSliderRef);

    const currentSettings = {
      isOn,
      generalVolume: localGeneralVolume,
      effectsVolume: localEffectsVolume,
      musicVolume: localMusicVolume,
      selectedLanguage,
    };

    setHasChanges(
      JSON.stringify(currentSettings) !==
        JSON.stringify(initialSettings.current)
    );
  }, [isOn, localGeneralVolume, localEffectsVolume, localMusicVolume, selectedLanguage]);

  const handleVolumeChange = (setter, sliderRef) => (e) => {
    const newValue = Number(e.target.value);
    setter(newValue);
    updateSliderBackground(newValue, sliderRef);
  };
  const handleLanguageChange = (newLanguage) => {
    playSound();
    setSelectedLanguage(newLanguage);
  };

  const handleVisualEffectsToggle = () => {
    playSound();  
    setIsOn(!isOn);
  };

  const handleSaveChanges = async () => {
    playSound();
    const languageCode = languageCodes[selectedLanguage];
    const settings = {
      userId: user.user_id,
      languageCode: languageCode,
      visualEffects: !isOn,
      generalVolume: localGeneralVolume,
      effectsVolume: localEffectsVolume,
      musicVolume: localMusicVolume,
    };

    try {
      const response = await axios.post(API_URLS.UPDATE_SETTINGS, settings);
      if (response) {
        initialSettings.current = {
          isOn,
          generalVolume: localGeneralVolume,
          effectsVolume: localEffectsVolume,
          musicVolume: localMusicVolume,
          selectedLanguage,
        };
        await i18n.changeLanguage(languageCode);
        updateUser({
          visual_effects: !isOn,
          general_volume: localGeneralVolume,
          effects_volume: localEffectsVolume,
          music_volume: localMusicVolume,
          lang_code: languageCode,
        });

        setGeneralVolume(localGeneralVolume);
        setEffectsVolume(localEffectsVolume);
        setMusicVolume(localMusicVolume);
        updateVolume();

        setHasChanges(false);
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  return (
    <div id="settings" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="h-14 flex items-center justify-center">
        <p className="title">{t("SettingsPage.Title")}</p>
      </div>
      <div className="settings-container">
        <div className="setting-container">
          <div className="sub-title-container">
            <p className="sub-title">{t("SettingsPage.Language")}</p>
          </div>
          <div className="carousel-container">
          <LanguageCarousel
              languages={Object.values(languageNames)}
              selectedLanguage={selectedLanguage}
              onLanguageSelect={handleLanguageChange}
            />
          </div>
        </div>
        <div className="setting-container">
          <div className="sub-title-container">
            <p className="sub-title">{t("SettingsPage.VisualEffects")}</p>
          </div>
          <div className="togle-container">
            <div
              className="w-[200px] h-[50px] rounded-[12px] cursor-pointer relative overflow-hidden"
              style={{
                background: "rgba(30, 33, 38, 0.4)",
                boxShadow: "0 0 10px rgba(255, 199, 0, 0.5)",
              }}
              onClick={handleVisualEffectsToggle}
            >
              <div
                className="absolute top-0 left-0 right-0 bottom-0"
                style={{
                  content: '""',
                  position: "absolute",
                  borderRadius: "11px",
                  padding: "1.1px",
                  background:
                    "linear-gradient(105.63deg, #FFC700 -30.5%, #FFAE63 66.66%)",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                }}
              />
              <div
                className="absolute top-0 left-0 h-full w-1/2 transition-transform duration-300"
                style={{
                  background:
                    "linear-gradient(105.63deg, #FFC700 -30.5%, #FFAE63 66.66%)",
                  transform: isOn ? "translateX(100%)" : "translateX(0)",
                }}
              />
              <div className="w-full h-full flex items-center justify-between px-4 relative">
                <span
                  className={`text-white transition-all duration-300 ${
                    isOn ? "z-20 font-semibold" : "z-20"
                  }`}
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "14px",
                    fontWeight: 500,
                    transform: isOn ? "translateX(6px)" : "translateX(0)",
                    opacity: 1,
                  }}
                >
                  {t("SettingsPage.VisualEffectsOn")}
                </span>
                <span
                  className={`text-white transition-all duration-300 ${
                    !isOn ? "z-20 font-semibold" : "z-20"
                  }`}
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "14px",
                    fontWeight: 500,
                    transform: !isOn ? "translateX(-6px)" : "translateX(0)",
                    opacity: 1,
                  }}
                >
                  {t("SettingsPage.VisualEffectsOff")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="setting-container">
        <div className="sound-slider-container">
        <div className="sub-title-container">
              <p className="sub-title">{t("SettingsPage.GeneralVolume")}</p>
            </div>
              <input
                type="range"
                min="0"
                max="100"
                value={localGeneralVolume}
                className="slider"
                ref={generalSliderRef}
                onChange={handleVolumeChange(setLocalGeneralVolume, generalSliderRef)}
                onMouseDown={handleSliderMouseDown}
            onMouseUp={handleSliderMouseUp}
            onTouchStart={handleSliderMouseDown}
            onTouchEnd={handleSliderMouseUp}
              />
            </div>
          </div>
          <div className="sound-container">
            <div className="sub-title-container">
              <p className="sub-title">{t("SettingsPage.EffectsVolume")}</p>
            </div>
            <div className="sound-slider-container">
              <input
                type="range"
                min="0"
                max="100"
                value={localEffectsVolume}
                className="slider"
                ref={effectsSliderRef}
                onChange={handleVolumeChange(setLocalEffectsVolume, effectsSliderRef)}
                onMouseDown={handleSliderMouseDown}
            onMouseUp={handleSliderMouseUp}
            onTouchStart={handleSliderMouseDown}
            onTouchEnd={handleSliderMouseUp}
              />
            </div>
          </div>
          <div className="sound-container">
            <div className="sub-title-container">
              <p className="sub-title">{t("SettingsPage.BackMusicVolume")}</p>
            </div>
            <div className="sound-slider-container">
              <input
                type="range"
                min="0"
                max="100"
                value={localMusicVolume}
                className="slider"
                ref={musicSliderRef}
                onChange={handleVolumeChange(setLocalMusicVolume, musicSliderRef)}
                onMouseDown={handleSliderMouseDown}
            onMouseUp={handleSliderMouseUp}
            onTouchStart={handleSliderMouseDown}
            onTouchEnd={handleSliderMouseUp}
              />
            </div>
          </div>
      </div>
      {hasChanges && (
        <div
          className={`save-changes-container ${hasChanges ? "visible" : ""}`}
        >
          <button className="save-changes-button" onClick={handleSaveChanges}>
            {t("SettingsPage.SaveChanges")}
          </button>
        </div>
      )}
    </div>
  );
};

export default Settings;