// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-buttom-list-five{
    gap: 3vw;
    width: 100%;
    padding-left: 3vw;
    padding-right: 3vw;
    display: flex;
    position: absolute;
    bottom: 25vw;
    left: 0;
    right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/games/game_core5.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,OAAO;IACP,QAAQ;AACZ","sourcesContent":[".game-buttom-list-five{\r\n    gap: 3vw;\r\n    width: 100%;\r\n    padding-left: 3vw;\r\n    padding-right: 3vw;\r\n    display: flex;\r\n    position: absolute;\r\n    bottom: 25vw;\r\n    left: 0;\r\n    right: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
