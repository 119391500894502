import React, { useEffect, memo } from 'react';
import { useUser } from '../contexts/UserContext';
import { useReward } from '../contexts/RewardContext';
import axios from 'axios';
import { API_URLS } from '../config/config';

const BackendInitializer = memo(({ userId, onError, onComplete }) => {
  const { user, updateUser } = useUser();
  const { setLastVisitTime } = useReward();

  useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(API_URLS.GET_USER_INFO, {
          params: { userId },
          headers: {
            'Accept': 'application/json',
            'ngrok-skip-browser-warning': 'true'
          }
        });
        if (isMounted) {
          if (typeof response.data === 'object' && response.data.info) {
            const userInfo = { ...response.data.info, user_id: userId };
            updateUser(userInfo);

            // Update the last_visited time in RewardContext
            if (userInfo.last_visited) {
              setLastVisitTime(new Date(userInfo.last_visited));
            }

            await GetRegion(userInfo);

            onComplete();
            console.log("user info", userInfo);
          } else {
            throw new Error("Unexpected response structure from backend");
          }
        }
      } catch (error) {
        if (isMounted) {
          console.error('BackendInitializer: Error fetching user data:', error);
          onError();
        }
      }
    };

    const GetRegion = async (userInfo) => {
      try {
        // Fetch the actual region code using a geolocation API
        const locationResponse = await axios.get('https://ipapi.co/json/');
        const actualRegion = locationResponse.data.country_code.toLowerCase();
        console.log("actualRegion", actualRegion);

        if (!userInfo.region && actualRegion) {
          // Save user region via API call
          const saveRegionResponse = await axios.post(API_URLS.SAVE_USER_GEO, {
            userId: userInfo.user_id,
            countryCode: actualRegion
          }, {
            headers: {
              'Accept': 'application/json',
              'ngrok-skip-browser-warning': 'true'
            }
          });

          if (saveRegionResponse.data.result === 'ok') {
            // Update the user context with the new region
            updateUser({ ...userInfo, region: actualRegion });
          }
        }
      } catch (error) {
        console.error('GetRegion: Error saving user region:', error);
      }
    };

    fetchUserData();

    return () => {
      isMounted = false;
    };
  }, [userId, onError, onComplete, updateUser, setLastVisitTime]);

  return null;
});

export default BackendInitializer;