import React, { useState, useEffect,useRef, useCallback } from "react";
import "../assets/styles/page4.css";
import { API_URLS } from "../config/config";
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../utils/LoadingScreen";
import clickSoundMp3 from '../assets/sounds/menu-button-click.mp3';

const loadSocialsData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_USER_SOCIALS, {
      userId: userId,
    });
    if (Array.isArray(response.data.tasks)) {
      return response.data.tasks.map((task) => ({
        id: task.id,
        name: task.name,
        amount: task.amount,
        link: task.link,
        photo: task.photo,
        isCompleted: task.is_completed,
      }));
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

const SocialsPage = ({ preloadedData }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [completedTasks, setCompletedTasks] = useState({});
  const [socialsData, setSocialsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([
            fetch(clickSoundMp3),
          ]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;
      
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
      
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      
      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const updateLastActive = async (userId) => {
    try {
      const response = await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
      console.log('Last active time updated:', response.data);
    } catch (error) {
      console.error('Error updating last active time:', error);
    }
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);
  
  useEffect(() => {
    const fetchData = async () => {
      if (user && user.user_id) {
        setIsLoading(true);
        setLoadingProgress(0);
        
        // Simulate progress
        const progressInterval = setInterval(() => {
          setLoadingProgress((prevProgress) => {
            const newProgress = prevProgress + 10;
            return newProgress > 90 ? 90 : newProgress;
          });
        }, 500);

        const data = await loadSocialsData(user.user_id);
        setSocialsData(data);

        clearInterval(progressInterval);
        setLoadingProgress(100);
        
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    };

    fetchData();
  }, [user]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleClick = async (link, socialId) => {
    playSound();
    if (link) {
      window.open(link, "_blank");

      try {
        await delay(10000);

        const data = {
          userId: user.user_id,
          taskId: socialId,
        };

        const response = await axios.post(API_URLS.CHECK_SOCIALS_TASKS, data);
        console.log("soc, check", response);
        if (
          response.data.result === "ok" ||
          response.data.result === "already completed task"
        ) {
          setCompletedTasks((prev) => ({ ...prev, [socialId]: true }));
          const updatedData = await loadSocialsData(user.user_id);
          console.log("soc updatedData", updatedData);
          setSocialsData(updatedData);
        }
      } catch (error) {
        console.error(
          "An error occurred during the execution of the socials task:",
          error
        );
      }
    }
  };
  const renderSocial = (social, index) => {
    const isLongName = social.name.length > 25;
    const imageUrl = `${API_URLS.API_BASE_URL}${social.photo}`;
    return (
      <div
        className={`social-container ${
          isLongName ? "social-container-tall" : ""
        }`}
        key={index}
      >
        <div className="flex flex-row w-30">
          <div className="m-1 flex items-center justify-center">
            <div className="soc-img-container">
              <img
                className="img-soc"
                src={imageUrl}
                alt={social.name}
              />
            </div>
          </div>
          <div className="flex flex-col w-60 m-1">
            <p className="social-text">{social.name}</p>
            <div className="flex flex-row items-center w-20 ">
              <img
                src={preloadedData.iconDolar}
                alt="dolar"
                className="socials-dolar-icon"
              />
              <p className="social-award">+{social.amount}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-10">
          <button
            className="social-button"
            onClick={() => handleClick(social.link.url, social.id)}
          >
            {social.isCompleted ? (
              <img
                src={preloadedData.iconChecked}
                alt="checked"
                className="socials-checked-icon"
              />
            ) : (
              <img
                src={preloadedData.iconArrow}
                alt="arrow"
                className="socials-arrow-icon -rotate-90"
              />
            )}
          </button>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingScreen progress={loadingProgress} />;
  }

  return (
    <div
      id="page4"
      style={{ backgroundImage: `url(${preloadedData.backgroundImage})` }}
    >
      <p className="title">{t("SocialsPage.Title")}</p>
      <div className="socials">
        {socialsData.map((social, index) => renderSocial(social, index))}
      </div>
    </div>
  );
};

export default SocialsPage;
