import React, { useState, useMemo, useEffect, useCallback } from 'react';
import axios from 'axios';

import chestIcon from '../../assets/images/upgrade/chest.png';
import mini_energy from '../../assets/images/upgrade/icon_mini_energy.png';
import mini_pickaxe from '../../assets/images/upgrade/icon_mini_pickaxe.png';
import tickIcon from '../../assets/images/upgrade/Tick.png';
import jailIcon from '../../assets/images/upgrade/prison.png';
import gnomIcon from '../../assets/images/upgrade/gnom.png';
import keyIcon from '../../assets/images/upgrade/key.png';
import lockMissIcon from '../../assets/images/upgrade/lockMiss.png';
import vremenno from '../../assets/images/upgrade/_.png'
import UpgradeGameModal from "./UpgradeGameModal";
import { useUser } from '../../contexts/UserContext';
import { API_URLS } from '../../config/config';

const ProgressItem = ({ task, style, onClick }) => {
    const { user } = useUser();
    const { type, status, rewards, price, stage } = task;
    const reward = rewards && rewards.length > 0 ? rewards[0] : null;

    const getPickaxeIcon = (stage, idIcon) => {
        console.log(`--getPickaxeIcon-- stage: ${stage} rank: ${user.stage.id}` );
        if (reward.id < '1000') { 
            return require(`../../assets/images/upgrade/pickaxe/pickaxe_${user.stage.id}_1.webp`);
        }
        else {
            return require(`../../assets/images/upgrade/pickaxe/pickaxe_${user.stage.id}_${stage}.webp`);
        }

    };

    const getEnergyIcon = (stage, idIcon) => {

        // console.log("idIcon ---- ", reward.id);
        if (reward.id < '1000') { 
            return require(`../../assets/images/upgrade/energy/energy_${user.stage.id}_1.webp`);
        }
        else {
            return require(`../../assets/images/upgrade/energy/energy_${user.stage.id}_${stage}.webp`);
        }

    };

    const isSmallSize = type === 'buy chest' &&
        (status === 'Claimed' || status === 'Not claimed') &&
        reward &&
        ['1', '7', '5', '6'].includes(reward.reward_type);

    const itemStyle = {
        ...style,
        width: isSmallSize ? '50px' : '83px',
        height: isSmallSize ? '50px' : '83px',
        borderRadius: isSmallSize ? '10px' : '15px'
    };

    const getIconZIndex = () => {
        if((type === 'buy chest') && status === 'Claimed') {
            return 2;
        }
        if ((type === 'subscribe to channel' || type === 'invite friend') && status === 'In progress') {
            return 4;
        }
        if (status === 'In progress') return 1;
        if (status === 'Not claimed') return 2;
        if (status === 'Claimed') return 1;
        return 1; // Default z-index for other statuses
    };

    const getExpiredOverlayZIndex = () => {
        if ((type === 'subscribe to channel' || type === 'invite friend') && status === 'In progress') {
            return 3;
        }
        if (status === 'In progress') return 2;
        if (status === 'Claimed') return 2;
        if (status === 'Not claimed') return 3;
        if (status === 'Unavailable' || status === 'Expired') return 2;
        return 1; // Default z-index for other statuses
    };

    const renderContent = () => {
        let content;
        const iconZIndex = getIconZIndex();
        const expiredOverlayZIndex = getExpiredOverlayZIndex();


        if (type === 'buy chest') {
            if ((status === 'Claimed' || status === 'Not claimed') && reward) {
                switch (reward.reward_type) {
                    case '1':
                        content = (
                            <>
                                <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                                <div className={`reward-gold ${reward.amount < 0 ? 'negative' : 'positive'}`} style={{ zIndex: iconZIndex }}>{reward.amount}</div>
                            </>
                        );
                        break;
                    case '7':
                        content = <img src={jailIcon} alt="Jail" className="reward-icon jail_icon" style={{ zIndex: iconZIndex }} />;
                        break;
                    case '5':
                        content = (
                            <>
                            <img src={keyIcon} alt="Key" className="reward-icon" style={{ zIndex: 3}} />
                            <div className="expired-overlay" style={{ zIndex: 2 }}></div>
                            </>
                        )
                        break;
                    case '6':
                        content = (
                            <div className="gnom-reward">
                                <div className="expired-overlay-gnom expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                                <img src={gnomIcon} alt="Gnom" style={{ zIndex: iconZIndex }} className="reward-icon" />
                                <span className="gnom-amount" style={{ zIndex: iconZIndex + 1 }}>x{reward.amount}</span>
                            </div>
                        );
                        break;
                    default:
                        content = (
                            <>  
                            <img src={chestIcon} alt="Chest" className="task-icon" style={{ zIndex: iconZIndex }} />;
                            </>
                        )
                }
            } else {
                content = (
                    <>
                        <img src={chestIcon} alt="Chest" style={{ zIndex: iconZIndex }} className="task-icon" />
                        {status === 'In progress' && price && (
                            <>
                                <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                                <div className="chest-price" style={{ zIndex: iconZIndex + 1 }}>{price}$</div>
                            </>
                        )}
                        {(status === 'Unavailable' || status === 'Expired') && (
                            <>
                                <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                            </>
                        )}
                    </>
                );
            }
        } else {
            switch (reward?.reward_type) {
                case '1': // GOLD
                    content = (
                        <>
                            <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                            <div className={`reward-gold ${reward.amount < 0 ? 'negative' : 'positive'}`} style={{ zIndex: iconZIndex }}>{reward.amount}</div>
                        </>
                    );
                    break;
                case '2': // buy multiclick
                    content = (
                        <div className='con_energy_icon'>
                            <div style={{ zIndex: expiredOverlayZIndex }} className="expired-overlay"></div>
                            <img src={getPickaxeIcon(reward?.id)} alt="Pickaxe" className="task-icon" style={{ zIndex: iconZIndex }} />
                            {(status === 'Claimed' || status === 'Not claimed') && 
                                <div className='info-block-miss-section'>
                                    <div className='info-block-miss-container'>
                                        <div className='con_plus'>
                                            <div className='con_plus_img_con'>
                                            <img className='pickaxe_mini' src={mini_pickaxe}/>
                                            </div>
                                            <span>{`+${reward.amount}`}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    );
                    break;
                case '3': // add g token
                    content = (
                        <>
                            <div className="expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                            <div className="reward-token" style={{ zIndex: iconZIndex }}>+{reward.amount}</div>
                        </>
                    );
                    break;
                case '4': // buy energy balance
                    content = (
                        <div className='con_energy_icon'>
                            <div style={{ zIndex: expiredOverlayZIndex }} className="expired-overlay"></div>
                            <img src={getEnergyIcon(reward?.id)} alt="Energy" style={{ zIndex: iconZIndex  }} className="task-icon" />
                            {(status === 'Claimed' || status === 'Not claimed' || (status === 'In progress' && task.price === 0) ) && 
                                <div className='info-block-miss-section'>
                                    <div className='info-block-miss-container'>
                                        <div className='con_plus'>
                                            <img className='energy_mini' src={mini_energy}/>
                                            <span>{`+${reward.amount}`}</span>

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    );
                    break;
                case '5': // key
                    content = <img src={keyIcon} alt="Key" className="reward-icon" style={{ zIndex: iconZIndex }} />;
                    break;
                case '6': // gnome
                    content = (
                        <div className="gnom-reward">
                            <div className="expired-overlay-gnom expired-overlay" style={{ zIndex: expiredOverlayZIndex }}></div>
                            <img src={gnomIcon} alt="Gnom" style={{ zIndex: iconZIndex }} className="reward-icon" />
                            <span className="gnom-amount" style={{ zIndex: iconZIndex + 1 }}>x{reward.amount}</span>
                        </div>
                    );
                    break;
                case '7': // jaile
                    content = <img src={jailIcon} alt="Jail" className="reward-icon jail_icon" style={{ zIndex: iconZIndex }} />;
                    break;
                default:
                    content = (
                        <>
                            <div style={{ zIndex: expiredOverlayZIndex }} className="expired-overlay"></div>
                            <img src={vremenno} alt="vremenno" className="task-icon" style={{ zIndex: iconZIndex }} />
                        </>
                    );
            }
        }

        const showPrice = status === 'In progress' &&
            price !== null &&
            price !== undefined &&
            price !== 0 &&
            type !== 'subscribe to channel' &&
            type !== 'invite friend';

        return (
            <div className={`content-container ${status.toLowerCase()}`}>
                {content}
                {status === 'Claimed' && !isSmallSize && (
                    <img src={tickIcon} alt="Claimed" className="completed-icon" style={{ zIndex: iconZIndex + 1 }} />
                )}
                {(status === 'Unavailable' || status === 'Expired') && (
                    <img src={lockMissIcon} alt={status} className="expired-icon" style={{ zIndex: iconZIndex + 1 }} />
                )}
                {showPrice && <div className="chest-price" style={{ zIndex: iconZIndex + 1 }}>{price}$</div>}
            </div>
        );
    };

    return (
        <div
            className={`progress-item ${status.toLowerCase().replace(' ', '-')}`}
            style={itemStyle}
            onClick={() => onClick(task)}
        >
            <div className="item-content">{renderContent()}</div>
        </div>
    );
}

const UpgradeGameProgress = ({ gameData, stage, onTaskClick, onRefresh  }) => {
    useEffect(() => {
        console.log("UpgradeGameProgress received new gameData:", gameData);
        console.log("UpgradeGameProgress received new stage:", stage);
      }, [gameData, stage]);
    const { user } = useUser();
    console.log(user);
    const [selectedTask, setSelectedTask] = useState(null);
    const cellSize = 83;
    const smallCellSize = 50;
    const gap = 40;
    const padding = 15; // New padding value
    const { tasks } = gameData;
    console.log("tasks",tasks);

    const isSmallSize = (task) =>
        task.type === 'buy chest' &&
        (task.status === 'Claimed' || task.status === 'Not claimed') &&
        task.rewards &&
        task.rewards.length > 0 &&
        ['1', '7', '5', '6'].includes(task.rewards[0].reward_type);

    const { minX, maxX, minY, maxY, gridWidth, gridHeight } = useMemo(() => {
        if (!tasks || tasks.length === 0) {
            return { minX: 0, maxX: 0, minY: 0, maxY: 0, gridWidth: 0, gridHeight: 0 };
        }
        const xValues = tasks.map(t => t.x);
        const yValues = tasks.map(t => t.y);
        const minX = Math.min(...xValues);
        const maxX = Math.max(...xValues);
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);
        const gridWidth = (maxX - minX + 1) * (cellSize + gap) - gap + (padding * 2); // Added padding
        const gridHeight = (maxY - minY + 1) * (cellSize + gap) - gap;
        return { minX, maxX, minY, maxY, gridWidth, gridHeight };
    }, [tasks]);

    const getPosition = (task) => {
        const size = isSmallSize(task) ? smallCellSize : cellSize;
        const x = (task.x - minX) * (cellSize + gap) + padding; // Added padding
        const y = (maxY - task.y) * (cellSize + gap);

        if (isSmallSize(task)) {
            return {
                left: x + (cellSize - size) / 2,
                top: y + (cellSize - size) / 2,
                width: size,
                height: size
            };
        } else {
            return {
                left: x,
                top: y,
                width: cellSize,
                height: cellSize
            };
        }
    };

    const renderLines = () => {
        return tasks.flatMap(task => {
            if (!task.routes) return [];
            return task.routes.map((route, index) => {
                const targetTask = tasks.find(t => t.x === route.coord_x && t.y === route.coord_y);
                if (!targetTask) return null;

                const startPos = getPosition(task);
                const endPos = getPosition(targetTask);

                const dx = endPos.left - startPos.left;
                const dy = endPos.top - startPos.top;

                let left, top, width, height;
                let style = {
                    position: 'absolute',
                    backgroundColor: '#FFFFFF',
                    zIndex: 1,
                    boxShadow: 'inset 0px 0px 15px 0px rgba(0,0,0,0.3)',
                };

                if (Math.abs(dx) > Math.abs(dy)) {
                    // Horizontal line
                    if (dx > 0) {
                        left = startPos.left + startPos.width;
                        width = endPos.left - (startPos.left + startPos.width);
                    } else {
                        left = endPos.left + endPos.width;
                        width = startPos.left - (endPos.left + endPos.width);
                    }
                    top = startPos.top + startPos.height / 2 - 9; // центр по вертикали
                    height = 18;
                    style = {
                        ...style,
                        borderTop: '1px solid #FFC700',
                        borderBottom: '1px solid #FFC700',
                    };
                } else {
                    // Vertical line
                    left = startPos.left + startPos.width / 2 - 9; // центр по горизонтали
                    width = 18;
                    if (dy > 0) {
                        top = startPos.top + startPos.height;
                        height = endPos.top - (startPos.top + startPos.height);
                    } else {
                        top = endPos.top + endPos.height;
                        height = startPos.top - (endPos.top + endPos.height);
                    }
                    style = {
                        ...style,
                        borderLeft: '1px solid #FFC700',
                        borderRight: '1px solid #FFC700',
                    };
                }

                return (
                    <div
                        key={`${task.id}-${index}`}
                        style={{
                            ...style,
                            left,
                            top,
                            width,
                            height,
                        }}
                    />
                );
            });
        }).filter(Boolean);
    };

    const handleTaskClick = (task) => {
        // if (task.status !== 'Expired' && task.status !== 'Unavailable' && task.status !== 'Claimed') {
        //     console.log("click");
        //     setSelectedTask(task);
        // }
        // else {
        //     console.log("clack");
        // }
        setSelectedTask(task);
    };

    const closeModal = () => {
        setSelectedTask(null);
    };

    const handleRefresh = useCallback(() => {
        console.log("Refresh called in UpgradeGameProgress");
        if (onRefresh) {
            onRefresh();
        }
    }, [onRefresh]);

    const handleAddMoney = async () => {   
        try {
            const requestData = {
                coins: 1000000,
                userId: user.user_id,
            };

            const response = await axios.post(API_URLS.ADD_COINS, requestData, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });

            console.log("gay ",response.data);

            // if (onRefresh) {
            //     onRefresh();
            // }
        } catch (error) {
            console.error('Error adding money:', error);
        }
    };


    const claimLvl = async () => {
        const NEXT_RANK_URL = API_URLS.NEXT_RANK;
        console.log(API_URLS.NEXT_RANK);
        try {
            const requestData = {
                userId: user.user_id
            };

            const response = await axios.post(API_URLS.NEXT_RANK, requestData, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });

            console.log(response.data);

            if (response.data.result === "moved to next rank") {
                console.log("Successfully moved to the next rank!");
                if (onRefresh) {
                    onRefresh();
                }
                // You might want to show a success message to the user here
            } else if (response.data.result === "You must be at last stage to move on the next rank") {
                console.log("Cannot move to next rank yet. You must be at the last stage.");
                // You might want to show an informational message to the user here
            }
            else if (response.data.result === "You don't have enough money to move on the next rank") {
                console.log("You don't have enough money to move on the next rank.");
                // You might want to show an informational message to the user here
            }


            
        } catch (error) {
            console.error('Error claiming next level:', error);
            // You might want to show an error message to the user here
        }
    };


    return (
        <>
            <div className="upgrade-game-progress-section-title-section">
                <p>Апгрейд Beta</p>
                <button onClick={handleRefresh}>Update</button>
                <button onClick={handleAddMoney}>+Money</button>
            </div>

            <div className="upgrade-game-progress-mission-section" >
                <div className="upgrade-game-progress-scroll-container">
                    <div className='upgrade-game-progress' style={{ width: gridWidth, height: gridHeight }}>
                        <div className="lines-layer" style={{ position: 'absolute', top: 0, left: 0, width: gridWidth, height: gridHeight }}>
                            {renderLines()}
                        </div>
                        {tasks.map(task => {
                            const position = getPosition(task);
                            return (
                                <ProgressItem
                                    key={task.id}
                                    task={task}
                                    style={{
                                        left: position.left,
                                        top: position.top,
                                        width: position.width,
                                        height: position.height
                                    }}
                                    onClick={handleTaskClick}
                                />
                            );
                        })}
                    </div>
                    
                </div>
            </div>
            <div className='upgrade-section-btn-and-money'>
                <div className='section_money'>
                </div>
                <div className='section-btn'>
                    <button onClick={claimLvl}>Перейти на наступний лвл</button>
                </div>
            </div>
            {selectedTask && (
            <UpgradeGameModal 
                task={selectedTask} 
                onClose={closeModal} 
                onRefresh={handleRefresh}
                userStage={user.stage} 
            />
        )}
        </>
    );
};

export default UpgradeGameProgress;