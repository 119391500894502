// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
 .menu-container {
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 66px;
  margin-bottom: 10px;
  background-color: transparent;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1%;
} `, "",{"version":3,"sources":["webpack://./src/assets/styles/home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;CACC;EACC,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,6BAA6B;EAC7B,qBAAqB;EACrB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;AACb","sourcesContent":[".main {\r\n  height: 100vh;\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  position: relative;\r\n}\r\n .menu-container {\r\n  display: flex;\r\n  justify-content:space-between;\r\n  align-items: center;\r\n  flex-direction: row;\r\n  width: 100%;\r\n  height: 66px;\r\n  margin-bottom: 10px;\r\n  background-color: transparent;\r\n  /* position: fixed; */\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  padding: 1%;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
