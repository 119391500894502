import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/games/game_modal.css";
import iconArrowLeft from "../../assets/icons/arrow-left.png";
import { useTranslation } from "react-i18next";

const GameHelpModal = ({
                           isOpen,
                           onClose,
                           levelImage,
                           gameName,
                           gameLevel,
                           gameRules
                       }) => {
    const modalRef = useRef(null);
    const [animationClass, setAnimationClass] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            setAnimationClass("slide-in");
            document.body.classList.add("modal-open", "slide-in");
            document.body.classList.remove("slide-out");
            document.addEventListener("mousedown", handleOverlayClick);
        } else {
            setAnimationClass("slide-out");
            document.body.classList.remove("modal-open", "slide-in");
            document.body.classList.add("slide-out");
            document.removeEventListener("mousedown", handleOverlayClick);
        }

        return () => {
            document.body.classList.remove("slide-in", "slide-out");
            document.removeEventListener("mousedown", handleOverlayClick);
        };
    }, [isOpen]);

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const getImageUrl = (imagePath) => {
        try {
            return require(`../${imagePath}`);
        } catch (err) {
            console.error(`Failed to load image: ${imagePath}`, err);
            return null;
        }
    };

    const imageUrl = getImageUrl(levelImage);

    return (
        <div className={`about-level-modal-overlay ${isOpen ? "active" : ""}`}
             onClick={handleOverlayClick}>
            <div
                ref={modalRef}
                className={`about-level-modal ${animationClass}`}
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className="about-level-modal-content">
                    <div className="w-full flex flex-row">
                        <button className="level-modal-close-button" onClick={onClose}>
                            <img src={iconArrowLeft} alt="<" />
                            <p className="level-modal-close-text">{t('GamePage.GameModal.Back')}</p>
                        </button>
                    </div>
                    <div className="game-modal-main">
                        <div className="game-modal-cont-text">
                            <p className="game-modal-text">{t('GamePage.GameModal.GameRule')}</p>
                            <p className="game-modal-text game-modal-lvl">Lvl {gameLevel}</p>
                        </div>
                    </div>
                    <div className="game-modal-name">
                        <p>{gameName}</p>
                    </div>
                    <div className="game-modal-rules">
                        <p>{gameRules}</p>
                    </div>
                    <div className='game-section-btn'>
                        <button onClick={onClose}>{t('GamePage.GameModal.Next')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameHelpModal;