import React from 'react';
import { useTranslation } from 'react-i18next';
import mini_energy from '../../assets/images/upgrade/icon_mini_energy.png';
import mini_pickaxe from '../../assets/images/upgrade/icon_mini_pickaxe.png';

const useUpgradeGameModalFunctions = (user) => {
    const { t } = useTranslation();

    const getTitle = (task) => {
        const { stage, rank } = user;
        const { rewards, status, type } = task;
        const reward = rewards[0];

        if (!reward) return '';

        const getKey = () => {
            if (type === 'buy chest' && ['Unavailable', 'In progress', 'Expired'].includes(status)) {
                return "UpgradePage.Cheast.titleOne";
            }

            switch (reward.reward_type) {
                case "1":
                    return reward.amount < 0 ? "UpgradePage.CheastMoneyMinus.titleOne" : "UpgradePage.CheastMoneyPlus.titleOne";
                case "2":
                    return `UpgradePage.Pickaxe.pickaxe_${stage.id}_${reward.id < '1000' ? user.stage.instrument : reward.id}`;
                case "4":
                    return `UpgradePage.Energy.energy_${stage.id}_${reward.id < '1000' ? user.stage.drink : reward.id}`;
                case "6":
                    return "UpgradePage.CheastGnom.titleOne";
                default:
                    return "";
            }
        };

        const key = getKey();
        const translatedObject = t(key, { returnObjects: true });

        if (typeof translatedObject === 'object' && translatedObject.title) {
            return translatedObject.title;
        } else if (typeof translatedObject === 'string') {
            return translatedObject;
        } else {
            console.warn(`Translation not found for key: ${key}`);
            return key;
        }
    };

    const getDescription = (task) => {
        const { stage } = user;
        const { rewards, status, type } = task;
        const reward = rewards[0];

        if (!reward) return '';

        const getKey = () => {
            if (type === 'buy chest' && ['Unavailable', 'In progress', 'Expired'].includes(status)) {
                return "UpgradePage.Cheast.description";
            }

            switch (reward.reward_type) {
                case "1":
                    return reward.amount < 0 ? "UpgradePage.CheastMoneyMinus.description" : "UpgradePage.CheastMoneyPlus.description";
                case "2":
                    return `UpgradePage.Pickaxe.pickaxe_${stage.id}_${reward.id < '1000' ? user.stage.instrument : reward.id}.dsc`;
                case "4":
                    return `UpgradePage.Energy.energy_${stage.id}_${reward.id < '1000' ? user.stage.drink : reward.id}.dsc`;
                case "6":
                    return "UpgradePage.CheastGnom.description";
                case "7":
                    return "UpgradePage.CheastPrison.description";
                default:
                    return "";
            }
        };

        return t(getKey());
    };

    const getRewardText = (task) => {
        const { rewards, status, type } = task;
        const reward = rewards[0];

        if (!reward) return '';

        if (type === 'buy chest' && ['Unavailable', 'In progress', 'Expired'].includes(status)) {
            return "";
        }

        const getRewardContent = () => {
            switch (reward.reward_type) {
                case "1":
                    const prefix = reward.amount < 0 ? '' : '+';
                    return `${prefix}${reward.amount} ${t("UpgradePage.different.gold_title2")}`;
                case "2":
                    return (
                        <div className='title-two-con'>
                            <p>+{`${reward.amount} ${t("UpgradePage.Universal.pickaxeTitleTwo")}`}</p>
                            <img className='img-mini' src={mini_pickaxe} alt='' />
                        </div>
                    );
                case "3":
                    return `+${reward.amount} токенов`;
                case "4":
                    return (
                        <div className='title-two-con'>
                            <p>+{`${reward.amount} ${t("UpgradePage.Universal.energyTitleTwo")}`}</p>
                            <img className='img-mini' src={mini_energy} alt='' />
                        </div>
                    );
                case "5":
                    return t("UpgradePage.CheastNextKey.titleTwo");
                case "6":
                    return `+${reward.amount} ${t("UpgradePage.CheastGnom.titleTwo")}`;
                case "7":
                    return `+${reward.amount} тюрьма`;
                default:
                    return `+${reward.amount} ${reward.name}`;
            }
        };

        return getRewardContent();
    };

    const getRewardTextColor = (reward) => {
        if (reward && reward.reward_type === '1') {
            return reward.amount < 0 ? 'red' : 'green';
        }
        return 'white';
    };

    return { getTitle, getDescription, getRewardText, getRewardTextColor };
};

export default useUpgradeGameModalFunctions;