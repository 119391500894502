import React, { useState, useCallback, useEffect } from 'react';
import { getBackgroundImage } from '../contexts/UpgradeContext';
import { useUpgrade } from '../contexts/UpgradeContext';
import { useUser } from '../contexts/UserContext';
import Modal from '../components/upgrade/UpgradeGameModalFix';
import JailScene from '../components/upgrade/JailScene';
import UpgradeGameRoad from '../components/upgrade/UpgradeGameRoad';
import UpgradeGameFix from '../components/upgrade/UpgradeGameFix';
import '../assets/styles/upgrade/UpgradePage.css';
import '../assets/styles/upgrade/UpgradeGame.css';
import '../assets/styles/upgrade/upgradeIviteModal.css';

// Компонент спиннера (Loader)
const Loader = ({ visible }) => (
  <div className={`loading-overlay-up-page ${visible ? '' : 'hidden'}`}>
    <div className="loading-content">
      <div className="loading-spinner"></div>
    </div>
  </div>
);

function UpgradePageFix({ preloadedData, startRankId, startStageId }) {
  const { stageInfo, rankInfo, userInfo, loading, error, updateAllData, isBlocked, blockedUntil } = useUpgrade();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isPageLoaded, setIsPageLoaded] = useState(false); // Флаг для управления появлением страницы
  const [showLoader, setShowLoader] = useState(true); // Флаг для управления отображением спиннера

  const { updateUser, user } = useUser();
  const userId = user ? user.user_id : null;

  // Минимальное время отображения спиннера
  const MIN_LOADING_TIME = 1500;

  useEffect(() => {
    if (startRankId && startStageId) {
      const bgImage = getBackgroundImage(startRankId, startStageId);
      setBackgroundImage(bgImage);
    }
  }, [startRankId, startStageId]);

  // Управляем первой загрузкой и отображением спиннера
  useEffect(() => {
    if (isInitialLoad && userId) {
      console.log("===preloadedData: ", preloadedData);

      const loadingStartTime = Date.now(); // Время начала загрузки
      document.body.classList.add('no-scroll'); // Скрываем скроллбар

      updateAllData(userId).then(() => {
        const elapsedTime = Date.now() - loadingStartTime;
        const remainingTime = MIN_LOADING_TIME - elapsedTime;

        // Убедимся, что спиннер показывается минимум 1.5 секунды
        if (remainingTime > 0) {
          setTimeout(() => {
            setIsInitialLoad(false);
            document.body.classList.remove('no-scroll'); // Включаем скроллбар
            setTimeout(() => {
              setIsPageLoaded(true);
              setShowLoader(false); // Убираем спиннер с задержкой
            }, 200); // Задержка перед скрытием спиннера
          }, remainingTime);
        } else {
          setIsInitialLoad(false);
          document.body.classList.remove('no-scroll'); // Включаем скроллбар
          setTimeout(() => {
            setIsPageLoaded(true);
            setShowLoader(false); // Убираем спиннер с задержкой
          }, 200);
        }
      });
    }
  }, [isInitialLoad, updateAllData, userId]);

  // Обновляем фон при изменении ранга или стадии пользователя
  useEffect(() => {
    if (rankInfo && userInfo) {
      const bgImage = getBackgroundImage(rankInfo.rank.id, userInfo.stage.id);
      setBackgroundImage(bgImage);
    }
  }, [rankInfo, userInfo]);

  // Функция обновления без скрытия контента
  const handleRefresh = useCallback(() => {
    if (userId) {
      // Просто обновляем данные без спиннера и скрытия контента
      updateAllData(userId);
    }
  }, [updateAllData, userId]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (backgroundImage) {
      const upgradeSection = document.querySelector('.upgrade-page-section');
      if (upgradeSection) {
        upgradeSection.style.setProperty('--background-image', `url(${backgroundImage})`);
      }
    }
  }, [backgroundImage]);

  // Показываем спиннер только на первой загрузке
  if (isInitialLoad || loading) return (
    <div className='upgrade-page-section' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Loader visible={showLoader} />
    </div>
  );

  if (error) return <p>Error: {error}</p>;

  return (
    <div className='upgrade-page-section' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={`upgrade-page ${isPageLoaded ? 'loaded' : ''}`}>
        {/* JailScene component based on user block status */}
        {userInfo?.is_blocked && <JailScene isBlocked={userInfo.is_blocked} blockedUntil={userInfo.blocked_until} />}

        {stageInfo && (
          stageInfo.type === 1 ? (
            <UpgradeGameFix
              gameData={stageInfo}
              rankData={rankInfo}
              userData={userInfo}
              preloadedData={preloadedData}
              globalRefreshData={handleRefresh}
              isUpdating={true}
            />
          ) : (
            <UpgradeGameRoad
              gameData={stageInfo.data}
              rankData={rankInfo}
              userData={userInfo}
              preloadedData={preloadedData}
              refreshData={handleRefresh}
            />
          )
        )}
      </div>
    </div>
  );
}

export default React.memo(UpgradePageFix);
