export const TELEGRAM_BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
const API_BASE_URL = process.env.REACT_APP_API_BASE_BASE_URL;
const API_BASE_NOPROTOCOL_URL = process.env.REACT_APP_API_BASE_NOPROTOCOL_URL;

const GET_USER_INFO_ENDPOINT = process.env.REACT_APP_GET_USER_INFO_ENDPOINT;
const ADD_COINS_ENDPOINT = process.env.REACT_APP_ADD_COINS_ENDPOINT;
const SAVE_USER_GEO = process.env.REACT_APP_SAVE_USER_GEO;
const ADD_COINS = process.env.REACT_APP_ADD_COINS;
const GET_USER_REFERRALS_ENDPOINT = process.env.REACT_APP_GET_USER_REFERRALS_ENDPOINT;
const GET_FREN_LINK_ENDPOINT = process.env.REACT_APP_GET_FREN_LINK_ENDPOINT;
const GET_USER_PARTNERS_ENDPOINT = process.env.REACT_APP_GET_USER_PARTNERS_ENDPOINT;
const GET_USER_SOCIALS_ENDPOINT = process.env.REACT_APP_GET_USER_SOCIALS_ENDPOINT;
const EXECUTE_SWAP_ENDPOINT = process.env.REACT_APP_EXECUTE_SWAP_ENDPOINT;
const EXECUTE_TRANSFER_ENDPOINT = process.env.REACT_APP_EXECUTE_TRANSFER_ENDPOINT;
const EXECUTE_TRANSACTIONS_ENDPOINT = process.env.REACT_APP_EXECUTE_TRANSACTIONS_ENDPOINT;
const CREATE_INVOICE_ENDPOINT = process.env.REACT_APP_CREATE_INVOICE_ENDPOINT;
const CHECK_INVOICE_ENDPOINT = process.env.REACT_APP_CHECK_INVOICE_ENDPOINT;
const CRYPTO_TRANSFER_ENDPOINT = process.env.REACT_APP_CRYPTO_TRANSFER_ENDPOINT;
const GET_REGIONS_ENDPOINT=process.env.REACT_APP_GET_ALL_REGIONS;
const CREATE_ADSET_ENDPOINT = process.env.REACT_APP_CREATE_ADSET;
const GET_USER_ADSETS = process.env.REACT_APP_GET_USER_ADSETS;
const GET_BANNER_ADVERT_ENDPONT = process.env.REACT_APP_GET_BANNER_ADVERT;
const GET_FULLSCREEN_ADVERT_ENDPONT = process.env.REACT_APP_GET_FULLSCREEN_ADVERT;
const UPDATE_SETTINGS_ENDPOINT = process.env.REACT_APP_UPDATE_SETTINGS_ENDPOINT;
const GET_ALL_ACTIVE_BANNER_ADVERTS_ENDPOINT=process.env.REACT_APP_GET_ALL_ACTIVE_BANNER_ADVERTS_ENDPOINT;
const GET_RANDOM_FULLSCREEN_BANNER_ADVERTS_ENDPOINT=process.env.REACT_APP_GET_RANDOM_FULLSCREEN_BANNER_ADVERTS_ENDPOINT;
const CREATE_BANER_ADVERT_ENDPOINT = process.env.REACT_APP_CREATE_BANER_ADVERT;
const CREATE_FULLSCREEN_ADVERT_ENDPOINT = process.env.REACT_APP_CREATE_FULLSCREEN_ADVERT;
const ENABLE_ADSET_ENDPOINT = process.env.REACT_APP_ENABLE_ADSET;
const DISABLE_ADSET_ENDPOINT = process.env.REACT_APP_DISABLE_ADSET;
const DELETE_ADSET_ENDPOINT=process.env.REACT_APP_ADD_DELETE_ADSET;
const CANSELL_SHUTDOWN_ENDPOINT= process.env.REACT_APP_CANSELL_SHUTDOWN;
const TRACK_BANNER_ADVERTS_ENDPOINT=process.env.REACT_APP_TRACK_BANNER_ADVERTS_ENDPOINT;
const GIVE_ADVIEW_REWARD_ENDPOINT = process.env.REACT_APP_GIVE_ADVIEW_REWARD_ENDPOINT;
const CHECK_PARTNER_TASK_ENDPOINT = process.env.REACT_APP_CHECK_PARTNER_TASK_ENDPOINT;
const CHECK_SOCIALS_TASK_ENDPOINT = process.env.REACT_APP_CHECK_SOCIALS_TASK_ENDPOINT;
const GET_TRANSFER_FEE_ENDPOINT = process.env.REACT_APP_GET_TRANSFER_FEE_ENDPOINT;
const GET_EXCHANGER_RATE_ENDPOINT = process.env.REACT_APP_GET_EXCHANGER_RATE_ENDPOINT;
const BY_VIP_ENDPOINT = process.env.REACT_APP_BY_VIP_RATE_ENDPOINT;
const UPDATE_LAST_ACTIVE=process.env.REACT_APP_UPDATE_LAST_ACTIVE;
const GET_EXECUTE_GAMELIST = process.env.REACT_APP_EXECUTE_GAMELIST;
const POST_GAME1_MONEY = process.env.REACT_APP_GAME1_MONEY;
const POST_GAME1_START = process.env.REACT_APP_GAME1_START;
const POST_GAME1_RESULT = process.env.REACT_APP_GAME1_RESULT;
const POST_GAME2_MONEY = process.env.REACT_APP_GAME2_MONEY;
const POST_GAME2_START = process.env.REACT_APP_GAME2_START;
const POST_GAME2_RESULT = process.env.REACT_APP_GAME2_RESULT;
const POST_APP_GAME3_MAKEBET = process.env.REACT_APP_GAME3_MAKEBET;
const POST_APP_GAME3_TAKEBET = process.env.REACT_APP_GAME3_TAKEBET;
const POST_APP_GAME4_TAKEBET = process.env.REACT_APP_GAME4_MAKEBET;
const POST_APP_GAME4_LINKS = process.env.REACT_APP_GAME4_LINKS;
const POST_APP_GAME5_START = process.env.REACT_APP_GAME5_START;
const POST_APP_GAME5_END = process.env.REACT_APP_GAME5_END;
const WEBS_GAME5_CONNECT = process.env.REACT_APP_GAME5_WEBS;
const WEBS_GAME3_CONNECT = process.env.REACT_APP_GAME3_WEBS;
const WEBS_GAME4_CONNECT = process.env.REACT_APP_GAME4_WEBS;

const SET_GENDER = process.env.REACT_APP_PICK_GENDER;

const GET_USER_CURRENT_STAGE_INFO = process.env.REACT_APP_GET_USER_CURRENT_STAGE_INFO
const NEXT_RANK = process.env.REACT_APP_LEVEL_NEXT_RANK;
const CLAIM_TASK = process.env.REACT_APP_CLAIM_TASK;
const BUY_TASK = process.env.REACT_APP_BUY_TASK;
const BUY_ROAD = process.env.REACT_APP_BUY_ROAD;
const GET_USER_CURRENT_RANK_INFO = process.env.REACT_APP_GET_USER_CURRENT_RANK_INFO;
const NEXT_STAGE = process.env.REACT_APP_NEXT_STAGE;

// Додаємо нові змінні
export const FAIRY_GIFT_SHOW_INTERVAL = parseInt(process.env.REACT_APP_FAIRY_GIFT_SHOW_INTERVAL, 10) || 600;
export const SKIP_TELEGRAM_INIT = process.env.REACT_APP_SKIP_TELEGRAM_INIT === 'true';
export const TEST_USER_ID = parseInt(process.env.REACT_APP_TEST_USER_ID, 10);

export const API_URLS = {
  API_BASE_URL: `${API_BASE_URL}`,
  GET_USER_INFO: `${API_BASE_URL}${GET_USER_INFO_ENDPOINT}`,
  SAVE_USER_GEO: `${API_BASE_URL}${SAVE_USER_GEO}`,
  ADD_COINS: `${API_BASE_URL}${ADD_COINS_ENDPOINT}`,
  GET_USER_REFERRALS: `${API_BASE_URL}${GET_USER_REFERRALS_ENDPOINT}`,
  GET_FREN_LINK:`${API_BASE_URL}${GET_FREN_LINK_ENDPOINT}`,
  GET_USER_PARTNERS: `${API_BASE_URL}${GET_USER_PARTNERS_ENDPOINT}`,
  GET_USER_SOCIALS: `${API_BASE_URL}${GET_USER_SOCIALS_ENDPOINT}`,
  EXECUTE_SWAP: `${API_BASE_URL}${EXECUTE_SWAP_ENDPOINT}`,
  EXECUTE_TRANSFER: `${API_BASE_URL}${EXECUTE_TRANSFER_ENDPOINT}`,
  EXECUTE_TRANSACTIONS: `${API_BASE_URL}${EXECUTE_TRANSACTIONS_ENDPOINT}`,
  CREATE_INVOICE:`${API_BASE_URL}${CREATE_INVOICE_ENDPOINT}`,
  CHECK_INVOICE:`${API_BASE_URL}${CHECK_INVOICE_ENDPOINT}`,
  CRYPTO_TRANSFER:`${API_BASE_URL}${CRYPTO_TRANSFER_ENDPOINT}`,
  GET_REGIONS:`${API_BASE_URL}${GET_REGIONS_ENDPOINT}`,
  CREATE_ADSET:`${API_BASE_URL}${CREATE_ADSET_ENDPOINT}`,
  GET_BANNER_ADVERT:`${API_BASE_URL}${GET_BANNER_ADVERT_ENDPONT}`,
  GET_FULLSCREEN_ADVERT:`${API_BASE_URL}${GET_FULLSCREEN_ADVERT_ENDPONT}`,
  CREATE_BANER_ADVERT:`${API_BASE_URL}${CREATE_BANER_ADVERT_ENDPOINT}`,
  CREATE_FULLSCREEN_ADVERT:`${API_BASE_URL}${CREATE_FULLSCREEN_ADVERT_ENDPOINT}`,
  ENABLE_ADSET:`${API_BASE_URL}${ENABLE_ADSET_ENDPOINT}`,
  DISABLE_ADSET:`${API_BASE_URL}${DISABLE_ADSET_ENDPOINT}`,
  DELETE_ADSET:`${API_BASE_URL}${DELETE_ADSET_ENDPOINT}`,
  CANSELL_SHUTDOWN:`${API_BASE_URL}${CANSELL_SHUTDOWN_ENDPOINT}`,
  GET_USER_ADSETS:`${API_BASE_URL}${GET_USER_ADSETS}`,
  UPDATE_SETTINGS: `${API_BASE_URL}${UPDATE_SETTINGS_ENDPOINT}`,
  GET_ALL_ACTIVE_BANNER_ADVERTS_ENDPOINT: `${API_BASE_URL}${GET_ALL_ACTIVE_BANNER_ADVERTS_ENDPOINT}`,
  GIVE_ADVIEW_REWARD_ENDPOINT:`${API_BASE_URL}${GIVE_ADVIEW_REWARD_ENDPOINT}`,
  GET_RANDOM_FULLSCREEN_BANNER_ADVERTS_ENDPOINT: `${API_BASE_URL}${GET_RANDOM_FULLSCREEN_BANNER_ADVERTS_ENDPOINT}`,
  TRACK_BANNER_ADVERTS_ENDPOINT: `${API_BASE_URL}${TRACK_BANNER_ADVERTS_ENDPOINT}`,
  CHECK_PARTNER_TASKS:`${API_BASE_URL}${CHECK_PARTNER_TASK_ENDPOINT}`,
  CHECK_SOCIALS_TASKS:`${API_BASE_URL}${CHECK_SOCIALS_TASK_ENDPOINT}`,
  GET_TRANSFER_FEE:`${API_BASE_URL}${GET_TRANSFER_FEE_ENDPOINT}`,
  GET_EXCHANGER_RATE:`${API_BASE_URL}${GET_EXCHANGER_RATE_ENDPOINT}`,
  BY_VIP:`${API_BASE_URL}${BY_VIP_ENDPOINT}`,
  UPDATE_LAST_ACTIVE:`${API_BASE_URL}${UPDATE_LAST_ACTIVE}`,
  GET_EXECUTE_GAMELIST: `${API_BASE_URL}${GET_EXECUTE_GAMELIST}`,
  POST_GAME1_MONEY: `${API_BASE_URL}${POST_GAME1_MONEY}`,
  POST_GAME1_START: `${API_BASE_URL}${POST_GAME1_START}`,
  POST_GAME1_RESULT: `${API_BASE_URL}${POST_GAME1_RESULT}`,
  POST_GAME2_MONEY: `${API_BASE_URL}${POST_GAME2_MONEY}`,
  POST_GAME2_START: `${API_BASE_URL}${POST_GAME2_START}`,
  POST_GAME2_RESULT: `${API_BASE_URL}${POST_GAME2_RESULT}`,
  REACT_APP_GAME3_MAKEBET: `${API_BASE_URL}${POST_APP_GAME3_MAKEBET}`,
  REACT_APP_GAME3_TAKEBET: `${API_BASE_URL}${POST_APP_GAME3_TAKEBET}`,
  REACT_APP_GAME4_TAKEBET: `${API_BASE_URL}${POST_APP_GAME4_TAKEBET}`,
  REACT_APP_GAME4_LINKSS: `${API_BASE_URL}${POST_APP_GAME4_LINKS}`,
  REACT_APP_GAME5_START: `${API_BASE_URL}${POST_APP_GAME5_START}`,
  REACT_APP_GAME5_END: `${API_BASE_URL}${POST_APP_GAME5_END}`,
  WEBS_GAME3_CONNECT: `${API_BASE_NOPROTOCOL_URL}${WEBS_GAME3_CONNECT}`,
  WEBS_GAME4_CONNECT: `${API_BASE_NOPROTOCOL_URL}${WEBS_GAME4_CONNECT}`,
  WEBS_GAME5_CONNECT: `${API_BASE_NOPROTOCOL_URL}${WEBS_GAME5_CONNECT}`,
  PICK_GENDER: `${API_BASE_URL}${SET_GENDER}`,
  NEXT_RANK: `${API_BASE_URL}${NEXT_RANK}`,
  GET_USER_CURRENT_STAGE_INFO: `${API_BASE_URL}${GET_USER_CURRENT_STAGE_INFO}`,
  CLAIM_TASK: `${API_BASE_URL}${CLAIM_TASK}`,
  BUY_TASK: `${API_BASE_URL}${BUY_TASK}`,
  BUY_ROAD: `${API_BASE_URL}${BUY_ROAD}`,
  GET_USER_CURRENT_RANK_INFO: `${API_BASE_URL}${GET_USER_CURRENT_RANK_INFO}`,
  NEXT_STAGE: `${API_BASE_URL}${NEXT_STAGE}`,
};

// Додайте цей код для діагностики
console.log('API_BASE_URL:', API_BASE_URL);
console.log('Full API_URLS object:', API_URLS);
console.log('SKIP_TELEGRAM_INIT:', SKIP_TELEGRAM_INIT);
console.log('TEST_USER_ID:', TEST_USER_ID);

