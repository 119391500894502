import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../assets/styles/copmonents/Modal/adModal.css";

const AdModal = ({
  isOpen,
  onClose,
  background,
  ad,
  iconArrowLeft,
  iconCoin,
  iconModerationSuccess,
  iconModerationInProgress,
  iconModerationFail,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [localAd, setLocalAd] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsActive(true);
      setIsClosing(false);
      setLocalAd(ad);
    } else {
      setIsClosing(true);
      const timer = setTimeout(() => {
        setIsActive(false);
        setLocalAd(null);
        setIsClosing(false);
      }, 300); // Час анімації в мс
      return () => clearTimeout(timer);
    }
  }, [isOpen, ad]);

  const handleClose = () => {
    setIsClosing(true);
    onClose();
  };

  const getModerationStatus = () => {
    if (!localAd) return { text: "", className: "", icon: null };

    switch (localAd.status) {
      case "success":
        return {
          text: t("AdOfficePage.AdModal.ModerationPassed"),
          className: "moderation-success",
          icon: iconModerationSuccess,
        };
      case "inProgress":
        return {
          text: t("AdOfficePage.AdModal.ModerationInProgress"),
          className: "moderation-inprogress",
          icon: iconModerationInProgress,
        };
      case "fail":
        return {
          text: t("AdOfficePage.AdModal.ModerationFailed"),
          className: "moderation-fail",
          icon: iconModerationFail,
        };
      default:
        return {
          text: "",
          className: "",
          icon: null,
        };
    }
  };

  const moderationStatus = getModerationStatus();

  const modalContainerStyle = {
    '--modal-background': `url(${background})`
  };

  if (!isOpen && !isActive) return null;

  return (
    <div
    className={`ad-modal-overlay ${isActive ? "active" : ""} ${isClosing ? "closing" : ""}`}
      onClick={handleClose}
    >
      <div
        className={`ad-modal-container ${isActive ? "active" : ""} ${isClosing ? "closing" : ""}`}
        style={modalContainerStyle}
        onClick={(e) => {e.stopPropagation(); handleClose()}}
      >
        <div className="close-modal-container">
          <button className="level-modal-close-button" onClick={onClose}>
            <img src={iconArrowLeft} alt="<" />
            <p className="level-modal-close-text">
              {t("AdOfficePage.ModalBackButtonText")}
            </p>
          </button>
        </div>
        <div className="w-full flex justify-center items-center">
          <p className="modal-title">{t("AdOfficePage.AdModal.Title")}</p>
        </div>
        {localAd && (
          <>
            <div className="ad-modal-row">
              <div className="modal-col w-1/4">
                <p className="modal-text">
                  {t("AdOfficePage.AdModal.ModalTextType")}
                </p>
                <div className="modal-data-container">
                  <p className="modal-data-text">{localAd.type || ""}</p>
                </div>
              </div>
              <div className="modal-col w-1/4">
                <p className="modal-text">
                  {t("AdOfficePage.AdModal.ModalTextAmountPerDay")}
                </p>
                <div className="modal-data-container">
                  <div className="modal-icon-container">
                    <img
                      src={iconCoin}
                      className="modal-icon-gtoken"
                      alt="gtoken"
                    />
                    <p className="modal-token">Token</p>
                  </div>
                  <div className="modal-text-container">{localAd.amountPerDay || ""}</div>
                </div>
              </div>
              <div className="modal-col w-1/4">
                <p className="modal-text">
                  {t("AdOfficePage.AdModal.ModalTextNumberDays")}
                </p>
                <div className="modal-data-container">
                  <p className="modal-data-text">{localAd.numberOfDays || ""}</p>
                </div>
              </div>
            </div>
            <div className="ad-modal-row2">
              <div className="flex flex-col w-full">
                <p className="modal-text-start">
                  {t("AdOfficePage.AdModal.ModalTextAdName")}
                </p>
                <div className="modal-data-container">
                  <p className="modal-data-text">{localAd.Name || ""}</p>
                </div>
              </div>
            </div>
            <div className="ad-modal-row">
              <div className="modal-col w-2/4">
                <p className="modal-text-start">
                  {t("AdOfficePage.AdModal.ModalTextType")}
                </p>
                <div className="modal-data-container">
                  <p className="modal-data-text">{localAd.type || ""}</p>
                </div>
              </div>
              <div className="modal-col w-1.5/4">
                <p className="modal-text-start">
                  {t("AdOfficePage.AdModal.ModalTextDuration")}
                </p>
                <div className="modal-data-container">
                  <p className="modal-data-text">{localAd.AdvertisementDuration || ""}</p>
                </div>
              </div>
            </div>
            <div className="ad-modal-row">
              <div className="w-full flex justify-center items-center flex-row">
                <p className={moderationStatus.className}>
                  {moderationStatus.text}
                </p>
                {moderationStatus.icon && (
                  <img
                    src={moderationStatus.icon}
                    alt={`Status: ${localAd.status}`}
                    className="w-5 h-5 ml-2"
                  />
                )}
              </div>
            </div>
            <div className="ad-modal-row3">
              <button
                className="ad-modal-button"
                // onClick={handleStartClick}
              >
                {t("AdOfficePage.AdModal.ModalButton")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdModal;