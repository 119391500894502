import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import '../assets/styles/languageCarousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LanguageCarousel = ({
  languages,
  selectedLanguage,
  onLanguageSelect,
}) => {
  const sliderRef = useRef(null);

  const settings = {
    className: "center",
    
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    arrows:false,
    focusOnSelect: true,
    beforeChange: (current, next) => {
      onLanguageSelect(languages[next]);
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      const selectedIndex = languages.indexOf(selectedLanguage);
      sliderRef.current.slickGoTo(selectedIndex);
    }
  }, [selectedLanguage, languages]);

  const languagesList = languages.map((element, index) => (
    <div key={index} className="language-item-wrapper">
      <div className={`language-item ${element === selectedLanguage ? 'active' : ''}`}>
        {element}
      </div>
    </div>
  ));

  return (
    <div className="language-slider-container">
      <Slider ref={sliderRef} {...settings}>
        {languagesList}
      </Slider>
    </div>
  );
};

export default LanguageCarousel;