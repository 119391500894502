import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "../assets/styles/copmonents/Modal/addAdsetModalStyle.css";
const CustomRegionSelect = ({ options, onSelect, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);
  const optionsRef = useRef(null);

  const toggleSelect = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`regions-custom-select-wrapper ${isOpen ? "open" : ""}`}
      ref={selectRef}
    >
      <div className="regions-custom-select" onClick={toggleSelect}>
        <span className="regions-custom-option">
          <p className="w-5/6 flex justify-start">{selectedOption.name}</p>
          <div className="w-1/6 flex justify-start flex-row"> 
            <p className="mr-1">|</p>
            <p>{selectedOption.code}</p>
          </div>
        </span>
        <img
          src={icon}
          alt="arrow"
          className={`w-3 ${isOpen ? "regions-rotated" : ""}`}
        />
      </div>
      {isOpen && (
        <div className="regions-custom-options" ref={optionsRef}>
          {options
            .filter((option) => option.value !== selectedOption.value)
            .map((option, index) => (
              <div
                key={index}
                className="regions-custom-option"
                onClick={() => handleOptionClick(option)}
              >
                <p className="w-5/6 flex justify-start">{option.name}</p>
                <div className="w-1/6 flex justify-start flex-row"> 
                  <p className="mr-1">|</p>
                  <p>{option.code}</p>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};  

const AddAdsetModal = ({
  isOpen,
  onClose,
  background,
  iconArrowLeft,
  iconArrow,
  iconCoin,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(isOpen);
  const [isClosing, setIsClosing] = useState(false);
  const [adsetName, setAdsetName] = useState("");
  const [clicksGoal, setClicksGoal] = useState("");
  const [amountPerDay, setAmountPerDay] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [days, setDays] = useState(6);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const regionOptions = Object.entries(t('AdOfficePage.Regions', { returnObjects: true })).map(([key, value]) => ({
    value: key,
    name: value.Name,
    code: value.Code
  }));

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsClosing(false);
    }
  }, [isOpen]);


  const closeModal = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      setIsVisible(false);
      setIsClosing(false);
      onClose();
    }, 300);
  }, [onClose]);

  const handleAdsetNameChange = (e) => {
    setAdsetName(e.target.value);
  };

  const handleClicksGoalChange = (e) => {
    setClicksGoal(e.target.value);
  };

  const handleAmountPerDayChange = (e) => {
    setAmountPerDay(e.target.value);
  };

  const handleRegionChange = (selectedOptions) => {
    setSelectedRegions(selectedOptions);
  };
  const handleInputFocus = () => {
    const menuElement = document.querySelector(".menu");
    if (menuElement) {
      menuElement.classList.remove("menu");
      menuElement.classList.add("menu-hidden");
    }
    const tradeButton = document.querySelector(".trade-button");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button");
      tradeButton.classList.add("trade-button-hidden");
    }
  };

  const handleInputBlur = () => {
    const menuElement = document.querySelector(".menu-hidden");
    if (menuElement) {
      menuElement.classList.remove("menu-hidden");
      menuElement.classList.add("menu");
    }
    const tradeButton = document.querySelector(".trade-button-hidden");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button-hidden");
      tradeButton.classList.add("trade-button");
    }
  };

  const updateSliderBackground = (value) => {
    if (sliderRef.current) {
      const percentage = ((value - 1) / (31 - 1)) * 100;
      sliderRef.current.style.setProperty(
        "--slider-percentage",
        `${percentage}%`
      );
    }
  };

  useEffect(() => {
    updateSliderBackground(days);
  }, [days]);

  const handleDaysChange = (e) => {
    const value = Number(e.target.value);
    setDays(value);
    updateSliderBackground(value);
  };

  const getDayText = (number) => {
    return t("AdOfficePage.DayText", { count: number });
  };

  const handleChange = useCallback((e) => {
    let newValue = e.target.value.replace(/[^0-9.]/g, "");
    if (newValue === "" || /^(\d*\.?\d*)$/.test(newValue)) {
      const parsedValue = parseFloat(newValue) || 0;
      setAmountPerDay(parsedValue);
    }
  }, []);

  const handleCloseButtonClick = useCallback((e) => {
    closeModal();
  }, [closeModal]);

  const handleOverlayClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  }, [closeModal]);

  const modalContainerStyle = {
    '--modal-background': `url(${background})`
  };


  if (!isOpen && !isActive) return null;

  return (
    <div
      className={`add-adset-modal-overlay ${isClosing ? "closing" : "active"}`}
      onClick={handleOverlayClick}
    >
      <div
        className={`add-adset-modal-container ${isClosing ? "closing" : "active"}`}
        style={{ '--modal-background': `url(${background})` }}
      >
        <div className="add-adset-close-modal-container">
          <button className="level-modal-close-button" onClick={handleCloseButtonClick}>
            <img src={iconArrowLeft} alt="<" />
            <p className="level-modal-close-text">
              {t("AdOfficePage.ModalBackButtonText")}
            </p>
          </button>
        </div>
        <div className="w-full flex justify-center items-center">
          <p className="modal-title">{t("AdOfficePage.AdsetModal.Title")}</p>
        </div>
        <div className="w-full flex flex-col justify-between items-center h-2/4">
          <div className="w-full flex flex-col justify-center items-center">
            <p className="add-adset-modal-text">
              {t("AdOfficePage.AdsetModal.AdsetName")}
            </p>
            <div className="modal-data-input-container">
              <input
                type="text"
                value={adsetName}
                onChange={handleAdsetNameChange}
                className="modal-data-text-input"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Name"
              />
            </div>
          </div>
          {/* CAN BE ADD SOON */}
          {/* <div className="w-full flex flex-col justify-center items-center">
            <p className="add-adset-modal-text">
              {t("AdOfficePage.AdsetModal.ClickGoal")}
            </p>
            <div className="modal-data-input-container">
              <input
                type="text"
                inputMode="decimal"
                pattern="[0-9]*"
                min={1}
                value={clicksGoal}
                onChange={handleClicksGoalChange}
                className="modal-data-text-input"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Clicks count"
              />
            </div>
          </div> */}
          <div className="flex flex-row justify-between items-center nt-2" style={{width:"90%"}}>
            <div className="w-8/12 flex flex-col justify-center" >
              <p className="add-adset-modal-text">
                {t("AdOfficePage.AdsetModal.RegionText")}
              </p>
              <div className="modal-data-input-container">
              <CustomRegionSelect
                options={regionOptions}
                onSelect={handleRegionChange}
                icon={iconArrow}
              />
              </div>
            </div>
            <div className="w-5/12 flex flex-col justify-center items-end">
              <p className="add-adset-modal-text">
                {t("AdOfficePage.AdsetModal.AmountPerDay")}
              </p>
              <div className="add-adset-amount-item">
                <div className="add-adset-amount-item-content">
                  <div className="add-adset-amount-item-icon-container">
                    <img
                      src={iconCoin}
                      className="add-adset-amount-icon-gtoken"
                      alt="gtoken"
                    />
                    <p className="token">Token</p>
                  </div>
                  <div className="add-adset-amount-item-text-container">
                    <input
                      type="text"
                      min={1}
                      value={amountPerDay}
                      inputMode="decimal"
                      pattern="[0-9]*"
                      onChange={handleAmountPerDayChange}
                      className="add-adset-amount-input"
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      placeholder="Amount"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center mt-2">
          <p className="add-adset-modal-text">
                {t("AdOfficePage.AdsetModal.Duration")}
              </p>
          <div className="day-range-slider">
            <div className="slider-labels flex justify-between">
              <span className="days-max-min">
                1 {t("AdOfficePage.DayText_one")}
              </span>
              <span className="days-value">
                {days} {getDayText(days)}
              </span>
              <span className="days-max-min">
                31 {t("AdOfficePage.DayText_one")}
              </span>
            </div>
            <div className="days-slider-container">
              <input
                type="range"
                min="1"
                max="31"
                value={days}
                className="slider"
                ref={sliderRef}
                onChange={handleDaysChange}
              />
            </div>
          </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center mb-5">
            <button className="save-adset-modal-button">{t("AdOfficePage.AdsetModal.SaveBtn")}</button>
        </div>
      </div>
    </div>
  );
};

export default AddAdsetModal;