import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconFairy } from "../assets/icons/fairy.svg";
import { ReactComponent as IconGift } from "../assets/icons/gift.svg";
import { useAudio } from "../contexts/AudioContext";
import "../assets/styles/copmonents/Modal/fairyModal.css";
import clickSoundMp3 from '../assets/sounds/menu-button-click.mp3';
import AdViewer from './AdViewer';

const RewardModal = ({ isOpen, onClose, adType, reward, rewardType, id, filePath, url, isVideo, iconAdWatchOnly, iconAdWatchRedirect, iconGnome, background }) => {
  const { t } = useTranslation();
  const [showAd, setShowAd] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  const isDataEmpty = !adType || !reward || !rewardType || !id || !filePath || !url;

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const response = await fetch(clickSoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);

          clickAudioBufferRef.current = audioBuffer;
          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;
      
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
      
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      
      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
      setTimeout(() => setIsActive(true), 50);
    } else {
      setIsClosing(true);
      setIsActive(false);
      const timer = setTimeout(() => {
        setIsClosing(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleOverlayClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      playSound();
      setIsClosing(true);
      setIsActive(false);
      setTimeout(onClose, 500);
    }
  }, [onClose, playSound]);

  const handleWatchAdClick = () => {
    playSound();
    console.log("Watch Ad button clicked");
    setShowAd(true);
  };

  const handleAdClose = () => {
    playSound();
    console.log("Ad closed");
    setShowAd(false);
    onClose();
  };

  if (!isOpen && !isActive && !isClosing && !showAd) return null;

  if (showAd) {
    return (
      <div className="full-screen-ad" style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999, '--modal-background': `url(${background})`}}>
        <AdViewer 
          id={id}
          rewardType={rewardType}
          mediaUrl={url} 
          adFileName={filePath}
          goldReward={reward}
          isVideo={isVideo} 
          onClose={handleAdClose}
        />
      </div>
    );
  }

  const Icon = adType === 'fairy' ? IconFairy : IconGift;
  const title = (adType === 'fairy' ? t('MainPage.FairyModalTitle') : t('MainPage.GiftModalTitle'));
  const message = isDataEmpty ? t('MainPage.EmptyDataMessage') : (adType === 'fairy' ? t('MainPage.FairyModalMessage') : t('MainPage.GiftModalMessage'));

  const RewardDisplay = ({ value }) => {
    if (rewardType === 2) {
      return <p className="fairy-remuneration-text">${value}</p>;
    } else if (rewardType === 3) {
      return (
        <div className="w-full flex flex-row h-1/2 items-center justify-center">
          <img src={iconGnome} alt="Gnome" className="h-full" />
          <p className="fairy-remuneration-text">{value}</p>
        </div>
      );
    }
    return <p className="fairy-remuneration-text">{value}</p>;
  };

  const backgroundStyle = background
    ? { '--modal-background': `url(${background})` }
    : { backgroundColor: 'rgba(0, 0, 0, 0.8)' }; 

  return (
    <div className={`fairy-modal-overlay ${isActive ? "active" : ""} ${isClosing ? "closing" : ""}`} onClick={handleOverlayClick}>
      <div className={`fairy-modal-container ${isActive ? "active" : ""} ${isClosing ? "closing" : ""}`} style={backgroundStyle}>
        <div className="fairy-modal-content">
          <div className="flex flex-col justify-between items-center h-full">
            <div className="h-10 flex flex-row items-center">
              <div className="w-52">
                <p className="fairy-modal-title flex justify-center">{title}</p>
              </div>
              <div className="w-5">
                <Icon/>
              </div>
            </div>
            <div className="h-14 flex flex-col justify-center items-center">
              <p className="fairy-modal-text">{message}</p>
            </div>
            {!isDataEmpty && (
              <>
                <div className="h-14 w-11/12 flex flex-row justify-between items-center">
                  <div className="fairy-remuneration">
                    <div className="fairy-remuneration-icon-div">
                      <img src={iconAdWatchOnly} alt="iconAdWatchOnly" className="w-1/2 h-1/2"/>
                    </div>
                    <RewardDisplay value={reward/2} />
                    <p className="fairy-remuneration-sub-text">
                      {t('MainPage.FairyModalSubTextWatchOnly')}
                    </p>
                  </div>
                  <div className="fairy-remuneration">
                    <div className="fairy-remuneration-icon-div">
                      <img src={iconAdWatchRedirect} alt="iconAdWatchRedirect" className="w-1/2 h-1/2"/>
                    </div>
                    <RewardDisplay value={reward} />
                    <p className="fairy-remuneration-sub-text">
                      {t('MainPage.FairyModalSubTextWatchAndRedirect')}
                    </p>
                  </div>
                </div>
                <div className="h-16 flex flex-col justify-center items-center w-80 space-y-2">
                  <button className="fairy-claim-button" onClick={handleWatchAdClick}>
                    {t('MainPage.RewardModalButtonText')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardModal;