import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../contexts/UserContext";
import { API_URLS } from "../config/config";
import axios from "axios";
import "../assets/styles/rules.css";
const Rules = ({ preloadedData }) => {
  const { t } = useTranslation();
  const [openSection, setOpenSection] = useState(null);
  const [openSubsection, setOpenSubsection] = useState(null);
  const [sections, setSections] = useState([]);
  const { user } = useUser();
  const updateLastActive = async (userId) => {
    try {
      const response = await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
      console.log('Last active time updated:', response.data);
    } catch (error) {
      console.error('Error updating last active time:', error);
    }
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);

  useEffect(() => {
    const rulesData = t("RulesPage", { returnObjects: true });
    const dynamicSections = Object.keys(rulesData).filter((key) =>
      key.startsWith("Paragraph")
    );
    setSections(dynamicSections);
  }, [t]);

  const toggleSection = (sectionKey) => {
    setOpenSection(openSection === sectionKey ? null : sectionKey);
    setOpenSubsection(null);
  };

  const toggleSubsection = (subsectionKey) => {
    setOpenSubsection(openSubsection === subsectionKey ? null : subsectionKey);
  };

  const renderContent = (content) => {
    if (typeof content === "string") {
      return <p>{content}</p>;
    } else if (content && typeof content === "object") {
      return (
        <div>
          {content.MainText && <p>{content.MainText}</p>}
          {content.Image && (
            <img
              src={content.Image.src}
              alt={content.Image.alt}
              width={content.Image.width}
              height={content.Image.height}
              className="my-4"
            />
          )}
          {content.TableIntro && <p>{content.TableIntro}</p>}
          {content.Table && (
            <table className="w-full border-collapse overflow-hidden rounded-lg">
           <thead>
             <tr>
               {content.Table[0].map((header, index) => (
                 <th
                   key={index}
                   scope="col"
                   className={`p-1 table-text border border-lightgray ${
                     index === 0 ? 'rounded-tl-lg' : ''
                   } ${
                     index === content.Table[0].length - 1 ? 'rounded-tr-lg' : ''
                   }`}
                 >
                   {header}
                 </th>
               ))}
             </tr>
           </thead>
           <tbody>
             {content.Table.slice(1).map((row, rowIndex) => (
               <tr key={rowIndex}>
                 {row.map((cell, cellIndex) => (
                   <td
                     key={cellIndex}
                     className={`p-1 table-text border border-lightgray ${
                       rowIndex === content.Table.length - 2 && cellIndex === 0
                         ? 'rounded-bl-lg'
                         : ''
                     } ${
                       rowIndex === content.Table.length - 2 &&
                       cellIndex === row.length - 1
                         ? 'rounded-br-lg'
                         : ''
                     }`}
                   >
                     {cell}
                   </td>
                 ))}
               </tr>
             ))}
           </tbody>
         </table>
          )}
          {content.TableOutro && <p>{content.TableOutro}</p>}
          {content.Explanation && (
            <div>
              <h4 className="font-bold mt-4">{content.Explanation.Title}</h4>
              <ul className="list-disc pl-5">
                {content.Explanation.Items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
          {content.Conclusion && <p className="mt-4">{content.Conclusion}</p>}
          {content.AdditionalInfo && (
            <ul className="list-disc pl-5 mt-4">
              {content.AdditionalInfo.map((info, index) => (
                <li key={index}>{info}</li>
              ))}
            </ul>
          )}
          {content.ListItems && (
            <>
              {content.ListTitle && <p className="font-medium mb-2">{content.ListTitle}</p>}
              <ul className="list-disc pl-5">
                {content.ListItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </>
          )}
          {content.SubLists && content.SubLists.map((subList, subIndex) => (
            <div key={subIndex} className="mt-4">
              {subList.ListTitle && <h4 className="font-medium">{subList.ListTitle}</h4>}
              {subList.MainText && <p>{subList.MainText}</p>}
              <ul className="list-disc pl-5">
                {subList.ListItems.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
          {!content.SubLists && content.ListTitle && (
            <div>
              <p className="font-medium mb-2">{content.ListTitle}</p>
              <ul className="list-disc pl-5">
                {Object.entries(content)
                  .filter(([key]) => key.startsWith("ListItem"))
                  .map(([key, value]) => (
                    <li key={key} className="mb-2">
                      {value}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const renderSubsections = (sectionKey) => {
    const sectionData = t(`RulesPage.${sectionKey}`, { returnObjects: true });
    const subsections = Object.keys(sectionData).filter(
      (key) => key.startsWith("Clause") && !key.endsWith("Text")
    );

    return subsections.map((subsectionKey, index) => (
      <div key={subsectionKey} className="w-full">
        <h2 id={`accordion-collapse-heading-${sectionKey}-${index}`}>
          <button
            type="button"
            className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-white border border-t-0 border-l-0 border-r-0 border-gray-200 dark:text-white gap-3"
            onClick={() => toggleSubsection(`${sectionKey}.${subsectionKey}`)}
            aria-expanded={openSubsection === `${sectionKey}.${subsectionKey}`}
            aria-controls={`accordion-collapse-body-${sectionKey}-${index}`}
          >
            <span className="subtitle">{sectionData[subsectionKey]}</span>
            <svg
              data-accordion-icon
              className={`w-3 h-3 transform ${
                openSubsection === `${sectionKey}.${subsectionKey}`
                  ? "rotate-180"
                  : ""
              } shrink-0`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="19"
              height="8"
              viewBox="0 0 19 8"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18 1L9.5 7L1 0.999999"
              />
            </svg>
          </button>
        </h2>
        <div
          id={`accordion-collapse-body-${sectionKey}-${index}`}
          className={`transition-all duration-500 ease-in-out overflow-hidden ${
            openSubsection === `${sectionKey}.${subsectionKey}` ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
          }`}
          aria-labelledby={`accordion-collapse-heading-${sectionKey}-${index}`}
        >
          <div className="p-1 rules-text w-full">
            {renderContent(sectionData[`${subsectionKey}Text`])}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div
      id="rules"
      style={{ backgroundImage: `url(${preloadedData.backgroundImage})` }}
    >
      <div className="h-14 flex items-center justify-center">
        <p className="title">
          {t("RulesPage.Title")}
          <span className="game-name"> {t("RulesPage.GameName")}</span>
        </p>
      </div>
      <div className="accordion-container">
        <div id="accordion-collapse" data-accordion="collapse">
          {sections.map((sectionKey, index) => (
            <div key={sectionKey}>
              <h2 id={`accordion-collapse-heading-${index}`}>
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-white border border-t-0 border-l-0 border-r-0 border-gray-200 dark:text-white gap-3"
                  onClick={() => toggleSection(sectionKey)}
                  aria-expanded={openSection === sectionKey}
                  aria-controls={`accordion-collapse-body-${index}`}
                >
                  <span className="subtitle">
                    {t(`RulesPage.${sectionKey}.Title`)}
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 transform ${
                      openSection === sectionKey ? "rotate-180" : ""
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width="19"
                    height="8"
                    viewBox="0 0 19 8"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 1L9.5 7L1 0.999999"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id={`accordion-collapse-body-${index}`}
                className={`transition-all duration-500 ease-in-out overflow-hidden ${
                  openSection === sectionKey ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
                }`}
                aria-labelledby={`accordion-collapse-heading-${index}`}
              >
                <div className="p-1 rules-text">
                  {renderSubsections(sectionKey)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rules;
