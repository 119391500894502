import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import "../assets/styles/copmonents/Modal/noResourceModal.css";

const TradeWarningModal = memo(({ modalState, background, onClose }) => {
  const { isOpen, message, link } = modalState;
  const [isActive, setIsActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      console.log("ll", link);
      console.log("mm", message);
      setIsClosing(false);
      setTimeout(() => setIsActive(true), 50);
    } else {
      setIsClosing(true);
      setIsActive(false);
      const timer = setTimeout(() => {
        setIsClosing(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setIsActive(false);
    setTimeout(onClose, 500);
  };

  if (!isOpen && !isActive && !isClosing) return null;

  const modalContainerStyle = {
    '--modal-background': `url(${background})`
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div 
      className={`no-resourse-modal-overlay ${isActive ? "active" : ""} ${isClosing ? "closing" : ""}`} 
      onClick={handleClose}
    >
      <div
         className={`no-resourse-modal-container ${isActive ? "active" : ""} ${
          isClosing ? "closing" : ""
        } ${link ? "with-link" : ""}`}
        style={{
          ...modalContainerStyle,
          height: link ? "auto" : "160px",
          minHeight: "160px" 
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="no-resourse-modal-content">
          <div className="flex flex-col justify-between items-center h-full">
            <div className={`flex flex-col items-center ${link ? "h-auto" : "h-20"}`}>
              <p className="no-resourse-modal-title text-center mb-4">
                {message}
              </p>
              {link && link !== "" && isValidUrl(link) && (
  <a 
    href={link} 
    className="solution-link mb-4" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    {link}
  </a>
)}
            </div>
            <div className="h-auto flex justify-center items-center w-full">
              <button className="claim-button" onClick={handleClose}>
                {t("TradePage.ModalBackButtonText")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TradeWarningModal;