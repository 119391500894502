import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "../assets/styles/copmonents/Modal/aboutLevelModal.css";
import { useNavigate } from "react-router-dom";

const AboutLevelModal = ({
  isOpen,
  onClose,
  iconArrowLeft,
  levelNumber,
  levelDescription,
  levelImage,
  levelTitle,
  activeLevelIndex,
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const navigate = useNavigate();
  const [animationClass, setAnimationClass] = useState("");
  const isCurrentLevel = levelNumber === activeLevelIndex;

  useEffect(() => {
    if (isOpen) {
      setAnimationClass("slide-in");
      document.body.classList.add("modal-open", "slide-in");
      document.body.classList.remove("slide-out");
      document.addEventListener("mousedown", handleOverlayClick);
    } else {
      setAnimationClass("slide-out");
      document.body.classList.remove("modal-open", "slide-in");
      document.body.classList.add("slide-out");
      document.removeEventListener("mousedown", handleOverlayClick);
    }

    return () => {
      document.body.classList.remove("slide-in", "slide-out");
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, [isOpen]);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const getImageUrl = (imagePath) => {
    try {
      return require(`../${imagePath}`);
    } catch (err) {
      console.error(`Failed to load image: ${imagePath}`, err);
      return null;
    }
  };
  
  const handleUpgardeClick = () => navigate("/pump");

  const imageUrl = getImageUrl(levelImage);

  return (
    <div
      className={`about-level-modal-overlay ${isOpen ? "active" : ""}`}
      onClick={handleOverlayClick}
    >
      <div
        ref={modalRef}
        className={`about-level-modal ${animationClass} ${isCurrentLevel ? 'current-level-modal' : ''}`}
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="about-level-modal-content">
          <div className="w-full flex flex-row">
            <button className="level-modal-close-button" onClick={onClose}>
              <img src={iconArrowLeft} alt="<" />
              <p className="level-modal-close-text">
                {t("LevelsPage.CloseModalText")}
              </p>
            </button>
          </div>
          <div className="w-full flex flex-col justify-center items-center h-2/4">
            <div className="w-full flex flex-row justify-center items-center h-2.5/5">
              <div
                className="modal-level-image-container"
                style={{
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "40%",
                  height: "100%",
                  aspectRatio: "1 / 1",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <p className="modal-level-image-text">LVL {levelNumber}</p>
              </div>
            </div>
            <p className="modal-level-title-text">{levelTitle}</p>
            <p className="modal-level-number-text">
              {t("LevelsPage.Level")} {levelNumber}
            </p>
          </div>
          <p className="modal-level-description-text">{levelDescription}</p>
          {isCurrentLevel && (
            <button className="go-to-upgrare-button" onClick={handleUpgardeClick}>
              {t("LevelsPage.ActiveLevelButton")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutLevelModal;