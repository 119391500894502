import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../assets/styles/upgrade/UpgradeGameModal.css'
import chestIcon from '../../assets/images/upgrade/chest.png';
import ArrowLeft from '../../assets/images/upgrade/Arrow 1.png'
import goldIcon from '../../assets/images/upgrade/_.png';
import tokenIcon from '../../assets/images/upgrade/_.png';
import keyIcon from '../../assets/images/upgrade/key.png';
import gnomIcon from '../../assets/images/upgrade/gnom.png';
import jailIcon from '../../assets/images/upgrade/prison.png';
import vremenno from '../../assets/images/upgrade/_.png'
import mini_energy from '../../assets/images/upgrade/icon_mini_energy.png';
import mini_pickaxe from '../../assets/images/upgrade/icon_mini_pickaxe.png';
import { useUser } from '../../contexts/UserContext';
import { API_URLS } from '../../config/config';
import { useTranslation } from 'react-i18next';

const UpgradeGameModal = ({ task, onClose, onRefresh, userStage, refLink }) => {
    const { t } = useTranslation();
    const { user, updateUser } = useUser();

    const [isOpen, setIsOpen] = useState(false);
    const [taskStatus, setTaskStatus] = useState(task?.status);
    const [taskType, setTaskType] = useState(task?.type);
    const [buttonText, setButtonText] = useState('');
    const [error, setError] = useState(null);
    const [updatedTask, setUpdatedTask] = useState(task);
    const [taskRewardType, setTaskRewardType] = useState(task?.rewards[0]?.reward_type);
    const [shouldUpdateReward, setShouldUpdateReward] = useState(false);
    const [shouldRerender, setShouldRerender] = useState(false);
    console.log("-Task: ", task);

    const userId = user.user_id;

    useEffect(() => {
        if (shouldRerender) {
            setIsOpen(false); // Закрываем модалку перед ререндером (опционально)
            setTimeout(() => {
                setIsOpen(true); // Открываем модалку снова через короткий промежуток времени
                setShouldRerender(false); // Сбрасываем флаг
            }, 50);
        }
    }, [shouldRerender]);

    useEffect(() => {
        if (task) {
            setTimeout(() => setIsOpen(true), 50);
            setTaskStatus(task.status);
            setTaskType(task.type);
            setTaskRewardType(task.rewards[0]?.reward_type);
            updateButtonText(task.status, task.type, task.rewards[0]?.reward_type);
            setUpdatedTask(task);
        } else {
            setIsOpen(false);
        }
    }, [task]);

    const handleRefresh = () => {
        if (onRefresh) {
            console.log("Calling onRefresh from UpgradeGameModal");
            onRefresh();
        }
    };


    const updateButtonText = (status, type, rewardType) => {
        if (status === 'Claimed') {
            setButtonText(t('UpgradePage.btnContent.claimed'));
        } else if (status === 'Not claimed') {
            setButtonText(t('UpgradePage.btnContent.not_claimed'));
        } else if (status === 'In progress') {
            if (type === 'Subscribe to Channel') {
                setButtonText('Subscribe');
            } else {
                setButtonText(`${t('UpgradePage.btnContent.buy')} $${task.price}`);
            }
        } else if (status === 'No Money') {
            setButtonText(t('UpgradePage.btnContent.back'));
        } else if (status === 'Unavailable') {
            setButtonText('Unavailable');
        } else {
            setButtonText(getInitialButtonText());
        }
    };

    const getInitialButtonText = () => {
        switch (updatedTask.type) {
            case 'subscribe to channel':
                return 'Підписатись на канал';
            case 'invite friend':
                return 'Запросити друга';
            default:
                return user.gold_balance < updatedTask.price ? `Недоступно (потрібно ${updatedTask.price}$)` : `Придбати за $${updatedTask.price}`;
        }
    };

    const buyButton = async () => {
        if (updatedTask.price > user.gold_balance) {
            updateButtonText('No Money');
        } else {

            let button_text = `${t('UpgradePage.btnContent.claimed')}`;
            if (buttonText === button_text) {
                setShouldUpdateReward(true);

                handleClose();
            }
            else {
                try {
                    setError(null);
                    const headers = {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'ngrok-skip-browser-warning': 'true'
                    };

                    let response;
                    if (taskStatus === 'Not claimed') {
                        response = await axios.post(API_URLS.CLAIM_TASK, { taskId: updatedTask.id }, { headers });
                        if (response.data.message === 'ok') {
                            setTaskStatus('Claimed');
                            updateButtonText('Claimed', updatedTask.type, taskRewardType);
                        }
                    }
                    else if (taskStatus === 'In progress') {

                        if (updatedTask.type === 'subscribe to channel') {
                            let link = 'https://t.me/test_chanel1234534521352';
                            window.open(link, '_blank');
                            response = await axios.post(API_URLS.BUY_TASK, { taskId: task.id }, { headers });
                            if (response.data.message === 'You completed the task. Now claim it' && response.data.task_status === 'Not claimed') {
                                setTaskStatus('Not claimed');
                                updateButtonText('Not claimed');
                            } else if (response.data.message === 'Task already done') {
                                setButtonText('3');
                            }
                        }

                        else if (updatedTask.type === "invite friend") {
                            if (window.Telegram && window.Telegram.WebApp) {
                                const title = document.querySelector('meta[property="og:title"]')?.content || "";
                                const description = document.querySelector('meta[property="og:description"]')?.content || "";

                                const imagePath = document.querySelector('meta[property="og:image"]')?.content || "";

                                const fullImageUrl = new URL(imagePath, window.location.origin).href;

                                const message = `${title}\n\n${description}\n\n${fullImageUrl}`;

                                const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(refLink)}&text=${encodeURIComponent(message)}`;

                                response = await axios.post(API_URLS.BUY_TASK, { taskId: task.id }, { headers });
                                if (response.data.message === 'You completed the task. Now claim it' && response.data.task_status === 'Not claimed') {
                                    setTaskStatus('Not claimed');
                                    updateButtonText('Not claimed');
                                } else if (response.data.message === 'Task already done') {
                                    setButtonText('3');
                                }

                                window.Telegram.WebApp.openTelegramLink(shareUrl);
                            }
                        }
                        else {
                            response = await axios.post(API_URLS.BUY_TASK, { taskId: updatedTask.id }, { headers });
                            if (response.data.message === 'You completed the task. Now claim it' && response.data.task_status === 'Not claimed') {
                                setTaskStatus('Not claimed');
                                updateButtonText('Not claimed', updatedTask.type, taskRewardType);
                            }
                        }

                    }
                    else {
                        response = await axios.post(API_URLS.BUY_TASK, { taskId: updatedTask.id }, { headers });
                        if (response.data.message === 'You completed the task. Now claim it' && response.data.task_status === 'Not claimed') {
                            setTaskStatus('Not claimed');
                            updateButtonText('Not claimed', updatedTask.type, taskRewardType);
                        } else if (response.data.message === 'Task already done') {
                            setButtonText('Claimed');
                        }
                    }

                    if (response.data.task) {
                        console.log('Новые данные задачи:', response.data.task);
                        setUpdatedTask(response.data.task);
                        setTaskStatus(response.data.task.status);
                        setTaskRewardType(response.data.task.rewards[0]?.reward_type);
                        updateButtonText(response.data.task.status, response.data.task.type, response.data.task.rewards[0]?.reward_type);
                        setShouldUpdateReward(true);
                    }

                    const userResponse = await axios.get(API_URLS.GET_USER_INFO, { params: { userId }, headers });
                    if (typeof userResponse.data === 'object' && userResponse.data.info) {
                        updateUser({ ...userResponse.data.info });
                    }

                } catch (error) {
                    console.error('Error during task operation:', error);
                    setError('Произошла ошибка при выполнении операции. Пожалуйста, попробуйте еще раз.');
                }
            }

        }
    };

    const handleClose = () => {
        console.log("Closing modal and refreshing data");
        setIsOpen(false);
        setTimeout(() => {
            onClose();
            handleRefresh();
        }, 600);
    };
    
    const getBackgroundImage = (stageId, rankId) => {
        return require(`../../assets/images/upgrade/ModalBackground/${stageId}_1.webp`);
    };

    const renderRewardIcon = () => {
        const reward = updatedTask?.rewards[0];

        if (updatedTask.type === 'buy chest' && (taskStatus === 'Not claimed' || taskStatus === 'Claimed')) {
            if (taskRewardType === '1') {
                return (
                    <div className='modal-task-icon-con-two'>
                        <div className={`reward-gold ${reward.amount < 0 ? 'negative' : 'positive'}`}>{reward.amount}$</div>
                    </div>
                );
            } 
            else if(taskRewardType === '5') {
                <div className='modal-task-icon-con'>
                        <img className='key' src={keyIcon} alt={updatedTask.name} />
                    </div>
            }
            else if (taskRewardType === '6') {
                return (
                    <div className='modal-task-icon-con'>
                        <img className='gnom-mod' src={gnomIcon} alt={updatedTask.name} />
                    </div>
                );
            }
            else if (taskRewardType === '7') {
                return (
                    <div className='modal-task-icon-con' style={{padding: '0'}}>
                        <img className='jail-icon' style={{width: "100%", height: "100%"}} src={jailIcon} alt={updatedTask.name} />
                    </div>
                );
            }

        }

        if (updatedTask.type === 'buy chest' && (taskStatus === 'Unavailable' || taskStatus === 'In progress' || taskStatus === 'Expired')) {
            return (
                <div className='modal-task-icon-con'>
                    <img className='gnom-modа' src={chestIcon} alt={updatedTask.name} />
                </div>
            );
        }

        if (taskRewardType === '2' || taskRewardType === '4') {
            const icon = taskRewardType === '2' ?
                getPickaxeIcon(user.stage.id, reward.id) :
                getEnergyIcon(user.stage.id, reward.id);
            return (
                <div className='modal-task-icon-con'>
                    <img src={icon} alt={updatedTask.name} />
                </div>
            );
        }

        return null;
    };

    const getPickaxeIcon = (stageId, rewardId) => {
        return require(`../../assets/images/upgrade/pickaxe/pickaxe_${stageId}_${rewardId < '1000' ? '1' : rewardId}.webp`);
    };

    const getEnergyIcon = (stageId, rewardId) => {
        return require(`../../assets/images/upgrade/energy/energy_${stageId}_${rewardId < '1000' ? '1' : rewardId}.webp`);
    };

    const getTitle = (task) => {
        var stageId = user.stage.id;
        var rewardId = task.rewards[0].id;
        var reward_type = task.rewards[0].reward_type;
        var status = task.status;
        var taskType = task.type;



        if (task.type === 'buy chest') {
            if (status === 'Unavailable' || status === 'In progress' || status === 'Expired') {
                return t("UpgradePage.Cheast.titleOne");
            }

           else if(reward_type === "1" && (status === 'Not claimed' || status === 'Claimed')) {
                let result;
                if (task.rewards[0].amount < 0) {
                    result = 'negative';
                    return t("UpgradePage.CheastMoneyMinus.titleOne");
                }
                else {
                    result = 'positive';
                    return t('UpgradePage.CheastMoneyPlus.titleOne');
                }
            }

            else if (reward_type === "5" && (status === 'Not claimed' || status === 'Claimed')) {
                return t('UpgradePage.CheastNextKey.titleOne');

            }
            else if (reward_type === "6" && (status === 'Not claimed' || status === 'Claimed')) {
                let key = "UpgradePage.CheastGnom.titleOne";
                let translatedText = t(key);
                return t("UpgradePage.CheastGnom.titleOne");

            }
            

            else if (reward_type === "7" && (status === 'Not claimed' || status === 'Claimed')) {
                return t("UpgradePage.CheastPrison.titleOne");
            }

        }
        

        else if (reward_type === "2") {
            let key;
            if (rewardId < '1000') {
                key = `UpgradePage.Pickaxe.pickaxe_${stageId}_${user.stage.instrument}`;
            } else {
                key = `UpgradePage.Pickaxe.pickaxe_${stageId}_${rewardId}`;
            }

            let translatedObject = t(key, { returnObjects: true });

            if (typeof translatedObject === 'object' && translatedObject.title) {
                return translatedObject.title;
            } else {
                console.warn(`Translation not found for key: ${key}`);
                return key;
            }
        }

        else if (reward_type === "4") {
            let key;
            if (rewardId < '1000') {
                key = `UpgradePage.Energy.energy_${stageId}_${user.stage.drink}`;
            } else {
                key = `UpgradePage.Energy.energy_${stageId}_${rewardId}`;
            }

            let translatedObject = t(key, { returnObjects: true });

            if (typeof translatedObject === 'object' && translatedObject.title) {
                return translatedObject.title;
            } else {
                console.warn(`Translation not found for key: ${key}`);
                return key;
            }
        }

        

        return "";
    };


    const getDescription = (task) => {

        var stageId = user.stage.id;
        var rewardId = task.rewards[0].id;
        var reward_type = task.rewards[0].reward_type;
        var status = task.status;
        var taskType = task.type;
        console.log("--status: ", status);
        console.log("--task.type: ", task.type);
        console.log("--reward_type: ", reward_type);



        switch (task.type) {
                case 'buy chest':
                    if (status === 'Unavailable' || status === 'In progress' || status === 'Expired') {
                        return t("UpgradePage.Cheast.description");
                    }
                    if ((status === 'Not claimed' || status === 'Claimed') && reward_type === '1') {
                        return task.price > 0 
                            ? t('UpgradePage.CheastMoneyPlus.description')
                            : t('UpgradePage.CheastMoneyMinus.description');
                    }
                    if (reward_type === '5') {
                        return t('UpgradePage.CheastNextKey.description');
                    }
                    if (reward_type === '6') {
                        let key;
                        if (rewardId < '1000') {
                            key = `UpgradePage.CheastGnom.description`;
                            return t(key);
                        } 
                        else {
                        key = `UpgradePage.CheastGnom.description`;
                        return t(key);
                    }
                    }
                    if (reward_type === '7') {
                        return t('UpgradePage.CheastPrison.description');
                    }
                    break;
                case 'subscribe to channel':
                    return t('UpgradePage.Subscribe.description');
                case 'invite friend':
                    return t('UpgradePage.Invite.description');
                default:
                    return t('UpgradePage.default.description');
        }
        
    };

    const getTitleTwo = (task) => {
        const reward = task.rewards[0];
        const status = task.status;
        
    
        const prefix = reward.reward_type === '1' && reward.amount < 0 ? '' : '+';
    
        if (reward.reward_type === '4') {
            return (
                <div className='title-two-con'>
                    <p className=''>+{`${reward.amount} ${t("UpgradePage.Universal.energyTitleTwo")}`}</p>
                    <img className='img-mini' src={mini_energy} alt='' />
                </div>
            );
        }
    
        if (reward.reward_type === '2') {
            return (
                <div className='title-two-con'>
                    <p className=''>+{`${reward.amount} ${t("UpgradePage.Universal.pickaxeTitleTwo")}`}</p>
                    <img className='img-mini' src={mini_pickaxe} alt='' />
                </div>
            );
        }

        var plusminusbalik;


        if (prefix === '+') {
            plusminusbalik = `${t("UpgradePage.CheastMoneyPlus.titleTwo")} ${reward.amount} ${t("UpgradePage.Universal.goldTitleTwo")}`;
        }
        else {
            plusminusbalik = `${t("UpgradePage.CheastMoneyMinus.titleTwo")} ${reward.amount} ${t("UpgradePage.Universal.goldTitleTwo")}`;
        }

        if (status === 'Claimed' || status === 'Not claimed') {
            switch (reward.reward_type) {
                case '1':
                    return plusminusbalik;
                case '3':
                    return `+${reward.amount} g-token`;
                case '5':
                    return t("UpgradePage.CheastNextKey.titleTwo");
                case '6':
                    return `+${reward.amount} ${t("UpgradePage.CheastGnom.titleTwo")}`;
                case '7':
                    return `${reward.amount} ${t("UpgradePage.CheastPrison.titleTwo")}`;
                default:
                    return `+${reward.amount} ${reward.name}`;
            }
        }
    };

    const getTitleTwoColor = (reward) => {
        if (!reward) return 'white';

        if (reward.reward_type === '1') {
            return reward.amount < 0 ? 'red' : 'green';
        }

        return 'white';
    };

    const backgroundStyle = {
        backgroundImage: `url(${getBackgroundImage(user.stage.id, user.rank.id)})`,
    };

    if (!updatedTask) return null;

    return (
        <div className={`modal-task-section-bck ${isOpen ? 'open' : ''}`}>
            <div className={`modal-task-section ${isOpen ? 'open' : ''}`}>
                <div className='modal-task-container' style={backgroundStyle}>
                    {/* Dynamically render reward icon */}
                    {renderRewardIcon()}

                    {/* Dynamic title based on task */}
                    <p className='modal-task-title'>{getTitle(updatedTask)}</p>

                    {/* Dynamic reward text with conditional coloring */}
                    <div className='modal-task-title-two' style={{ color: getTitleTwoColor(updatedTask.rewards[0]) }}>
                        {getTitleTwo(updatedTask)}
                    </div>

                    {/* Dynamic task description */}
                    <p className='modal-task-description'>{getDescription(updatedTask)}</p>

                    {/* Display dynamic task metadata */}
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <p className='' style={{ color: "white", marginRight: '15px' }}>id: {updatedTask.id}</p>
                        <p className='' style={{ color: "white", marginRight: '15px' }}>id i: {updatedTask.rewards[0]?.id}</p>
                        <p className='' style={{ color: "white", marginRight: '15px' }}>S: {taskStatus}</p>
                        <p className='' style={{ color: "white" }}>RT: {taskRewardType}</p>
                    </div>

                    {/* Display error message, if any */}
                    {error && <p className="error-message">{error}</p>}

                    {/* Dynamic button text and handler */}
                    <button className='modal-task-buy-button' onClick={buyButton}>
                        {buttonText}
                    </button>

                    {/* Modal close button */}
                    <div className='modal-task-back-btn-section'>
                        <img src={ArrowLeft} alt='<' />
                        <button onClick={handleClose}>Назад</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default UpgradeGameModal;