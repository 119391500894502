import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import UpgradeGameRender from './UpgradeGameRender';
import '../../assets/styles/upgrade/UpgradeGame.css'
import '../../assets/styles/upgrade/upgradeIviteModal.css'
import { ReactComponent as IconDolar } from "../../assets/icons/dolar.svg";
import { useUser } from '../../contexts/UserContext';
import { API_URLS } from '../../config/config';
import UpgradeGameModalGlobal from './UpgradeGameModalGlobal';

const UpgradeGame = ({ gameData, userData, preloadedData, globalRefreshData, isUpdating }) => {
    const [getGameData, setGameData] = useState(gameData);
    const [getUserData, setUserData] = useState(userData);
    const [displayedBalance, setDisplayedBalance] = useState(userData?.gold_balance || 0);
    const [isOpenGlobalModal, setIsOpenGlobalModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    
    const prevGameDataRef = useRef();
    const prevUserDataRef = useRef();

    useEffect(() => {
        if (gameData && gameData.stages && gameData.stages.length > 0) {
            setGameData(gameData);
            prevGameDataRef.current = gameData;
        }
        if (userData) {
            setUserData(userData);
            setDisplayedBalance(userData.gold_balance || 0);
            prevUserDataRef.current = userData;
        }
    }, [gameData, userData]);

    const handleRefreshComplete = useCallback(async () => {
        setIsRefreshing(true);
        await globalRefreshData();
        setIsRefreshing(false);
        setTimeout(() => {
            setIsOpenGlobalModal(false);
        }, 500);
    }, [globalRefreshData]);

    const handleglobalRefreshData = useCallback(async () => {
        setIsRefreshing(true);
        await globalRefreshData();
        setIsRefreshing(false);
    }, [globalRefreshData]);

    const claimLvl = useCallback(async () => {
        if (!userData || !userData.user_id) {
            console.error('User data is not available');
            return;
        }

        try {
            const requestData = { userId: userData.user_id };
            const endpoint = gameData.stages && gameData.stages.length > 1 ? API_URLS.NEXT_STAGE : API_URLS.NEXT_RANK;
            const response = await axios.post(endpoint, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            handleResponse(response.data);
        } catch (error) {
            handleError(error);
        }
    }, [gameData, userData]);

    const handleResponse = useCallback((data) => {
        if (data.result === "moved to next rank") {
            console.log("Ви перейшли на наступний рівень");
            setModalStatus("NextRank");
            setIsOpenGlobalModal(true);
        } else if(data.result === "moved to next stage") {
            console.log("Ви перейшли на наступний етап");
            setModalStatus("NextStage");
            setIsOpenGlobalModal(true);
        } else {
            console.log("Не зрозуміла помилка:", data.result);
        }
    }, []);

    const handleError = useCallback((error) => {
        console.error('Error claiming next level:', error);
        if (error.response && error.response.status === 403 && error.response.data) {
            switch (error.response.data.result) {
                case "You must be at last stage to move on the next rank":
                    setModalStatus("ErrorNextRank");
                    break;
                case "You don't have enough money to move on the next rank":
                    setModalStatus("NoMoneyBuyRank");
                    break;
                case "You don't have key to go next stage":
                    setModalStatus("ErrorNextStage");
                    break;
                case "You can't buy tasks while prisoning":
                    setModalStatus("PrisonError");
                    break;
                default:
                    setModalStatus("Error");
            }
            setIsOpenGlobalModal(true);
        }
    }, []);

    const renderContent = () => {
        const currentGameData = getGameData || prevGameDataRef.current;
        const currentUserData = getUserData || prevUserDataRef.current;

        if (!currentGameData || !currentUserData) {
            return <div>Loading...</div>;
        }

        return (
            <>
                <div className="upgradeGame-section">
                    <div className="upgradeGame-section-title">
                        <p>Апгрейд Beta</p>
                    </div>
                    <UpgradeGameRender
                        gameData={currentGameData}
                        stage={currentUserData.stage}
                        globalRefreshData={handleglobalRefreshData}
                        preloadedData={preloadedData}
                    />
                    <div className='upgrade-section-btn-and-money'>
                        <div className='section_money'>
                        </div>
                        <div className='section-btn'>
                            <div className='mny-con'>
                                <div className='mny-con-dolar'>
                                    <IconDolar className="mny-dolar" />
                                </div>
                                <p className='mny-price'>{displayedBalance}</p>
                            </div>
                            <button onClick={claimLvl} onTouchStart={claimLvl}>Перейти на наступний лвл</button>
                        </div>
                    </div>
                </div>
                {isOpenGlobalModal && (
                    <UpgradeGameModalGlobal 
                        statusCustom={modalStatus} 
                        onClose={handleRefreshComplete} 
                        onRefreshGlobal={handleglobalRefreshData} 
                    />
                )}
            </>
        );
    };

    return (
        <div className={`upgradeGame-container ${isUpdating || isRefreshing ? 'updating-Con' : ''}`} style={{height: "100%", width: "100%"}}>
            {renderContent()}
            {(isUpdating || isRefreshing) && (
                <div className="overlay">
                    <div className="spinner-custom"></div>
                </div>
            )}
        </div>
    );
}

export default UpgradeGame;