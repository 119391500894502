import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AdModal from "../components/AdModal";
import AddAdsetModal from "../components/AddAdsetModal";
import TradeWarningModal from "../components/TradeWarningModal";
import ConfirmDeleteModal from "../components/ConfirmDeleteAdsetModal";
import LoadingDots from "../components/LoadingDots";
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext";
import { API_URLS } from "../config/config";
import axios from "axios";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";
import "../assets/styles/adOffice.css";

const CustomSelect = ({
  options,
  onSelect,
  icon,
  isSmall,
  value,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || options[0]);
  const selectRef = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    if (value && options.includes(value)) {
      setSelectedOption(value);
    }
  }, [value, options]);

  const toggleSelect = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (optionsRef.current) {
      if (isOpen) {
        const height = optionsRef.current.scrollHeight;
        optionsRef.current.style.maxHeight = `${height}px`;
      } else {
        optionsRef.current.style.maxHeight = "0px";
      }
    }
  }, [isOpen]);

  return (
    <div
      className={`custom-select-wrapper ${isOpen ? "open" : ""} ${
        isSmall ? "small" : ""
      } ${disabled ? "disabled" : ""}`}
      ref={selectRef}
    >
      <div className="custom-select" onClick={toggleSelect}>
        <span>{selectedOption}</span>
        <img
          src={icon}
          alt="arrow"
          className={`w-3 ${isOpen ? "rotated" : ""}`}
        />
      </div>
      {!disabled && (
        <div className="custom-options" ref={optionsRef}>
          {options
            .filter((option) => option !== selectedOption)
            .map((option, index) => (
              <div
                key={index}
                className="custom-option"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const AdOffice = ({ preloadedData }) => {
  const { t } = useTranslation();
  const adOptions = [
    t("AdOfficePage.AdOptions.Banner"),
    t("AdOfficePage.AdOptions.FairyGift"),
  ];

  const statisticsOptions = [
    t("AdOfficePage.StatisticsOptions.AllTime"),
    t("AdOfficePage.StatisticsOptions.Month"),
    t("AdOfficePage.StatisticsOptions.Week"),
    t("AdOfficePage.StatisticsOptions.Day"),
  ];

  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const audioBufferRef = useRef(null);

  const [selectedAdOption, setSelectedAdOption] = useState(
    t("AdOfficePage.AdOptions.Banner")
  );
  const [statistics, setStatistics] = useState(0);
  const [adSize, setAdSize] = useState("182х50 px");
  const [days, setDays] = useState(6);
  const [link, setLink] = useState("");
  const [amountPerDay, setAmountPerDay] = useState(1);
  const [startY, setStartY] = useState(null);
  const [isAdModalOpen, setIsAdModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddAdsetModalOpen, setIsAddAdsetModalOpen] = useState(false);
  const [isCreativeModalOpen, setIsCreativeModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [adsetToDelete, setAdsetToDelete] = useState(null);
  const [selectedAd, setSelectedAd] = useState(null);
  const [selectedFilePath, setSelectedFilePath] = useState(null);
  const [selectedAdFullPath, setSelectedAdFullPath] = useState(null);
  const [selectedAdType, setSelectedAdType] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedIsVideo, setSelectedIsVideo] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const sliderRef = useRef(null);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [isLoading, setIsLoading] = useState({});
  const { user } = useUser();
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    link: "",
  });
  const [adSets, setAdSets] = useState(preloadedData.adsets || []);
  const [selectedAdSetName, setSelectedAdSetName] = useState(null);
  const [selectedAdSetId, setSelectedAdSetId] = useState(null);

  const [adFilePaths, setAdFilePaths] = useState({});
  useEffect(() => {
    if (preloadedData && preloadedData.adsets) {
      setAdSets(preloadedData.adsets);
      if (preloadedData.adsets.length > 0) {
        setSelectedAdSetName(preloadedData.adsets[0].name);
        setSelectedAdSetId(preloadedData.adsets[0].id);
      }
    }
  }, [preloadedData]);
  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const response = await fetch(clickSoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          audioBufferRef.current =
            await audioContextRef.current.decodeAudioData(arrayBuffer);

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      audioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const updateModalState = (message, isOpen = true, link = "") => {
    setModalState({ isOpen, message, link });
  };
  const fetchAdFilePath = async (adset) => {
    let bannerPath, fullscreenPath;

    try {
      console.log("Fetching ad file paths for adset:", adset.id);
      console.log("Banner ID:", adset.banner);
      console.log("Fullscreen ID:", adset.fullscreen);

      if (adset.banner) {
        // const response = await axios.post("https://8ac0-91-214-139-32.ngrok-free.app/ads/get_banner_advert/", {
        //   adId: adset.banner,
        // });
        const response = await axios.post(API_URLS.GET_BANNER_ADVERT, {
          adId: adset.banner,
        });
        bannerPath = response.data.file_path;
        console.log("Banner path received:", bannerPath);
      }

      if (adset.fullscreen) {
        const response = await axios.post(API_URLS.GET_FULLSCREEN_ADVERT, {
          adId: adset.fullscreen,
        });
        fullscreenPath = response.data.file_path;
        console.log("Fullscreen path received:", fullscreenPath);
      }

      console.log("Setting ad file paths for adset:", adset.id, {
        bannerPath,
        fullscreenPath,
      });
    } catch (error) {
      console.error("Error fetching ad file paths:", error);
    }

    setAdFilePaths((prevPaths) => {
      const newPaths = {
        ...prevPaths,
        [adset.id]: { bannerPath, fullscreenPath },
      };
      console.log("Updated adFilePaths:", newPaths);
      return newPaths;
    });
  };

  useEffect(() => {
    if (
      openAccordion !== null &&
      adSets[openAccordion] &&
      !adFilePaths[adSets[openAccordion].id]
    ) {
      fetchAdFilePath(adSets[openAccordion]);
    }
  }, [openAccordion, adSets, adFilePaths]);

  const loadAdsets = async () => {
    try {
      const response = await axios.post(API_URLS.GET_USER_ADSETS, {
        userId: user.user_id,
      });

      console.log("get adsets", response.data.adsets);
      if (Array.isArray(response.data.adsets)) {
        const formattedAdsets = response.data.adsets.map((adset) => ({
          id: adset.id,
          name: adset.name,
          current_clicks_number: adset.current_clicks_number,
          display_duration: adset.display_duration,
          fullscreen: adset.fullscreen,
          banner: adset.banner,
          is_active: adset.is_active,
          g_tokens_per_day: adset.g_tokens_per_day,
          is_verified: adset.is_verified,
          relevant_regions: adset.relevant_regions,
          total_active_duration: adset.total_active_duration,
          last_started_at: adset.last_started_at,
          will_be_displayed_until: adset.will_be_displayed_until,
        }));
        setAdSets(formattedAdsets);
        if (formattedAdsets.length > 0) {
          setSelectedAdSetName(formattedAdsets[0].name);
          setSelectedAdSetId(formattedAdsets[0].id);
        }
      } else {
        setAdSets([]);
        setSelectedAdSetName(null);
        setSelectedAdSetId(null);
      }
    } catch (error) {
      console.error(error);
      setAdSets([]);
      setSelectedAdSetName(null);
      setSelectedAdSetId(null);
    }
  };

  const updateLastActive = async (userId) => {
    try {
      const response = await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
      console.log("Last active time updated:", response.data);
    } catch (error) {
      console.error("Error updating last active time:", error);
    }
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);

  useEffect(() => {
    setAmountPerDay(1);
    console.log(preloadedData.regions);
  }, []);

  const toggleAccordion = (index) => {
    setOpenAccordion((prevOpen) => (prevOpen === index ? null : index));
    if (!isLoading[index]) {
      setIsLoading((prev) => ({ ...prev, [index]: true }));
      fetchAdFilePath(adSets[index]).then(() => {
        setIsLoading((prev) => ({ ...prev, [index]: false }));
      });
    }
  };

  const openAddAdsetModal = () => {
    playSound();
    setIsAddAdsetModalOpen(true);
  };

  const closenAddAdsetModal = () => {
    setIsAddAdsetModalOpen(false);
  };

  const handleWatchCreative = (ad) => {
    setIsCreativeModalOpen(true);
  };

  const openAdModal = (ad, fileName, filePath) => {
    console.log("ad modal");
    playSound();
    setSelectedAd(ad);
    setSelectedFilePath(fileName);
    setSelectedAdFullPath(filePath);

    const adType =
      filePath === adFilePaths[ad.id].bannerPath
        ? t("AdOfficePage.AdOptions.Banner")
        : t("AdOfficePage.AdOptions.FairyGift");

    setSelectedAdType(adType);

    const isVideo =
      filePath.toLowerCase().endsWith(".mp4") ||
      filePath.toLowerCase().endsWith(".webm") ||
      filePath.toLowerCase().endsWith(".ogg");
    setSelectedIsVideo(isVideo);
    setSelectedAdType(adType);
    setIsAdModalOpen(true);
  };

  const closeAdModal = () => {
    setIsAdModalOpen(false);
    setSelectedAd(null);
    setSelectedFilePath(null);
    setSelectedAdType(null);
  };

  const handleSelectChange = (option) => {
    setSelectedAdOption(option);
    console.log("Selected ad type:", option);

    switch (option) {
      case t("AdOfficePage.AdOptions.FairyGift"):
        setAdSize("1080х1920 px");
        break;
      case t("AdOfficePage.AdOptions.Banner"):
      default:
        setAdSize("500х200 px");
    }
  };

  const handleAdsetChange = (option) => {
    const selectedSet = adSets.find((set) => set.name === option);
    if (selectedSet) {
      setSelectedAdSetName(selectedSet.name);
      setSelectedAdSetId(selectedSet.id);
      console.log("Selected AdSet:", selectedSet.name, "ID:", selectedSet.id);
    } else {
      console.error("AdSet not found for option:", option);
      setSelectedAdSetName(null);
      setSelectedAdSetId(null);
    }
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const updateSliderBackground = (value) => {
    if (sliderRef.current) {
      const percentage = ((value - 1) / (31 - 1)) * 100;
      sliderRef.current.style.setProperty(
        "--slider-percentage",
        `${percentage}%`
      );
    }
  };

  useEffect(() => {
    updateSliderBackground(days);
  }, [days]);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!startY) return;
    const currentY = e.touches[0].clientY;
    const diff = startY - currentY;
    e.currentTarget.scrollTop += diff;
    setStartY(currentY);
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    console.log("Selected file:", file.name, file.type, file.size);

    try {
      let minWidth, minHeight, maxWidth, maxHeight;
      if (selectedAdOption === t("AdOfficePage.AdOptions.Banner")) {
        minWidth = 300;
        minHeight = 50;
        maxWidth = 1000;
        maxHeight = 500;
      } else if (selectedAdOption === t("AdOfficePage.AdOptions.FairyGift")) {
        minWidth = 720;
        minHeight = 1280;
        maxWidth = 3000;
        maxHeight = 3000;
      }

      const maxFileSize = 150 * 1024 * 1024;
      if (file.size > maxFileSize) {
        throw new Error(t("AdOfficePage.FileTooLarge", { maxSize: "150 MB" }));
      }

      if (file.type.startsWith("image/")) {
        await checkImageDimensions(
          file,
          minWidth,
          minHeight,
          maxWidth,
          maxHeight
        );
      } else if (file.type.startsWith("video/")) {
        await checkVideoDimensions(
          file,
          minWidth,
          minHeight,
          maxWidth,
          maxHeight
        );
      } else {
        throw new Error(t("AdOfficePage.UnsupportedFileType"));
      }

      setSelectedFile(file);
      setUploadStatus(t("AdOfficePage.FileSelected", { fileName: file.name }));
    } catch (error) {
      console.error("File validation error:", error);
      updateModalState(error.message, true);
      event.target.value = null; // Reset file input
    }
  };

  const checkImageDimensions = (
    file,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight
  ) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width < minWidth || img.height < minHeight) {
          reject(
            new Error(t("AdOfficePage.ImageTooSmall", { minWidth, minHeight }))
          );
        } else if (img.width > maxWidth || img.height > maxHeight) {
          reject(
            new Error(t("AdOfficePage.ImageTooLarge", { maxWidth, maxHeight }))
          );
        } else {
          resolve();
        }
      };
      img.onerror = () => reject(new Error(t("AdOfficePage.ImageLoadError")));
      img.src = URL.createObjectURL(file);
    });
  };

  const checkVideoDimensions = (
    file,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight
  ) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        console.log(
          `Video dimensions: ${video.videoWidth}x${video.videoHeight}`
        );
        if (video.videoWidth < minWidth || video.videoHeight < minHeight) {
          reject(
            new Error(t("AdOfficePage.VideoTooSmall", { minWidth, minHeight }))
          );
        } else if (
          video.videoWidth > maxWidth ||
          video.videoHeight > maxHeight
        ) {
          reject(
            new Error(t("AdOfficePage.VideoTooLarge", { maxWidth, maxHeight }))
          );
        } else {
          resolve();
        }
      };

      video.onerror = () => {
        URL.revokeObjectURL(video.src);
        reject(new Error(t("AdOfficePage.VideoLoadError")));
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const openFilePicker = () => {
    playSound();
    fileInputRef.current.click();
  };

  const extractFileName = (path) => {
    if (!path) return "";
    const parts = path.split("/");
    return parts[parts.length - 1];
  };

  const checkIfVideo = (file) => {
    const videoTypes = [
      "video/mp4",
      "video/webm",
      "video/ogg",
      "video/quicktime",
    ];
    return videoTypes.includes(file.type);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    playSound();

    if (!selectedFile || !selectedAdSetId) {
      setUploadStatus(t("AdOfficePage.MissingInformation"));
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", selectedFile, selectedFile.name);
      formData.append("link", link);
      formData.append("adsetId", selectedAdSetId);
      formData.append(
        "isVideo",
        selectedFile.type.startsWith("video/") ? "1" : "0"
      );

      console.log("FormData contents:", Object.fromEntries(formData));

      const endpoint =
        selectedAdOption === t("AdOfficePage.AdOptions.Banner")
          ? API_URLS.CREATE_BANER_ADVERT
          : API_URLS.CREATE_FULLSCREEN_ADVERT;

      setUploadStatus(t("AdOfficePage.Uploading"));
      const response = await axios.post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Upload response:", response.data);

      setSelectedFile(null);
      setLink("");
      await loadAdsets();
      setUploadStatus(t("AdOfficePage.AdCreatedSuccessfully"));
    } catch (error) {
      console.error("Error creating ad:", error);
      setUploadStatus(t("AdOfficePage.ErrorCreatingAd"));
    }
  };

  const handleInputFocus = () => {
    const menuElement = document.querySelector(".menu");
    if (menuElement) {
      menuElement.classList.remove("menu");
      menuElement.classList.add("menu-hidden");
    }
    const tradeButton = document.querySelector(".trade-button");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button");
      tradeButton.classList.add("trade-button-hidden");
    }
  };

  const handleInputBlur = () => {
    const menuElement = document.querySelector(".menu-hidden");
    if (menuElement) {
      menuElement.classList.remove("menu-hidden");
      menuElement.classList.add("menu");
    }
    const tradeButton = document.querySelector(".trade-button-hidden");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button-hidden");
      tradeButton.classList.add("trade-button");
    }
  };

  const convertShutdownTime = (shutdownTime) => {
    const now = new Date();
    const shutdownDate = new Date(shutdownTime);
    const diffTime = Math.abs(shutdownDate - now);

    const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffTime % (1000 * 60)) / 1000);

    let result = "";
    if (days > 0) result += `${days} ${t("AdOfficePage.Days")} `;
    if (hours > 0) result += `${hours} ${t("AdOfficePage.Hours")} `;
    if (minutes > 0) result += `${minutes} ${t("AdOfficePage.Minutes")} `;
    if (seconds > 0) result += `${seconds} ${t("AdOfficePage.Seconds")}`;

    return result.trim();
  };

  function formatShutdownTime(shutdownTime) {
    const now = new Date();
    const shutdownDate = new Date(shutdownTime);
    const diffTime = Math.abs(shutdownDate - now);

    const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffTime % (1000 * 60)) / 1000);

    let result = "";

    if (days > 0) result += `${days}${t("AdOfficePage.TimeUnits.Day")} `;
    if (hours > 0) result += `${hours}${t("AdOfficePage.TimeUnits.Hour")} `;
    if (minutes > 0)
      result += `${minutes}${t("AdOfficePage.TimeUnits.Minute")} `;
    if (seconds > 0)
      result += `${seconds}${t("AdOfficePage.TimeUnits.Second")}`;

    return result.trim();
  }
  const handleEnableAdSet = async (adset) => {
    try {
      playSound();
      const response = await axios.post(API_URLS.ENABLE_ADSET, {
        adsetId: adset.id,
      });

      console.log(response.data);
      if (response.data.ok === true) {
        const timeLeft = formatShutdownTime(response.data.shutdown_time);
        updateModalState(t("AdOfficePage.AdSetEnableSuccess") + timeLeft, true);
        setAdSets((prevAdSets) =>
          prevAdSets.map((set) =>
            set.id === adset.id ? { ...set, is_active: 1 } : set
          )
        );
      }
    } catch (error) {
      let message = t("AdOfficePage.EnableUnexpectedError");
      if (error.response && error.response.data && error.response.data.error) {
        switch (error.response.data.error) {
          case "no adset with such ID":
            message = t("AdOfficePage.NoSuchAdset");
            break;
          case "not enough balance to pay for the requested display time":
            message = t("AdOfficePage.NotEnoughBalance");
            break;
          case "there are unverified adverts in this adset":
            message = t("AdOfficePage.UnverifiedAdsError");
            break;
          case "the adset is empty":
            message = t("AdOfficePage.EmptyAdsetError");
            break;
          case "adset has no more display time":
            message = t("AdOfficePage.NoMoreTimeError");
            break;
          default:
            t("AdOfficePage.EnableUnexpectedError");
            break;
        }
      }
      updateModalState(message, true);
    }
  };

  const handleDisableAdSet = async (adset) => {
    try {
      playSound();
      const response = await axios.post(API_URLS.DISABLE_ADSET, {
        adsetId: adset.id,
      });
      console.log(response.data);
      if (response.data.ok === true) {
        const timeLeft = formatShutdownTime(response.data.shutdown_time);
        updateModalState(
          t("AdOfficePage.AdSetDisableSuccess") + timeLeft,
          true
        );
        setAdSets((prevAdSets) =>
          prevAdSets.map((set) =>
            set.id === adset.id ? { ...set, is_active: 2 } : set
          )
        );
      } else if (response.data.error === "adset is not waiting for shutdown") {
        updateModalState(t("AdOfficePage.AdSetShotDownError"), true);
      }
    } catch (error) {
      let message = t("AdOfficePage.DisableUnexpectedError");
      if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error === "unexpected database error") {
          updateModalState(t("AdOfficePage.DatabaseError"), true);
        }
      }
      updateModalState(message, true);
    }
  };

  const handleCansellShutdown = async (adset) => {
    try {
      playSound();
      const response = await axios.post(API_URLS.CANSELL_SHUTDOWN, {
        adsetId: adset.id,
      });
      console.log(response.data);

      if (response.data.ok === true) {
        const timeLeft = formatShutdownTime(response.data.new_shutdown_time);
        updateModalState(
          t("AdOfficePage.AdCancelDisableSuccess") + timeLeft,
          true
        );
        setAdSets((prevAdSets) =>
          prevAdSets.map((set) =>
            set.id === adset.id ? { ...set, is_active: 1 } : set
          )
        );
      }
    } catch (error) {
      let message = t("AdOfficePage.DisableUnexpectedError");
      if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error === "unexpected database error") {
          updateModalState(t("AdOfficePage.DatabaseError"), true);
        }
      }
      updateModalState(message, true);
    }
  };

  const handleAdSetToggle = async (adset) => {
    console.log(adset.is_active);
    switch (adset.is_active) {
      case 0:
        await handleEnableAdSet(adset);
        break;
      case 1:
        await handleDisableAdSet(adset);
        break;
      case 2:
        await handleCansellShutdown(adset);
        break;
      default:
        return null;
    }
  };

  const handleDeleteAdset = (adset) => {
    playSound();
    setAdsetToDelete(adset);
    setIsConfirmDeleteModalOpen(true);
  };

  const confirmDeleteAdset = async () => {
    if (adsetToDelete) {
      try {
        const response = await axios.post(API_URLS.DELETE_ADSET, {
          adsetId: adsetToDelete.id,
        });

        if (response.data.ok) {
          setAdSets((prevAdSets) =>
            prevAdSets.filter((adset) => adset.id !== adsetToDelete.id)
          );
          updateModalState(t("AdOfficePage.AdSetDeleteSuccess"), true);
        } else {
          updateModalState(t("AdOfficePage.UnexpectedDeleteAdsetResult"), true);
        }
      } catch (error) {
        console.error("Error deleting adset:", error);
        updateModalState(t("AdOfficePage.UnexpectedDeleteAdsetResult"), true);
      }
    }
    setIsConfirmDeleteModalOpen(false);
    setAdsetToDelete(null);
  };

  const renderAdSet = (adset, index) => {
    const isOpen = openAccordion === index;
    const adContent = adFilePaths[adset.id];
    const loading = isLoading[index];

    return (
      <li key={index} className="ad-item-container">
        <div className="flex flex-row justify-between w-full relative">
          <div>
            <button className="bin-button">
              <img
                alt="bin"
                className="bin-icon"
                src={preloadedData.iconBin}
                onClick={() => handleDeleteAdset(adset)}
              />
            </button>
          </div>
          <div className="adv-accordion w-3/6">
            <div
              className="adv-accordion-header"
              onClick={() => toggleAccordion(index)}
            >
              <span className="adv-accordion-title">{adset.name}</span>
              <svg
                className={`adv-accordion-icon ${isOpen ? "rotate" : ""}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                color="white"
                stroke="currentColor"
                width="20"
                height="20"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
          <div className="w-2/6 flex justify-end">
            <button
              className="adset-start-button"
              onClick={() => handleAdSetToggle(adset)}
            >
              {adset.is_active === 0
                ? t("AdOfficePage.StartBtnText")
                : adset.is_active === 1
                ? t("AdOfficePage.StopBtnText")
                : adset.is_active === 2
                ? t("AdOfficePage.CansleShutdownBtnText")
                : ""}
            </button>
          </div>
        </div>
        <div
          className={`adv-accordion-content transition-all duration-500 ease-in-out overflow-hidden ${
            isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          {isOpen &&
            (loading ? (
              <div className="loading-container">
                <LoadingDots />
              </div>
            ) : adContent ? (
              renderAd(adset)
            ) : (
              <p className="adv-detail-ad-title-text">
                {t("AdOfficePage.NoAdsYet")}
              </p>
            ))}
        </div>
      </li>
    );
  };

  const renderAd = (adset) => {
    const { bannerPath, fullscreenPath } = adFilePaths[adset.id] || {};

    const bannerFileName = extractFileName(bannerPath);
    const fullscreenFileName = extractFileName(fullscreenPath);

    if (!bannerPath && !fullscreenPath) {
      return (
        <div className="adv-accordion-content">
          <p className="adv-detail-ad-title-text">
            {t("AdOfficePage.NoAdsYet")}
          </p>
        </div>
      );
    }

    const getModerationStatusClass = (status) => {
      switch (status) {
        case 3:
          return "success";
        case 2:
          return "waiting";
        default:
          return "failure";
      }
    };

    if (!bannerPath && !fullscreenPath) {
      return (
        <div className="adv-accordion-content">
          <p className="adv-detail-ad-title-text">
            {t("AdOfficePage.NoAdsYet")}
          </p>
        </div>
      );
    }

    return (
      <div className="adv-accordion-content">
        <div className="adv-start-date-row">
          <p className="adv-start-date-text">{adset.created_at}</p>
        </div>

        {bannerPath && (
          <div
            className="flex flex-col w-full mb-4"
            onClick={() => openAdModal(adset, bannerFileName, bannerPath)}
          >
            <div className="adv-detail-row">
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">
                  {t("AdOfficePage.AdName")}
                </p>
                <p className="adv-detail-ad-title-text">{bannerFileName}</p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">
                  {t("AdOfficePage.Status")}
                </p>
                <p className="adv-detail-ad-status">
                  {adset.is_active
                    ? t("AdOfficePage.StatusActive")
                    : t("AdOfficePage.StatusInactive")}
                </p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">
                  {t("AdOfficePage.Moderation")}
                </p>
                <p
                  className={`adv-detail-ad-status ${getModerationStatusClass(
                    adset.is_verified
                  )}`}
                >
                  {adset.is_verified === 3
                    ? t("AdOfficePage.ModerationSuccess")
                    : adset.is_verified === 2
                    ? t("AdOfficePage.ModerationInProgress")
                    : t("AdOfficePage.ModerationFailed")}
                </p>
              </div>
            </div>
            <hr className="mt-2 mb-2 ad-line" />
            <div className="adv-detail-row mt-2">
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title2">
                  {t("AdOfficePage.NumberOfTransitions")}
                </p>
                <p className="adv-detail-ad-title-text2">
                  {adset.current_clicks_number}
                </p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title2">
                  {t("AdOfficePage.AdvertisementDuration")}
                </p>
                <p className="adv-detail-ad-title-text2">
                  {adset.display_duration}
                </p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title2">
                  {t("AdOfficePage.AdType")}
                </p>
                <p className="adv-detail-ad-title-text2">
                  {t("AdOfficePage.AdOptions.Banner")}
                </p>
              </div>
            </div>
          </div>
        )}

        {fullscreenPath && (
          <div
            className="flex flex-col w-full mb-4"
            onClick={() =>
              openAdModal(adset, fullscreenFileName, fullscreenPath)
            }
          >
            <div className="adv-detail-row">
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">
                  {t("AdOfficePage.AdName")}
                </p>
                <p className="adv-detail-ad-title-text">{fullscreenFileName}</p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">
                  {t("AdOfficePage.Status")}
                </p>
                <p className="adv-detail-ad-status">
                  {adset.is_active
                    ? t("AdOfficePage.StatusActive")
                    : t("AdOfficePage.StatusInactive")}
                </p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">
                  {t("AdOfficePage.Moderation")}
                </p>
                <p
                  className={`adv-detail-ad-status ${getModerationStatusClass(
                    adset.is_verified
                  )}`}
                >
                  {adset.is_verified === 3
                    ? t("AdOfficePage.ModerationSuccess")
                    : adset.is_verified === 2
                    ? t("AdOfficePage.ModerationInProgress")
                    : t("AdOfficePage.ModerationFailed")}
                </p>
              </div>
            </div>
            <hr className="mt-2 mb-2 ad-line" />
            <div className="adv-detail-row mt-2">
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title2">
                  {t("AdOfficePage.NumberOfTransitions")}
                </p>
                <p className="adv-detail-ad-title-text2">
                  {adset.current_clicks_number}
                </p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title2">
                  {t("AdOfficePage.AdvertisementDuration")}
                </p>
                <p className="adv-detail-ad-title-text2">
                  {adset.display_duration}
                </p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title2">
                  {t("AdOfficePage.AdType")}
                </p>
                <p className="adv-detail-ad-title-text2">
                  {t("AdOfficePage.AdOptions.FairyGift")}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      id="ad"
      style={{ backgroundImage: `url(${preloadedData.background})` }}
    >
      <AdModal
        isOpen={isAdModalOpen}
        onClose={closeAdModal}
        background={preloadedData.modalBackground}
        ad={selectedAd}
        filePath={selectedFilePath}
        fullFilePath={selectedAdFullPath}
        adType={selectedAdType}
        isVideo={selectedIsVideo}
        iconArrowLeft={preloadedData.iconArrowLeft}
        iconCoin={preloadedData.iconCoin}
        iconModerationSuccess={preloadedData.iconModerationSuccess}
        iconModerationInProgress={preloadedData.iconModerationInProgress}
        iconModerationFail={preloadedData.iconModerationFail}
        onWatchCreative={handleWatchCreative}
      />
      <AddAdsetModal
        isOpen={isAddAdsetModalOpen}
        onClose={closenAddAdsetModal}
        background={preloadedData.modalBackground}
        iconArrowLeft={preloadedData.iconArrowLeft}
        iconCoin={preloadedData.iconCoin}
        iconArrow={preloadedData.iconArrow}
        regions={preloadedData.regions}
        onAdsetCreated={loadAdsets}
      />
      <TradeWarningModal
        key={modalState.isOpen ? "open" : "closed"}
        modalState={modalState}
        background={preloadedData.background}
        onClose={() => updateModalState("", false)}
      />
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
        onConfirm={confirmDeleteAdset}
        background={preloadedData.modalBackground}
      />
      <div className="scrolable-adoffice-content">
        <div className="h-14 flex items-center justify-center">
          <p className="title">{t("AdOfficePage.Title")}</p>
        </div>
        <div className="ad-office-col">
          <div className="w-full">
            <p className="ad-page-label mb-1">
              {t("AdOfficePage.ChooseAdTypeText")}
            </p>
          </div>
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex items-center justify-center flex-col w-2/4">
              <CustomSelect
                options={adOptions}
                onSelect={handleSelectChange}
                icon={preloadedData.iconArrow}
                isSmall={false}
              />
            </div>
            <div className="flex items-center justify-between flex-row w-2/6">
              <p className="ad-size">{t("AdOfficePage.Size")}</p>
              <p className="ad-size">{adSize}</p>
            </div>
          </div>
        </div>
        <div className="ad-office-col">
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex items-center justify-center flex-col w-full">
              <p className="ad-page-label mb-1">{t("AdOfficePage.Link")}</p>
              <div className="data-input-container">
                <input
                  type="text"
                  value={link}
                  onChange={handleLinkChange}
                  className="modal-data-text-input"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="Link"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ad-office-col">
          <div className="flex flex-row w-full justify-between items-center">
            {/* WHILL BE ADD SOON */}
            {/* <div className="flex items-center justify-center flex-col w-2/5">
            <p className="ad-page-label mb-1">
              {t("AdOfficePage.ChooseAdCountryText")}
            </p>
            <CustomSelect
              options={tempСountries}
              onSelect={handleCountryChange}
              icon={preloadedData.iconArrow}
              isSmall={false}
            />
          </div> */}
          </div>
        </div>
        <div className="ad-office-col">
          <div className="flex flex-row w-full justify-start items-end">
            <div className="flex items-center justify-center flex-col w-2/4">
              <p className="ad-page-label mb-1">
                {t("AdOfficePage.ChooseAdSetText")}
              </p>
              <CustomSelect
                options={adSets.map((set) => set.name)}
                onSelect={handleAdsetChange}
                icon={preloadedData.iconArrow}
                isSmall={false}
                disabled={adSets.length === 0}
                value={selectedAdSetName || t("AdOfficePage.NoAdsetsYet")}
              />
            </div>
            <div className="flex items-center justify-center flex-col ml-2">
              <button
                className="add-adset-button mb-1"
                onClick={openAddAdsetModal}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div className="ad-office-col">
          <div className="w-full">
            <p className="ad-page-label mb-1">{t("AdOfficePage.ChooseAd")}</p>
          </div>
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex items-center justify-center flex-col w-14">
              <button className="choose-file-button" onClick={openFilePicker}>
                <img
                  src={preloadedData.iconFile}
                  alt="file"
                  className="icon-file"
                />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/*,video/*"
                style={{ display: "none" }}
              />
            </div>
            <div className="flex items-end justify-end flex-col w-full">
              <div className="choose-file-input-container">
                <input
                  className="choose-file-input"
                  placeholder={t("AdOfficePage.ChooseAdInputPlaceholder")}
                  value={selectedFile ? selectedFile.name : ""}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ad-office-col">
          <button className="ad-start-button" onClick={handleSubmit}>
            {t("AdOfficePage.AddAdButtonText")}
          </button>
        </div>

        {/* MUST BE STUTISTIC, WHILL BE AD SOON */}
        {/* <div className="ad-office-col">
            <div className="w-full flex justify-start">
              <div className="flex flex-row justify-start items-center w-2/4">
                <p className="ad-page-label mb-1">
                  {t("AdOfficePage.ShowForText")}
                </p>
                <CustomSelect
                  options={statisticsOptions}
                  onSelect={handleStatisticsChange}
                  icon={preloadedData.iconArrow}
                  isSmall={true}
                />
              </div>
            </div>
          </div> */}

        <div className="ad-office-col mt-5">
          <div
            className="ads-office-list"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <ul>
              {adSets.length > 0 ? (
                adSets.map((adset, index) => renderAdSet(adset, index))
              ) : (
                <div className="adv-accordion-content">
                  <p className="adv-detail-ad-title-text">
                    {t("AdOfficePage.NoAdsYet")}
                  </p>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdOffice;
