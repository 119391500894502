import React, { useEffect, useState, memo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAudio } from "../contexts/AudioContext";
import "../assets/styles/copmonents/Modal/noResourceModal.css";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";

const TradeWarningModal = memo(({ modalState, background, onClose }) => {
  const { isOpen, message, link } = modalState;
  const [isActive, setIsActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { t } = useTranslation();
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
      setTimeout(() => setIsActive(true), 50);
    } else {
      setIsClosing(true);
      setIsActive(false);
      const timer = setTimeout(() => {
        setIsClosing(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);
  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([fetch(clickSoundMp3)]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const handleClose = () => {
    playSound();
    setIsClosing(true);
    setIsActive(false);
    setTimeout(onClose, 500);
  };

  if (!isOpen && !isActive && !isClosing) return null;

  const modalContainerStyle = {
    "--modal-background": `url(${background})`,
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkClick = () => {
    window.open(link, '_blank');
  };

  return (
    <div
      className={`no-resourse-modal-overlay ${isActive ? "active" : ""} ${
        isClosing ? "closing" : ""
      }`}
      onClick={handleClose}
    >
      <div
        className={`no-resourse-modal-container ${isActive ? "active" : ""} ${
          isClosing ? "closing" : ""
        } ${link ? "with-link" : ""}`}
        style={{
          ...modalContainerStyle,
          height: link ? "auto" : "160px",
          minHeight: "160px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="no-resourse-modal-content">
          <div className="flex flex-col justify-between items-center h-full">
            <div
              className={`flex flex-col items-center ${
                link ? "h-auto" : "h-20"
              }`}
            >
              <p className="no-resourse-modal-title text-center mb-4">
                {message}
              </p>
              
              {/* {link && link !== "" && isValidUrl(link) && (
                <a
                  href={link}
                  className="solution-link mb-4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link}
                </a>
              )} */}
               {link && isValidUrl(link) && (
                <button
                  className="solution-link mb-4"
                  onClick={handleLinkClick}
                >
                {t("TradePage.FollowTheLink")}
                </button>
              )}
            </div>
            <div className="h-auto flex justify-center items-center w-full">
              <button className="claim-button" onClick={handleClose}>
                {t("TradePage.ModalBackButtonText")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TradeWarningModal;
