import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../assets/styles/navbar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';
import { useUser } from "../contexts/UserContext";
import { API_URLS } from "../config/config";
import axios from "axios";

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../assets/images/Adverts', false, /\.(png|jpe?g|svg|jfif)$/));

const Navbar = ({ onPageChange, preloadedData }) => {
  const { user } = useUser();
  const [adverts, setAdverts] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (preloadedData && preloadedData.adverts) {
      setAdverts(preloadedData.adverts);
      console.log("adverts",preloadedData.adverts);
    }
  }, [preloadedData]);

  const handleSettingsClick = () => {
    onPageChange("/settings");
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: adverts.length > 1,
    autoplaySpeed: 3000,
  };

  const handleAdvertClick = async (advertId, url) => {
    try {
      const data = {
        userId: user.user_id
      };
      const response = await axios.post(`${API_URLS.TRACK_BANNER_ADVERTS_ENDPOINT}${advertId}/`, data);
      console.log("Advert click tracked successfully:", response);
      if(response.data.result === 'ok'){
        window.location.href = response.data.redirection_link;
      }
    } catch (error) {
      console.error("Error tracking advert click:", error);
    }
  };

  const renderAdvert = (advert) => (
    <div key={advert.id} className="advert-item">
      <div 
        onClick={() => handleAdvertClick(advert.id, advert.url)}
        style={{ cursor: 'pointer' }}
      >
        <img 
          src={images[advert.file_path] || `${process.env.PUBLIC_URL}/assets/images/Adverts/${advert.image_path}`} 
          alt={advert.name} 
          className="advert-image" 
          onError={(e) => {
            console.error(`Error loading image: ${advert.file_path || advert.image_path}`);
            e.target.onerror = null;
            e.target.src = `${process.env.PUBLIC_URL}/path/to/placeholder-image.png`;
          }}
        />
      </div>
    </div>
  );

  return (
    <nav className="header">
      <div className="user-container">
        <div className="logo-container">
          <img 
            className="logo" 
            src={user.gender === 0 ? preloadedData.genderMale : preloadedData.genderFemale} 
            alt="logo" 
          />
          {user.is_vip && (
            <div className="vip-indicator">
              <img src={preloadedData.iconCrown} alt="VIP Crown" className="vip-crown" />
              <span className="vip-indikator-text">VIP</span>
            </div>
          )}
        </div>
        <div className="ml-1">
          <p className="user-name">{user.username}</p>
        </div>
      </div>
      <div className="advertising-container">
        {adverts.length === 0 ? (
          <div className="no-adverts">{t('Navbar.PlaceForAd')}</div>
        ) : adverts.length === 1 ? (
          renderAdvert(adverts[0])
        ) : (
          <Slider {...settings}>
            {adverts.map(renderAdvert)}
          </Slider>
        )}
      </div>
      <button className="settings-button" onClick={handleSettingsClick}>
        <img
          src={preloadedData.settingsIcon}
          alt="Settings Icon"
          className="settings-icon"
        />
      </button>
    </nav>
  );
};

export default Navbar;