  // import { useEffect, useCallback, useMemo } from 'react';
  // import { useTranslation } from 'react-i18next';
  // import { useUser } from '../contexts/UserContext';
  // import { useLanguageInit } from '../contexts/LanguageInitContext';

  // const useSetLanguage = () => {
  //   const { i18n } = useTranslation();
  //   const { user, updateUser } = useUser();
  //   const { isLanguageInitialized, setIsLanguageInitialized } = useLanguageInit();

  //   const availableLanguages = useMemo(() => {
  //     return i18n.options.supportedLngs || Object.keys(i18n.options.resources || {});
  //   }, [i18n]);

  //   const setLanguage = useCallback((lang) => {
  //     i18n.changeLanguage(lang);
  //     if (user && user.lang_code !== lang) {
  //       updateUser({ ...user, lang_code: lang });
  //     }
  //     setIsLanguageInitialized(true);
  //   }, [i18n, user, updateUser, setIsLanguageInitialized]);

  //   useEffect(() => {
  //     if (isLanguageInitialized || !user) {
  //       return;
  //     }

  //     if (user.lang_code && availableLanguages.includes(user.lang_code)) {
  //       setLanguage(user.lang_code);
  //     } else {
  //       setLanguage(i18n.options.fallbackLng || 'en');
  //     }
  //   }, [user, isLanguageInitialized, setLanguage, availableLanguages, i18n.options.fallbackLng]);
  // };

  // export default useSetLanguage;




// works on tg
// import { useEffect, useCallback, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useLanguageInit } from '../contexts/LanguageInitContext';

// const useSetLanguage = (user) => {
//   const { i18n } = useTranslation();
//   const { isLanguageInitialized, setIsLanguageInitialized } = useLanguageInit();

//   const availableLanguages = useMemo(() => {
//     return i18n.options.supportedLngs || Object.keys(i18n.options.resources || {});
//   }, [i18n]);

//   const setLanguage = useCallback((lang) => {
//     i18n.changeLanguage(lang);
//     setIsLanguageInitialized(true);
//   }, [i18n, setIsLanguageInitialized]);

//   useEffect(() => {
//     if (isLanguageInitialized || !user) {
//       return;
//     }

//     if (user.tgInfo.language && availableLanguages.includes(user.tgInfo.language)) {
//       setLanguage(user.tgInfo.language);
//     } else {
//       setLanguage(i18n.options.fallbackLng || 'en');
//     }

//   }, [user, isLanguageInitialized, setLanguage, availableLanguages, i18n]);
// };

// export default useSetLanguage;


//need to test
// import { useEffect, useCallback, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useLanguageInit } from '../contexts/LanguageInitContext';
// import { useUser } from '../contexts/UserContext';
// const useSetLanguage = () => {
//   const { i18n } = useTranslation();
//   const { user, updateUser } = useUser();
//   const { isLanguageInitialized, setIsLanguageInitialized } = useLanguageInit();

//   const availableLanguages = useMemo(() => {
//     return i18n.options.supportedLngs || Object.keys(i18n.options.resources || {});
//   }, [i18n]);

//   const setLanguage = useCallback((lang) => {
//     i18n.changeLanguage(lang);
//     setIsLanguageInitialized(true);
//   }, [i18n, setIsLanguageInitialized]);

//   useEffect(() => {
//     if (isLanguageInitialized || !user) {
//       return;
//     }

//     let selectedLanguage;

//     if (user.lang_code && availableLanguages.includes(user.lang_code)) {
//       selectedLanguage = user.lang_code;
//     } else if (user.tgInfo && user.tgInfo.language && availableLanguages.includes(user.tgInfo.language)) {
//       selectedLanguage = user.tgInfo.language;
//     } else {
//       selectedLanguage = 'en';
//     }

//     setLanguage(selectedLanguage);

//   }, [user, isLanguageInitialized, setLanguage, availableLanguages]);
// };

// export default useSetLanguage;



import { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../contexts/UserContext';
import { useLanguageInit } from '../contexts/LanguageInitContext';

const useSetLanguage = () => {
  const { i18n, t } = useTranslation();
  const { user, updateUser } = useUser();
  const { isLanguageInitialized, setIsLanguageInitialized } = useLanguageInit();

  const availableLanguages = useMemo(() => {
    return i18n.options.supportedLngs || Object.keys(i18n.options.resources || {});
  }, [i18n]);

  const setLanguage = useCallback((lang) => {
    if (availableLanguages.includes(lang)) {
      i18n.changeLanguage(lang).then(() => {
        if (user && user.lang_code !== lang) {
          updateUser({ ...user, lang_code: lang });
        }
        setIsLanguageInitialized(true);
      });
    } else {
      const fallbackLang = i18n.options.fallbackLng || 'en';
      i18n.changeLanguage(fallbackLang).then(() => {
        setIsLanguageInitialized(true);
      });
    }
  }, [i18n, user, updateUser, setIsLanguageInitialized, availableLanguages]);

  useEffect(() => {   
    if (!user) {
      return;
    }

    
    if (user.lang_code && availableLanguages.includes(user.lang_code)) {
      setLanguage(user.lang_code);
    } else {
      const fallbackLang = i18n.options.fallbackLng || 'en';
      setLanguage(fallbackLang);
    }
  }, [user, setLanguage, availableLanguages, i18n]);


  return setLanguage;
};

export default useSetLanguage;