// AppInitializer.js
import React, { useState, useEffect, useCallback } from 'react';
import TelegramInitializer from '../initializer/TelegramInitializer';
import BackendInitializer from '../initializer/BackendInitializer';
import { useUser } from '../contexts/UserContext';
import LoadingScreen from '../utils/LoadingScreen';

const AppInitializer = ({ children, setIsInitialized }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const { updateUser, user } = useUser();

  const initializeApp = useCallback(async () => {
    if (userId) return; // Предотвращаем повторную инициализацию
    try {
      const id = '123568'; // В реальном приложении это должно быть получено из TelegramInitializer
      setUserId(id);
    } catch (error) {
      console.error('Initialization error:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Если у нас уже есть данные пользователя, не нужно снова вызывать BackendInitializer
  if (user && user.user_id) {
    return <>{children}</>;
  }

  return (
    <>
      <BackendInitializer
        userId={userId}
        onError={() => console.error('Failed to fetch user data from backend')}
        setIsInitializing={setIsLoading}
        updateUser={updateUser}
        setIsInitialized={setIsInitialized}
      />
      {children}
    </>
  );
};

export default AppInitializer;
