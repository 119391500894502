//UserContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const updateUser = useCallback((newData) => {
    setUser(prevUser => {
      const updatedUser = {...prevUser, ...newData};
      if (JSON.stringify(prevUser) === JSON.stringify(updatedUser)) {
        return prevUser;
      }
      return updatedUser;
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};