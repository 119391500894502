// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ru from './locales/ru.json';
import uk from './locales/uk.json';
import fr from './locales/fr.json';
import hi from './locales/hi.json';
import pl from './locales/pl.json';
import es from './locales/es.json';
import de from './locales/de.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en.translation },
      ru: { translation: ru.translation },
      uk: { translation: uk.translation },
      fr: { translation: fr.translation },
      hi: { translation: hi.translation },
      pl: { translation: pl.translation },
      es: { translation: es.translation },
      de: { translation: de.translation }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

  export const getAvailableLocales = () => Object.keys(i18n.options.resources);
  
export default i18n;