import React, { useState, useEffect } from "react";
import { ReactComponent as IconDolar } from "../assets/icons/dolar.svg";
import { ReactComponent as IconChecked } from "../assets/icons/checked.svg";
import "../assets/styles/page3.css";
import { API_URLS } from "../config/config";
import { useUser } from "../contexts/UserContext";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import LoadingScreen from "../utils/LoadingScreen";
const loadPartnersData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_USER_PARTNERS, {
      userId: userId,
    });
    console.log(response.data);
    if (Array.isArray(response.data.tasks)) {
      console.log(response.data.tasks);
      return response.data.tasks.map((task) => ({
        id: task.id,
        name: task.name,
        amount: task.amount,
        link: task.link,
        photo: task.photo,
        action: task.button_type,
        isCompleted: task.is_completed
      }));
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

const PartnersPage = ({ preloadedData }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [completedTasks, setCompletedTasks] = useState({});
  const [partnersData, setPartnersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const updateLastActive = async (userId) => {
    try {
      const response = await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
      console.log('Last active time updated:', response.data);
    } catch (error) {
      console.error('Error updating last active time:', error);
    }
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]); 

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.user_id) {
        setIsLoading(true);
        setLoadingProgress(0);

        const progressInterval = setInterval(() => {
          setLoadingProgress((prevProgress) => {
            const newProgress = prevProgress + 10;
            return newProgress > 90 ? 90 : newProgress;
          });
        }, 500);

        const data = await loadPartnersData(user.user_id);
        setPartnersData(data);

        clearInterval(progressInterval);
        setLoadingProgress(100);
        
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    };

    fetchData();
  }, [user]);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleClick = async (link, partnerId) => {
    if (link) {
      window.open(link, "_blank");

      try {
        await delay(10000);

        const data = {
          userId: user.user_id,
          taskId: partnerId
        };
        
        const response = await axios.post(API_URLS.CHECK_PARTNER_TASKS, data);
        console.log("partner, check", response);
        if (response.data.result === "ok" || response.data.result === "already completed task") {
          setCompletedTasks(prev => ({ ...prev, [partnerId]: true }));
          const updatedData = await loadPartnersData(user.user_id);
          console.log("partners updatedData", updatedData);
          setPartnersData(updatedData);
        }
      } catch (error) {
        console.error("An error occurred during the execution of the partners task:", error);
      }
    }
  };
  const renderPartner = (partner, index) => {
    const isLongName = partner.name.length > 25;
    const imageUrl = `${API_URLS.API_BASE_URL}${partner.photo}`;
    return (
      <div className={`partner-container ${isLongName ? 'partner-container-tall' : ''}`} key={index}>
        <div className="flex flex-row">
          <div className="m-1 flex items-center justify-center">
            <div className="img-container">
              <img
                className="img-partner"
                src={imageUrl}
                alt={partner.name}
              />
            </div>
          </div>
          <div className="flex flex-col w-25 m-1">
            <p className={`partner-name ${isLongName ? 'partner-name-long' : ''}`}>{partner.name}</p>
            <div className="flex flex-row items-center  w-20 ">
              <IconDolar />
              <p className="partner-award">+{partner.amount}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-30 h-30">
          {partner.isCompleted ? (
            <div className="completed-task">
              <IconChecked />
            </div>
          ) : (
            <button 
              className="partner-action" 
              onClick={() => handleClick(partner.link.url, partner.id)}
            >
              {partner.action}
            </button>
          )}
        </div>
      </div>
    );
  };
  
  if (isLoading) {
    return <LoadingScreen progress={loadingProgress} />;
  }
  
  return (
    <div id="page3" style={{ backgroundImage: `url(${preloadedData.backgroundImage})` }}>
      <p className="title">{t('PartnersPage.Title')}</p>
      <div className="partners">
        {partnersData.map((partner, index) => renderPartner(partner, index))}
      </div>
    </div>
  );
};

export default PartnersPage;