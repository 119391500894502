//works before
// import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
// import backgroundSound from '../assets/sounds/background-sound.mp3';

// const AudioContext = createContext();

// export const AudioProvider = ({ children }) => {
//   const [generalVolume, setGeneralVolume] = useState(0);
//   const [musicVolume, setMusicVolume] = useState(0);
//   const [effectsVolume, setEffectsVolume] = useState(100);
//   const [isInitialized, setIsInitialized] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [isVolumeSet, setIsVolumeSet] = useState(false);
  
//   const audioContextRef = useRef(null);
//   const sourceNodeRef = useRef(null);
//   const gainNodeRef = useRef(null);

//   const initializeAudio = useCallback(async () => {
//     if (!audioContextRef.current) {
//       const AudioContext = window.AudioContext || window.webkitAudioContext;
//       audioContextRef.current = new AudioContext();
      
//       try {
//         const response = await fetch(backgroundSound);
//         const arrayBuffer = await response.arrayBuffer();
//         const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
        
//         sourceNodeRef.current = audioContextRef.current.createBufferSource();
//         sourceNodeRef.current.buffer = audioBuffer;
//         sourceNodeRef.current.loop = true;
        
//         gainNodeRef.current = audioContextRef.current.createGain();
//         gainNodeRef.current.gain.setValueAtTime(0, audioContextRef.current.currentTime);
        
//         sourceNodeRef.current.connect(gainNodeRef.current);
//         gainNodeRef.current.connect(audioContextRef.current.destination);

//         setIsInitialized(true);
//       } catch (error) {
//         console.error('Error initializing audio:', error);
//       }
//     }
//   }, []);

//   const calculateVolume = useCallback((general, music) => {
//     return (general / 100) * (music / 100);
//   }, []);

//   const updateVolume = useCallback(() => {
//     if (gainNodeRef.current && isInitialized && isVolumeSet) {
//       const volume = calculateVolume(generalVolume, musicVolume);
//       const currentTime = audioContextRef.current.currentTime;
//       gainNodeRef.current.gain.setValueAtTime(gainNodeRef.current.gain.value, currentTime);
//       gainNodeRef.current.gain.linearRampToValueAtTime(volume, currentTime + 2);
//       console.log('Volume updated:', volume, 'General:', generalVolume, 'Music:', musicVolume);
//     }
//   }, [generalVolume, musicVolume, calculateVolume, isInitialized, isVolumeSet]);

//   const playBackgroundMusic = useCallback(async () => {
//     try {
//       if (!audioContextRef.current) {
//         await initializeAudio();
//       }
      
//       if (audioContextRef.current.state === 'suspended') {
//         await audioContextRef.current.resume();
//       }
      
//       if (sourceNodeRef.current && !sourceNodeRef.current.isStarted) {
//         sourceNodeRef.current.start();
//         sourceNodeRef.current.isStarted = true;
//       }
      
//       setIsPaused(false);
      
//       if (isVolumeSet) {
//         updateVolume();
//       }
      
//       console.log('Background music started playing');
//     } catch (error) {
//       console.error('Error playing background music:', error);
//     }
//   }, [initializeAudio, updateVolume, isVolumeSet]);

//   const pauseBackgroundMusic = useCallback(() => {
//     if (audioContextRef.current && audioContextRef.current.state === 'running') {
//       audioContextRef.current.suspend();
//       setIsPaused(true);
//       console.log('Background music paused');
//     }
//   }, []);

//   const resumeBackgroundMusic = useCallback(() => {
//     if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
//       audioContextRef.current.resume();
//       setIsPaused(false);
//       console.log('Background music resumed');
//     }
//   }, []);

//   const setVolumes = useCallback((general, music, effects) => {
//     console.log('Setting volumes:', general, music, effects);
//     setGeneralVolume(general);
//     setMusicVolume(music);
//     setEffectsVolume(effects);
//     setIsVolumeSet(true);
//   }, []);

//   useEffect(() => {
//     if (isInitialized && isVolumeSet) {
//       updateVolume();
//     }
//   }, [generalVolume, musicVolume, updateVolume, isInitialized, isVolumeSet]);

//   return (
//     <AudioContext.Provider value={{
//       generalVolume,
//       setGeneralVolume,
//       musicVolume,
//       setMusicVolume,
//       effectsVolume,
//       setEffectsVolume,
//       playBackgroundMusic,
//       pauseBackgroundMusic,
//       resumeBackgroundMusic,
//       updateVolume,
//       setVolumes,
//       calculateVolume,
//       isInitialized,
//       isPaused
//     }}>
//       {children}
//     </AudioContext.Provider>
//   );
// };

// export const useAudio = () => useContext(AudioContext);


import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import backgroundSound from '../assets/sounds/background-sound.mp3';

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [generalVolume, setGeneralVolume] = useState(0);
  const [musicVolume, setMusicVolume] = useState(0);
  const [effectsVolume, setEffectsVolume] = useState(100);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isVolumeSet, setIsVolumeSet] = useState(false);
  const [currentMusic, setCurrentMusic] = useState(backgroundSound);
  const [isGameMusic, setIsGameMusic] = useState(false);
  
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const gainNodeRef = useRef(null);
  const defaultSourceNodeRef = useRef(null);

  const initializeAudio = useCallback(async () => {
    if (!audioContextRef.current) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      audioContextRef.current = new AudioContext();
      
      try {
        await loadAndPlayMusic(backgroundSound, true);
        setIsInitialized(true);
        // Встановлюємо початкову гучність на 0
        if (gainNodeRef.current) {
          gainNodeRef.current.gain.setValueAtTime(0, audioContextRef.current.currentTime);
        }
      } catch (error) {
        console.error('Error initializing audio:', error);
      }
    }
  }, []);

  

  const changeBackgroundMusic = useCallback(async (newMusicSource, isGame = true) => {
    if (newMusicSource !== currentMusic || isGame !== isGameMusic) {
      try {
        if (isGame) {
          if (defaultSourceNodeRef.current) {
            defaultSourceNodeRef.current.stop();
          }
        } else {
          if (sourceNodeRef.current) {
            sourceNodeRef.current.stop();
          }
        }
        await loadAndPlayMusic(newMusicSource, !isGame);
        setIsGameMusic(isGame);
        setCurrentMusic(newMusicSource);
      } catch (error) {
        console.error('Error changing background music:', error);
        revertToDefaultMusic();
      }
    }
  }, [currentMusic, isGameMusic]);

  const revertToDefaultMusic = useCallback(() => {
    if (currentMusic !== backgroundSound || isGameMusic) {
      if (defaultSourceNodeRef.current && !defaultSourceNodeRef.current.isPlaying) {
        // Якщо базова музика не грає, запускаємо її
        changeBackgroundMusic(backgroundSound, false);
      } else if (sourceNodeRef.current) {
        // Якщо грає інша музика, зупиняємо її
        sourceNodeRef.current.stop();
        sourceNodeRef.current.disconnect();
      }
      // Встановлюємо стан, що зараз грає базова музика
      setCurrentMusic(backgroundSound);
      setIsGameMusic(false);
    }
  }, [currentMusic, isGameMusic, changeBackgroundMusic]);


  const loadAndPlayMusic = async (musicSource, isDefault = false) => {
    const sourceRef = isDefault ? defaultSourceNodeRef : sourceNodeRef;
    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current.disconnect();
    }

    const response = await fetch(musicSource);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
    
    sourceRef.current = audioContextRef.current.createBufferSource();
    sourceRef.current.buffer = audioBuffer;
    sourceRef.current.loop = true;
    sourceRef.current.isPlaying = true; // Додаємо флаг, щоб відстежувати, чи грає музика
    
    if (!gainNodeRef.current) {
      gainNodeRef.current = audioContextRef.current.createGain();
      gainNodeRef.current.connect(audioContextRef.current.destination);
    }
    
    sourceRef.current.connect(gainNodeRef.current);
    sourceRef.current.start();
    sourceRef.current.onended = () => {
      sourceRef.current.isPlaying = false;
    };
  };

  const calculateVolume = useCallback((general, music) => {
    return (general / 100) * (music / 100);
  }, []);

  const setVolumes = useCallback((general, music, effects) => {
    setGeneralVolume(general);
    setMusicVolume(music);
    setEffectsVolume(effects);
    setIsVolumeSet(true);
    // Оновлюємо гучність відразу після встановлення нових значень
    updateVolume();
  }, []);

  const updateVolume = useCallback(() => {
    if (gainNodeRef.current && isInitialized) {
      const volume = calculateVolume(generalVolume, musicVolume);
      const currentTime = audioContextRef.current.currentTime;
      gainNodeRef.current.gain.setValueAtTime(gainNodeRef.current.gain.value, currentTime);
      gainNodeRef.current.gain.linearRampToValueAtTime(volume, currentTime + 0.5);
    }
  }, [generalVolume, musicVolume, calculateVolume, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      updateVolume();
    }
  }, [generalVolume, musicVolume, updateVolume, isInitialized]);

  const playBackgroundMusic = useCallback(async () => {
    try {
      if (!audioContextRef.current) {
        await initializeAudio();
      }
      
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }
      
      setIsPaused(false);
      
      if (isVolumeSet) {
        updateVolume();
      }
    } catch (error) {
      console.error('Error playing background music:', error);
    }
  }, [initializeAudio, updateVolume, isVolumeSet]);

  const pauseBackgroundMusic = useCallback(() => {
    if (audioContextRef.current && audioContextRef.current.state === 'running') {
      audioContextRef.current.suspend();
      setIsPaused(true);
    }
  }, []);

  const resumeBackgroundMusic = useCallback(() => {
    if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
      setIsPaused(false);
    }
  }, []);

  useEffect(() => {
    if (isInitialized && isVolumeSet) {
      updateVolume();
    }
  }, [generalVolume, musicVolume, updateVolume, isInitialized, isVolumeSet]);

  return (
    <AudioContext.Provider value={{
      generalVolume,
      setGeneralVolume,
      musicVolume,
      setMusicVolume,
      effectsVolume,
      setEffectsVolume,
      playBackgroundMusic,
      pauseBackgroundMusic,
      resumeBackgroundMusic,
      updateVolume,
      setVolumes,
      calculateVolume,
      isInitialized,
      isPaused,
      changeBackgroundMusic,
      revertToDefaultMusic,
      currentMusic
    }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);

// import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
// import backgroundSound from '../assets/sounds/background-sound.mp3';

// const AudioContext = createContext();

// export const AudioProvider = ({ children }) => {
//   const [generalVolume, setGeneralVolume] = useState(0);
//   const [musicVolume, setMusicVolume] = useState(0);
//   const [effectsVolume, setEffectsVolume] = useState(100);
//   const [isInitialized, setIsInitialized] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [isVolumeSet, setIsVolumeSet] = useState(false);
//   const [activeMusic, setActiveMusic] = useState(backgroundSound);
  
//   const audioContextRef = useRef(null);
//   const sourceNodeRef = useRef(null);
//   const gainNodeRef = useRef(null);

//   const initializeAudio = useCallback(async () => {
//     if (!audioContextRef.current) {
//       const AudioContext = window.AudioContext || window.webkitAudioContext;
//       audioContextRef.current = new AudioContext();
      
//       gainNodeRef.current = audioContextRef.current.createGain();
//       gainNodeRef.current.connect(audioContextRef.current.destination);

//       setIsInitialized(true);
//     }
//   }, []);

//   const calculateVolume = useCallback((general, music) => {
//     return (general / 100) * (music / 100);
//   }, []);

//   const updateVolume = useCallback(() => {
//     if (gainNodeRef.current && isInitialized && isVolumeSet) {
//       const volume = calculateVolume(generalVolume, musicVolume);
//       const currentTime = audioContextRef.current.currentTime;
//       gainNodeRef.current.gain.setValueAtTime(gainNodeRef.current.gain.value, currentTime);
//       gainNodeRef.current.gain.linearRampToValueAtTime(volume, currentTime + 0.5);
//     }
//   }, [generalVolume, musicVolume, calculateVolume, isInitialized, isVolumeSet]);

//   const changeBackgroundMusic = useCallback(async (newMusicSource) => {
//     if (newMusicSource === activeMusic) return;

//     try {
//       if (!audioContextRef.current) {
//         await initializeAudio();
//       }

//       // Stop the current music if it's playing
//       if (sourceNodeRef.current) {
//         sourceNodeRef.current.stop();
//         sourceNodeRef.current.disconnect();
//       }

//       const response = await fetch(newMusicSource);
//       const arrayBuffer = await response.arrayBuffer();
//       const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);

//       const newSourceNode = audioContextRef.current.createBufferSource();
//       newSourceNode.buffer = audioBuffer;
//       newSourceNode.loop = true;

//       newSourceNode.connect(gainNodeRef.current);
//       newSourceNode.start();

//       sourceNodeRef.current = newSourceNode;
//       setActiveMusic(newMusicSource);
//       updateVolume();

//     } catch (error) {
//       console.error('Error changing background music:', error);
//     }
//   }, [activeMusic, updateVolume, initializeAudio]);

//   const revertToDefaultMusic = useCallback(() => {
//     if (activeMusic !== backgroundSound) {
//       changeBackgroundMusic(backgroundSound);
//     }
//   }, [activeMusic, changeBackgroundMusic]);

//   const playBackgroundMusic = useCallback(async () => {
//     try {
//       if (!audioContextRef.current) {
//         await initializeAudio();
//       }
      
//       if (audioContextRef.current.state === 'suspended') {
//         await audioContextRef.current.resume();
//       }
      
//       if (!sourceNodeRef.current) {
//         await changeBackgroundMusic(activeMusic);
//       }
      
//       setIsPaused(false);
      
//       if (isVolumeSet) {
//         updateVolume();
//       }
      
//     } catch (error) {
//       console.error('Error playing background music:', error);
//     }
//   }, [initializeAudio, updateVolume, isVolumeSet, changeBackgroundMusic, activeMusic]);

//   const pauseBackgroundMusic = useCallback(() => {
//     if (audioContextRef.current && audioContextRef.current.state === 'running') {
//       audioContextRef.current.suspend();
//       setIsPaused(true);
//     }
//   }, []);

//   const resumeBackgroundMusic = useCallback(() => {
//     if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
//       audioContextRef.current.resume();
//       setIsPaused(false);
//     }
//   }, []);

//   const setVolumes = useCallback((general, music, effects) => {
//     setGeneralVolume(general);
//     setMusicVolume(music);
//     setEffectsVolume(effects);
//     setIsVolumeSet(true);
//   }, []);

//   useEffect(() => {
//     if (isInitialized && isVolumeSet) {
//       updateVolume();
//     }
//   }, [generalVolume, musicVolume, updateVolume, isInitialized, isVolumeSet]);

//   return (
//     <AudioContext.Provider value={{
//       generalVolume,
//       setGeneralVolume,
//       musicVolume,
//       setMusicVolume,
//       effectsVolume,
//       setEffectsVolume,
//       playBackgroundMusic,
//       pauseBackgroundMusic,
//       resumeBackgroundMusic,
//       updateVolume,
//       setVolumes,
//       calculateVolume,
//       isInitialized,
//       isPaused,
//       changeBackgroundMusic,
//       revertToDefaultMusic,
//       activeMusic
//     }}>
//       {children}
//     </AudioContext.Provider>
//   );
// };

// export const useAudio = () => useContext(AudioContext);
















// import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
// import backgroundSound from '../assets/sounds/background-sound.mp3';

// const AudioContext = createContext();

// export const AudioProvider = ({ children }) => {
//   const [generalVolume, setGeneralVolume] = useState(0);
//   const [musicVolume, setMusicVolume] = useState(0);
//   const [effectsVolume, setEffectsVolume] = useState(0);
//   const [isInitialized, setIsInitialized] = useState(false);
//   const [isPaused, setIsPaused] = useState(false);
//   const [userVolumeSet, setUserVolumeSet] = useState(false);
  
//   const audioContextRef = useRef(null);
//   const sourceNodeRef = useRef(null);
//   const gainNodeRef = useRef(null);

//   const initializeAudio = useCallback(async () => {
//     if (!audioContextRef.current) {
//       const AudioContext = window.AudioContext || window.webkitAudioContext;
//       audioContextRef.current = new AudioContext();
      
//       try {
//         const response = await fetch(backgroundSound);
//         const arrayBuffer = await response.arrayBuffer();
//         const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
        
//         sourceNodeRef.current = audioContextRef.current.createBufferSource();
//         sourceNodeRef.current.buffer = audioBuffer;
//         sourceNodeRef.current.loop = true;
        
//         gainNodeRef.current = audioContextRef.current.createGain();
//         gainNodeRef.current.gain.setValueAtTime(0, audioContextRef.current.currentTime);
        
//         sourceNodeRef.current.connect(gainNodeRef.current);
//         gainNodeRef.current.connect(audioContextRef.current.destination);

//         sourceNodeRef.current.start();
//         setIsInitialized(true);
//       } catch (error) {
//         console.error('Error initializing audio:', error);
//       }
//     }
//   }, []);

//   const calculateVolume = useCallback((general, music) => {
//     return (general / 100) * (music / 100);
//   }, []);

//   const updateVolume = useCallback(() => {
//     if (gainNodeRef.current && isInitialized && !isPaused) {
//       const volume = calculateVolume(generalVolume, musicVolume);
//       const currentTime = audioContextRef.current.currentTime;
//       gainNodeRef.current.gain.cancelScheduledValues(currentTime);
//       gainNodeRef.current.gain.setValueAtTime(gainNodeRef.current.gain.value, currentTime);
//       gainNodeRef.current.gain.linearRampToValueAtTime(volume, currentTime + 0.5);
//       console.log('Volume updated:', volume, 'General:', generalVolume, 'Music:', musicVolume);
//     }
//   }, [generalVolume, musicVolume, calculateVolume, isInitialized, isPaused]);

//   const setVolumes = useCallback((general, music, effects) => {
//     console.log('Setting volumes:', general, music, effects);
//     setGeneralVolume(general);
//     setMusicVolume(music);
//     setEffectsVolume(effects);
//     setUserVolumeSet(true);
//   }, []);

//   const playBackgroundMusic = useCallback(async () => {
//     if (!isInitialized) {
//       await initializeAudio();
//     }
//     setIsPaused(false);
//   }, [initializeAudio, isInitialized]);

//   const pauseBackgroundMusic = useCallback(() => {
//     setIsPaused(true);
//   }, []);

//   const resumeBackgroundMusic = useCallback(() => {
//     setIsPaused(false);
//   }, []);

//   useEffect(() => {
//     initializeAudio();
//   }, [initializeAudio]);

//   useEffect(() => {
//     if (isInitialized && userVolumeSet) {
//       updateVolume();
//     }
//   }, [generalVolume, musicVolume, updateVolume, isInitialized, userVolumeSet, isPaused]);

//   return (
//     <AudioContext.Provider value={{
//       generalVolume,
//       setGeneralVolume,
//       musicVolume,
//       setMusicVolume,
//       effectsVolume,
//       setEffectsVolume,
//       playBackgroundMusic,
//       pauseBackgroundMusic,
//       resumeBackgroundMusic,
//       setVolumes,
//       isInitialized,
//       isPaused
//     }}>
//       {children}
//     </AudioContext.Provider>
//   );
// };

// export const useAudio = () => useContext(AudioContext);