// preloadFunctions.js
// icons
import iconCoin from "../assets/icons/coin.webp";
import iconDolar from "../assets/icons/dolar.webp";
import iconUsdt from "../assets/icons/usdt.webp";
import iconSettings from "../assets/icons/settings.webp";
import iconSwap from "../assets/icons/swap.webp";
import iconCopy from "../assets/icons/copy.webp";
import iconGnom from "../assets/icons/gnom.webp";
import iconNotification from "../assets/icons/notification.webp";
import iconVip from "../assets/icons/vip.webp";
import iconArrow from "../assets/icons/arrow.webp";
import iconCrown from "../assets/icons/crown.webp";
import iconChecked from "../assets/icons/checked.webp";
import iconLevelsCheck from "../assets/icons/levels-check.webp";
import iconLevelsLock from "../assets/icons/levels-lock.webp";
import iconArrowLeft from "../assets/icons/arrow-left.png";//need webp
import iconFile from "../assets/icons/file.webp";
import iconModerationSuccess from "../assets/icons/moderation-success.webp";
import iconModerationInProgress from "../assets/icons/moderation-in-progress.webp";
import iconModerationFail from "../assets/icons/moderation-fail.webp";
import iconAdWatchRedirect from "../assets/icons/ad-watch-only.webp";
import iconAdWatchOnly from "../assets/icons/ad-watch-redirect.webp";

import modalBackground from "../assets/images/fairy-modal-background.png";

//navbar
import logo from "../assets/images/logo.png";

//socials-images
import socialBackground from "../assets/images/Socials/background-socials.webp";

//partners-images
import partnersBackground from "../assets/images/Partners/background-partners.webp";

//games-images
import gamesBackground from "../assets/images/Games/background-games.webp";
import gameIconOne from "../assets/icons/pocket.webp";

//referral-images
import referralBackground from "../assets/images/Refferals/background-refferals.webp";

//trade-images
import tradeBackground from "../assets/images/Trade/background-trade.webp";

//rules-images
import rulesBackground from "../assets/images/Rules/background-rules.webp";

//levels-images
import levelsBackground from "../assets/images/Levels/background.webp";

//page1
import tempPage1Back from "../assets/images/background-page1.png";


import genderMale from "../assets/images/gender-male.webp";
import genderFemale from "../assets/images/gender-female.webp";

import { API_URLS } from "../config/config";
import axios from "axios";

// Функция для предзагрузки изображения
const preloadImage = (src, name) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      console.log(`Изображение "${name}" загружено`);
      resolve(src);
    };
    img.onerror = reject;
    img.src = src;
  });
};

// Предзагрузка всех изображений проекта
export const preloadFullImgProject = async () => {
  const allImages = {
    navbar: { logo: logo, iconSettings: iconSettings,  genderMale: genderMale, genderFemale: genderFemale, iconCrown:iconCrown },
    main:{
      rewardBackground:modalBackground,
      iconAdWatchOnly:iconAdWatchOnly,
      iconAdWatchRedirect:iconAdWatchRedirect
    },
    socials: { 
      background: socialBackground,
      iconArrow:iconArrow,
      iconChecked:iconChecked,
      iconDolar:iconDolar
     },
    partners: { background: partnersBackground },
    rules: { background: rulesBackground },
    referral: {
      background: referralBackground,
      genderMale: genderMale,
      genderFemale: genderFemale,
      iconCoin: iconCoin,
      iconNotification: iconNotification,
    },
    trade: {
      background: tradeBackground,
      iconCoin: iconCoin,
      iconDolar: iconDolar,
      iconUsdt: iconUsdt,
      iconCopy: iconCopy,
      swapIcon: iconSwap,
      genderMale: genderMale,
      genderFemale: genderFemale,
      iconGnom: iconGnom,
      iconVip: iconVip,
      iconArrow:iconArrow
    },
    games:{
      background: gamesBackground,
      gameicon: gameIconOne
    },
    levels:{
      background:levelsBackground,
      iconLevelsCheck:iconLevelsCheck,
      iconLevelsLock:iconLevelsLock,
      iconArrowLeft:iconArrowLeft
    }
  };

  const preloadPromises = Object.entries(allImages).flatMap(
    ([pageName, images]) =>
      Object.entries(images).map(([imgName, src]) =>
        preloadImage(src, `${pageName}_${imgName}`)
      )
  );

  await Promise.all(preloadPromises);
  console.log("Все изображения загружены");

  return allImages;
};

const loadAdverts = async (userId) => {
  try {
    const data = {
      userId: userId,
    };

    const response = await axios.post(API_URLS.GET_ALL_ACTIVE_BANNER_ADVERTS_ENDPOINT, data);

    if (Array.isArray(response.data.active_banner_adverts)) {
      return response.data.active_banner_adverts.map((advert) => ({
        id: advert.id,
        file_path: advert.file_path,
        url: advert.url,
        name: advert.name,
      }));
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
const loadSocialsData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_USER_SOCIALS, {
      userId: userId,
    });
    console.log("soc",response.data);
    if (Array.isArray(response.data.tasks)) {
      return response.data.tasks.map((task) => ({
        id:task.id,
        name: task.name,
        amount: task.amount,
        link: task.link,
        photo: task.photo,
        isCompleted:task.is_completed
      }));
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadreferralData = async (userId) => {
  console.log(`------------userId------------- `, userId);
  try {
    const response = await axios.get(API_URLS.GET_USER_REFERRALS, {
      params: { userId: userId },
      headers: {
        Accept: "application/json",
        "ngrok-skip-browser-warning": "true",
      },
    });
    if (Array.isArray(response.data.referrals)) {
      console.log(response.data.referrals);
      return response.data.referrals.map((ref) => ({
        tg_username: ref.tg_username,
        gender: ref.gender,
        rank: ref.rank,
        g_token: ref.g_token,
      }));
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadReffLink = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_FREN_LINK, {
      userId: userId,
    });
    console.log(response.data);
    return response.data.link;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadRegions = async () => {
  try {

    const response = await axios.get(API_URLS.GET_REGIONS, {
      headers: {
        Accept: "application/json",
        "ngrok-skip-browser-warning": "true",
      },
    });
    if (Array.isArray(response.data.regions)) {
      console.log(response.data.regions);
      return response.data.regions;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
const loadPartnersData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_USER_PARTNERS, {
      userId: userId,
    });
    console.log("partners",response.data);
    if (Array.isArray(response.data.tasks)) {
      console.log("partners",response.data.tasks);
      return response.data.tasks.map((task) => ({
        id:task.id,
        name: task.name,
        amount: task.amount,
        link: task.link,
        photo: task.photo,
        action: task.button_type,
        isCompleted:task.is_completed
      }));
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadTransactionsData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.EXECUTE_TRANSACTIONS, {
      userId: userId,
    });
    console.log("aaa", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadTransaferFeeData = async () => {
  try {
    const response = await axios.get(API_URLS.GET_TRANSFER_FEE, {
      headers: {
        Accept: "application/json",
        "ngrok-skip-browser-warning": "true",
      },
    });
    if (response.data.transfer_fee) {
      return response.data.transfer_fee;
    } else {
      throw new Error("TRANSFER Unexpected response structure from backend");
    }
  } catch (error) {
    console.error("Error fetching transfer fee:", error);
  }
};
// Главная функция предзагрузки всех данных и изображений
export const preloadAllData = async (setProgress, userId) => {
  const totalSteps = 4; // Количество операций загрузки
  let completedSteps = 0;

  const updateProgress = () => {
    completedSteps++;
    const progress = Math.round((completedSteps / totalSteps) * 100);
    setProgress(progress);
    console.log(`Прогресс загрузки: ${progress}%`);
  };

  try {
    console.log("Начало загрузки всех данных");

    const navbarData = await loadAdverts(userId);
    updateProgress();

    const socialsData = await loadSocialsData(userId);
    updateProgress();

    const partnersData = await loadPartnersData(userId);
    updateProgress();

    const referralData = await loadreferralData(userId);
    updateProgress();

    const referralLinkData = await loadReffLink(userId);
    updateProgress();

    const transferFeeData = await loadTransaferFeeData();
    updateProgress();

    const transactionsData = await loadTransactionsData(userId);
    updateProgress();

    const regions= await loadRegions();
    updateProgress();

    const allImages = await preloadFullImgProject();
    updateProgress();


    console.log("Все данные и изображения успешно загружены");

    return {
      navbar: {
        adverts: navbarData,
        settingsIcon: allImages.navbar.iconSettings,
        genderMale: allImages.navbar.genderMale,
        genderFemale: allImages.navbar.genderFemale,
        iconCrown:allImages.navbar.iconCrown
      },
      main: {
        modalBackground: allImages.main.rewardBackground,
        iconAdWatchOnly: allImages.main.iconAdWatchOnly,
        iconAdWatchRedirect: allImages.main.iconAdWatchRedirect,
        iconGnom: iconGnom
      },
      socials: {
        data: socialsData,
        backgroundImage: allImages.socials.background,
        iconArrow:allImages.socials.iconArrow,
        iconChecked:allImages.socials.iconChecked,
        iconDolar:allImages.socials.iconDolar
      },
      rules: {
        backgroundImage: allImages.rules.background,
      },
      partners: {
        data: partnersData,
        backgroundImage: allImages.partners.background,
      },
      referral: {
        data: referralData,
        refLink:referralLinkData,
        backgroundImage: allImages.referral.background,
        genderMale: allImages.referral.genderMale,
        genderFemale: allImages.referral.genderFemale,
        iconCoin: allImages.referral.iconCoin,
        iconNotification: iconNotification,
      },
      trade: {
        background: allImages.trade.background,
        data: {
          transferFee: transferFeeData,
          transactions: {
            swaps: transactionsData.swaps || [],
            transfers: transactionsData.transfers || [],
          },
        },
        iconCoin: iconCoin,
        iconDolar: iconDolar,
        iconUsdt: iconUsdt,
        iconGnom: iconGnom,
        iconCopy: iconCopy,
        genderMale: genderMale,
        genderFemale: genderFemale,
        iconSwap: iconSwap,
        iconVip: iconVip,
        iconArrow:iconArrow,
        modalBackground:modalBackground
      },
      adOffice: {
        regions:regions,
        background: allImages.trade.background,
        iconArrow:iconArrow,
        iconCoin: iconCoin,
        iconFile:iconFile,
        iconModerationSuccess:iconModerationSuccess,
        iconModerationInProgress:iconModerationInProgress,
        iconModerationFail:iconModerationFail,
        iconArrowLeft:iconArrowLeft,
        modalBackground:modalBackground
      },
      games:{
        background:gamesBackground,
        gameicon: gameIconOne
      },
      levels:{
        background:allImages.levels.background,
        iconLevelsCheck:allImages.levels.iconLevelsCheck,
        iconLevelsLock:allImages.levels.iconLevelsLock,
        iconArrowLeft:allImages.levels.iconArrowLeft,
        backgroundMain:tempPage1Back
      }
    };
  } catch (error) {
    console.error("Ошибка при предзагрузке данных:", error);
    throw error;
  }
};

// preloadFunctions.js

// // icons
// import iconCoin from "../assets/icons/coin.webp";
// import iconDolar from "../assets/icons/dolar.webp";
// import iconSettings from "../assets/icons/settings.webp";
// import iconSwap from "../assets/icons/swap.webp";

// //socials-images
// import socialBackground from "../assets/images/Socials/background-socials.png";

// //partners-images
// import partnersBackground from "../assets/images/Partners/background-partners.png";

// //referral-images
// import referralBackground from "../assets/images/Refferals/background-refferals.png";
// import genderMale from "../assets/images/Refferals/gender-male.png";
// import genderFemale from "../assets/images/Refferals/gender-female.png";

// //trade-images
// import tradeBackground from "../assets/images/Trade/background-trade.png";

// import { API_URLS } from "../config/config";
// import axios from "axios";

// // Функция для предзагрузки изображения
// const preloadImage = (src, name) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.onload = () => {
//       console.log(`Изображение "${name}" загружено`);
//       resolve(src);
//     };
//     img.onerror = reject;
//     img.src = src;
//   });
// };

// // Предзагрузка всех изображений проекта
// export const preloadFullImgProject = async () => {
//   const allImages = {
//     navbar: { iconSettings: iconSettings },
//     socials: { background: socialBackground },
//     partners: { background: partnersBackground },
//     referral: {
//       background: referralBackground,
//       genderMale: genderMale,
//       genderFemale: genderFemale,
//       iconCoin: iconCoin,
//     },
//     trade:{
//       iconCoin: iconCoin,
//     }
//   };

//   const preloadPromises = Object.entries(allImages).flatMap(
//     ([pageName, images]) =>
//       Object.entries(images).map(([imgName, src]) =>
//         preloadImage(src, `${pageName}_${imgName}`)
//       )
//   );

//   await Promise.all(preloadPromises);
//   console.log("Все изображения загружены");

//   return allImages;
// };

// const loadSocialsData = async (userId) => {
//   try {
//     const response = await axios.post(API_URLS.GET_USER_SOCIALS, {
//       userId: userId,
//     });
//     if (Array.isArray(response.data.tasks)) {
//       return response.data.tasks.map((task) => ({
//         name: task.name,
//         amount: task.amount,
//         link: task.link,
//         image: task.image || "..",
//       }));
//     }
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };

// const loadreferralData = async (userId) => {
//   console.log(`------------userId------------- `, userId)
//   try {
//     const response = await axios.get(API_URLS.GET_USER_REFERRALS, {
//       params: { userId: userId },
//       headers: {
//         Accept: "application/json",
//         "ngrok-skip-browser-warning": "true",
//       },
//     });
//     console.log("Referrals", response);
//     if (Array.isArray(response.data.referrals)) {
//       return response.data.referrals.map((ref) => ({
//         name: ref.name,
//         gender: ref.gender,
//         level: ref.level,
//         tokens: ref.tokens,
//       }));
//     }
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };

// const loadPartnersData = async (userId) => {
//   try {
//     const response = await axios.post(API_URLS.GET_USER_PARTNERS, {
//       userId: userId,
//     });
//     console.log(response.data);
//     if (Array.isArray(response.data.tasks)) {
//       console.log(response.data.tasks);
//       return response.data.tasks.map((task) => ({
//         name: task.name,
//         amount: task.amount,
//         link: task.link,
//         image: task.image || "..",
//         action: "Підписатися", //temp, need to get from back
//       }));
//     }
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };

// // Главная функция предзагрузки всех данных и изображений
// export const preloadAllData = async (setProgress, userId) => {
//   const totalSteps = 4; // Количество операций загрузки
//   let completedSteps = 0;

//   const updateProgress = () => {
//     completedSteps++;
//     const progress = Math.round((completedSteps / totalSteps) * 100);
//     setProgress(progress);
//     console.log(`Прогресс загрузки: ${progress}%`);
//   };

//   try {
//     console.log("Начало загрузки всех данных");

//     const socialsData = await loadSocialsData(userId);
//     updateProgress();

//     const partnersData = await loadPartnersData(userId);
//     updateProgress();

//     const referralData = await loadreferralData(userId);
//     updateProgress();

//     const allImages = await preloadFullImgProject();
//     updateProgress();

//     console.log("Все данные и изображения успешно загружены");

//     return {
//       navbar: { settingsIcon: allImages.navbar.iconSettings },
//       socials: {
//         data: socialsData,
//         backgroundImage: allImages.socials.background,
//       },
//       partners: {
//         data: partnersData,
//         backgroundImage: allImages.partners.background,
//       },
//       referral: {
//         data: referralData,
//         backgroundImage: allImages.referral.background,
//         genderMale: allImages.referral.genderMale,
//         genderFemale: allImages.referral.genderFemale,
//         iconCoin: allImages.referral.iconCoin,
//       },
//       trade:{
//         iconCoin: iconCoin,
//       }
//     };
//   } catch (error) {
//     console.error("Ошибка при предзагрузке данных:", error);
//     throw error;
//   }
// };
