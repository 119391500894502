// contexts/LanguageInitContext.js
import React, { createContext, useContext, useState } from 'react';

const LanguageInitContext = createContext();

export const LanguageInitProvider = ({ children }) => {
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false);

  return (
    <LanguageInitContext.Provider value={{ isLanguageInitialized, setIsLanguageInitialized }}>
      {children}
    </LanguageInitContext.Provider>
  );
};

export const useLanguageInit = () => useContext(LanguageInitContext);