import React, { useEffect, useRef } from 'react';

function ScrollManager({ children }) {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // Открываем приложение на весь экран
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.expand();
    }

    function ensureDocumentIsScrollable() {
      const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
      if (!isScrollable) {
        document.documentElement.style.setProperty("height", "calc(100vh + 1px)", "important");
      }
    }

    function preventCollapse() {
      if (window.scrollY === 0) {
        window.scrollTo(0, 1);
      }
    }

    ensureDocumentIsScrollable();
    window.addEventListener('load', ensureDocumentIsScrollable);
    
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('touchstart', preventCollapse);
    }

    // Добавляем обработчик изменения viewport'а
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.onEvent('viewportChanged', ensureDocumentIsScrollable);
    }

    return () => {
      window.removeEventListener('load', ensureDocumentIsScrollable);
      if (scrollContainer) {
        scrollContainer.removeEventListener('touchstart', preventCollapse);
      }
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.offEvent('viewportChanged', ensureDocumentIsScrollable);
      }
    };
  }, []);

  return (
    <div ref={scrollContainerRef} style={{ height: '100%', overflowY: 'auto' }}>
      {children}
    </div>
  );
}

export default ScrollManager;