import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon1 } from "../assets/icons/menu-first.svg";
import { ReactComponent as Icon2 } from "../assets/icons/menu-second.svg";
import { ReactComponent as Icon3 } from "../assets/icons/menu-thrid.svg";
import { ReactComponent as Icon4 } from "../assets/icons/menu-fourth.svg";
import { ReactComponent as Icon5 } from "../assets/icons/menu-fifth.svg";
import { useAudio } from "../contexts/AudioContext";
import "../assets/styles/menu.css";
import clickSoundMp3 from '../assets/sounds/menu-button-click.mp3';

const Menu = ({ activePage, onMenuClick }) => {
  const { t } = useTranslation();
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const audioBufferRef = useRef(null);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized && window.Telegram && window.Telegram.WebApp) {
        try {
          // Створюємо AudioContext
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          // Завантажуємо аудіо файл
          const response = await fetch(clickSoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          audioBufferRef.current = await audioContextRef.current.decodeAudioData(arrayBuffer);

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    // Викликаємо ініціалізацію при першій взаємодії користувача
    const handleInteraction = () => {
      if (!isAudioInitialized) {
        initializeAudio();
      }
    };

    window.Telegram.WebApp.onEvent('viewportChanged', handleInteraction);
    document.addEventListener('touchstart', handleInteraction, { once: true });
    document.addEventListener('mousedown', handleInteraction, { once: true });

    return () => {
      window.Telegram.WebApp.offEvent('viewportChanged', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
      document.removeEventListener('mousedown', handleInteraction);
    };
  }, [isAudioInitialized]);

  const playSound = () => {
    if (isAudioInitialized && audioContextRef.current && audioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBufferRef.current;
      
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
      
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
      
      source.start(0);
    }
  };

  const handleButtonClick = (path) => {
    playSound();
    onMenuClick(path);
    
    // Викликаємо метод Telegram для створення тактильного відгуку
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
  };
  return (
    <div className="menu-container">
      <div className="menu">
        <button
          className={`menu-button ${activePage === '/' ? 'active' : ''}`} 
          onClick={() => handleButtonClick("/")}
        >
          <Icon1 className="icon" />
          {t('Menu.MineText')}
        </button>
        <div className="vertical-line"></div>
        <button
          className={`menu-button ${activePage === '/pump' ? 'active' : ''}`} 
          onClick={() => handleButtonClick("/pump")}
        >
          <Icon2 className="icon" />
          {t('Menu.UpgradeText')}
        </button>
        <div className="vertical-line"></div>
        <button
          className={`menu-button ${activePage === '/partners' ? 'active' : ''}`} 
          onClick={() => handleButtonClick("/partners")}
        >
          <Icon3 className="icon" />
          {t('Menu.PartnersText')}
        </button>
        <div className="vertical-line"></div>
        <button
          className={`menu-button ${activePage === '/socials' ? 'active' : ''}`} 
          onClick={() => handleButtonClick("/socials")}
        >
          <Icon4 className="icon" />
          {t('Menu.MediaText')}
        </button>
      </div>
      <button 
        className={`trade-button ${activePage === '/trade' ? 'active' : ''}`} 
        onClick={() => handleButtonClick("/trade")}
      >
        <Icon5 className="icon" />
        <p className="button-text">{t('Menu.TraderText')}</p>
      </button>
    </div>
  );
};

export default Menu;