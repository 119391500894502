    import React, { useState, useEffect, useRef, useCallback } from 'react';
    import axios from 'axios';
    import "../../assets/styles/games/game_core3.css";
    import { useAudio } from '../../contexts/AudioContext';
    import { API_URLS } from "../../config/config";
    import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg"
    import { useUser } from "../../contexts/UserContext";
    import { useTranslation } from "react-i18next";
    import GameIconLL from "../../assets/images/Games/ui_graph_person.jpg"
    import TopRightImage from "../../assets/icons/gnom.webp"
    import TradeWarningModal from "../../components/TradeWarningModal";
    import modalBackground from "../../assets/images/fairy-modal-background.png";
    import formatBalance from "../../utils/formatBalance";
    import backSound from "../../assets/sounds/game3-back.mp3";
    import clickSoundMp3 from '../../assets/sounds/menu-button-click.mp3';
    import moneySoundMp3 from "../../assets/sounds/bonus-money-sound.mp3";

    const styles = {
        gameWrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
            marginTop: '10px',
        },
        gameContainer: {
            width: '100%',
            maxWidth: '500px',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
        },
        gameHeader: {
            padding: '15px',
            backgroundColor: '#4a90e2',
            color: 'white',
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        canvas: {
            width: '100%',
            height: 'auto',
            maxHeight: '300px',
        },
        controlsContainer: {
            padding: '15px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        statusText: {
            margin: '5px 0',
            fontSize: '14px',
            color: 'white',
        },
        finalMessage: {
            color: '#e74c3c',
            fontWeight: 'bold',
        },
        input: {
            width: '100%',
            padding: '5px',
            color: 'white',
            fontSize: '18px',
            backgroundColor: 'transparent',
            borderRadius: '4px',
            outline: 'none',
        },
        whiteText: {
            color: 'white',
        },
    };

    const CoreGameThree = ({ tgUserId }) => {
        const [columns, setColumns] = useState(6);
        const [rows, setRows] = useState(6);
        const [currentValue, setCurrentValue] = useState(1);
        const [isRunning, setIsRunning] = useState(false);
        const [growthProgress, setGrowthProgress] = useState(0);
        const [showFinalMessage, setShowFinalMessage] = useState(false);
        const [isConnected, setIsConnected] = useState(false);
        const [gameStatus, setGameStatus] = useState('wait');
        const [waitTime, setWaitTime] = useState(0);
        const [sessionId, setSessionId] = useState(null);
        const [error, setError] = useState(null);
        const [betMessage, setBetMessage] = useState('');
        const [pickupMessage, setPickupMessage] = useState('');
        const [endPosition, setEndPosition] = useState({ x: 0, y: 0 });
        const [canvasSize, setCanvasSize] = useState({ width: 350, height: 350 });
        const [hasPickedUpBet, setHasPickedUpBet] = useState(false);
        const [fallProgress, setFallProgress] = useState(0);
        const [isGrowthComplete, setIsGrowthComplete] = useState(false);
        const [betAmount, setBetAmount] = useState('');
        const [betId, setBetId] = useState(null);
        const [currentBet, setCurrentBet] = useState('');
        const [betDisplayed, setBetDisplayed] = useState(false);
        const [finalValue, setFinalValue] = useState(null);
        const [betPlaced, setBetPlaced] = useState(false);
        const canvasRef = useRef(null);
        const wsRef = useRef(null);
        const imageRef = useRef(null);
        const topRightImageRef = useRef(null);
        const betMessageRef = useRef('');
        const { user, updateUser } = useUser();
        //const [localBalance, setLocalBalance] = useState(user.g_token);
        const { t } = useTranslation();
        const [modalState, setModalState] = useState({
            isOpen: false,
            message: "",
            link: "",
        });
        const { changeBackgroundMusic, revertToDefaultMusic, activeMusic } = useAudio();
        const { effectsVolume, generalVolume } = useAudio();
    const [isAudioInitialized, setIsAudioInitialized] = useState(false);
    const audioContextRef = useRef(null);
    const clickAudioBufferRef = useRef(null);
    const moneyAudioBufferRef = useRef(null);

    const playSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = clickAudioBufferRef.current;
          
          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
          
          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);
          
          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

      const playMoneySound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && moneyAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = moneyAudioBufferRef.current;
    
          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
    
          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);
    
          source.start(0);
        }
    }, [isAudioInitialized, effectsVolume, generalVolume]);

      useEffect(() => {
        const initializeAudio = async () => {
          if (!isAudioInitialized) {
            try {
              const AudioContext = window.AudioContext || window.webkitAudioContext;
              audioContextRef.current = new AudioContext();

              const clickResponse = await fetch(clickSoundMp3);
              const clickArrayBuffer = await clickResponse.arrayBuffer();
              clickAudioBufferRef.current = await audioContextRef.current.decodeAudioData(clickArrayBuffer);

              const moneyResponse = await fetch(moneySoundMp3);
              const moneyArrayBuffer = await moneyResponse.arrayBuffer();
              moneyAudioBufferRef.current = await audioContextRef.current.decodeAudioData(moneyArrayBuffer);
    
              setIsAudioInitialized(true);
            } catch (error) {
              console.error("Error initializing audio:", error);
            }
          }
        };
    
        initializeAudio();
    }, [isAudioInitialized]);

        // useEffect(() => {
        //     const handleAudioChange = async () => {
        //         if (activeMusic !== backSound) {
        //             await changeBackgroundMusic(backSound);
        //         }
        //     };
        
        //     handleAudioChange();
        
        //     return () => {
        //         revertToDefaultMusic();
        //     };
        // }, [changeBackgroundMusic, revertToDefaultMusic, activeMusic]);

        const maxCoefficient = 6;
        const minCoefficient = 0;

        // useEffect(() => {
        //     changeBackgroundMusic(backSound);
        //     return () => {
        //         revertToDefaultMusic();
        //     };
        // }, [changeBackgroundMusic, revertToDefaultMusic]);
        
        const updateCanvasSize = useCallback(() => {
            const windowWidth = window.innerWidth;
            let newWidth, newHeight;
    
            if (windowWidth < 360) {
                newWidth = windowWidth - 20;
            } else if (windowWidth < 768) {
                newWidth = windowWidth-150;
            } else {
                newWidth = 350;
            }
    
            newHeight = newWidth; 
    
            setCanvasSize({ width: newWidth, height: newHeight });
        }, []);
    
        const calculateFontSize = (canvasWidth) => {
            const baseSize = 11;
            const minSize = 10;
            const maxSize = 16;
    
            const newSize = Math.floor((canvasWidth / 500) * baseSize);
    
            return Math.max(minSize, Math.min(newSize, maxSize));
        };

        useEffect(() => {
            updateCanvasSize();
            window.addEventListener('resize', updateCanvasSize);
    
            return () => {
                window.removeEventListener('resize', updateCanvasSize);
            };
        }, [updateCanvasSize]);

        // const updateLocalBalance = useCallback((amount) => {
        //     setLocalBalance(prevBalance => {
        //         const newBalance = (parseFloat(prevBalance) + parseFloat(amount)).toFixed(5);
        //         updateUser({ ...user, g_token: newBalance });
        //         return newBalance;
        //     });
        // }, [user, updateUser]);

        useEffect(() => {
            const img = new Image();
            img.src = GameIconLL;
            img.onload = () => {
                imageRef.current = img;
            };

            const topRightImg = new Image();
            topRightImg.src = TopRightImage;
            topRightImg.onload = () => {
                topRightImageRef.current = topRightImg;
            };
        }, []);

        const handleBetAmountChange = (e) => {
            const value = e.target.value;
            if (/^\d*\.?\d*$/.test(value)) {
                const parts = value.split('.');
                if (parts[1] && parts[1].length > 5) {
                    return;
                }
                if (value.length <= 6) {
                    setBetAmount(value);
                }
            }
        };

        const resetGameState = useCallback(() => {
            setIsRunning(false);
            setShowFinalMessage(false);
            setCurrentValue(1);
            setGrowthProgress(0);
            setIsGrowthComplete(false);
            setFallProgress(0);
            setHasPickedUpBet(false);
            setBetId(null);
            setError(null);
            setBetPlaced(false);
            setCurrentBet(betAmount);
            betMessageRef.current = '';
            setBetMessage('');
        }, []);

        

        const connectWebSocket = useCallback(() => {
            try {
                const wsUrl = "wss://" + API_URLS.WEBS_GAME3_CONNECT;

                if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                    return;
                }

                wsRef.current = new WebSocket(wsUrl);

                const timeoutId = setTimeout(() => {
                    if (wsRef.current && wsRef.current.readyState === WebSocket.CONNECTING) {
                        wsRef.current.close();
                        setIsConnected(false);
                        setError(t('GamePage.Game3.ConnectError'));
                    }
                }, 10000);

                wsRef.current.onopen = (event) => {
                    clearTimeout(timeoutId);
                    setIsConnected(true);
                    setError(null);
                };

                wsRef.current.onmessage = (event) => {
                    const data = JSON.parse(event.data);

                    switch (data.status) {
                        case 'wait':
                            setGameStatus('wait');
                            setWaitTime(data.second);
                            setSessionId(data.session_id);
                            setPickupMessage('');
                            setFinalValue(null);
                            resetGameState();
                            break;
                        case 'sterted':
                            setGameStatus('started');
                            setCurrentValue(data.startnumber || 1);
                            setGrowthProgress(0);
                            setIsRunning(true);
                            setShowFinalMessage(false);
                            if (betPlaced) {
                                setBetMessage(betMessageRef.current);
                            }
                            break;
                        case 'prosed':
                            setGameStatus('prosed');
                            setCurrentValue(data.current);
                            setGrowthProgress(Math.min(data.current / maxCoefficient, 1));
                            if (betPlaced) {
                                setBetMessage(betMessageRef.current);
                            }
                            break;
                        case 'ended':
                            setGameStatus('ended');
                            setIsRunning(false);
                            setCurrentValue(data.number);
                            setFinalValue(data.number);
                            setShowFinalMessage(true);
                            setIsGrowthComplete(true);
                            setBetMessage('');
                            betMessageRef.current = '';
                            break;
                        default:
                    }
                };

                wsRef.current.onclose = (event) => {
                    clearTimeout(timeoutId);
                    setIsConnected(false);
                    setTimeout(connectWebSocket, 5000);
                };

                wsRef.current.onerror = (error) => {
                    clearTimeout(timeoutId);
                };
            } catch (error) {
            }
        }, [resetGameState, t, maxCoefficient]);

        useEffect(() => {
            connectWebSocket();

            return () => {
                if (wsRef.current) {
                    wsRef.current.close();
                }
            };
        }, [connectWebSocket]);

        useEffect(() => {
            if (isGrowthComplete) {
                const fallInterval = setInterval(() => {
                    setFallProgress(prev => {
                        const newValue = prev + 0.02;
                        if (newValue >= 1) {
                            clearInterval(fallInterval);
                            setShowFinalMessage(true);
                            return 1;
                        }
                        return newValue;
                    });
                }, 20);

                return () => clearInterval(fallInterval);
            }
        }, [isGrowthComplete]);

        useEffect(() => {
            const drawGraph = () => {
                const canvas = canvasRef.current;
                if (!canvas) return;

                const ctx = canvas.getContext('2d');
                const width = canvas.width;
                const height = canvas.height;

                const leftPadding = 25;
                const bottomPadding = 20;
                const rightPadding = 13;
                const topPadding = 12;

                const gridWidth = width - leftPadding - rightPadding;
                const gridHeight = height - topPadding - bottomPadding;

                ctx.clearRect(0, 0, width, height);
                ctx.fillStyle = 'white';
                ctx.strokeStyle = '#ffffff';
                ctx.lineWidth = 2;

                const cellWidth = gridWidth / columns;
                const cellHeight = gridHeight / rows;

                for (let i = 0; i <= columns; i++) {
                    const x = leftPadding + i * cellWidth;
                    ctx.beginPath();
                    ctx.moveTo(x, topPadding);
                    ctx.lineTo(x, height - bottomPadding);
                    ctx.stroke();
                }

                for (let i = 0; i <= rows; i++) {
                    const y = topPadding + i * cellHeight;
                    ctx.beginPath();
                    ctx.moveTo(leftPadding, y);
                    ctx.lineTo(width - rightPadding, y);
                    ctx.stroke();
                }

                ctx.strokeStyle = '#4a90e2';
                ctx.lineWidth = 3;
                ctx.beginPath();
                ctx.moveTo(leftPadding, height - bottomPadding);

                const curveFunction = (x) => {
                    const progress = x / gridWidth;
                    const scaledValue = (currentValue - minCoefficient) / (maxCoefficient - minCoefficient);
                    const y = Math.pow(progress, 0.6) * scaledValue * gridHeight;
                    return height - bottomPadding - y;
                };

                const maxX = gridWidth * growthProgress;

                for (let x = 0; x <= maxX; x += 2) {
                    const y = curveFunction(x);
                    ctx.lineTo(leftPadding + x, y);
                }

                ctx.stroke();

                if (imageRef.current) {
                    const imgWidth = 30;
                    const imgHeight = 30;
                    let endX, endY;

                    if (growthProgress > 0) {
                        endX = leftPadding + maxX;
                        endY = curveFunction(maxX);
                    } else {
                        endX = leftPadding;
                        endY = height - bottomPadding;
                    }

                    setEndPosition({ x: endX, y: endY });

                    const angle = growthProgress > 0
                        ? Math.atan2(endY - (height - bottomPadding), endX - leftPadding) - Math.PI / 2
                        : 0;

                    const fallDistance = 70;
                    const horizontalShift = 12;

                    const fallOffsetY = fallProgress * fallDistance;
                    const fallOffsetX = Math.sin(fallProgress * Math.PI / 2) * horizontalShift;

                    const rotationAngle = angle + (fallProgress * Math.PI / 4);

                    ctx.save();
                    ctx.translate(endX + fallOffsetX, endY + fallOffsetY);
                    ctx.rotate(rotationAngle);
                    ctx.rotate(Math.PI * 0.4);
                    ctx.drawImage(imageRef.current, -imgWidth / 2, -imgHeight / 1.10, imgWidth, imgHeight);

                    if (gameStatus === 'started' || gameStatus === 'prosed' || gameStatus === 'ended') {
                        ctx.rotate(-rotationAngle - Math.PI * 0.4);
                        ctx.font = 'bold 22px Arial';
                        ctx.fillStyle = isGrowthComplete ? 'red' : 'green';
                        ctx.textAlign = 'center';
                        ctx.fillText(`${currentValue.toFixed(2)}x`, 0, imgHeight / 2 + 5);
                    }

                    ctx.restore();
                }

                ctx.font = 'bold 32px Arial';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'top';
                const textX = width / 2;
                const textY = 10;

                if (isGrowthComplete) {
                    ctx.fillStyle = 'red';
                } else {
                    ctx.fillStyle = 'green';
                }

                //ctx.fillText(`${currentValue.toFixed(2)}x`, textX, textY);

                ctx.fillStyle = 'white';
                const fontSize = calculateFontSize(width);
                ctx.font = `${fontSize}px Arial`;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'top';
                for (let i = 0; i <= columns; i++) {
                    const value = `${i}.0X`;
                    const x = leftPadding + i * cellWidth;
                    ctx.fillText(value, x, height - bottomPadding + 5);
                }
                ctx.textAlign = 'right';
                ctx.textBaseline = 'middle';
                for (let i = 0; i <= rows; i++) {
                    const value = 5 * (rows - i);
                    const y = topPadding + i * cellHeight;

                    if (i === 6) {
                        ctx.fillStyle = 'rgba(255, 255, 255, 0)';
                    } else {
                        ctx.fillStyle = 'white';
                    }

                    ctx.fillText(`${value}s`, leftPadding - 5, y);
                }



                if (topRightImageRef.current) {
        const imgWidth = 30;
        const imgHeight = 40;
        const cellX = width - rightPadding - cellWidth;
        const cellY = topPadding;
        const x = cellX + (cellWidth - imgWidth) / 2;
        const y = cellY + (cellHeight - imgHeight) / 2;
        ctx.drawImage(topRightImageRef.current, x, y, imgWidth, imgHeight);
    }
    };

    drawGraph();
    }, [columns, rows, currentValue, growthProgress, fallProgress, isGrowthComplete, minCoefficient, maxCoefficient]);

    const startAnimation = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ action: 'start_game' }));
        } else {
        }
    };

    const handleGameAction = async (event) => {
        event.preventDefault();
        if (!isConnected) {
            return;
        }
        playSound();

        if (gameStatus === 'wait') {
            if (!betAmount || parseFloat(betAmount) <= 0) {
                return;
            }
           
            try {
                const response = await axios.post(API_URLS.REACT_APP_GAME3_MAKEBET, {
                    tg_user_id: user.user_id,
                    bet: parseFloat(betAmount),
                    session_id: sessionId
                });
                console.log("Make bet", response.data);
                
                if (response.data.status === 'done') {
                    setBetAmount(betAmount);
                    console.log("BEEEEEEEEEEEt", betAmount);
                    setBetDisplayed(true);
                    localStorage.setItem('gameBetId', response.data.bet_id);
                    setError(null);
                    const message = t('GamePage.Game3.BetConfirmation', { amount: betAmount });
                    setBetMessage(message);
                    betMessageRef.current = message;
                    setPickupMessage('');
                    //updateLocalBalance(-parseFloat(betAmount));
                    const newBalance=user.g_token - parseFloat(betAmount);
                    updateUser({ g_token: newBalance});
                    setBetPlaced(true);
                    startAnimation();
                }
                else if(response.data.status === 'error' || response.data.error_code==='552'){
                    updateModalState(t("GamePage.SomethingWrong"), true);
                  }
                  else if(response.data.status === 'error' || response.data.error_code==='551'){
                    updateModalState(t("GamePage.NotEnoughBalance"), true);
                  }
                   else {
                    updateModalState(t("GamePage.UnexpectedError"), true);
                }
            } catch (error) {
            }
        } else if (gameStatus === 'started' || gameStatus === 'prosed') {
            const storedBetId = localStorage.getItem('gameBetId');
            if (storedBetId) {
                try {
                    const response = await axios.post(API_URLS.REACT_APP_GAME3_TAKEBET, {
                        bet_id: storedBetId
                    });
                    if (response.data.status === 'ok') {
                        localStorage.removeItem('gameBetId');
                        setHasPickedUpBet(true);
                        const winAmount = parseFloat(response.data.win);
                        const message = t('GamePage.Game3.Congratulations', { winAmount: winAmount, xValue:currentValue.toFixed(2) });
                        setPickupMessage(message);
                        setBetMessage('');
                        betMessageRef.current = '';
                        setError(null);
                        //updateLocalBalance(winAmount);
                        const updatedBalance = user.g_token + winAmount;
                        updateUser({ g_token: updatedBalance });
                        playMoneySound();

                    } else {
                        updateModalState(t("GamePage.UnexpectedError"), true);
                    }
                } catch (error) {
                }
            } else {
            }
        }
    };

    useEffect(() => {
        if (gameStatus === 'ended' && !hasPickedUpBet) {
            setBetMessage('');
            setBetDisplayed(false);
            setBetAmount(0);
            betMessageRef.current = '';
        }
    }, [gameStatus, hasPickedUpBet, t]);

    const ArrowRight = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
        </svg>
    );

    const updateModalState = (message, isOpen = true, link = "") => {
        setModalState({ isOpen, message, link });
    };

    useEffect(() => {
        if (gameStatus === 'ended' && finalValue !== null) {
            let message;
            if (hasPickedUpBet) {
                    message = t('GamePage.Game3.Victory', { value: finalValue.toFixed(2) });
            } else {
                message = t('GamePage.Game3.Defeat', { value: finalValue.toFixed(2) });
            }
            updateModalState(message);
        }
    }, [gameStatus, finalValue, hasPickedUpBet, t]);

        return (
            <div style={styles.gameWrapper}>
                <div className='flex flex-row justify-between' style={{width:'80%'}}>
                <div className='bet-container flex flex-col w-1/2 justify-end'>
                    <p className="my-bet-text">{t('GamePage.Game3.YourBet')}</p>
                    <div className="earning-game-my-bet">
                        <div className="earning-content-game">
                            <div className="earning-icon-container-game">
                                <IconCoin className="earning-icon-small-game" />
                                <p className="token-game" style={styles.whiteText}>Token</p>
                            </div>
                            <div className="earning-text-container-game">
                                <p className="earning-text-game" style={styles.whiteText}>{betDisplayed ? betAmount : '0'}</p>
                            </div>
                        </div>
                    </div> 
                    </div>
               
                <div className="cont-game-3plz">
                    <div className="earning-game1">
                        <div className="earning-content-game">
                            <div className="earning-icon-container-game1">
                                <div className="text-font-size">-5.1x</div>
                            </div>
                            <ArrowRight />
                            <div className="earning-text-container-game1">
                                <div className="gnome-container">
                                    <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
                                    <div className="bonus bonus-bottom">100</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="earning-game1">
                        <div className="earning-content-game">
                            <div className="earning-icon-container-game1">
                                <div className="text-font-size">-5.5x</div>
                            </div>
                            <ArrowRight />
                            <div className="earning-text-container-game1">
                                <div className="gnome-container">
                                    <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
                                    <div className="bonus bonus-bottom">500</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="earning-game1">
                        <div className="earning-content-game">
                            <div className="earning-icon-container-game1">
                                <div className="text-font-size">-6.0x</div>
                            </div>
                            <ArrowRight />
                            <div className="earning-text-container-game1">
                                <div className="gnome-container">
                                    <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
                                    <div className="bonus bonus-bottom">1000</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* <div style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color: isGrowthComplete ? 'red' : 'green',
                    textAlign: 'center',
                    pointerEvents: 'none',
                }}>
                    {currentValue.toFixed(2)}
                </div> */}
                <div className="game-container" style={{ position: 'relative' }}>
                      <canvas 
                    ref={canvasRef} 
                    width={canvasSize.width} 
                    height={canvasSize.height} 
                    style={{ ...styles.canvas, width: '100%', height: '100%', display: 'block' }} 
                />
                    {/* <div style={styles.controlsContainer}>
                        {showFinalMessage && finalValue && (
                            <p style={{ ...styles.statusText, ...styles.finalMessage }}>
                                {t('GamePage.Game3.GameEnd')}: {finalValue.toFixed(2)}
                            </p>
                        )}
                        {betMessage && <p style={{ ...styles.statusText, color: 'green' }}>{betMessage}</p>}
                        {pickupMessage && <p style={{ ...styles.statusText, color: 'green' }}>{pickupMessage}</p>}
                    </div> */}
                </div>
                <div className="game-buttom-list">
                    <button
                        className="game-button"
                        onClick={handleGameAction}
                        disabled={!isConnected || gameStatus === 'ended' || (betId && gameStatus !== 'started' && gameStatus !== 'prosed')}
                    >
                        {!isConnected
                            ? t('GamePage.Game3.Connect')
                            : gameStatus === 'wait'
                                ? `${t('GamePage.Game3.StartGame')} (${waitTime.toFixed(1)}s)`
                                : (gameStatus === 'started' || gameStatus === 'prosed') && !hasPickedUpBet
                                    ? t('GamePage.Game3.Takethebet')
                                    : t('GamePage.Game3.Inprogress')}
                    </button>
                    <div className="earning-game">
                        <div className="earning-content-game">
                            <div className="earning-icon-container-game">
                                <IconCoin className="earning-icon-small-game" />
                                <p className="token-game" style={styles.whiteText}>Token</p>
                            </div>
                            <div className="earning-text-container-game">
                                <p className="earning-text-game" style={styles.whiteText}>{user.g_token}</p>
                            </div>
                        </div>
                    </div>
                    <div className="earning-game">
                        <div className="earning-content-game">
                            <div className="earning-icon-container-game1">
                                <IconCoin className="earning-icon-small-game"/>
                            </div>
                            <div className="earning-content-game">
                                <div className="earning-text-container-game">
                                    <input
                                        type="text"
                                        inputMode="decimal"
                                        pattern="[0-9]*"
                                        value={betAmount}
                                        onChange={handleBetAmountChange}
                                        placeholder="0.001"
                                        style={styles.input}
                                        disabled={gameStatus !== 'wait'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <TradeWarningModal
                    key={modalState.isOpen ? "open" : "closed"}
                    modalState={modalState}
                    background={modalBackground}
                    onClose={() => updateModalState("", false)}
                />
            </div>
        );
    };

    export default CoreGameThree;
//     import React, { useState, useEffect, useRef, useCallback } from "react";
// import axios from "axios";
// import "../../assets/styles/games/game_core3.css";
// import { API_URLS } from "../../config/config";
// import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
// import { useUser } from "../../contexts/UserContext";
// import { useTranslation } from "react-i18next";
// import GameIconLL from "../../assets/images/Games/ui_graph_person.jpg";
// import TopRightImage from "../../assets/icons/gnom.webp";
// import TradeWarningModal from "../../components/TradeWarningModal";
// import modalBackground from "../../assets/images/fairy-modal-background.png";

// const styles = {
//     gameWrapper: {
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         boxSizing: "border-box",
//         marginTop: "10px",
//     },
//     gameContainer: {
//         width: "100%",
//         maxWidth: "500px",
//         backgroundColor: "white",
//         borderRadius: "10px",
//         boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//         overflow: "hidden",
//     },
//     gameHeader: {
//         padding: "15px",
//         backgroundColor: "#4a90e2",
//         color: "white",
//         fontSize: "18px",
//         fontWeight: "bold",
//         textAlign: "center",
//     },
//     canvas: {
//         width: "100%",
//         height: "auto",
//         maxHeight: "300px",
//     },
//     controlsContainer: {
//         padding: "15px",
//         display: "flex",
//         flexDirection: "column",
//         gap: "10px",
//     },
//     statusText: {
//         margin: "5px 0",
//         fontSize: "14px",
//         color: "white",
//     },
//     finalMessage: {
//         color: "#e74c3c",
//         fontWeight: "bold",
//     },
//     input: {
//         width: "100%",
//         padding: "5px",
//         color: "white",
//         fontSize: "18px",
//         backgroundColor: "transparent",
//         borderRadius: "4px",
//         outline: "none",
//     },
//     whiteText: {
//         color: "white",
//     },
// };

// const Notification = ({ message, isVisible, onClose }) => {
//     useEffect(() => {
//         if (isVisible) {
//             const timer = setTimeout(() => {
//                 onClose();
//             }, 500);
//             return () => clearTimeout(timer);
//         }
//     }, [isVisible, onClose]);

//     if (!isVisible) return null;

//     return (
//         <div
//             style={{
//                 position: "fixed",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 backgroundColor: "rgba(0, 0, 0, 0.8)",
//                 color: "white",
//                 padding: "20px",
//                 borderRadius: "10px",
//                 zIndex: 1000,
//                 textAlign: "center",
//                 fontWeight: "bold",
//                 fontSize: "24px",
//             }}
//         >
//             {message}
//         </div>
//     );
// };

// const CoreGameThree = ({ tgUserId }) => {
//     const [columns, setColumns] = useState(6);
//     const [rows, setRows] = useState(6);
//     const [currentValue, setCurrentValue] = useState(1);
//     const [isRunning, setIsRunning] = useState(false);
//     const [growthProgress, setGrowthProgress] = useState(0);
//     const [showFinalMessage, setShowFinalMessage] = useState(false);
//     const [isConnected, setIsConnected] = useState(false);
//     const [gameStatus, setGameStatus] = useState("wait");
//     const [waitTime, setWaitTime] = useState(0);
//     const [sessionId, setSessionId] = useState(null);
//     const [error, setError] = useState(null);
//     const [betMessage, setBetMessage] = useState("");
//     const [pickupMessage, setPickupMessage] = useState("");
//     const [endPosition, setEndPosition] = useState({ x: 0, y: 0 });
//     const [hasPickedUpBet, setHasPickedUpBet] = useState(false);
//     const [fallProgress, setFallProgress] = useState(0);
//     const [isGrowthComplete, setIsGrowthComplete] = useState(false);
//     const [betAmount, setBetAmount] = useState("");
//     const [betId, setBetId] = useState(null);
//     const [finalValue, setFinalValue] = useState(null);
//     const [betPlaced, setBetPlaced] = useState(false);
//     const [gameResult, setGameResult] = useState(null);
//     const [winNotification, setWinNotification] = useState({
//         isVisible: false,
//         message: "",
//     });
//     const [betConfirmation, setBetConfirmation] = useState({
//         isVisible: false,
//         message: "",
//     });
//     const canvasRef = useRef(null);
//     const wsRef = useRef(null);
//     const imageRef = useRef(null);
//     const topRightImageRef = useRef(null);
//     const betMessageRef = useRef("");
//     const { user, updateUser } = useUser();
//     const [localBalance, setLocalBalance] = useState(user.g_token);
//     const { t } = useTranslation();
//     const [modalState, setModalState] = useState({
//         isOpen: false,
//         message: "",
//         link: "",
//     });

//     const maxCoefficient = 6;
//     const minCoefficient = 0;

//     const updateLocalBalance = useCallback(
//         (amount) => {
//             setLocalBalance((prevBalance) => {
//                 const newBalance = (
//                     parseFloat(prevBalance) + parseFloat(amount)
//                 ).toFixed(5);
//                 updateUser({ ...user, g_token: newBalance });
//                 return newBalance;
//             });
//         },
//         [user, updateUser]
//     );

//     useEffect(() => {
//         const img = new Image();
//         img.src = GameIconLL;
//         img.onload = () => {
//             imageRef.current = img;
//         };

//         const topRightImg = new Image();
//         topRightImg.src = TopRightImage;
//         topRightImg.onload = () => {
//             topRightImageRef.current = topRightImg;
//         };
//     }, []);

//     const handleBetAmountChange = (e) => {
//         const value = e.target.value;
//         if (/^\d*\.?\d*$/.test(value)) {
//             const parts = value.split(".");
//             if (parts[1] && parts[1].length > 5) {
//                 return;
//             }
//             if (value.length <= 6) {
//                 setBetAmount(value);
//             }
//         }
//     };

//     const resetGameState = useCallback(() => {
//         setIsRunning(false);
//         setShowFinalMessage(false);
//         setCurrentValue(1);
//         setGrowthProgress(0);
//         setIsGrowthComplete(false);
//         setFallProgress(0);
//         setHasPickedUpBet(false);
//         setBetId(null);
//         setError(null);
//         setBetPlaced(false);
//         betMessageRef.current = "";
//         setBetMessage("");
//     }, []);

//     const connectWebSocket = useCallback(() => {
//         try {
//             const wsUrl = "wss://" + API_URLS.WEBS_GAME3_CONNECT;

//             if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
//                 return;
//             }

//             wsRef.current = new WebSocket(wsUrl);

//             const timeoutId = setTimeout(() => {
//                 if (
//                     wsRef.current &&
//                     wsRef.current.readyState === WebSocket.CONNECTING
//                 ) {
//                     wsRef.current.close();
//                     setIsConnected(false);
//                     setError(t("GamePage.Game3.ConnectError"));
//                 }
//             }, 10000);

//             wsRef.current.onopen = (event) => {
//                 clearTimeout(timeoutId);
//                 setIsConnected(true);
//                 setError(null);
//             };

//             wsRef.current.onmessage = (event) => {
//                 const data = JSON.parse(event.data);

//                 switch (data.status) {
//                     case "wait":
//                         setGameStatus("wait");
//                         setWaitTime(data.second);
//                         setSessionId(data.session_id);
//                         setPickupMessage("");
//                         setFinalValue(null);
//                         resetGameState();
//                         break;
//                     case "sterted":
//                         setGameStatus("started");
//                         setCurrentValue(data.startnumber || 1);
//                         setGrowthProgress(0);
//                         setIsRunning(true);
//                         setShowFinalMessage(false);
//                         if (betPlaced) {
//                             setBetMessage(betMessageRef.current);
//                         }
//                         break;
//                     case "prosed":
//                         setGameStatus("prosed");
//                         setCurrentValue(data.current);
//                         setGrowthProgress(Math.min(data.current / maxCoefficient, 1));
//                         if (betPlaced) {
//                             setBetMessage(betMessageRef.current);
//                         }
//                         break;
//                     case "ended":
//                         setGameStatus("ended");
//                         setIsRunning(false);
//                         setCurrentValue(data.number);
//                         setFinalValue(data.number);
//                         setShowFinalMessage(true);
//                         setIsGrowthComplete(true);
//                         setBetMessage("");
//                         betMessageRef.current = "";
//                         break;
//                     default:
//                 }
//             };

//             wsRef.current.onclose = (event) => {
//                 clearTimeout(timeoutId);
//                 setIsConnected(false);
//                 setTimeout(connectWebSocket, 5000);
//             };

//             wsRef.current.onerror = (error) => {
//                 clearTimeout(timeoutId);
//             };
//         } catch (error) {}
//     }, [resetGameState, t, maxCoefficient]);

//     useEffect(() => {
//         connectWebSocket();

//         return () => {
//             if (wsRef.current) {
//                 wsRef.current.close();
//             }
//         };
//     }, [connectWebSocket]);

//     useEffect(() => {
//         if (isGrowthComplete) {
//             const fallInterval = setInterval(() => {
//                 setFallProgress((prev) => {
//                     const newValue = prev + 0.02;
//                     if (newValue >= 1) {
//                         clearInterval(fallInterval);
//                         setShowFinalMessage(true);
//                         return 1;
//                     }
//                     return newValue;
//                 });
//             }, 20);

//             return () => clearInterval(fallInterval);
//         }
//     }, [isGrowthComplete]);

//     useEffect(() => {
//         const drawGraph = () => {
//             const canvas = canvasRef.current;
//             if (!canvas) return;

//             const ctx = canvas.getContext("2d");
//             const width = canvas.width;
//             const height = canvas.height;

//             const leftPadding = 25;
//             const bottomPadding = 20;
//             const rightPadding = 13;
//             const topPadding = 12;

//             const gridWidth = width - leftPadding - rightPadding;
//             const gridHeight = height - topPadding - bottomPadding;

//             ctx.clearRect(0, 0, width, height);
//             ctx.fillStyle = "white";
//             ctx.strokeStyle = "#ffffff";
//             ctx.lineWidth = 2;

//             const cellWidth = gridWidth / columns;
//             const cellHeight = gridHeight / rows;

//             for (let i = 0; i <= columns; i++) {
//                 const x = leftPadding + i * cellWidth;
//                 ctx.beginPath();
//                 ctx.moveTo(x, topPadding);
//                 ctx.lineTo(x, height - bottomPadding);
//                 ctx.stroke();
//             }

//             for (let i = 0; i <= rows; i++) {
//                 const y = topPadding + i * cellHeight;
//                 ctx.beginPath();
//                 ctx.moveTo(leftPadding, y);
//                 ctx.lineTo(width - rightPadding, y);
//                 ctx.stroke();
//             }

//             ctx.strokeStyle = "#4a90e2";
//             ctx.lineWidth = 3;
//             ctx.beginPath();
//             ctx.moveTo(leftPadding, height - bottomPadding);

//             const curveFunction = (x) => {
//                 const progress = x / gridWidth;
//                 const scaledValue =
//                     (currentValue - minCoefficient) / (maxCoefficient - minCoefficient);
//                 const y = Math.pow(progress, 0.6) * scaledValue * gridHeight;
//                 return height - bottomPadding - y;
//             };

//             const maxX = gridWidth * growthProgress;

//             for (let x = 0; x <= maxX; x += 2) {
//                 const y = curveFunction(x);
//                 ctx.lineTo(leftPadding + x, y);
//             }

//             ctx.stroke();

//             if (imageRef.current) {
//                 const imgWidth = 30;
//                 const imgHeight = 30;
//                 let endX, endY;

//                 if (growthProgress > 0) {
//                     endX = leftPadding + maxX;
//                     endY = curveFunction(maxX);
//                 } else {
//                     endX = leftPadding;
//                     endY = height - bottomPadding;
//                 }

//                 setEndPosition({ x: endX, y: endY });

//                 const angle =
//                     growthProgress > 0
//                         ? Math.atan2(endY - (height - bottomPadding), endX - leftPadding) -
//                         Math.PI / 2
//                         : 0;

//                 const fallDistance = 70;
//                 const horizontalShift = 12;

//                 const fallOffsetY = fallProgress * fallDistance;
//                 const fallOffsetX =
//                     Math.sin((fallProgress * Math.PI) / 2) * horizontalShift;

//                 const rotationAngle = angle + (fallProgress * Math.PI) / 4;

//                 ctx.save();
//                 ctx.translate(endX + fallOffsetX, endY + fallOffsetY);
//                 ctx.rotate(rotationAngle);
//                 ctx.rotate(Math.PI * 0.4);
//                 ctx.drawImage(
//                     imageRef.current,
//                     -imgWidth / 2,
//                     -imgHeight / 1.1,
//                     imgWidth,
//                     imgHeight
//                 );

//                 if (
//                     gameStatus === "started" ||
//                     gameStatus === "prosed" ||
//                     gameStatus === "ended"
//                 ) {
//                     ctx.rotate(-rotationAngle - Math.PI * 0.4);
//                     ctx.font = "bold 22px Arial";
//                     ctx.fillStyle = isGrowthComplete ? "red" : "green";
//                     ctx.textAlign = "center";
//                     ctx.fillText(`${currentValue.toFixed(2)}x`, 0, imgHeight / 2 + 5);
//                 }

//                 ctx.restore();
//             }

//             ctx.font = "bold 32px Arial";
//             ctx.textAlign = "center";
//             ctx.textBaseline = "top";
//             const textX = width / 2;
//             const textY = 10;

//             if (isGrowthComplete) {
//                 ctx.fillStyle = "red";
//             } else {
//                 ctx.fillStyle = "green";
//             }

//             ctx.fillStyle = "white";
//             ctx.font = "12px Arial";
//             ctx.textAlign = "center";
//             ctx.textBaseline = "top";
//             for (let i = 0; i <= columns; i++) {
//                 const value = `${i}.0X`;
//                 const x = leftPadding + i * cellWidth;
//                 ctx.fillText(value, x, height - bottomPadding + 5);
//             }
//             ctx.textAlign = "right";
//             ctx.textBaseline = "middle";
//             for (let i = 0; i <= rows; i++) {
//                 const value = 5 * (rows - i);
//                 const y = topPadding + i * cellHeight;

//                 if (i === 6) {
//                     ctx.fillStyle = "rgba(255, 255, 255, 0)";
//                 } else {
//                     ctx.fillStyle = "white";
//                 }

//                 ctx.fillText(`${value}s`, leftPadding - 5, y);
//             }

//             if (topRightImageRef.current) {
//                 const imgWidth = 30;
//                 const imgHeight = 40;
//                 const cellX = width - rightPadding - cellWidth;
//                 const cellY = topPadding;
//                 const x = cellX + (cellWidth - imgWidth) / 2;
//                 const y = cellY + (cellHeight - imgHeight) / 2;
//                 ctx.drawImage(topRightImageRef.current, x, y, imgWidth, imgHeight);
//             }
//         };

//         drawGraph();
//     }, [
//         columns,
//         rows,
//         currentValue,
//         growthProgress,
//         fallProgress,
//         isGrowthComplete,
//         minCoefficient,
//         maxCoefficient,
//     ]);

//     const startAnimation = () => {
//         if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
//             wsRef.current.send(JSON.stringify({ action: "start_game" }));
//         } else {
//             // Handle error
//         }
//     };

//     const handleGameAction = async () => {
//         if (!isConnected) {
//             return;
//         }

//         if (gameStatus === "wait") {
//             if (!betAmount || parseFloat(betAmount) <= 0) {
//                 return;
//             }

//             try {
//                 const response = await axios.post(API_URLS.REACT_APP_GAME3_MAKEBET, {
//                     tg_user_id: user.user_id,
//                     bet: parseFloat(betAmount),
//                     session_id: sessionId,
//                 });
//                 console.log(response.data);
//                 //errro 551 no resources
//                 if (response.data.status === "done") {
//                     localStorage.setItem("gameBetId", response.data.bet_id);
//                     setError(null);
//                     const message = t("GamePage.Game3.BetConfirmation", {
//                         amount: betAmount,
//                     });
//                     setBetConfirmation({ isVisible: true, message: message });
//                     betMessageRef.current = message;
//                     setPickupMessage("");
//                     updateLocalBalance(-parseFloat(betAmount));
//                     setBetPlaced(true);
//                     startAnimation();
//                 } else {
//                     // Handle error
//                 }
//             } catch (error) {
//                 // Handle error
//             }
//         } else if (gameStatus === "started" || gameStatus === "prosed") {
//             const storedBetId = localStorage.getItem("gameBetId");
//             if (storedBetId) {
//                 try {
//                     const response = await axios.post(API_URLS.REACT_APP_GAME3_TAKEBET, {
//                         bet_id: storedBetId,
//                     });
//                     if (response.data.status === "ok") {
//                         localStorage.removeItem("gameBetId");
//                         setHasPickedUpBet(true);
//                         const winAmount = parseFloat(response.data.win);
//                         const resultMessage = `Вітаємо! Ви забрали ${winAmount} токенів при ${currentValue.toFixed(
//                             2
//                         )}x.`;
//                         setWinNotification({ isVisible: true, message: resultMessage });
//                         setGameResult({
//                             isWin: true,
//                             oldBalance: localBalance,
//                             newBalance: parseFloat(localBalance) + winAmount,
//                             winAmount: winAmount,
//                         });
//                         setBetMessage("");
//                         betMessageRef.current = "";
//                         setError(null);
//                         updateLocalBalance(winAmount);
//                     } else {
//                     }
//                 } catch (error) {
//                 }
//             } else {
//             }
//         }
//     };

//     useEffect(() => {
//         if (winNotification.isVisible) {
//             const timer = setTimeout(() => {
//                 setWinNotification({ isVisible: false, message: '' });
//             }, 2000);
//             return () => clearTimeout(timer);
//         }
//     }, [winNotification.isVisible]);

//     useEffect(() => {
//         if (betConfirmation.isVisible) {
//             const timer = setTimeout(() => {
//                 setBetConfirmation({ isVisible: false, message: '' });
//             }, 2000);
//             return () => clearTimeout(timer);
//         }
//     }, [betConfirmation.isVisible]);

//     useEffect(() => {
//         if (gameStatus === 'ended' && finalValue !== null) {
//             let message;
//             if (hasPickedUpBet) {
//                 message = t('GamePage.Game3.Victory', { value: finalValue.toFixed(2) });
//             } else {
//                 message = t('GamePage.Game3.Defeat', { value: finalValue.toFixed(2) });
//             }
//             setWinNotification({ isVisible: true, message: message });
//         }
//     }, [gameStatus, finalValue, hasPickedUpBet, t]);

//     useEffect(() => {
//         if (gameStatus === "ended" && !hasPickedUpBet) {
//             setBetMessage("");
//             betMessageRef.current = "";
//         }
//     }, [gameStatus, hasPickedUpBet, t]);

//     const ArrowRight = () => (
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//             stroke="currentColor"
//             strokeWidth="2"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//         >
//             <line x1="5" y1="12" x2="19" y2="12"></line>
//             <polyline points="12 5 19 12 12 19"></polyline>
//         </svg>
//     );

//     const updateModalState = (message, isOpen = true, link = "") => {
//         setModalState({ isOpen, message, link });
//     };

//     useEffect(() => {
//         if (gameStatus === "ended" && finalValue !== null) {
//             let message;
//             if (hasPickedUpBet) {
//                 message = t("GamePage.Game3.Victory", { value: finalValue.toFixed(2) });
//             } else {
//                 message = t("GamePage.Game3.Defeat", { value: finalValue.toFixed(2) });
//             }
//             setWinNotification({ isVisible: true, message: message });
//         }
//     }, [gameStatus, finalValue, hasPickedUpBet, t]);

//     return (
//         <div style={styles.gameWrapper}>
//             <div className="cont-game-3plz">
//                 <div className="earning-game1">
//                     <div className="earning-content-game">
//                         <div className="earning-icon-container-game1">
//                             <div className="text-font-size">-5.1x</div>
//                         </div>
//                         <ArrowRight />
//                         <div className="earning-text-container-game1">
//                             <div className="gnome-container">
//                                 <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
//                                 <div className="bonus bonus-bottom">100</div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="earning-game1">
//                     <div className="earning-content-game">
//                         <div className="earning-icon-container-game1">
//                             <div className="text-font-size">-5.5x</div>
//                         </div>
//                         <ArrowRight />
//                         <div className="earning-text-container-game1">
//                             <div className="gnome-container">
//                                 <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
//                                 <div className="bonus bonus-bottom">500</div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="earning-game1">
//                     <div className="earning-content-game">
//                         <div className="earning-icon-container-game1">
//                             <div className="text-font-size">-6.0x</div>
//                         </div>
//                         <ArrowRight />
//                         <div className="earning-text-container-game1">
//                             <div className="gnome-container">
//                                 <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
//                                 <div className="bonus bonus-bottom">1000</div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div
//                 style={{
//                     position: "fixed", // Змінено з "absolute" на "fixed"
//                     top: "50%", // Додано для вертикального центрування
//                     left: "50%",
//                     transform: "translate(-50%, -50%)", // Змінено для вертикального та горизонтального центрування
//                     fontSize: "32px",
//                     fontWeight: "bold",
//                     color: isGrowthComplete ? "red" : "green",
//                     textAlign: "center",
//                     pointerEvents: "none",
//                     zIndex: 9999, // Додано високе значення z-index
//                     textShadow: "2px 2px 4px rgba(0,0,0,0.5)", // Додано тінь для кращої видимості
//                 }}
//             >
//                 {currentValue.toFixed(2)}
//             </div>
//             <div className="game-container" style={{ position: "relative" }}>
//                 <canvas
//                     ref={canvasRef}
//                     width={350}
//                     height={350}
//                     style={styles.canvas}
//                 />
//                 <div style={styles.controlsContainer}>
//                     {showFinalMessage && finalValue && (
//                         <p style={{ ...styles.statusText, ...styles.finalMessage }}>
//                             {t("GamePage.Game3.GameEnd")}: {finalValue.toFixed(2)}
//                         </p>
//                     )}
//                     {betMessage && (
//                         <p style={{ ...styles.statusText, color: "green" }}>{betMessage}</p>
//                     )}
//                     {pickupMessage && (
//                         <p style={{ ...styles.statusText, color: "green" }}>
//                             {pickupMessage}
//                         </p>
//                     )}
//                 </div>
//             </div>
//             <div className="game-buttom-list">
//                 <button
//                     className="game-button"
//                     onClick={handleGameAction}
//                     disabled={
//                         !isConnected ||
//                         gameStatus === "ended" ||
//                         (betId && gameStatus !== "started" && gameStatus !== "prosed")
//                     }
//                 >
//                     {!isConnected
//                         ? t("GamePage.Game3.Connect")
//                         : gameStatus === "wait"
//                             ? `${t("GamePage.Game3.StartGame")} (${waitTime.toFixed(1)}s)`
//                             : (gameStatus === "started" || gameStatus === "prosed") &&
//                             !hasPickedUpBet
//                                 ? t("GamePage.Game3.Takethebet")
//                                 : t("GamePage.Game3.Inprogress")}
//                 </button>
//                 <div className="earning-game">
//                     <div className="earning-content-game">
//                         <div className="earning-icon-container-game">
//                             <IconCoin className="earning-icon-small-game" />
//                             <p className="token-game" style={styles.whiteText}>
//                                 Token
//                             </p>
//                         </div>
//                         <div className="earning-text-container-game">
//                             <p className="earning-text-game" style={styles.whiteText}>
//                                 {localBalance}
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="earning-game">
//                     <div className="earning-content-game">
//                         <div className="earning-icon-container-game1">
//                             <IconCoin className="earning-icon-small-game" />
//                         </div>
//                         <div className="earning-content-game">
//                             <div className="earning-text-container-game">
//                                 <input
//                                     type="text"
//                                     inputMode="decimal"
//                                     value={betAmount}
//                                     onChange={handleBetAmountChange}
//                                     placeholder="0.001"
//                                     style={styles.input}
//                                     disabled={gameStatus !== "wait"}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <Notification
//                 message={winNotification.message}
//                 isVisible={winNotification.isVisible}
//                 onClose={() => setWinNotification({ isVisible: false, message: "" })}
//             />

//             <Notification
//                 message={betConfirmation.message}
//                 isVisible={betConfirmation.isVisible}
//                 onClose={() => setBetConfirmation({ isVisible: false, message: "" })}
//             />

//             <TradeWarningModal
//                 key={modalState.isOpen ? "open" : "closed"}
//                 modalState={modalState}
//                 background={modalBackground}
//                 onClose={() => updateModalState("", false)}
//             />
//         </div>
//     );
// };

// export default CoreGameThree;