import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import "../assets/styles/upgrade/upgrade.css";
import UpgradeGameProgress from "../components/upgrade/UpgradeGameProgress";
import UpgradeGameRoadProgress from "../components/upgrade/UpgradeGameRoadProgress";
import UpgradeGameModal from "../components/upgrade/UpgradeGameModal";
import { useUser } from '../contexts/UserContext';
import { API_URLS } from '../config/config';

import backgroundImageRoad from '../assets/images/upgrade/Road/background-page3.png'

const PumpingPage = ({ preloadedData }) => {
  const [userData, setUserData] = useState(null);
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showRoadProgress, setShowRoadProgress] = useState(false);
  const { updateUser, user } = useUser();

  const updateLastActive = async (userId) => {
    console.log("userId: ", userId);
    try {
      const response = await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
      console.log('Last active time updated:', response.data);
    } catch (error) {
      console.error('Error updating last active time:', error);
    }
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]); 

  const userId = user.user_id;

  const getBackgroundImage = (stageId, rankId) => {
    
    try {
      return require(`../assets/images/upgrade/GameBackground/${stageId}_1.webp`);
    } catch (error) {
      console.error(`Failed to load background image for stage ${rankId} and rank ${stageId}:`, error);
      return ''; // Return a default or fallback image URL
    }
  };

  const backgroundStyle = showRoadProgress
    ? { backgroundImage: `url(${backgroundImageRoad})` }
    : { backgroundImage: `url(${getBackgroundImage(user.stage.id, user.rank.id)})` };

  const getUserCurrentStageInfo = useCallback(async (userId) => {
    try {
      console.log("Fetching user current stage info...");
      const gameResponse = await axios({
        method: 'POST',
        url: `${API_URLS.GET_USER_CURRENT_STAGE_INFO}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
        data: { userId }
      });

      console.log("Received game data:", gameResponse.data);
      setGameData(gameResponse.data);
      setShowRoadProgress(false);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.log('Received 403 error. Road setup might need to be completed first.');
        setGameData(error.response.data);
        setShowRoadProgress(true);
      } else {
        console.error('Error fetching user stage info:', error.message);
        setError('Error fetching user stage info: ' + error.message);
      }
    }
  }, []);

  const fetchUserAndGameData = useCallback(async () => {
    console.log("Fetching user and game data...");
    setLoading(true);
    setError(null);
    try {
      const userResponse = await axios.get(API_URLS.GET_USER_INFO, {
        params: { userId },
        headers: {
          'Accept': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });

      if (typeof userResponse.data === 'object' && userResponse.data.info) {
        console.log("Received user data:", userResponse.data.info);
        setUserData(userResponse.data.info);
        updateUser(userResponse.data.info);
        
        // Fetch game data after updating user data
        await getUserCurrentStageInfo(userId);
      } else {
        throw new Error("Unexpected response structure from backend for user data");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data: ' + (error.response?.data?.detail || error.message));
    } finally {
      setLoading(false);
      console.log("Finished fetching data");
    }
  }, [userId, updateUser, getUserCurrentStageInfo]);

  useEffect(() => {
    fetchUserAndGameData();
  }, [fetchUserAndGameData]);

  const handleRefresh = useCallback(() => {
    console.log("Refresh called in PumpingPage");
    fetchUserAndGameData();
  }, [fetchUserAndGameData]);

  const handleTaskClick = (task) => {
    console.log("Task clicked:", task);
    setSelectedTask(task);
  };

  const handleCloseModal = useCallback(() => {
    console.log("Closing modal and refreshing data");
    setSelectedTask(null);
    handleRefresh();
  }, [handleRefresh]);

  const claimLvl = async () => {
    try {
      const requestData = {
        userId: user.user_id
      };

      const response = await axios.post(API_URLS.NEXT_RANK, requestData, {
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });

      console.log(response.data);

      if (response.data.result === "moved to next rank") {
        console.log("Successfully moved to the next rank!");
        await fetchUserAndGameData(); // Refresh all data after successful level up
      } else if (response.data.result === "You must be at last stage to move on the next rank") {
        console.log("Cannot move to next rank yet. You must be at the last stage.");
        // Show an informational message to the user here
      } else if (response.data.result === "You don't have enough money to move on the next rank") {
        console.log("You don't have enough money to move on the next rank.");
        // Show an informational message to the user here
      }
    } catch (error) {
      console.error('Error claiming next level:', error);
      setError('Error claiming next level: ' + error.message);
    }
  };

  if (error) return <div>Error: {error}</div>;
  if (loading || !userData || !gameData) return <div className='upgrade-game-sectopn-line'>
    <div className="upgrade-game-progress-section-page" style={backgroundStyle}>Loading...</div>
  </div>;

  return (
    <div className='upgrade-game-sectopn-line'>
      <div className="upgrade-game-progress-section-page" style={backgroundStyle}>
        {showRoadProgress ? (
          <UpgradeGameRoadProgress
            gameData={gameData}
            onRefresh={handleRefresh}
          />
        ) : (
          <UpgradeGameProgress
          gameData={gameData}
          stage={userData.stage}
          onTaskClick={handleTaskClick}
          onRefresh={fetchUserAndGameData}
          />
        )}
      </div>
      {selectedTask && (
        <UpgradeGameModal
          task={selectedTask}
          onClose={handleCloseModal}
          onRefresh={handleRefresh}
          userStage={userData.stage}
          refLink={preloadedData.refLink}
        />
      )}
      
    </div>
  );
};

export default PumpingPage;