import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import "../../assets/styles/games/game_core3.css";
import { API_URLS } from "../../config/config";
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg"
import { useUser } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import GameIconLL from "../../assets/images/Games/ui_graph_person.jpg"
import TopRightImage from "../../assets/icons/gnom.webp"

const styles = {
    gameWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        boxSizing: 'border-box',
    },
    gameContainer: {
        width: '100%',
        maxWidth: '500px',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    gameHeader: {
        padding: '15px',
        backgroundColor: '#4a90e2',
        color: 'white',
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    canvas: {
        width: '100%',
        height: 'auto',
        maxHeight: '300px',
    },
    controlsContainer: {
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    statusText: {
        margin: '5px 0',
        fontSize: '14px',
        color: 'white',
    },
    finalMessage: {
        color: '#e74c3c',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '5px',
        color: 'white',
        fontSize: '18px',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        outline: 'none',
    },
    whiteText: {
        color: 'white',
    },
};

const CoreGameThree = ({ tgUserId }) => {
    const [columns, setColumns] = useState(6);
    const [rows, setRows] = useState(6);
    const [currentValue, setCurrentValue] = useState(1);
    const [isRunning, setIsRunning] = useState(false);
    const [growthProgress, setGrowthProgress] = useState(0);
    const [showFinalMessage, setShowFinalMessage] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [gameStatus, setGameStatus] = useState('wait');
    const [waitTime, setWaitTime] = useState(0);
    const [sessionId, setSessionId] = useState(null);
    const [error, setError] = useState(null);
    const [betMessage, setBetMessage] = useState('');
    const [pickupMessage, setPickupMessage] = useState('');
    const [endPosition, setEndPosition] = useState({ x: 0, y: 0 });
    const [hasPickedUpBet, setHasPickedUpBet] = useState(false);
    const [fallProgress, setFallProgress] = useState(0);
    const [isGrowthComplete, setIsGrowthComplete] = useState(false);
    const [betAmount, setBetAmount] = useState('');
    const [betId, setBetId] = useState(null);
    const [finalValue, setFinalValue] = useState(null);
    const [betPlaced, setBetPlaced] = useState(false);
    const canvasRef = useRef(null);
    const wsRef = useRef(null);
    const imageRef = useRef(null);
    const topRightImageRef = useRef(null);
    const betMessageRef = useRef('');
    const { user, updateUser } = useUser();
    const [localBalance, setLocalBalance] = useState(user.g_token);
    const { t } = useTranslation();

    const maxCoefficient = 6;
    const minCoefficient = 0;

    const updateLocalBalance = useCallback((amount) => {
        setLocalBalance(prevBalance => {
            const newBalance = parseFloat(prevBalance) + parseFloat(amount);
            updateUser({ ...user, g_token: newBalance.toString() });
            return newBalance.toString();
        });
    }, [user, updateUser]);

    useEffect(() => {
        const img = new Image();
        img.src = GameIconLL;
        img.onload = () => {
            imageRef.current = img;
        };

        const topRightImg = new Image();
        topRightImg.src = TopRightImage;
        topRightImg.onload = () => {
            topRightImageRef.current = topRightImg;
        };
    }, []);

    const handleBetAmountChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            const parts = value.split('.');
            if (parts[1] && parts[1].length > 5) {
                return;
            }
            if (value.length <= 6) {
                setBetAmount(value);
            }
        }
    };

    const resetGameState = useCallback(() => {
        setIsRunning(false);
        setShowFinalMessage(false);
        setCurrentValue(1);
        setGrowthProgress(0);
        setIsGrowthComplete(false);
        setFallProgress(0);
        setHasPickedUpBet(false);
        setBetId(null);
        setError(null);
        setBetPlaced(false);
        betMessageRef.current = '';
        setBetMessage('');
    }, []);

    const connectWebSocket = useCallback(() => {
        try {
            const wsUrl = "wss://" + API_URLS.WEBS_GAME3_CONNECT;

            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                return;
            }

            wsRef.current = new WebSocket(wsUrl);

            const timeoutId = setTimeout(() => {
                if (wsRef.current && wsRef.current.readyState === WebSocket.CONNECTING) {
                    wsRef.current.close();
                    setIsConnected(false);
                    setError(t('GamePage.Game3.ConnectError'));
                }
            }, 10000);

            wsRef.current.onopen = (event) => {
                clearTimeout(timeoutId);
                setIsConnected(true);
                setError(null);
            };

            wsRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);

                switch (data.status) {
                    case 'wait':
                        setGameStatus('wait');
                        setWaitTime(data.second);
                        setSessionId(data.session_id);
                        setPickupMessage('');
                        setFinalValue(null);
                        resetGameState();
                        break;
                    case 'sterted':
                        setGameStatus('started');
                        setCurrentValue(data.startnumber || 1);
                        setGrowthProgress(0);
                        setIsRunning(true);
                        setShowFinalMessage(false);
                        if (betPlaced) {
                            setBetMessage(betMessageRef.current);
                        }
                        break;
                    case 'prosed':
                        setGameStatus('prosed');
                        setCurrentValue(data.current);
                        setGrowthProgress(Math.min(data.current / maxCoefficient, 1));
                        if (betPlaced) {
                            setBetMessage(betMessageRef.current);
                        }
                        break;
                    case 'ended':
                        setGameStatus('ended');
                        setIsRunning(false);
                        setCurrentValue(data.number);
                        setFinalValue(data.number);
                        setShowFinalMessage(true);
                        setIsGrowthComplete(true);
                        setBetMessage('');
                        betMessageRef.current = '';
                        break;
                    default:
                }
            };

            wsRef.current.onclose = (event) => {
                clearTimeout(timeoutId);
                setIsConnected(false);
                setTimeout(connectWebSocket, 5000);
            };

            wsRef.current.onerror = (error) => {
                clearTimeout(timeoutId);
                setError(t('GamePage.Game3.TempError'));
            };
        } catch (error) {
            setError(t('GamePage.Game3.TempError'));
        }
    }, [resetGameState, t, maxCoefficient]);

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [connectWebSocket]);

    useEffect(() => {
        if (isGrowthComplete) {
            const fallInterval = setInterval(() => {
                setFallProgress(prev => {
                    const newValue = prev + 0.02;
                    if (newValue >= 1) {
                        clearInterval(fallInterval);
                        setShowFinalMessage(true);
                        return 1;
                    }
                    return newValue;
                });
            }, 20);

            return () => clearInterval(fallInterval);
        }
    }, [isGrowthComplete]);

    useEffect(() => {
        const drawGraph = () => {
            const canvas = canvasRef.current;
            if (!canvas) return;

            const ctx = canvas.getContext('2d');
            const width = canvas.width;
            const height = canvas.height;

            const leftPadding = 30;
            const bottomPadding = 30;
            const rightPadding = 13;
            const topPadding = 50;

            const gridWidth = width - leftPadding - rightPadding;
            const gridHeight = height - topPadding - bottomPadding;

            ctx.clearRect(0, 0, width, height);

            ctx.strokeStyle = '#e0e0e0';
            ctx.lineWidth = 1;

            const cellWidth = gridWidth / columns;
            const cellHeight = gridHeight / rows;

            for (let i = 0; i <= columns; i++) {
                const x = leftPadding + i * cellWidth;
                ctx.beginPath();
                ctx.moveTo(x, topPadding);
                ctx.lineTo(x, height - bottomPadding);
                ctx.stroke();
            }

            for (let i = 0; i <= rows; i++) {
                const y = topPadding + i * cellHeight;
                ctx.beginPath();
                ctx.moveTo(leftPadding, y);
                ctx.lineTo(width - rightPadding, y);
                ctx.stroke();
            }

            ctx.strokeStyle = '#4a90e2';
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.moveTo(leftPadding, height - bottomPadding);

            const curveFunction = (x) => {
                const progress = x / gridWidth;
                const scaledValue = (currentValue - minCoefficient) / (maxCoefficient - minCoefficient);
                const y = Math.pow(progress, 0.3) * scaledValue * gridHeight;
                return height - bottomPadding - y;
            };

            const maxX = gridWidth * growthProgress;

            for (let x = 0; x <= maxX; x += 2) {
                const y = curveFunction(x);
                ctx.lineTo(leftPadding + x, y);
            }

            ctx.stroke();

            // Always draw the character image
            if (imageRef.current) {
                const imgWidth = 30;
                const imgHeight = 30;
                let endX, endY;

                if (growthProgress > 0) {
                    endX = leftPadding + maxX;
                    endY = curveFunction(maxX);
                } else {
                    // If the game hasn't started, position the character at the start
                    endX = leftPadding;
                    endY = height - bottomPadding;
                }

                setEndPosition({ x: endX, y: endY });

                const angle = growthProgress > 0
                    ? Math.atan2(endY - (height - bottomPadding), endX - leftPadding) - Math.PI / 2
                    : 0;

                const fallDistance = 70;
                const horizontalShift = 12;

                const fallOffsetY = fallProgress * fallDistance;
                const fallOffsetX = Math.sin(fallProgress * Math.PI / 2) * horizontalShift;

                const rotationAngle = angle + (fallProgress * Math.PI / 4);

                ctx.save();
                ctx.translate(endX + fallOffsetX, endY + fallOffsetY);
                ctx.rotate(rotationAngle);
                ctx.rotate(Math.PI * 0.4);
                ctx.drawImage(imageRef.current, -imgWidth / 2, -imgHeight / 1.10, imgWidth, imgHeight);
                ctx.restore();
            }

            ctx.font = 'bold 24px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'top';
            const textX = width / 2;
            const textY = 10;

            if (isGrowthComplete) {
                ctx.fillStyle = 'red';
            } else {
                ctx.fillStyle = 'green';
            }

            ctx.fillText(`${currentValue.toFixed(2)}x`, textX, textY);

            ctx.fillStyle = 'white';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'top';
            for (let i = 0; i <= columns; i++) {
                const value = `${i}.0X`;
                const x = leftPadding + i * cellWidth;
                ctx.fillText(value, x, height - bottomPadding + 5);
            }
            ctx.textAlign = 'right';
            ctx.textBaseline = 'middle';
            for (let i = 0; i <= rows; i++) {
                const value = rows - i;
                const y = topPadding + i * cellHeight;
                if (i === 6) {
                    ctx.fillStyle = 'rgba(255, 255, 255, 0)';
                } else {
                    ctx.fillStyle = 'white';
                }
                ctx.fillText(`${value}.0X`, leftPadding - 5, y);
            }

            // Draw top-right image
            if (topRightImageRef.current) {
                const imgWidth = 30;
                const imgHeight = 40;
                const cellX = width - rightPadding - cellWidth;
                const cellY = topPadding;
                const x = cellX + (cellWidth - imgWidth) / 2;
                const y = cellY + (cellHeight - imgHeight) / 2;
                ctx.drawImage(topRightImageRef.current, x, y, imgWidth, imgHeight);
            }
        };

        drawGraph();
    }, [columns, rows, currentValue, growthProgress, fallProgress, isGrowthComplete, minCoefficient, maxCoefficient]);

    const startAnimation = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ action: 'start_game' }));
        } else {
            setError(t('GamePage.Game3.WebSError'));
        }
    };

    const handleGameAction = async () => {
        if (!isConnected) {
            setError(t('GamePage.Game3.Error'));
            return;
        }

        if (gameStatus === 'wait') {
            if (!betAmount || parseFloat(betAmount) <= 0) {
                setError(t('GamePage.Game3.Error'));
                return;
            }

            try {
                const response = await axios.post(API_URLS.REACT_APP_GAME3_MAKEBET, {
                    tg_user_id: user.user_id,
                    bet: parseFloat(betAmount),
                    session_id: sessionId
                });

                if (response.data.status === 'done') {
                    localStorage.setItem('gameBetId', response.data.bet_id);
                    setError(null);
                    const message = t('GamePage.Game3.BetConfirmation', { amount: betAmount });
                    setBetMessage(message);
                    betMessageRef.current = message;
                    setPickupMessage('');
                    updateLocalBalance(-parseFloat(betAmount));
                    setBetPlaced(true);
                    startAnimation();
                } else {
                    setError(t('GamePage.Game3.ErrorBet'));
                }
            } catch (error) {
                setError(t('GamePage.Game3.ErrorBet'));
            }
        } else if (gameStatus === 'started' || gameStatus === 'prosed') {
            const storedBetId = localStorage.getItem('gameBetId');
            if (storedBetId) {
                try {
                    const response = await axios.post(API_URLS.REACT_APP_GAME3_TAKEBET, {
                        bet_id: storedBetId
                    });
                    if (response.data.status === 'ok') {
                        localStorage.removeItem('gameBetId');
                        setHasPickedUpBet(true);
                        const winAmount = parseFloat(response.data.win);
                        setPickupMessage(`Вітаємо! Ви забрали ${winAmount} токенів при ${currentValue.toFixed(2)}x.`);
                        setBetMessage('');
                        betMessageRef.current = '';
                        setError(null);
                        updateLocalBalance(winAmount);
                    } else {
                        setError(t('GamePage.Game3.ErrorTakeBet'));
                    }
                } catch (error) {
                    setError(t('GamePage.Game3.ErrorTakeBet'));
                }
            } else {
                setError(t('GamePage.Game3.ErrorTakeBet'));
            }
        }
    };

    useEffect(() => {
        if (gameStatus === 'ended' && !hasPickedUpBet) {
            setBetMessage('');
            betMessageRef.current = '';
            setPickupMessage(t('GamePage.Game3.SorryBro'));
        }
    }, [gameStatus, hasPickedUpBet, t]);

    return (
        <div style={styles.gameWrapper}>
            <div style={styles.gameContainer}>
                <canvas ref={canvasRef} width={350} height={280} style={styles.canvas} />
                <div style={styles.controlsContainer}>
                    {showFinalMessage && finalValue && (
                        <p style={{ ...styles.statusText, ...styles.finalMessage }}>
                            {t('GamePage.Game3.GameEnd')}: {finalValue.toFixed(2)}x
                        </p>
                    )}
                    {error && <p style={{ ...styles.statusText, color: 'red' }}>{error}</p>}
                    {betMessage && <p style={{ ...styles.statusText, color: 'green' }}>{betMessage}</p>}
                    {pickupMessage && <p style={{ ...styles.statusText, color: 'green' }}>{pickupMessage}</p>}
                </div>
            </div>
            <div className="game-buttom-list">
                <button
                    className="game-button"
                    onClick={handleGameAction}
                    disabled={!isConnected || gameStatus === 'ended' || (betId && gameStatus !== 'started' && gameStatus !== 'prosed')}
                >
                    {!isConnected
                        ? t('GamePage.Game3.Connect')
                        : gameStatus === 'wait'
                            ? `${t('GamePage.Game3.StartGame')} (${waitTime.toFixed(1)}s)`
                            : (gameStatus === 'started' || gameStatus === 'prosed') && !hasPickedUpBet
                                ? t('GamePage.Game3.Takethebet')
                                : t('GamePage.Game3.Inprogress')}
                </button>
                <div className="earning-game">
                    <div className="earning-content-game">
                        <div className="earning-icon-container-game">
                            <IconCoin className="earning-icon-small-game" />
                            <p className="token-game" style={styles.whiteText}>Token</p>
                        </div>
                        <div className="earning-text-container-game">
                            <p className="earning-text-game" style={styles.whiteText}>{localBalance}</p>
                        </div>
                    </div>
                </div>
                <div className="earning-game">
                    <div className="earning-content-game">
                        <div className="earning-icon-container-game1">
                            <IconCoin className="earning-icon-small-game"/>
                        </div>
                        <div className="earning-content-game">
                            <div className="earning-text-container-game">
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    value={betAmount}
                                    onChange={handleBetAmountChange}
                                    placeholder="0.001"
                                    style={styles.input}
                                    disabled={gameStatus !== 'wait'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoreGameThree;