import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AdModal from "../components/AdModal";
import AddAdsetModal from "../components/AddAdsetModal";
import { useUser } from "../contexts/UserContext";
import { API_URLS } from "../config/config";
import axios from "axios";
import "../assets/styles/adOffice.css";

const CustomSelect = ({ options, onSelect, icon, isSmall }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);
  const optionsRef = useRef(null);

  const toggleSelect = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (optionsRef.current) {
      if (isOpen) {
        const height = optionsRef.current.scrollHeight;
        optionsRef.current.style.maxHeight = `${height}px`;
      } else {
        optionsRef.current.style.maxHeight = "0px";
      }
    }
  }, [isOpen]);

  return (
    <div
      className={`custom-select-wrapper ${isOpen ? "open" : ""} ${
        isSmall ? "small" : ""
      }`}
      ref={selectRef}
    >
      <div className="custom-select" onClick={toggleSelect}>
        <span>{selectedOption}</span>
        <img
          src={icon}
          alt="arrow"
          className={`w-3 ${isOpen ? "rotated" : ""}`}
        />
      </div>
      <div className="custom-options" ref={optionsRef}>
        {options
          .filter((option) => option !== selectedOption)
          .map((option, index) => (
            <div
              key={index}
              className="custom-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
      </div>
    </div>
  );
};

const CustomRegionSelect = ({ options, onSelect, icon, isSmall }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);
  const optionsRef = useRef(null);

  const toggleSelect = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (optionsRef.current) {
      if (isOpen) {
        const height = optionsRef.current.scrollHeight;
        optionsRef.current.style.maxHeight = `${height}px`;
      } else {
        optionsRef.current.style.maxHeight = "0px";
      }
    }
  }, [isOpen]);
  return (
    <div
      className={`custom-select-wrapper ${isOpen ? "open" : ""} ${
        isSmall ? "small" : ""
      }`}
      ref={selectRef}
    >
      <div className="custom-select" onClick={toggleSelect}>
        {/* <span>{selectedOption.display}</span> */}
        <span className="custom-option">
        <p className="w-5/6 flex justify-start">
              {selectedOption.name}
              </p>

<div className="w-1/6 flex justify-start flex-row"> 
              <p className="mr-1">|</p>
              <p>{selectedOption.code}</p>
              </div>
        </span>
        <img
          src={icon}
          alt="arrow"
        />
      </div>
      <div className="custom-options" ref={optionsRef}>
        {options
          .filter((option) => option.value !== selectedOption.value)
          .map((option, index) => (
            <div
              key={index}
              className="custom-option"
              onClick={() => handleOptionClick(option)}
            >
           
              <p className="w-5/6 flex justify-start">
              {option.name}
              </p>

<div className="w-1/6 flex justify-start flex-row"> 
              <p className="mr-1">|</p>
              <p>{option.code}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};


const AdOffice = ({ preloadedData }) => {
  const { t } = useTranslation();
  const adOptions = [
    t("AdOfficePage.AdOptions.Banner"),
    t("AdOfficePage.AdOptions.FairyGift"),
  ];

  const statisticsOptions = [
    t("AdOfficePage.StatisticsOptions.AllTime"),
    t("AdOfficePage.StatisticsOptions.Month"),
    t("AdOfficePage.StatisticsOptions.Week"),
    t("AdOfficePage.StatisticsOptions.Day"),
  ];
  const regionOptions = Object.entries(t('AdOfficePage.Regions', { returnObjects: true })).map(([key, value]) => ({
    value: key,
    name:value.Name,
    code:value.Code
  }));
  const tempСountries = ["FFF", "KKK"];
  const [selectedAdOption, setSelectedAdOption] = useState(adOptions[0]);
  const [region, setRegion] = useState(0);
  const [country, setCountry] = useState(0);
  const [statistics, setStatistics] = useState(0);
  const [adSet, setAdSet] = useState(0);
  const [adSize, setAdSize] = useState("182х50 px");
  const [days, setDays] = useState(6);
  const [amountPerDay, setAmountPerDay] = useState(1);
  const [startY, setStartY] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddAdsetModalOpen, setIsAddAdsetModalOpen]=useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const sliderRef = useRef(null);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [openAccordions, setOpenAccordions] = useState({});
  const { user } = useUser();

  const ads = [
    {
      Name: "Banner Ad 1",
      NumberOfTransitions: 100,
      AdvertisementDuration: "5 days",
      status: "success",
      type: "fairy",
    },
    {
      Name: "Gift Ad",
      NumberOfTransitions: 150,
      AdvertisementDuration: "7 days",
      status: "fail",
      type: "gift",
    },
    {
      Name: "Fake Ad",
      NumberOfTransitions: 80,
      AdvertisementDuration: "3 days",
      status: "inProgress",
      type: "baner",
    },
  ];


  const updateLastActive = async (userId) => {
    try {
      const response = await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
      console.log('Last active time updated:', response.data);
    } catch (error) {
      console.error('Error updating last active time:', error);
    }
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]); 

  useEffect(() => {
    setAmountPerDay(1);
    console.log(preloadedData.regions);
  }, []);

  const toggleAccordion = (index) => {
    setOpenAccordions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const openModal = (ad) => {
    setSelectedAd(ad);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAd(null);
  };

  const openAddAdsetModal = () => {
    setIsAddAdsetModalOpen(true);
  };

  const closenAddAdsetModal = () => {
    setIsAddAdsetModalOpen(false);
  };

  const handleSelectChange = (option) => {
    setSelectedAdOption(option);

    switch (option) {
      case t("AdOfficePage.AdOptions.FairyGift"):
        setAdSize("399х736 px");
        break;
      case t("AdOfficePage.AdOptions.Banner"):
      default:
        setAdSize("182х50 px");
    }
  };

  // const handleRegionChange = (option) => {
  //   setRegion(option);
  // };

  // const handleCountryChange = (option) => {
  //   setCountry(option);
  // };

  const handleAdsetChange = (option) => {
    setAdSet(option);
  };

  const handleStatisticsChange = (option) => {
    setStatistics(option);
  };

  const updateSliderBackground = (value) => {
    if (sliderRef.current) {
      const percentage = ((value - 1) / (31 - 1)) * 100;
      sliderRef.current.style.setProperty(
        "--slider-percentage",
        `${percentage}%`
      );
    }
  };

  useEffect(() => {
    updateSliderBackground(days);
  }, [days]);

  // const handleDaysChange = (e) => {
  //   const value = Number(e.target.value);
  //   setDays(value);
  //   updateSliderBackground(value);
  // };

  // const getDayText = (number) => {
  //   return t("AdOfficePage.DayText", { count: number });
  // };

  // const handleChange = useCallback((e) => {
  //   let newValue = e.target.value.replace(/[^0-9.]/g, "");
  //   if (newValue === "" || /^(\d*\.?\d*)$/.test(newValue)) {
  //     const parsedValue = parseFloat(newValue) || 0;
  //     setAmountPerDay(parsedValue);
  //   }
  // }, []);

  // const handleFocus = useCallback((e) => {
  //   const menuElement = document.querySelector(".menu");
  //   if (menuElement) {
  //     menuElement.classList.remove("menu");
  //     menuElement.classList.add("menu-hidden");
  //   }
  //   const tradeButton = document.querySelector(".trade-button");
  //   if (tradeButton) {
  //     tradeButton.classList.remove("trade-button");
  //     tradeButton.classList.add("trade-button-hidden");
  //   }
  //   setTimeout(() => {
  //     if (inputRef.current) {
  //       inputRef.current.focus();
  //     }
  //   }, 100);
  // }, []);

  // const handleBlur = () => {
  //   const menuElement = document.querySelector(".menu-hidden");
  //   if (menuElement) {
  //     menuElement.classList.remove("menu-hidden");
  //     menuElement.classList.add("menu");
  //   }
  //   const tradeButton = document.querySelector(".trade-button-hidden");
  //   if (tradeButton) {
  //     tradeButton.classList.remove("trade-button-hidden");
  //     tradeButton.classList.add("trade-button");
  //   }
  // };

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!startY) return;
    const currentY = e.touches[0].clientY;
    const diff = startY - currentY;
    e.currentTarget.scrollTop += diff;
    setStartY(currentY);
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log("Selected file:", event.target.files[0]);
  };

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setUploadStatus("Будь ласка, виберіть файл");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setUploadStatus("Завантаження...");
      const response = await axios.post(
        "https://d12b-77-47-219-139.ngrok-free.app/ads/file_upload_view/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Файл успішно завантажено:", response.data);
      setUploadStatus("Файл успішно завантажено");
    } catch (error) {
      console.error("Помилка завантаження файлу:", error);
      setUploadStatus("Помилка завантаження файлу");
    }
  };
  const renderAd = (ad, index) => {
    return (
      <li key={index} className="ad-item-container">
      <div className="flex flex-row justify-between w-full relative">
        <div className="adv-accordion w-3/6">
          <div className="adv-accordion-header" onClick={() => toggleAccordion(index)}>
            <span className="adv-accordion-title">{ad.Name}</span>
            <svg
              className={`adv-accordion-icon ${openAccordions[index] ? "rotate" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              color="white"
              stroke="currentColor"
              width="20"
              height="20"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </div>
        <div className="w-2/6 flex justify-end">
          <button className="adset-start-button" onClick={() => openModal(ad)}>
            {t("AdOfficePage.StartBtnText")}
          </button>
        </div>
      </div>
      {openAccordions[index] && (
        <div className="adv-accordion-content">
          <div className="adv-start-date-row">
            <p className="adv-start-date-text">09.03.3030</p>
          </div>
          <div className="flex flex-col w-full">
            <div className="adv-detail-row">
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">{t('AdOfficePage.AdName')}</p>
                <p className="adv-detail-ad-title-text">Adv 1</p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">{t('AdOfficePage.Status')}</p>
                <p className="adv-detail-ad-status">Успішна</p>
              </div>
              <div className="flex flex-col w-1/3">
                <p className="adv-detail-row-title">{t('AdOfficePage.Moderation')}</p>
                <p className="adv-detail-ad-status">Успішна</p>
              </div>
            </div>
          </div>
          <hr className="mt-2 mb-2"/>
          <div className="adv-detail-row">
            <div className="flex flex-col w-1/3">
              <p className="adv-detail-row-title2">{t('AdOfficePage.NumberOfTransitions')}</p>
              <p className="adv-detail-ad-title-text2">100</p>
            </div>
            <div className="flex flex-col w-1/3">
              <p className="adv-detail-row-title2">{t('AdOfficePage.AdvertisementDuration')}</p>
              <p className="adv-detail-ad-title-text2">5 днів</p>
            </div>
            <div className="flex flex-col w-1/3">
            </div>
          </div>
        </div>
      )}
    </li>
    );
  };

  return (
    <div
      id="ad"
      style={{ backgroundImage: `url(${preloadedData.background})` }}
    >
      <AdModal
        isOpen={isModalOpen}
        onClose={closeModal}
        background={preloadedData.modalBackground}
        ad={selectedAd}
        iconArrowLeft={preloadedData.iconArrowLeft}
        iconCoin={preloadedData.iconCoin}
        iconModerationSuccess={preloadedData.iconModerationSuccess}
        iconModerationInProgress={preloadedData.iconModerationInProgress}
        iconModerationFail={preloadedData.iconModerationFail}
      />
      <AddAdsetModal
       isOpen={isAddAdsetModalOpen}
       onClose={closenAddAdsetModal}
       background={preloadedData.modalBackground}
       iconArrowLeft={preloadedData.iconArrowLeft}
       iconCoin={preloadedData.iconCoin}
       iconArrow={preloadedData.iconArrow}
       />
      <div className="scrolable-adoffice-content">
        <div className="h-14 flex items-center justify-center">
          <p className="title">{t("AdOfficePage.Title")}</p>
        </div>
        <div className="ad-office-col">
          <div className="w-full">
            <p className="ad-page-label mb-1">
              {t("AdOfficePage.ChooseAdTypeText")}
            </p>
          </div>
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex items-center justify-center flex-col w-2/4">
              <CustomSelect
                options={adOptions}
                onSelect={handleSelectChange}
                icon={preloadedData.iconArrow}
                isSmall={false}
              />
            </div>
            <div className="flex items-center justify-between flex-row w-2/6">
              <p className="ad-size">{t("AdOfficePage.Size")}</p>
              <p className="ad-size">{adSize}</p>
            </div>
          </div>
        </div>
        <div className="ad-office-col">
          <div className="flex flex-row w-full justify-between items-center">
            {/* <div className="flex items-center justify-center flex-col w-3/5">
              <p className="ad-page-label mb-1">
                {t("AdOfficePage.ChooseAdRegionText")}
              </p>
              <CustomRegionSelect
                options={regionOptions}
                onSelect={handleRegionChange}
                icon={preloadedData.iconArrow}
                isSmall={false}
              />
            </div> */}
            {/* WHILL BE ADD SOON */}
            {/* <div className="flex items-center justify-center flex-col w-2/5">
            <p className="ad-page-label mb-1">
              {t("AdOfficePage.ChooseAdCountryText")}
            </p>
            <CustomSelect
              options={tempСountries}
              onSelect={handleCountryChange}
              icon={preloadedData.iconArrow}
              isSmall={false}
            />
          </div> */}
          </div>
        </div>
        <div className="ad-office-col">
          <div className="flex flex-row w-full justify-start items-end">
            <div className="flex items-center justify-center flex-col w-2/4">
              <p className="ad-page-label mb-1">
                {t("AdOfficePage.ChooseAdSetText")}
              </p>
              <CustomSelect
                options={adOptions}
                onSelect={handleAdsetChange}
                icon={preloadedData.iconArrow}
                isSmall={false}
              />
            </div>
            <div className="flex items-center justify-end flex-col ml-2">
              <button className="add-adset-button" onClick={openAddAdsetModal}>+</button>
            </div>
          </div>
        </div>
        <div className="ad-office-col">
          <div className="w-full">
            <p className="ad-page-label mb-1">{t("AdOfficePage.ChooseAd")}</p>
          </div>
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex items-center justify-center flex-col w-14">
              <button className="choose-file-button" onClick={openFilePicker}>
                <img
                  src={preloadedData.iconFile}
                  alt="file"
                  className="icon-file"
                />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/*,video/*"
                style={{ display: "none" }}
              />
            </div>
            <div className="flex items-end justify-end flex-col w-full">
              <div className="choose-file-input-container">
                <input
                  className="choose-file-input"
                  placeholder={t("AdOfficePage.ChooseAdInputPlaceholder")}
                  value={selectedFile ? selectedFile.name : ""}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="ad-office-col">
          <div className="day-range-slider w-full">
            <div className="slider-labels flex justify-between">
              <span className="days-max-min">
                1 {t("AdOfficePage.DayText_one")}
              </span>
              <span className="days-value">
                {days} {getDayText(days)}
              </span>
              <span className="days-max-min">
                31 {t("AdOfficePage.DayText_one")}
              </span>
            </div>
            <div className="days-slider-container">
              <input
                type="range"
                min="1"
                max="31"
                value={days}
                className="slider"
                ref={sliderRef}
                onChange={handleDaysChange}
              />
            </div>
          </div>
        </div> */}
        {/* <div className="ad-office-col">
          <div className="w-full">
            <p className="ad-page-label mb-1">
              {t("AdOfficePage.AmountPerDayText")}
            </p>
          </div>
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex items-start justify-center flex-col  w-2/3">
              <div className="amount-item">
                <div className="amount-item-content">
                  <div className="amount-item-icon-container">
                    <img
                      src={preloadedData.iconCoin}
                      className="amount-icon-gtoken"
                      alt="gtoken"
                    />
                    <p className="token">Token</p>
                  </div>
                  <div className="amount-item-text-container">
                    <input
                      ref={inputRef}
                      type="text"
                      min={1}
                      value={amountPerDay}
                      inputMode="decimal"
                      pattern="[0-9]*"
                      onChange={handleChange}
                      className="amount-input"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-2/3">
              <button className="ad-start-button" onClick={handleSubmit}>
                {t("AdOfficePage.AddAdButtonText")}
              </button>
              {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
            </div>
          </div>
        </div> */}

<div className="ad-office-col">
<button className="ad-start-button" onClick={handleSubmit}>
                {t("AdOfficePage.AddAdButtonText")}
              </button>
  </div>

       
        <div className="ad-office-col">
          <div className="w-full flex justify-start">
            <div className="flex flex-row justify-start items-center w-2/4">
              <p className="ad-page-label mb-1">
                {t("AdOfficePage.ShowForText")}
              </p>
              <CustomSelect
                options={statisticsOptions}
                onSelect={handleStatisticsChange}
                icon={preloadedData.iconArrow}
                isSmall={true}
              />
            </div>
          </div>
        </div>

        <div className="ad-office-col">
          <div
            className="ads-office-list"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <ul>{ads.map((ad, index) => renderAd(ad, index))}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdOffice;
