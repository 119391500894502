import Slider from "react-slick";
import React, { useEffect, useState, useRef, useCallback } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import "../assets/styles/games.css";
import { API_URLS } from "../config/config";
import { useAudio } from "../contexts/AudioContext";
import axios from "axios";
import GameHelpModal from "../components/games/GameHelpModal";
import CoreGameOne from "./games/core_game1";
import CoreGameTwo from "./games/core_game2";
import CoreGameThree from "./games/core_game3";
import CoreGameFour from "./games/core_game4";
import CoreGameFive from "./games/core_game5";
import { useUser } from "../contexts/UserContext";
import lockIcon from "../assets/images/Games/gamelock.webp";
import clickSoundMp3 from '../assets/sounds/menu-button-click.mp3';
import GamesAudioManager from '../components/games/GamesAudioMeneger';
const Games = ({ preloadedData }) => {
    const { user } = useUser();
    const { t } = useTranslation();
    const [gamesData, setGamesData] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
    const [currentGame, setCurrentGame] = useState(null);
    const sliderRef = useRef(null);
    const activeLevelIndex = user.rank.id;
    const { effectsVolume, generalVolume } = useAudio();
    const [isAudioInitialized, setIsAudioInitialized] = useState(false);
    const audioContextRef = useRef(null);
    const clickAudioBufferRef = useRef(null);


    useEffect(() => {
        const initializeAudio = async () => {
          if (!isAudioInitialized) {
            try {
              const AudioContext = window.AudioContext || window.webkitAudioContext;
              audioContextRef.current = new AudioContext();
    
              const response = await fetch(clickSoundMp3);
              const arrayBuffer = await response.arrayBuffer();
              clickAudioBufferRef.current = await audioContextRef.current.decodeAudioData(arrayBuffer);
    
              setIsAudioInitialized(true);
            } catch (error) {
              console.error("Error initializing audio:", error);
            }
          }
        };
    
        initializeAudio();
      }, [isAudioInitialized]);
    
      const playSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = clickAudioBufferRef.current;
          
          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
          
          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);
          
          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_URLS.GET_EXECUTE_GAMELIST, {
                    headers: {
                        Accept: "application/json",
                        "ngrok-skip-browser-warning": "true",
                    },
                });
                if (response.data && response.data.levels) {
                    const sortedGames = response.data.levels.sort((a, b) => a.level - b.level);
                    setGamesData(sortedGames);
                }
            } catch (error) {
                console.error("Error fetching games data:", error);
            }
        };

        fetchData();
    }, []);

    const handleGameClick = (level, game) => {
        // if (level <= activeLevelIndex) {
        //     setSelectedLevel(level);
        //     setCurrentGame(game);
        // }
        playSound();
        setSelectedLevel(level);
        console.log(level);
        setCurrentGame(game);
    };

    const renderGameElement = () => {
        switch(selectedLevel) {
            case 1: return <CoreGameOne />;
            case 2: return <CoreGameTwo />;
            case 3: return <CoreGameThree />;
            case 4: return <CoreGameFour />;
            case 5: return <CoreGameFive />;
            default: return null;
        }
    };

    const openHelpModal = () => {
        playSound();
        setIsHelpModalOpen(true);
    };

    const closeHelpModal = () => {
        setIsHelpModalOpen(false);
    };

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
    };
    const getGameRules = (level) => {
        return t(`GamePage.Game${level}.GameRules`, '');
    };

    const getLevelImage = (level) => {
        return t(`LevelsPage.Level${level}Image`, '');
    };

    return (
        <div id="games" style={{ backgroundImage: `url(${preloadedData.background})` }}>
        {selectedLevel !== null && <GamesAudioManager selectedLevel={selectedLevel} />}
            <div className="gameMainCont">
                <p className="title-game">{t('GamePage.MainPage')}</p>
                {selectedLevel && (
                    <button className="custom-button-game" onClick={openHelpModal}>
                        <p className="btn-text">{t('GamePage.RulesButton')}</p>
                    </button>
                )}
            </div>
            <div className="slider-container" style={{ width: '100%', padding: '0 20px' }}>
                {gamesData.length > 0 ? (
                    <Slider ref={sliderRef} {...settings} className="button-manager">
                        {gamesData.map((game, index) => (
                            <div
                                key={index}
                                // className={`game-item ${game.level > activeLevelIndex ? 'locked' : ''}`}
                                className="game-item"
                                onClick={() => handleGameClick(game.level, game)}
                            >
                                {/* {game.level <= activeLevelIndex ? (
                               
                                    <div className="text-btn-selector">
                                        <p className="underline-text text-btn-maintext">{game.name}</p>
                                        <p className="text-btn-editor">{t('GamePage.Level')} {game.level}</p>
                                    </div>
                                ) : (
                                    <div className="lock-overlay">
                                        <div className="lock-content">
                                            <img src={lockIcon} alt="Locked" className="lock-icon" />
                                            <p className="text-btn-editor">{t('GamePage.Level')} {game.level}</p>
                                        </div>
                                    </div>
                                )} */}
                               <div className="text-btn-selector">
                                   <p className="underline-text text-btn-maintext">{game.name}</p>
                                   <p className="text-btn-editor">{t('GamePage.Level')} {game.level}</p>
                               </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div>No games available</div>
                )}
            </div>
            <div className="gameCore">
                {renderGameElement()}
            </div>
            <GameHelpModal
                isOpen={isHelpModalOpen}
                onClose={closeHelpModal}
                levelImageUrl={currentGame ? getLevelImage(currentGame.level) : ''}
                gameName={currentGame?.name}
                gameLevel={currentGame?.level}
                gameRules={currentGame ? getGameRules(currentGame.level) : ''}
            />
        </div>
    );
};

export default Games;