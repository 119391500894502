import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <h1>Помилка</h1>
      <p>Виникла несподівана помилка. Будь ласка, спробуйте пізніше.</p>
    </div>
  );
};

export default ErrorPage;