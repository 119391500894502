    import React, { useState, useEffect, useRef, useCallback } from "react";
    import { useTranslation } from "react-i18next";
    import { useAudio } from "../contexts/AudioContext";
    import Creative from "../components/Creative";
    import "../assets/styles/copmonents/Modal/adModal.css";
    import clickSoundMp3 from '../assets/sounds/menu-button-click.mp3';

    const AdModal = ({
      isOpen,
      onClose,
      background,
      ad,
      isVideo,
      filePath,
      fullFilePath,
      adType,
      iconArrowLeft,
      iconCoin,
      iconModerationSuccess,
      iconModerationInProgress,
      iconModerationFail,
      onWatchCreative
    }) => {
      const { t } = useTranslation();
      const [isActive, setIsActive] = useState(false);
      const [localAd, setLocalAd] = useState(null);
      const [isCreativeModalOpen, setIsCreativeModalOpen] = useState(false);
    const [selectedCreative, setSelectedCreative] = useState(null);
      const [isClosing, setIsClosing] = useState(false);
      const modalRef = useRef(null);
      const { effectsVolume, generalVolume } = useAudio();
      const [isAudioInitialized, setIsAudioInitialized] = useState(false);
      const audioContextRef = useRef(null);
      const clickAudioBufferRef = useRef(null);

      useEffect(() => {
        if (isOpen) {
          setIsActive(true);
          setIsClosing(false);
          setLocalAd(ad);
         console.log("full", adType);
        } else {
          setIsClosing(true);
          const timer = setTimeout(() => {
            setIsActive(false);
            setLocalAd(null);
            setIsClosing(false);
          }, 300); // Animation duration in ms
          return () => clearTimeout(timer);
        }
      }, [isOpen, ad]);

      useEffect(() => {
        const initializeAudio = async () => {
          if (!isAudioInitialized) {
            try {
              const AudioContext = window.AudioContext || window.webkitAudioContext;
              audioContextRef.current = new AudioContext();

              const response = await fetch(clickSoundMp3);
              const arrayBuffer = await response.arrayBuffer();
              clickAudioBufferRef.current = await audioContextRef.current.decodeAudioData(arrayBuffer);

              setIsAudioInitialized(true);
            } catch (error) {
              console.error("Error initializing audio:", error);
            }
          }
        };

        initializeAudio();
      }, [isAudioInitialized]);

      const playSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = clickAudioBufferRef.current;
          
          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
          
          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);
          
          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

      const closeModal = () => {
        setIsClosing(true);
        setTimeout(() => {
          setIsClosing(false);
          onClose();
        }, 300);
      };

      const handleCloseButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        playSound();
        closeModal();
      };

      const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
          playSound();
          closeModal();
        }
      };
      const handleWatchCreative = () => {
        playSound();
        setIsCreativeModalOpen(true);
      };
    
      const closeCreativeModal = () => {
        setIsCreativeModalOpen(false);
      };
    

      if (!isOpen && !isActive) return null;

      const getModerationStatus = () => {
        if (!localAd) return { text: "", className: "", icon: null };

        switch (localAd.is_verified) {
          case 3:
            return {
              text: t("AdOfficePage.AdModal.ModerationPassed"),
              className: "moderation-success",
              icon: iconModerationSuccess,
            };
          case 2:
            return {
              text: t("AdOfficePage.AdModal.ModerationInProgress"),
              className: "moderation-inprogress",
              icon: iconModerationInProgress,
            };
          case 1:
            return {
              text: t("AdOfficePage.AdModal.ModerationFailed"),
              className: "moderation-fail",
              icon: iconModerationFail,
            };
          default:
            return {
              text: "",
              className: "",
              icon: null,
            };
        }
      };

      const moderationStatus = getModerationStatus();

      return (
        <div
          className={`ad-modal-overlay ${isOpen ? 'active' : ''} ${isClosing ? "closing" : ""}`}
          onClick={handleOverlayClick}
        >
           {isCreativeModalOpen && (
        <Creative
          isOpen={isCreativeModalOpen}
          onClose={closeCreativeModal}
          background={background}
          ad={localAd}
          isVideo={isVideo}
          iconArrowLeft={iconArrowLeft}
          adFileName={filePath}
          fullFilePath={fullFilePath} 
        />
      )}
          <div
            className={`ad-modal-container ${isOpen ? 'active' : ''} ${isClosing ? "closing" : ""}`}
            ref={modalRef}
            style={{ "--modal-background": `url(${background})` }}
          >
          <div className="mt-1 close-modal-container">
            <button className="level-modal-close-button" onClick={handleCloseButtonClick}>
              <img src={iconArrowLeft} alt="<" />
              <p className="level-modal-close-text">
                {t("AdOfficePage.ModalBackButtonText")}
              </p>
            </button>
          </div>

            <div className="w-full flex justify-center items-center">
              <p className="modal-title">{t("AdOfficePage.AdModal.Title")}</p>
            </div>
            {localAd && (
              <>
                <div className="ad-modal-row">
                  <div className="modal-col w-2/6">
                    <p className="modal-text-start">
                      {t("AdOfficePage.AdModal.ModalTextType")}
                    </p>
                    <div className="modal-data-container">
                      <p className="modal-data-text">{adType || ""}</p>
                    </div>
                  </div>
                  <div className="modal-col w-1/5">
                    <p className="modal-text-start">
                      {t("AdOfficePage.AdModal.ModalTextAmountPerDay")}
                    </p>
                    <div className="modal-data-container">
                      <div className="modal-icon-container">
                        <img
                          src={iconCoin}
                          className="modal-icon-gtoken"
                          alt="gtoken"
                        />
                        <p className="modal-token">Token</p>
                      </div>
                      <div className="modal-text-container">
                      <p className="modal-data-text">{localAd.g_tokens_per_day || ""}</p>
                      </div>
                    </div> 
                  </div>
                  <div className="modal-col w-2/6">
                    <p className="modal-text-start">
                    {t("AdOfficePage.AdModal.ModalTextDuration")}
                    </p>
                    <div className="modal-data-container">
                      <p className="modal-data-text">{localAd.display_duration || ""}</p>
                    </div>
                  </div>
                </div>
                <div className="ad-modal-row2">
                  <div className="flex flex-col w-full">
                    <p className="modal-text-start">
                      {t("AdOfficePage.AdModal.ModalTextAdName")}
                    </p>
                    <div className="modal-data-container">
                      <p className="modal-data-text">{filePath || ""}</p>
                    </div>
                  </div>
                </div>
                <div className="ad-modal-row">
                  <div className="modal-col w-full">
                    <p className="modal-text-start">
                      {t("AdOfficePage.AdModal.ModalTextRelevantRegions")}
                    </p>
                    <div className="modal-data-container">
                      <p className="modal-data-text">
                        {localAd.relevant_regions && Array.isArray(localAd.relevant_regions) 
                          ? localAd.relevant_regions.join(", ")
                          : localAd.relevant_regions || ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ad-modal-row">
                  <div className="w-full flex justify-center items-center flex-row">
                    <p className={moderationStatus.className}>
                      {moderationStatus.text}
                    </p>
                    {moderationStatus.icon && (
                      <img
                        src={moderationStatus.icon}
                        alt={`Status: ${localAd.is_verified}`}
                        className="w-5 h-5 ml-2"
                      />
                    )}
                  </div>
                </div>
                <div className="ad-modal-row3">
                  <button
                    className="ad-modal-button"
                    onClick={handleWatchCreative}
                  >
                    {t("AdOfficePage.AdModal.ModalButton")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    };

    export default AdModal;




