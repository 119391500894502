import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import "../../assets/styles/games/game_core5.css";
import { useUser } from "../../contexts/UserContext";
import { API_URLS } from "../../config/config";
import { useAudio } from '../../contexts/AudioContext';
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
import TradeWarningModal from "../../components/TradeWarningModal";
import modalBackground from "../../assets/images/fairy-modal-background.png";
import soccerFieldImage from '../../assets/images/Games/footboll.webp';
import soccerBallImage from '../../assets/images/Games/boll.webp';
import gnom1 from '../../assets/images/Games/gmoms/gmom1.webp';
import gnom2 from '../../assets/images/Games/gmoms/gmom2.webp';
import gnom3 from '../../assets/images/Games/gmoms/gmom5.webp';
import gnom4 from '../../assets/images/Games/gmoms/gmom4.webp';
import gnom5 from '../../assets/images/Games/gmoms/gmom3.webp';
import gnom6 from '../../assets/images/Games/gmoms/gmom6.webp';
import gnomtake from '../../assets/images/Games/gmoms/gmomtaker.webp';
import { useTranslation } from "react-i18next";
import formatBalance from "../../utils/formatBalance";

function CoreGameFive() {
    const { user, updateUser } = useUser();
    const [ballPosition, setBallPosition] = useState({ x: 50, y: 10 });
    const [isKicking, setIsKicking] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [playerPositions, setPlayerPositions] = useState({
        line1: 0, line2: 0, line3: 0, line4: 0, line5: 0, line6: 0
    });
    const [showGoalkeeper, setShowGoalkeeper] = useState(false);
    const [gnomeSize, setGnomeSize] = useState(40);
    const wsRef = useRef(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const gameAreaRef = useRef(null);
    const [gameStarted, setGameStarted] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [gameResult, setGameResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const HORIZONTAL_PADDING = 30;
    const { playSound, playBallKikSound, playWinSound, playLoseSound } = useAudio();
    const [modalState, setModalState] = useState({
        isOpen: false,
        message: "",
        link: "",
    });

    const gnomeImages = {
        line1: gnom1,
        line2: gnom2,
        line3: gnom3,
        line4: gnom4,
        line5: gnom5,
        line6: gnom6,
    };

    const updateModalState = (message, isOpen = true, link = "") => {
        setModalState({ isOpen, message, link });
    };

    const connectWebSocket = useCallback(() => {
        try {
            const wsUrl = "wss://" + API_URLS.WEBS_GAME5_CONNECT;
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                return;
            }

            wsRef.current = new WebSocket(wsUrl);

            const timeoutId = setTimeout(() => {
                if (wsRef.current && wsRef.current.readyState === WebSocket.CONNECTING) {
                    wsRef.current.close();
                    setIsConnected(false);
                    console.error("WebSocket connection timeout");
                }
            }, 10000);

            wsRef.current.onopen = (event) => {
                clearTimeout(timeoutId);
                setIsConnected(true);
                console.log("WebSocket connected successfully");
            };

            wsRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === "players") {
                    const newPositions = {};
                    data.data.forEach(([line, position]) => {
                        newPositions[line] = position;
                    });
                    setPlayerPositions(newPositions);
                } else if (data.type === "goalkeeper") {
                    setShowGoalkeeper(data.data);
                }
            };

            wsRef.current.onclose = (event) => {
                setIsConnected(false);
                console.log("WebSocket disconnected. Attempting to reconnect...");
                setTimeout(connectWebSocket, 5000);
            };

            wsRef.current.onerror = (error) => {
                console.error("WebSocket error:", error);
            };
        } catch (error) {
            console.error("Error in connectWebSocket:", error);
        }
    }, []);

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [connectWebSocket]);

    const startGame = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(API_URLS.REACT_APP_GAME5_START, {
                tg_user_id: user.user_id
            });
            if (response.data.type === "ok") {
                setSessionId(response.data.session_id);
                setGameStarted(true);
                setGameResult(null);
            } else if(response.data.status === "error" || response.data.error_code === '551'){
                updateModalState(t("GamePage.NotEnoughBalance"), true);
            } else {
                console.error("Unexpected response:", response.data);
            }
        } catch (error) {
            console.error("Error starting game:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const endGame = async () => {
        try {
            const response = await axios.post(API_URLS.REACT_APP_GAME5_END, {
                tg_user_id: user.user_id,
                session_id: sessionId
            });
            if (response.data.type === "ok") {
                setGameResult(response.data.is_win);
                if (response.data.is_win) {
                    playWinSound();
                } else {
                    playLoseSound();
                }
                setGameStarted(false);
                setSessionId(null);
            }
        } catch (error) {
            console.error("Error ending game:", error);
        }
    };

    const handleKickOrStart = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!user || !user.user_id) {
            console.error("User information is missing");
            return;
        }
        if (!gameStarted) {
            await startGame();
            playSound();
        } else {
            setIsKicking(true);
            setBallPosition({ x: 50, y: 10 });
            playBallKikSound();
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send(JSON.stringify({ action: 'kick' }));
            }
        }
    };

    useEffect(() => {
        if (isKicking) {
            let animationFrameId;
            const animate = () => {
                setBallPosition(prev => {
                    const newPos = {
                        x: prev.x,
                        y: prev.y + 2
                    };
    
                    if (newPos.y >= 90) {
                        cancelAnimationFrame(animationFrameId);
                        setIsKicking(false);
                        endGame();
                        return prev;
                    }
    
                    animationFrameId = requestAnimationFrame(animate);
                    return newPos;
                });
            };
            animate();
    
            return () => cancelAnimationFrame(animationFrameId);
        }
    }, [isKicking]);

    const calculateBallSize = useMemo(() => {
        const initialSize = 20;
        const maxSize = 60;
        const size = initialSize + ((ballPosition.y / 100) * (maxSize - initialSize));
        return `${size}px`;
    }, [ballPosition.y]);

    const calculateGnomeSize = useCallback(() => {
        if (gameAreaRef.current) {
            const { width, height } = gameAreaRef.current.getBoundingClientRect();
            const minDimension = Math.min(width, height);
            return Math.max(20, Math.min(60, minDimension * 0.15));
        }
        return 40;
    }, []);

    const calculatePlayerPosition = useCallback((position) => {
        const adjustedPosition = ((position - 5) / 60) * 100;
        return Math.max(0, Math.min(100, (adjustedPosition * (screenWidth - (2 * HORIZONTAL_PADDING)) / screenWidth) + (HORIZONTAL_PADDING / screenWidth * 100)));
    }, [screenWidth, HORIZONTAL_PADDING]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setGnomeSize(calculateGnomeSize());
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [calculateGnomeSize]);

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const styles = useMemo(() => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100vw',
        },
        gameArea: {
            flex: 1,
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: '#4a8f29',
            padding: `0 ${HORIZONTAL_PADDING}px`,
        },
        backgroundImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${soccerFieldImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
        },
        ball: {
            width: calculateBallSize,
            height: calculateBallSize,
            position: 'absolute',
            left: `calc(${ballPosition.x}% - ${parseInt(calculateBallSize) / 2}px)`,
            bottom: `${ballPosition.y}%`,
            transition: 'none',
            zIndex: 10,
            transform: isIOS ? 'translateZ(0)' : 'none',
            willChange: 'transform',
        },
        player: {
            width: `${gnomeSize}px`,
            height: `${gnomeSize}px`,
            position: 'absolute',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transition: 'left 1s ease-out, bottom 1s ease-out',
        },
        goalkeeper: {
            width: `${gnomeSize * 1.2}px`,
            height: `${gnomeSize * 1.2}px`,
            position: 'absolute',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            left: '50%',
            bottom: '92%',
            transform: 'translateX(-50%)',
            transition: 'left 0.3s ease-out, bottom 0.3s ease-out',
        },
        controlPanel: {
            backgroundColor: '#f0f0f0',
            borderTop: '1px solid #ccc',
            padding: '10px',
        },
    }), [ballPosition, calculateBallSize, gnomeSize, HORIZONTAL_PADDING, isIOS]);

    const renderPlayers = useCallback(() => {
        const players = [];
        const lines = [
            { y: 80, players: ['line1', 'line2'] },
            { y: 67, players: ['line3', 'line4'] },
            { y: 55, players: ['line5', 'line6'] },
        ];

        lines.forEach(line => {
            line.players.forEach((playerKey) => {
                const xPosition = playerPositions[playerKey];
                if (xPosition !== undefined) {
                    const adjustedPosition = calculatePlayerPosition(xPosition);
                    players.push(
                        <div
                            key={playerKey}
                            style={{
                                ...styles.player,
                                left: `${adjustedPosition}%`,
                                bottom: `${line.y}%`,
                                backgroundImage: `url(${gnomeImages[playerKey]})`,
                            }}
                        />
                    );
                }
            });
        });

        if (showGoalkeeper) {
            players.push(
                <div
                    key="goalkeeper"
                    style={{
                        ...styles.goalkeeper,
                        backgroundImage: `url(${gnomtake})`,
                    }}
                />
            );
        }

        return players;
    }, [playerPositions, showGoalkeeper, styles.player, styles.goalkeeper, gnomeImages, calculatePlayerPosition]);

    const renderGameResult = () => {
        if (gameResult !== null) {
            return (
                <div className="game-result" style={{
                    position: 'absolute',
                    top: '20%',
                    left: '0',
                    right: '0',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0, 128, 0, 1)',
                    padding: '20px',
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 'clamp(24px, 5vw, 48px)',
                    fontWeight: 'bold',
                    color: 'white',
                    zIndex: 1000
                }}>
                    {gameResult ? t("GamePage.Game5.Win") : t("GamePage.Game5.Lose")}
                </div>
            );
        }
        return null;
    };

    return (
        <div style={styles.container}>
            <TradeWarningModal
                key={modalState.isOpen ? "open" : "closed"}
                modalState={modalState}
                background={modalBackground}
                onClose={() => updateModalState("", false)}
            />
            <div style={styles.gameArea} ref={gameAreaRef}>
                <div style={styles.backgroundImage} />
                <img 
                    src={soccerBallImage}
                    alt="Soccer Ball"
                    style={styles.ball}
                />
                {renderPlayers()}
                {renderGameResult()}
            </div>
            <div style={styles.controlPanel}>
                <div className="game-buttom-list-five">
                    <button
                        className="game-button"
                        onClick={handleKickOrStart}
                        onTouchStart={handleKickOrStart}
                        disabled={!isConnected || isKicking || isLoading}
                    >
                       {isLoading ? t("GamePage.Game5.Loading") : (gameStarted ? t("GamePage.Game5.Hit") : t("GamePage.Game5.StartGame"))}
                    </button>
                    <div className="earning-game">
                        <div className="earning-content-game">
                            <div className="earning-icon-container-game">
                                <IconCoin className="earning-icon-small-game" />
                                <p className="token-game">Token</p>
                            </div>
                            <div className="earning-text-container-game">
                                <p className="earning-text-game">{formatBalance(user.g_token)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CoreGameFive);