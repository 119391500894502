import React, { useRef, useEffect, useState } from "react";

const AnimatedTool = ({ isVisible, onAnimationEnd, position, Icon, showSparks }) => {
  const pickRef = useRef(null);
  const sparksContainerRef = useRef(null);
  const [isRightSide, setIsRightSide] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);

  useEffect(() => {
    const pick = pickRef.current;
    const sparksContainer = sparksContainerRef.current;
    if (!pick || !sparksContainer) return;

    setIsRightSide(position.x > window.innerWidth / 2);

    const animateHit = () => {
      return pick.animate(
        [
          { transform: `rotate(0deg) scaleX(${isRightSide ? -1 : 1})` },
          { transform: `rotate(${isRightSide ? '20deg' : '-20deg'}) scaleX(${isRightSide ? -1 : 1})` },
          { transform: `rotate(0deg) scaleX(${isRightSide ? -1 : 1})` },
        ],
        {
          duration: 400,
          iterations: 1,
          easing: "ease-out",
        }
      );
    };

    if (isVisible && !hasClicked) {
      setHasClicked(true);
    }

    if (isVisible && hasClicked) {
      const animation = animateHit();
      animation.onfinish = () => {
        if (showSparks) {
          createSparks(sparksContainer);
        }
        onAnimationEnd();
      };
    }
  }, [isVisible, onAnimationEnd, position, showSparks, isRightSide, hasClicked]);

    const createSparks = (container) => {
    container.innerHTML = "";
    const sparkCount = 20;
    for (let i = 0; i < sparkCount; i++) {
      const spark = document.createElement("div");
      spark.className = "spark";
      const size = Math.random() * 4 + 1;
      const animationDuration = Math.random() * 0.6 + 0.4;
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * 60 + 20;

      Object.assign(spark.style, {
        width: `${size}px`,
        height: `${size}px`,
        background: "orange",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        opacity: 0,
      });

      container.appendChild(spark);

      spark.animate(
        [
          { transform: "translate(-50%, -50%)", opacity: 1 },
          {
            transform: `translate(
              calc(-50% + ${Math.cos(angle) * distance}px), 
              calc(-50% + ${Math.sin(angle) * distance}px)
            )`,
            opacity: 0,
          },
        ],
        {
          duration: animationDuration * 1000,
          easing: "ease-out",
          fill: "forwards",
        }
      );
    }
  };

  return (
    <>
      <div
        className="pick-container"
        style={{
          position: 'absolute',
          left: `${position.x + (isRightSide ? -55 : 55)}px`,
          top: `${position.y - 15}px`,
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none',
          zIndex: 100,
          opacity: isVisible && hasClicked ? 1 : 0,
          transition: 'opacity 0.15s',
        }}
      >
        <div ref={pickRef}>
          <Icon width={"150px"} height={"150px"}/>
        </div>
      </div>
      <div
        ref={sparksContainerRef}
        style={{
          position: "absolute",
          left: `${position.x}px`,
          top: `${position.y}px`,
          width: "100px",
          height: "100px",
          transform: "translate(-50%, -50%)",
          pointerEvents: "none",
          zIndex: 101,
        }}
      />
    </>
  );
};

export default AnimatedTool;