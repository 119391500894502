import React, { useState } from 'react';
import axios from 'axios';
import '../../assets/styles/upgrade/UpgradeGameRoadProgress.css';
import '../../assets/images/upgrade/Road/3/0.png';
import LineImgSrc from '../../assets/images/upgrade/Road/line-img-road.png';
import close from '../../assets/images/upgrade/close.png';
import { useUser } from '../../contexts/UserContext';
import { API_URLS } from '../../config/config';

const UpgradeGameRoadProgress = ({ gameData, onRefresh }) => {
    const { user } = useUser();
    const [clickRoadId, setClickId] = useState(null);

    console.log("gameData" ,gameData);

    const handleRefresh = () => {
        if (onRefresh) {
            onRefresh();
        }
    };

    async function getUserCurrentStageInfo(userId, clickId) {
      console.log('=========== clickId: ', clickId);
      console.log('=========== userId: ', userId);
      console.log('=========== clickRoadId: ', clickRoadId);

        try {
            const gameResponse = await axios({
                method: 'POST',
                url: `${API_URLS.BUY_ROAD}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                },
                data: { userId, clickId }
            });
            console.log("BUY_ROAD", gameResponse);
            handleRefresh();
        } catch (error) {
            console.error('Error fetching user stage info:', error.message);
        }
    }

    const buttonUpdateRoad = (roadId) => {
        const userId = user.user_id;
        const clickId = roadId;
        setClickId(clickId);
        console.log("---------- roadId: ", roadId);
        console.log("---------- clickRoadId: ", clickRoadId); //null
        getUserCurrentStageInfo(userId, clickId);
    }

    const getBackgroundImage = (lvlId, photoId) => {
        try {
            return require(`../../assets/images/upgrade/Road/${lvlId}/${photoId}.png`);
        } catch (error) {
            console.error(`Error loading image for lvlId: ${lvlId}, photoId: ${photoId}`, error);
            return null;
        }
    };

    const parseJSONSafely = (jsonString) => {
        if (typeof jsonString !== 'string') {
            console.error('Invalid input: not a string', jsonString);
            return [];
        }
        if (jsonString.trim() === '[None]' || jsonString.trim() === '') {
            return [];
        }
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            console.error('Error parsing JSON:', error, 'Input was:', jsonString);
            return [];
        }
    };

    console.log('gameData:', gameData);
    console.log('user_road_setup:', gameData.user_road_setup);

    const allRoadIds = gameData.user_road_setup?.all_road_ids 
        ? parseJSONSafely(gameData.user_road_setup.all_road_ids)
        : [];
    const openedRoadIds = gameData.user_road_setup?.opened_road_ids 
        ? parseJSONSafely(gameData.user_road_setup.opened_road_ids)
        : [];

    console.log('Parsed allRoadIds:', allRoadIds);
    console.log('Parsed openedRoadIds:', openedRoadIds);

    if (!Array.isArray(allRoadIds) || allRoadIds.length === 0) {
        console.error('Invalid or empty allRoadIds:', allRoadIds);
        return <div>Error: No road data available</div>;
    }

    return (
        <div className='upgrade-game-roads-section'>
            <div className='upgrade-game-roads-lvl-section'>
                <p>Апгрейд Beta</p>
            </div>
            <div className='upgrade-game-roads-title-section'>
                <img src={LineImgSrc} className='line-ramka line-ramka-up' alt="Верхняя линия" />
                <p>обери правильну доріжку</p>
                <img src={LineImgSrc} className='line-ramka line-ramka-down' alt="Нижняя линия" />
            </div>

            <div className='upgrade-game-road-container'>
                {allRoadIds.map((roadId) => {
                    const backgroundImage = getBackgroundImage(gameData.user_road_setup.road_setup.id, roadId);
                    const isOpenedRoad = openedRoadIds.includes(roadId);
                    return (
                        <div key={roadId} className='upgrade-game-road-card'>
                            <div className='img-con'>
                                {backgroundImage && (
                                    <img 
                                        src={backgroundImage} 
                                        className='up-game-road-img'
                                        alt={`Дорога ${roadId}`}
                                    />
                                )}
                                {isOpenedRoad && (
                                    <div className='up-game-road-img-close-con'>
                                        <img src={close} className='up-game-road-img-close' alt="Закрыто" />
                                    </div>
                                )}
                            </div>
                            <span style={isOpenedRoad ? { color: '#F33030' } : {}}>
                                {`$${gameData.user_road_setup.road_setup.price}`}
                            </span>
                            <button 
                                onClick={() => buttonUpdateRoad(roadId)} 
                                className={isOpenedRoad ? 'button-close-road' : 'button-active-road'}
                            >
                                Відкрити дорогу
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default UpgradeGameRoadProgress;
