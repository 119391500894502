import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../assets/styles/upgrade/UpgradeGameRoadProgress.css';
import '../../assets/images/upgrade/Road/3/0.png';
import LineImgSrc from '../../assets/images/upgrade/Road/line-img-road.png';
import close from '../../assets/images/upgrade/close.png';
import { useUser } from '../../contexts/UserContext';
import { API_URLS } from '../../config/config';
import UpgradeGameRoadModal from './UpgradeGameRoadModal';
import { useTranslation } from 'react-i18next'; // Подключаем useTranslation для переводов

const UpgradeGameRoad = ({ gameData, rankData, userData, refreshData }) => {
    const { t } = useTranslation(); // Используем useTranslation для получения перевода
    const [clickRoadId, setClickId] = useState(null);
    const [status, setStatus] = useState(null); // Состояние для статуса
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRoadId, setSelectedRoadId] = useState(null);
    const [selectedRoadPrice, setSelectedRoadPrice] = useState(null); // Состояние для цены дороги

    useEffect(() => {
        console.log("gameDatagameDatagameDatagameDatagameDatagameData",gameData);
    }, [])

    const handleRefresh = () => {
        if (refreshData) {
            refreshData();
        }
    };

    const closeModal = () => {
        setTimeout(() => {
            setIsModalOpen(false);
            setSelectedRoadId(null);
            setSelectedRoadPrice(null); // Сбрасываем цену дороги
    
            if (handleRefresh) {
                handleRefresh(); // Обновляем данные после закрытия модалки
            }
        }, 500);
    };

    async function getUserCurrentStageInfo(userId, clickId) {
        console.log("clickId", clickId);
        try {
            const gameResponse = await axios({
                method: 'POST',
                url: `${API_URLS.BUY_ROAD}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                },
                data: { userId, clickId }
            });
            setStatus("Win");
        } catch (error) {
            if (error.response && error.response.status === 403) {
                const errorMessage = error.response.data.message;
                if (errorMessage.includes('Not enough gold coins')) {
                    setStatus('NoMoney');
                } else {
                    setStatus('Louse');
                }
            } else {
                setStatus('An unexpected error occurred.');
            }
        }
    }

    const buttonUpdateRoad = (roadId) => {
        const userId = userData.user_id;
        const clickId = roadId;
        const roadPrice = gameData.user_road_setup.road_setup.price; // Получаем цену дороги
        setClickId(clickId);
        setSelectedRoadId(roadId);
        setSelectedRoadPrice(roadPrice); // Сохраняем цену дороги
        setIsModalOpen(true);
        getUserCurrentStageInfo(userId, clickId);
    };

    const getBackgroundImage = (lvlId, photoId) => {
        try {
            return require(`../../assets/images/upgrade/Road/${lvlId}/${photoId}.png`);
        } catch (error) {
            return null;
        }
    };

    const parseJSONSafely = (jsonString) => {
        if (typeof jsonString !== 'string') {
            return [];
        }
        if (jsonString.trim() === '[None]' || jsonString.trim() === '') {
            return [];
        }
        try {
            return JSON.parse(jsonString);
        } catch {
            return [];
        }
    };

    const allRoadIds = gameData.user_road_setup?.all_road_ids
        ? parseJSONSafely(gameData.user_road_setup.all_road_ids)
        : [];
    const openedRoadIds = gameData.user_road_setup?.opened_road_ids
        ? parseJSONSafely(gameData.user_road_setup.opened_road_ids)
        : [];

    if (!Array.isArray(allRoadIds) || allRoadIds.length === 0) {
        return <div>Error: No road data available</div>;
    }

    return (
        <div className='upgrade-game-roads-section'>
            <div className='upgrade-game-roads-title-section'>
                <img src={LineImgSrc} className='line-ramка line-ramка-up' alt="Верхняя линия" />
                <p>{t("UpgradePage.RoadModal.Title")}</p>
                <img src={LineImgSrc} className='line-ramка line-ramка-down' alt="Нижняя линия" />
            </div>

            <div className='upgrade-game-road-container'>
                {allRoadIds.map((roadId) => {
                    const backgroundImage = getBackgroundImage(gameData.user_road_setup.road_setup.id, roadId);
                    const isOpenedRoad = openedRoadIds.includes(roadId); // Проверяем, открыта ли дорога

                    return (
                        <div key={roadId} className='upgrade-game-road-card'>
                            <div className='img-con'>
                                {backgroundImage && (
                                    <img
                                        src={backgroundImage}
                                        className='up-game-road-img'
                                        alt={`Дорога ${roadId}`}
                                    />
                                )}
                                {isOpenedRoad && (
                                    <div className='up-game-road-img-close-con'>
                                        <img src={close} className='up-game-road-img-close' alt="Закрыто" />
                                    </div>
                                )}
                            </div>
                            <span style={isOpenedRoad ? { color: '#F33030' } : {}}>
                                {`$${gameData.user_road_setup.road_setup.price}`}
                            </span>
                            <button
                                onClick={() => buttonUpdateRoad(roadId)}
                                className={isOpenedRoad ? 'block-btn-road' : 'button-active-road'} // Блокируем кнопку, если дорога открыта
                                disabled={isOpenedRoad}  // Делаем кнопку неактивной для открытых дорог
                            >
                                {isOpenedRoad ? t("UpgradePage.RoadModal.btnSt.passive") : t("UpgradePage.RoadModal.btnSt.active")}
                            </button>
                        </div>
                    );
                })}
            </div>

            {isModalOpen && (
                <UpgradeGameRoadModal
                    roadId={selectedRoadId}
                    price={selectedRoadPrice}  // Передаем цену
                    // status={"NoMoney"}  // Передаем статус
                    status={status}  // Передаем статус
                    closeModal={closeModal}
                    lvlId={gameData.user_road_setup.road_setup.id}


                    rankData={rankData} 
                    userData={userData}
                />
            )}
        </div>
    );
}

export default UpgradeGameRoad;
